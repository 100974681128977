<template>
  <section class="ph">
    <div class="" v-show="!showPay">
      <div class="form_card card-x" style="position: relative">
        <div class="title-x" style="margin-bottom: 10px; position: relative">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <div class="text_17">
            {{ $t("POINT_HISTORY.TITLE") }}
          </div>
          <el-alert
            style="
              text-align: left;
              margin: 0 0 0 0;
              padding: 5px 0;
              position: absolute;
              right: 0;
              bottom: 0;
              width: fit-content;
            "
            :title="`保有ポイント：${currentPoint} P`"
            type="warning"
            :closable="false"
            :show-icon="false"
          >
          </el-alert>
        </div>

        <div class="content__table">
          <div class="title-x" style="margin-bottom: 0px">
            <!-- <img
              class="image_2"
              src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
            />

            <div class="content__table-x flex-row">
              <div class="text_17" style="margin-bottom: 0px">
                {{ $t("POINT_HISTORY.CURRENT_POINT") }}
              </div>
            </div> -->
          </div>

          <el-alert
            style="text-align: left; margin: 20px 0 0px 0; padding: 5px 0"
            :title="'1ポイント＝1円（1000 P単位）'"
            type="warning"
            :closable="false"
            :show-icon="false"
          >
          </el-alert>

          <div class="option d-flex justify-content-between">
            <label class="radio-layout">
              <input
                v-model="charge_price"
                @click="charge_price_input = ''"
                type="radio"
                name="group1"
                value="5000"
              />
              <div class="btn-radio-layout">5000 P</div>
            </label>
            <label class="radio-layout">
              <input
                v-model="charge_price"
                @click="charge_price_input = ''"
                type="radio"
                name="group1"
                value="10000"
              />
              <div class="btn-radio-layout">10000 P</div>
            </label>
            <label class="radio-layout">
              <input
                v-model="charge_price"
                @click="charge_price_input = ''"
                type="radio"
                name="group1"
                value="20000"
              />
              <div class="btn-radio-layout">20000 P</div>
            </label>
            <input
              v-model="charge_price_input"
              @click="enter_yourself"
              class="radio-layout input_text"
              type="number"
              min="5000"
              max="100000"
              :placeholder="$t('POINT_HISTORY.ENTER_YOURSELF')"
            />
            <!-- <div class="radio-layout">P</div> -->
          </div>
          <div class="content__table"></div>
        </div>
        <b-button
          @click="charge()"
          class="btn-secondary content__card__form-submit__button mt-3"
          size="lg"
        >
          {{ $t("POINT_HISTORY.SUBMIT") }}
        </b-button>

        <div
          class="border border-2 p-2 bg-primary"
          style="margin-top: 20px; text-align: left"
        >
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <p style="margin: 0; width: 98%; line-height: 1.5">
                  {{ $t("POINT_HISTORY.NOTICE.TEXT4") }}
                </p>
                <!-- <p style="margin: 0;">
                    {{ $t("POINT_HISTORY.NOTICE.TEXT2") }}（{{ $t("POINT_HISTORY.NOTICE.TEXT3") }}）
                  </p> -->
              </template>
              <!-- <p>{{ $t("POINT_HISTORY.NOTICE.TEXT4") }}</p> -->
              <p>{{ $t("POINT_HISTORY.NOTICE.TEXT5") }}</p>
              <div class="important">
                {{ $t("POINT_HISTORY.NOTICE.IMPORTANT1") }}
                <span class="important_highlight">
                  <a href="#/details/vip/section5" class="important_highlight">
                    <p>{{ $t("POINT_HISTORY.NOTICE.HIGHLIGHT1") }}</p>
                  </a></span
                >
                {{ $t("POINT_HISTORY.NOTICE.IMPORTANT2") }}
                <span class="important_highlight">
                  <a href="#/details/sim/section5" class="important_highlight">
                    <p>{{ $t("POINT_HISTORY.NOTICE.HIGHLIGHT2") }}</p>
                  </a></span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="form_card card-x">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <div class="text_17">
            {{ $t("POINT_HISTORY.TABLE_TITLE") }}
          </div>

          <van-notice-bar
            mode="link"
            left-icon=""
            background="#fdf6ec"
            style="
              margin-top: 20px;
              margin-bottom: -1vw;
              color: #e6a23c;
              border-radius: 8px;
            "
            @click="$router.push('/details/vip/section5')"
          >
            <i class="iconfont icon-tishi"></i>
            {{ $t("POINT_HISTORY.MESSAGE") }}
          </van-notice-bar>
          <!-- <a class="thumbnail_4" href="#/mypage?view=1">{{
            $t("POINT_HISTORY.MESSAGE")
          }}</a> -->
        </div>
        <div class="wrapper">
          <div class="body">
            <div class="">
              <table class="responsive">
                <thead>
                  <tr>
                    <th v-for="(value, key) in fields" :key="key">
                      {{ value.label }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in lists" :key="key">
                    <td :data-label="fields[0].label">{{ value.dateTime }}</td>
                    <td :data-label="fields[1].label">
                      {{ value.pointTypeText }}
                    </td>
                    <td :data-label="fields[2].label">
                      {{ value.pointOperate }}
                    </td>
                    <td :data-label="fields[3].label">
                      {{ value.statusText }}
                    </td>
                    <td :data-label="fields[4].label">{{ value.operator }}</td>
                    <td :data-label="fields[5].label">
                      {{ value.depictText }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="records-list">
              <b-row class="justify-content-md-center">
                <b-col md="10">
                  <b-row>
                    <b-col>
                      <b-row class="pag-num">
                        <b-col
                          ><label for="select-show">
                            {{ $t("PAGINATION.NUMBER_RECORD") }}
                          </label></b-col
                        >
                        <b-col>
                          <BFormSelect
                            id="select-show"
                            name="select-show"
                            v-model="perPage"
                            class="display"
                          >
                            <option :value="5">5</option>
                            <option :value="10">10</option>
                            <option :value="15">15</option>
                          </BFormSelect>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <BPagination
                        :total-rows="totalRows"
                        v-model="currentPage"
                        :per-page="perPage"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Change
      v-if="showPay"
      @return-contract="returnContract"
      :dataChange="dataChange"
    ></Change>
  </section>
</template>

<script>
import { mounted } from "qrcode.vue";
import Change from "../Change/index.vue";
export default {
  name: "App",
  components: { Change },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: "dateTime",
          label: this.$t("POINT_HISTORY.DATETIME"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "pointTypeText",
          label: this.$t("POINT_HISTORY.DIFFERENTIATION"),
          sortable: true,
          class: "text-center ",
        },
        {
          key: "operator",
          label: this.$t("POINT_HISTORY.OPERATOR"),
          sortable: true,
          image: true,
        },
        {
          key: "status",
          label: this.$t("POINT_HISTORY.STATUS"),
          sortable: true,
        },
        {
          key: "pointOperate",
          label: this.$t("POINT_HISTORY.POINTOPERATE"),
          sortable: true,
        },
        {
          key: "depict",
          label: this.$t("POINT_HISTORY.DEPICT"),
          sortable: true,
        },
      ],
      result: [],
      currentPoint: 0,
      charge_price: 0,
      charge_price_input: "",
      alert: false,
      showPay: false,
      dataChange: {},
    };
  },
  watch: {
    perPage() {
      this.currentPage = 1;
    },
    result() {
      return this.result;
    },
    charge_price_input() {
      if (this.charge_price_input > 100000) {
        this.charge_price_input = this.charge_price_input.slice(0, 5);
      }
      this.charge_price = this.charge_price_input;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      if (this.$CONST.DEV) {
        console.log("Debug API: pointHistory/init");
        this.result = this.$CONST.COMMON.API_HISTORY_POINT.Response.result;
      } else {
        this.$api
          .post("pointHistory/pagination", {
            languageCode: window.localStorage.getItem("languageCode"),
          })
          .then((response) => {
            this.result = response.result;
          });
        this.$api.get("pointCharge/showPoint").then((response) => {
          this.currentPoint = response.result.userPoint;
        });
      }
    },
    charge() {
      if (this.charge_price < 5000) {
        this.alert = true;
      } else {
        this.alert = false;
        this.dataChange.change = {
          changePrice: this.charge_price,
          id: "",
          orderType: 5,
          revenueSummary: this.$CONST.PAYMENT.REVENUE_SUMMARY.CHARGE_POINT,
        };
        this.showPay = true;
      }
    },
    enter_yourself() {
      if (this.charge_price_input == "") {
        this.charge_price = 0;
      }
    },
    returnContract() {
      this.showPay = false;
    },
  },
  computed: {
    lists() {
      const items = this.result;
      // Return just page of items needed
      return items?.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.result.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";
@import "./index.px.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
