<template>
  <div>
    <vue-confirm-dialog></vue-confirm-dialog>
    <ul class="new_menu change">
      <div @click="$router.push('/home')" v-show="!changeHeader" class="new_menu__name"><img
          src="@/assets/white_logo2.png"></div>
      <div @click="$router.push('/home')" v-show="changeHeader" class="new_menu__name"><img
          src="@/assets/white_logo2.png"></div>
      <div @click="$router.push('/home')" class="new_menu__icon">
        <b-icon icon="house-fill" font-scale="2.5"></b-icon>
      </div>

      <li class="new_menu__item" v-for="(item, index) in newMenuItem" :key="index">
        <a v-bind:class="{ 'main': item.type == 1 }" @mouseenter="goChild(item.number, item, $event)"
          @mouseleave="step = null" @click="goChild(item.number, item, $event)" class="nav__link">
          <span class="">{{ item.name }} </span>
          <div class="new_menu__item--child" v-if="item.number == step && item.children.length > 0">
            <p v-for="(itemChild, indexChild) in item.children" @click="goRouter()" :key="indexChild">{{ itemChild.name }}</p>
          </div>
        </a>
      </li>
      <div class="new_menu__cart">
        <li class="new_menu__item layout-cart">
          <a class="nav__link">
            <span @click="showLang = !showLang" class="">{{ languageItem[indexLanguage].name }}</span>
            <div class="new_menu__item--child lang-top" v-if="showLang">
              <template v-for="(itemLang, indexLang) in languageItem">
                <p v-if="indexLang != indexLanguage" :key="indexLang" @click="changeLang(itemLang.value, indexLang)">
                  {{ itemLang.name }}</p>
              </template>
            </div>
          </a>
        </li>
      </div>
      <li class="new_menu__item mobile" hidden>
        <a class="main" @click="goChild(newMenuItem[4].number, newMenuItem[4], $event)">
          <span class="">{{ newMenuItem[4].name }} </span>
        </a>
      </li>
      <div class="new_menu__menu mobile" hidden>
        <b-icon v-b-toggle.my-collapse icon="list" @click="list = false" v-if="list" font-scale="2.5"></b-icon>
        <b-icon v-b-toggle.my-collapse icon="x" @click="list = true" v-else font-scale="2.5"></b-icon>
        <b-collapse class="new_menu__menu__container" id="my-collapse">
          <!-- <b-icon class="new_menu__menu__container__close" v-b-toggle.my-collapse icon="x-lg" font-scale="2"></b-icon> -->
          <li class="new_menu__menu__container__item" v-for="(item, index) in newMenuItem" v-show="item.type != 1"
            :key="index">
            <a @click="goChild(item.number, item, $event)">
              <span class="">{{ item.name }} </span>
              <div class="mb-child" v-if="item.number == step && item.children.length > 0">
                <p v-for="(itemChild, indexChild) in item.children" :key="indexChild">{{ itemChild.name }}</p>
              </div>
            </a>
            <div class="hoverAni"></div>
          </li>
          <li class="new_menu__menu__container__item">
            <a @click="goChild(item.number, item, $event)">
              <span @click="showLang = !showLang" class="">{{ languageItem[indexLanguage].name }}</span>
              <div class="mb-child" v-if="showLang">
                <template v-for="(itemLang, indexLang) in languageItem">
                  <p v-if="indexLang != indexLanguage" :key="indexLang" @click="changeLang(itemLang.value, indexLang)">
                    {{ itemLang.name }}</p>
                </template>
              </div>
            </a>
            <div class="hoverAni"></div>
          </li>
        </b-collapse>
      </div>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: true,
      step: null,
      main_item: [
        {
          name: this.$t("HEADER.HD1"),
          value: 9,
          url: "/register",
          type: 1,
          number: 9,
          children: [],
        },
        {
          name: this.$t("MENU.MYPAGE"),
          value: 2,
          url: "/mypage",
          type: 1,
          number: 10,
          children: [],
        },
      ],
      changeHeader: true,
      activeItem: 0,
      newMenuItem: [
        {
          name: this.$t("MENU.SIM_SERVICE"),
          value: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY,
          url: "/Order",
          type: 0,
          number: 0,
          children: [
            {
              name: this.$t("HEADER.HD2"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD3"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD4"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD5"),
              value: '',
              url: '',
              number: 0,
            }
          ]
        },
        {
          name: this.$t("MENU.WIFI_SERVICE"),
          value: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY,
          url: "/Order",
          number: 1,
          type: 0,
          children: [
            {
              name: this.$t("HEADER.HD6"),
              value: '11',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD7"),
              value: '006',
              url: '/Order',
              number: 0,
            }
          ]
        },
        {
          name: this.$t("MENU.OTP_SERVICE"),
          value: this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY,
          url: "/Order",
          type: 0,
          number: 2,
          children: [
            {
              name: this.$t("HEADER.HD8"),
              value: '',
              url: '',
              number: 0,
            },
          ]
        },

        {
          name: this.$t("MENU.TERMINAL"),
          value: '',
          url: "/Orde1r",
          type: 0,
          number: 3,
        },

        {
          name: this.$t("MENU.MYPAGE"),
          value: 2,
          url: "/mypage",
          type: 1,
          number: 4,
          children: [],
        },
        {
          name: this.$t("FOOTER.TITLE2.LINK1"),
          value: 4,
          url: "",
          type: 0,
          number: 5,
          children: [
            {
              name: this.$t("HEADER.HD9"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD10"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD11"),
              value: '',
              url: '',
              number: 0,
            },
          ],
        },
        {
          name: this.$t("FOOTER.TITLE3.TITLE"),
          value: 5,
          url: "/",
          type: 0,
          number: 6,
          children: [
            {
              name: this.$t("HEADER.HD12"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("FOOTER.TITLE3.LINK2"),
              value: '',
              url: '',
              number: 0,
            },
            {
              name: this.$t("HEADER.HD13"),
              value: '',
              url: '',
              number: 0,
            },
          ],
        },
        {
          name: this.$t("MENU.INVESTIGATION"),
          value: 6,
          url: "/inquiry",
          type: 0,
          number: 7,
          children: [],
        },
        {
          name: this.$t("BUTTON.LOGOUT"),
          value: 7,
          url: "/login",
          type: 0,
          number: 8,
          children: [],
        },
        {
          name: this.$t("BUTTON.LOGIN"),
          value: 8,
          url: "/login",
          type: 0,
          number: 8,
          children: [],
        },
      ],
      indexLanguage: 0,
      languageItem: [
        {
          name: this.$t("HEADER.HD14"),
          value: 'ja',
        },
        {
          name: this.$t("HEADER.HD15"),
          value: 'cn',
        },
        {
          name: this.$t("HEADER.HD16"),
          value: 'en',
        },
        {
          name: this.$t("HEADER.HD17"),
          value: 'vi',
        }
      ],
      showLang: false
    };
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  created() {
    if (this.$localStorage.get("menuItemHeader")) {
      this.activeItem = this.$localStorage.get("menuItemHeader");
    }
    this.checkLogin();
    for (const key in this.languageItem) {
        if(this.languageItem[key].value == this.$localStorage.get("language")){
          this.indexLanguage = key;
        }
    }
  },
  computed: {},
  methods: {
    changeLang(value,index) {
      this.indexLanguage = index;
      this.showLang = false;
      this.$localStorage.set("language", value);
      location.reload();
    },
    goChild(number, item, event) {
      if (this.step != number) {
        this.step = number;
      } else {
        this.step = null;
      }
      if (event.type == 'click') {
        if (item.children.length == 0) {
          this.goRouter(item)
        }
      }
    },
    checkLogin: function () {
      if (this.$localStorage.get("token")) {
        this.newMenuItem[4] = this.main_item[1]
        this.newMenuItem.splice(9, 1);
      } else {
        this.newMenuItem[4] = this.main_item[0]
        this.newMenuItem.splice(8, 1);
      }
    },
    goRouter: function (value, e) {
      let page = this.$localStorage.get("menuItemHeader")
      if (page == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY ||
        page == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY ||
        page == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY) {
        if (value.value != page && value.value == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY ||
          value.value != page && value.value == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY ||
          value.value != page && value.value == this.$CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY) {
          this.$localStorage.remove("Answer");
        }
      }
      if (value.value === 7) {
        this.$confirm(
          {
            message: this.$t("HEADER.MESSAGE"),
            button: {
              no: this.$t("BUTTON.CANCEL"),
              yes: this.$t("HEADER.YES")
            },
            /**
            * Callback Function
            * @param {Boolean} confirm
            */
            callback: confirm => {
              if (confirm) {
                this.logOut();
              }
            }
          }
        )
      }
      else {
        this.$localStorage.set("menuItemHeader", value.value);
        this.activeItem = value.value;
        this.$router.push({
          path: value.url,
        });
        if(value.value == 2) {
          location.reload();
        }
      }
    },
    logOut: function () {
      var token = localStorage.getItem('token');
      if (token) {
        if (this.$CONST.DEV) {
          console.log('Debug API: userLogin/login');
          localStorage.removeItem('token');
          localStorage.removeItem('accountBy');
          localStorage.removeItem('name');
          localStorage.removeItem('nameKana');
          localStorage.removeItem('roleId');
          localStorage.removeItem('userId');
          localStorage.removeItem('points');
          localStorage.removeItem('email');
          localStorage.removeItem('userIdCode');
          localStorage.removeItem('registerUrl');
          sessionStorage.setItem("loginComplete", 1);
        }
        else {
          this.$api.post("userLogin/logout")
            .then((response) => {
              localStorage.removeItem('token');
              localStorage.removeItem('accountBy');
              localStorage.removeItem('name');
              localStorage.removeItem('nameKana');
              localStorage.removeItem('roleId');
              localStorage.removeItem('userId');
              localStorage.removeItem('email');
              localStorage.removeItem('userIdCode');
              localStorage.removeItem('registerUrl');
              sessionStorage.removeItem("loginComplete");
              sessionStorage.removeItem("clickToLogin");
              this.$router.push('/login');
            });
        }
      }
    },
    // Toggle if navigation is shown or hidden
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      this.changeHeader = window.pageYOffset < 0
    },
  },
};
</script>
REGISTER
<style lang="scss" scoped>
.change {
  background-color: black;
  transition: height 1s, background-color 1s, transform 1s;
  // transition: background-color 1000ms linear;
  // transition-timing-function: linear;
  height: 80px !important;

  .new_menu__item,
  .new_menu__name,
  .new_menu__menu {

    color: white;

    a {

      color: white;
    }
  }
}

.new_menu {
  &__item {
    position: relative;

    .nav__link {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &--child {
      position: absolute;
      top: 75px;
      background-color: #000;
      width: 150px;
      padding: 10px;
      text-align: left;
      transform: translateX(-50%);
      left: 50%;
      border-radius: 2px;

      p {
        color: #ffff;
        padding: 0px;
        margin: 5px;
      }
    }
  }
}

.hoverAni {
  width: 0;
  height: 2px;
  background-color: black;
  transition: width 1s;
}

.lang-top {
  top: 47px !important;
}

.layout-cart {
  flex-direction: row !important;
}

.new_menu {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 30px;
  align-content: center;
  height: 110px;
  transition: height 0.5s, background-color 0.5s, transform 0.5s;
  color: black;

  font-size: 18px;

  &__name {
    position: fixed;
    align-self: center;
    left: 55px;
    cursor: pointer;

    img {
      width: 250px;
      margin-left: -70%;
    }

  }

  &__cart {
    position: fixed;
    align-self: center;
    right: 55px;
    cursor: pointer;

    img {
      width: 100px;
      margin-top: -15px;
    }
  }

  &__name:hover {
    color: var(--success) !important;
  }

  &__icon {
    display: none;
    position: fixed;
    align-self: center;
    left: 55px;
    cursor: pointer;
  }

  &__item {
    min-width: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    :hover {
      color: var(--success) !important;
    }

    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
    }

    .main {
      font-size: 40px;
      font-weight: 600;
      margin: 0 20px;
    }

    :hover+.hoverAni {
      width: 100%;
      transition: width 1s;
    }
  }

  &__menu {
    position: fixed;
    align-self: center;
    right: 55px;
    cursor: pointer;

    &__container {
      position: fixed;
      width: 100%;
      top: 74px;
      right: 0;
      background: rgb(0, 0, 0);
      display: flex;
      color: white;
      flex-direction: column;
      align-items: center;

      &__item {
        width: 100%;
        padding: 10px 0px;
        border-bottom: 2px solid gray;

        .mb-child {
          background-color: rgb(52 52 52);
          padding: 10px 10px 5px;

          p {
            color: #fff;
            margin-bottom: 10px;
          }
        }
      }

      :hover {
        color: var(--success) !important;
      }

      &__close {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
  }
}

@media (max-width: 1620px) {
  .change {
    height: 75px !important;
  }

  .new_menu {
    height: 90px;
    column-gap: 10px;
    font-size: 16px;

    .main {
      font-size: 30px;
      font-weight: 600;

    }

    &__name {
      left: 30px;
    }
  }
}

@media (max-width: 1310px) {
  .mobile {
    display: block !important;
    margin: auto;
  }

  .b-icon:hover {
    color: var(--success) !important;
  }

  .layout-cart {
    display: block !important;
  }

  .new_menu {
    color: white;
    background-color: black;

    &__name {
      img {
        content: url("@/assets/white_logo2.png");
      }
    }

    &__cart {
      right: 110px;
    }

    &__item {
      display: none;

      a {
        color: white;
      }
    }

  }
}

@media (max-width: 700px) {
  .layout-cart a:first-child {
    display: none;
  }
}

@media (max-width: 550px) {
  .new_menu {
    &__name {
      left: 15px;
    }

    &__menu {
      right: 15px;
    }
  }
}

@media (max-width: 450px) {
  .new_menu {
    &__name {
      display: none;
    }

    &__icon {
      display: block;
      left: 15px;
    }

    &__cart {
      right: 40px;
    }
  }
}
</style>
