export default {
  VIEWS_DEATAILS_SIM_SECTION1: {
    TEXT_0: "SIM",
    TEXT_1: "IF SIMの特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "MNP",
    TEXT_4: "初期設定",
    TEXT_5: "支払方法",
    TEXT_6: "IF Mobile",
    TEXT_7: "今すぐ申込み",
    TEXT_8: "他のプロバイダ ユーザーはこちら",
    TEXT_9: "MNP",
    TEXT_10: "今の電話番号のままIF Mobileに変更すると毎月の",
    TEXT_11: "携帯電話代が安くなる",
    TEXT_12:
      "MNP=携帯電話番号ポータビリティ（Mobile Number Portability）とは、携帯電話会社を変更した場合に、電話番号はそのままで、変更後の携帯電話会社のサービスをご利用できる制度です",
    TEXT_13: "IF 音声SIMのココがすごい！",
    TEXT_14: "手続きが簡単",
    TEXT_15: "身分証明書があれば,1分でインターネットで簡単にお申込みできます。",
    TEXT_16: "クレジットカードや銀行口座がなくても申込めます",
    TEXT_17:
      "コンビニエンスストア、Paypayなど豊富なお支払い方法が選択できます。クレジットカードを持っていない人や銀行口座・印鑑がない方も気軽にお申込みできます。",
    TEXT_18: "MNPなら、1GBプラン料金は無料",
    TEXT_19:
      "他社から乗換え(MNP)の場合は、最初2ヶ月は50GB無料、最初半年間1GBプラン料金は無料で利用可能です",
    TEXT_20: "低価格、豊富な料金プラン、NTTドコモ回線・au回線 両方対応",
    TEXT_21:
      "データ無制限利用！使いたいデータ量に合わせて、高速データ通信容量の1GBから50GBを自由に選べる！さらにNTTドコモ回線・au回線をお選びいただけます。",
    TEXT_22: "今のスマホをそのまま使い、すぐ利用できます。",
    TEXT_23:
      "お手持ちの端末がほぼ対応できるので、お申し込み後に届くSIMカードを、ご使用のスマホに挿入して設定するだけで、完了です。",
    TEXT_24: "無料の着信とテキスト",
    TEXT_25:
      "日本の電話番号090/080/070は、低コストの通話とテキストの恩恵を受けています(着信とテキストは無料です)。",
    TEXT_26: "モバイルホットスポット利用無料",
    TEXT_27:
      "デバイスがテザリング/ホットスポットの使用をサポートしている場合は、他のデバイスに簡単にテザリングできます。",
    TEXT_28: "多言語サポート",
    TEXT_29: "提供多語言客服支援",
    TEXT_30:
      "英語・ベトナム語・中国語・日本語の各国のスタッフによる手厚いサポートです",
    TEXT_31: "ご購入・お申し込みはこちら",
    TEXT_32: "ご利用までの流れ",
    TEXT_33: "01",
    TEXT_34: "インターネットで申込",
    TEXT_35:
      "オンラインでお申し込みいただき、身分証明書の写真をアップしていただきます。",
    TEXT_36: "身分証明書: パスポート 免許証 在留カード",
    TEXT_37: "02",
    TEXT_38: "審査",
    TEXT_39: "初期費用のお支払い後、弊社でお客様情報の審査を行います。",
    TEXT_40: "初期費用は、契約事務手数料と本月の月額基本料金です。",
    TEXT_41: "03",
    TEXT_42: "発送",
    TEXT_43: "審査完了後、SIMカードを発送致します。最短当日に発送します。",
    TEXT_44: "04",
    TEXT_45: "利用開始",
    TEXT_46:
      "お客様ご自身で簡単な初期設定をしていただいた後、すぐにご利用いただけます。",
    TEXT_47: "初期設定",
    TEXT_48: "ご購入・お申し込みはこちら",
    TEXT_49: " 支払い方法",
    TEXT_50: "詳細",
    data: {
      loopData0: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPng91e16baaef9e83197e7b055eb593c573585df8c061fb5679c481a9688697a103.png"),
          lanhutext0: "審査簡単",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPnge21e1191fbf164f8d68108b380a050b18d89d48d86aa59e53363677e24753f7a.png"),
          lanhutext0: "コンビニなどで支払い可能",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngd1a6c44a189601fcc1778e18f3f37b3e9075171375ca011d68c315e192c1676c.png"),
          lanhutext0: "MNP限定、1GBプラン無料",
        },
      ],
      loopData1: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: "特徴",
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "ご利用までの流れ",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "お支払方法",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      loopData2: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPngecd35c4bc14848bb548a68fae3f29164bc2d5fba52b156b33ab6b5d2ebe527a7.png"),
          lanhutext0: "コンビニエンスストア",
          lanhuurl0: "/details/sim/section5?id=collapse-0",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng29c465066a0aad4eb155407b76573b0e785937da026a6044a2f4e5d8384f0040.png"),
          lanhutext0: "口座振替",
          lanhuurl0: "/details/sim/section5?id=collapse-2",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngb12cb6398fd075a3ba1fca07fa5a55f40e949d72bfb390a874823e28e2e3d5ad.png"),
          lanhutext0: "現金",
          lanhuurl0: "/details/sim/section5?id=collapse-6",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng7af4eff94602901a23bf4f6032c76e24ef4b50580c0f65b47447632c135f0d7c.png"),
          lanhutext0: "IFポイント",
          lanhuurl0: "/details/sim/section5?id=collapse-5",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPngf392797002f3bb01e990e481843863fec01c6b00cd9fa07cacccd9a29bff40b2.png"),
          lanhutext0: "クレジットカード",
          lanhuurl0: "/details/sim/section5?id=collapse-1",
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng4370893381033e06e12c34857364205b872b90756c0d27172a27570e5d72e882.png"),
          lanhutext0: "バーコード決済",
          lanhuurl0: "/details/sim/section5?id=collapse-4",
        },
      ],
      constants: {},
    },
  },

  VIEWS_DEATAILS_SIM_SECTION2: {
    TEXT_0: "IF SIMの特徴",
    TEXT_1: "料金・プラン",
    TEXT_2: "MNP",
    TEXT_3: "初期設定",
    TEXT_4: "支払方法",
    TEXT_5: "毎⽉",
    TEXT_6: "の",
    TEXT_7: "暮",
    TEXT_8: "ら",
    TEXT_9: "し",
    TEXT_10: "に",
    TEXT_11: "合",
    TEXT_12: "わせ",
    TEXT_13: "て",
    TEXT_14: "、",
    TEXT_15: "データ",
    TEXT_16: "容量",
    TEXT_17: "を",
    TEXT_18: "選",
    TEXT_19: "べ",
    TEXT_20: "ま",
    TEXT_21: "す",
    TEXT_22: "。",
    TEXT_23: "⇔",
    TEXT_24: "♪",
    TEXT_25: "テレワークだから、",
    TEXT_26: "少なめで",
    TEXT_27: "♪",
    TEXT_28: "10GB",
    TEXT_29: "税込2508円",
    TEXT_30: "20GB",
    TEXT_31: "税込3608円",
    TEXT_32: "5GB",
    TEXT_33: "税込1958円",
    TEXT_34: "月額料金（音声SIM）",
    TEXT_35: "あなたにぴったりのプランが見つかる",
    TEXT_36: "音声通話機能付きSIM",
    TEXT_37: "音声通話",
    TEXT_38: "データ通信",
    TEXT_39: "SMS",
    TEXT_40: "通話定額オプション",
    TEXT_41: "選べる通話オプション",
    TEXT_42: " かけ放題で通話も安心！              ",
    TEXT_43: "かけ放題プランを自由に選べる！",
    TEXT_44: "５分かけ放題",
    TEXT_45: "１回５分以内の国内通話無料",
    TEXT_46: "500円",
    TEXT_47: "（税込550円）",
    TEXT_48: "10分かけ放題",
    TEXT_49: "１回10分以内の国内通話無料",
    TEXT_50: "950円",
    TEXT_51: "（税込1045円）",
    TEXT_52: "FULLかけ放題",
    TEXT_53: "無制限で国内通話無料",
    TEXT_54: "2700円",
    TEXT_55: "（税込2970円）",
    TEXT_56: "初期費用",
    TEXT_57: "登録事務手数料",
    TEXT_58: "3000円（税込3300円）",
    TEXT_59: "SIMカード発行料",
    TEXT_60: "2000円（税込2200円）",
    TEXT_61: "基本仕様",
    TEXT_62: "SIMカード",
    TEXT_63: "一人あたり契約可能枚数５枚。容量は無制限です。",
    TEXT_64:
      "【DOCOMO回線の場合】NTTドコモのLTE(5G)網及び4G網に対応したSIMカードを提供します。",
    TEXT_65: "【AU回線の場合】AUの5G LTE網に対応したSIMカードを提供します。",
    TEXT_66: "データ容量",
    TEXT_67:
      "1GBプラン/3GBプラン/5GBプラン/10GBプラン/15GBプラン/20GBプラン/50GBプラン",
    TEXT_68: "・有効期限はデータ容量が付与された月の月末までです。",
    TEXT_69: "・利用開始月の料金は日割り計算を行わず、1ヵ月分満額発生します。",
    TEXT_70: "・月額基本料金は当月分が請求されます。",
    TEXT_71: "通信速度",
    TEXT_72: "高速モード",
    TEXT_73: "【DOCOMO回線】下り最大1288Mbps、上り最大131.3Mbps",
    TEXT_74: "【AU回線】下り最大958Mbps上り最大112.5Mbps",
    TEXT_75:
      "【DOCOMO回線】NTTドコモの5G（LTE）網/4G網エリア内の対応エリア、対応機種により、理論上の最大速度は異なります。また、5G（LTE）網エリア外の場合は、4G網エリアでの最大速度によるデータ通信が可能です。",
    TEXT_76:
      "【AU回線】AUの4G LTE網エリア内の対応エリア、対応機種により、理論上の最大速度は異なります。",
    TEXT_77: "低速モード",
    TEXT_78: "最大300kbps",
    TEXT_79: "契約プランのデータ容量を超えた場合、低速モードになります。",
    TEXT_80: "通信規制対象となるデータ量",
    TEXT_81: "高速モード",
    TEXT_82: "3日あたり5GB",
    TEXT_83:
      "3日あたりの通信量が左記の規定値を超えた場合、当該SIMカードを使った通信の速度を制限する場合があります。当日深夜0時以降に速度制限が解除されます。または、追加購入でデータチャージされると即時速度制限が解除されます。",
    TEXT_84: "付加機能",
    TEXT_85: "データチャージ",
    TEXT_86: "500円/1GB（税込550円/1GB）",
    TEXT_87:
      "・有効期限は、ご購入いただいた当月末日となります。残ったデータ量は翌月に繰り越せません。",
    TEXT_88: "・データ量の追加はマイページより、ご利用できます。",
    TEXT_88_1: "マイページ",
    TEXT_89: "データプラン変更",
    TEXT_90: "可能",
    TEXT_91: "・変更受付期間は毎月1日～14日です。",
    TEXT_92: "・当日変更で翌月1日より適用となります。",
    TEXT_93: "・プラン変更の申し込みは、1ヵ月に1回に限ります。",
    TEXT_94: "・プランの変更はマイページより、ご利用できます。",
    TEXT_95: "手数料",
    TEXT_96:
      "SIMカード再発行、SIMカード交換、SIMカードタイプ変更、（DOCOMO⇔AU）",
    TEXT_97: "SIMカード1枚につき税込3300円",
    TEXT_98: "・３-５営業日がかかります。",
    TEXT_99: "データプラン変更",
    TEXT_100: "一回つき税込220円",
    TEXT_101: "・変更手数料として、200円（税込220円）が別途発生します。",
    TEXT_102: "MNP転出",
    TEXT_103: "税込3300円",
    TEXT_104: "・MNPについての詳細はMNPページをご確認ください。",
    TEXT_105:
      "SIMカード再発行、SIMカード交換、タイプ変更をお申し込み後、新しいSIMカードの準備ができた段階で、旧SIMカードはご利用ができなくなります。",
    TEXT_106: "SIMカード再発行",
    TEXT_107:
      "・SIMカード紛失時や、SIMカードが故障した際は、SIMカードの再発行が可能です。",
    TEXT_108: "SIMカード交換",
    TEXT_109:
      "・SIMカードの交換（番号変更）が可能です。eSIMへの交換はできません。",
    TEXT_110: "SIMカードタイプ変更(DOCOMO",
    TEXT_111: "⇔",
    TEXT_112: "AU)",
    TEXT_113: "・SIMカードのタイプ変更（DOCOMO",
    TEXT_114: "⇔",
    TEXT_115: "AU）の変更が可能です。",
    TEXT_116: "・「DOCOMOの音声SIM」",
    TEXT_117: "⇔",
    TEXT_118:
      "「AUの音声SIM」の変更の場合、変更後のSIMカードご利用にはSIMカード到着後にお電話による開通手続きが必要です。変更前のSIMカードは、変更後のSIMカードの開通処理が完了するまでご利用いただけます。",
    TEXT_119: "音声通話機能",
    TEXT_120: "通話料",
    TEXT_121: "通常料金",
    TEXT_122: "22円/30秒。（税込24円/30秒）",
    TEXT_123: "無料通話分はありません。",
    TEXT_124:
      "対象になるのは、携帯（070/080/090）、IP（050）、固定（0ABJ）への日本国内からの発信となります。テレビ電話などのデジタル通信料や電報サービス、時報サービス等の特番系サービスなどは上記通話料の対象外となります。",
    TEXT_125:
      "テレビ電話などのデジタル通信料は50円/30秒（税込55円/30秒）かかります。通話料は前々月分が請求されます。（例:６月分の通話料は８月に請求）",
    TEXT_126: "通話定額5分かけ放題：",
    TEXT_127: "500円/月（税込550円/月）1回5分以内の国内通話が無料",
    TEXT_128: "通話定額10分かけ放題：",
    TEXT_129: "950円/月（税込1045円/月）1回10分以内の国内通話が無料",
    TEXT_130: "FULLかけ放題：",
    TEXT_131: "2700円/月（税込2970円/月）無制限で国内通話が無料",
    TEXT_132: "通話定額オプション",
    TEXT_133: "・通話定額オプション料金は当月分が請求されます。",
    TEXT_134: "・超過分の通話料は従量課金となります（税込24円/30秒）",
    TEXT_135: "・SMSは定額対象にはなりません。",
    TEXT_136: "・利用開始月の料金は日割り計算を行わず、1ヵ月分満額発生します。",
    TEXT_137: "・ご利用を希望される場合は、SIMカードのご利用開始後に、",
    TEXT_138: "マイページ",
    TEXT_139: "からお申し込みください。",
    TEXT_140:
      "・通話定額オプションを解約される場合、解約申し込み日にかかわらず月末解約となり、料金は満額発生します。",
    TEXT_141:
      "・一般的な利用を逸脱したものと想定される通話（長時間にわたる通話等）は、当社の判断のもとに切断する場合があります。",
    TEXT_142:
      "・対象の音声通話機能付きSIMカードが、ご利用開始日前である場合やご解約予定がある場合などは、音声オプションの変更申し込みができません。ご利用開始日以降に、変更・解約予定などがない状態でお申し込みください。",
    TEXT_143: "・各種有料サービスは別途費用がかかります。",
    TEXT_144: "音声オプション",
    TEXT_145: "留守番電話（DOCOMO回線、AU回線）",
    TEXT_146: "350円/月（税込385円/月）",
    TEXT_147: "・留守番電話料金は当月分が請求されます。",
    TEXT_148:
      "・ご利用を希望される場合は、SIMカードのご利用開始後に、マイページからお申し込みください。お申し込みから利用可能になるまで、最大4日程度かかります。",
    TEXT_149:
      "・留守番電話利用料は、利用開始月は1ヵ月分請求になります。ご解約の場合、解約まで最大4日程度かかり、留守番電話利用料は1ヵ月分請求いたします。",
    TEXT_150:
      "・留守番電話の変更申し込みは、1つの電話番号につき1ヵ月に1回に限ります。",
    TEXT_151: "割り込み電話着信（DOCOMO回線、AU回線）",
    TEXT_152: "250円/月（税込275円/月）",
    TEXT_153:
      "【DOCOMO回線の場合】「割り込み電話着信」はNTTドコモの「キャッチホン」相当のサービスです。",
    TEXT_154:
      "【AU回線の場合】「割り込み電話着信」はAUの「割込通話サービス」相当のサービスです。",
    TEXT_155: "・割り込み電話着信料金は当月分が請求されます。",
    TEXT_156:
      "ご利用を希望される場合は、SIMのご利用開始後に、マイページからお申し込みください。お申し込みから利用可能になるまで、最大4日程度かかります。",
    TEXT_157:
      "割り込み電話着信利用料は、利用開始月は日割りになります。ご解約の場合、解約まで最大4日程度かかり、割り込み電話着信利用料は1ヵ月分請求いたします。",
    TEXT_158:
      "割り込み電話着信の変更申し込みは、1つの電話番号につき1ヵ月に1回に限ります。",
    TEXT_159: "国際ローミング",
    TEXT_160: "利用不可",
    TEXT_161: "国際電話（DOCOMO回線、AU回線）",
    TEXT_162:
      "【DOCOMO回線の場合】国際電話の利用停止目安額は5,000円/月です。金額の変更はできません。",
    TEXT_163:
      "【DOCOMO回線の場合】サービス運用上の都合により、本制限額を超過しても直ちに利用制限されない場合があります。また本制限額を超過して利用された場合、その事由に依らず当該利用により発生した料金の減免はいたしません。",
    TEXT_164: "【DOCOMO回線の場合】主な国への通話料金は以下のとおりです。",
    TEXT_165: "中国 150円／30秒",
    TEXT_166: "台湾 150円／30秒",
    TEXT_167: "香港 150円／30秒",
    TEXT_168: "ベトナム 150円／30秒",
    TEXT_169: "アメリカ 100円／30秒",
    TEXT_170: "インド 250円／30秒",
    TEXT_171: "スリランカ 250円／30秒",
    TEXT_172: "ネパール 250円／30秒",
    TEXT_173: "その他の国への通話料金は問い合わせください。",
    TEXT_174: "【AU回線の場合】対応不可",
    TEXT_175: "SMS送受信料",
    TEXT_176: "国内へ送信",
    TEXT_177: "税込5.5～35円/回",
    TEXT_178: "海外へ送信",
    TEXT_179: "100～500円/回（消費税はかかりません）",
    TEXT_180: "海外で送信",
    TEXT_181: "対応不可",
    TEXT_182: "受信",
    TEXT_183: "無料",
    TEXT_184: "仕様",
    TEXT_185:
      "端末またはアプリによっては全角最大670文字（半角英数字のみの場合は1530文字）までの文字メッセージを送受信できます。",
    TEXT_186:
      "全角71文字（半角英数字のみの場合は161文字）以上の文字メッセージを送信した場合、端末またはアプリによってはメッセージが分割されて届く場合があります。",
    TEXT_187:
      "1日に送信できるメッセージは、全角70文字（半角英数字のみの場合は160文字）以内の場合200回未満となります。",
    TEXT_188:
      "SMS送信料は前々月分が請求されます。（例:６月分のSMS送信料は８月に請求）",
    TEXT_189:
      "国内でのSMS送受信(海外への送信や、海外からの受信を含む)のみ可能です。海外での送受信には対応しておりません。",
    TEXT_190: "最低利用期間",
    TEXT_191: "最低利用期間",
    TEXT_192: "１年",
    TEXT_193: "音声SIM・音声eSIM注意事項",
    TEXT_194: "・【音声SIM・音声eSIMの場合】18歳未満の方はご契約できません。",
    TEXT_195:
      "・携帯電話不正利用防止法に基づく本人確認法に基づいた本人確認のため、運転免許証・マイナンバーカード・在留カードなどの本人確認書類が必要です。",
    TEXT_196:
      "・各種アプリによるSMS送受信の動作確認は行っておりません。また、端末側のSMS送受信機能の有無についても、弊社にて確認は行っておりません。端末の提供元へお問い合わせください。",
    TEXT_197: "・その他の注意事項については",
    TEXT_198: "サポートQ&A",
    TEXT_199: "をご覧ください。",
    TEXT_200: "ご購入・お申し込みはこちら",
    data: {
      loopData0: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: "SIM料金",
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "月額料金",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "通話定額オプション",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "初期費用",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "基本仕様",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "付加機能",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "手数料",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "音声通話機能",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "最低利用期間",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "音声カード",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      loopData1: [
        { lanhutext0: "先月" },
        { lanhutext0: "今月" },
        { lanhutext0: "来月" },
      ],
      loopData2: [
        {
          lanhutext0: "1GB",
          lanhutext1: "980円",
          lanhutext2: "(税込1078円)",
        },
        {
          lanhutext0: "3GB",
          lanhutext1: "1380円",
          lanhutext2: "(税込1518円)",
        },
        {
          lanhutext0: "5GB",
          lanhutext1: "1780円",
          lanhutext2: "(税込1958円)",
        },
        {
          lanhutext0: "10GB",
          lanhutext1: "2280円",
          lanhutext2: "(税込2508円)",
        },
        {
          lanhutext0: "15GB",
          lanhutext1: "2780円",
          lanhutext2: "(税込3058円)",
        },
        {
          lanhutext0: "20GB",
          lanhutext1: "3280円",
          lanhutext2: "(税込3608円)",
        },
        {
          lanhutext0: "50GB",
          lanhutext1: "4780円",
          lanhutext2: "(税込5258円)",
        },
      ],
      constants: {},
    },
  },
  DETAILS_COM_SECTION1_INDEX: {
    TEXT_0: "運営会社",
    TEXT_1: "運営会社",
    TEXT_2: "運営会社名",
    TEXT_3: "星和通商株式会社 SEIWA TRADING CO.,LTD",
    TEXT_4: "IFモバイル",
    TEXT_5: "〒169-0075",
    TEXT_6: "事業部所在地",
    TEXT_7: "東京都新宿区高田馬場１丁目２８−３工新ビル 5階",
    TEXT_8: "電話番号",
    TEXT_9: "03-6383-4910",
    TEXT_10: "代表者",
    TEXT_11: "代表取締役社長  宮沢 千秋",
    TEXT_12: "許認可・届出",
    TEXT_13: "電気通信事業許可番号",
    TEXT_14: "A-03-19030号",
    TEXT_15: "古物商",
    TEXT_16: "東京都公安委員会発行古物商販売許可申請番号 第304372217419号",
    data: {
      constants: {},
    },
  },
  DETAILS_COM_SECTION2_INDEX: {
    TEXT_0: "特定商取引法に基づく表示",
    TEXT_1: "販売業者名",
    TEXT_2: "星和通商株式会社",
    TEXT_3: "代表者名",
    TEXT_4: "宮沢 千秋",
    TEXT_5: "設立",
    TEXT_6: "2015-7-15",
    TEXT_7: "所在地",
    TEXT_8: "〒169-0075 東京都新宿区高田馬場1丁目28−3 工新ビル 5階",
    TEXT_9: "お問い合わせ先",
    TEXT_10: "メール：info@ifmobile.jp",
    TEXT_11: "電話：03-6383-4910（受付時間 10:00～18:30）",
    TEXT_12: "販売価格",
    TEXT_13: "1078円～ 商品ページに記載しております。",
    TEXT_14: "販売価格以外に発生する費用",
    TEXT_15: "消費税、郵送費用などがあります。",
    TEXT_16: "その他諸費用は各商品ページをご参照ください。",
    TEXT_17: "販売価格以外に発生する費用",
    TEXT_18:
      "1.クレジットカード（VISA、MasterCard、JCB、AMEX、Diners）：支払時期は各カード会社の引き落とし日です。",
    TEXT_19: "2.口座振替：支払時期は毎月の27日です。",
    TEXT_20: "3.コンビニ決済：請求メール送信した日から当月の25日までです。",
    TEXT_21:
      '商品によってご利用いただけるお支払い方法が異なります。各商品のお支払い画面でご確認ください。"',
    TEXT_22: "引渡時期、発送方法",
    TEXT_23:
      "お申し込み内容や登録審査状況に不備、または品切れ・品不足などがない場合、お申し込み完了から最短3～4日で契約のご住所にお届けします。",
    TEXT_24:
      "ただし、土日祝をはさむ場合やお届け先が離島の場合は配送にお時間をいただくことがあります。",
    TEXT_25: "契約の解除について",
    TEXT_26:
      "お申込みまたはご購入契約成立後は契約解除できません。返品または返金はできません。",
    TEXT_27: "返品・交換について",
    TEXT_28: "（返品）商品発送後の返品・返却等はお受けいたしかねます。",
    TEXT_29: "（交換）商品が不良の場合のみ交換いたします。",
    TEXT_30:
      "不良品の場合は弊社が負担いたします、それ以外はお客様の負担となります。",
    TEXT_31: "保証・修理",
    TEXT_32:
      "（新品）弊社は、商品についての保証を行いません。商品の修理及び修理の受付先は、商品に付帯する製造者の保証書に記載された条件によるものとします。",
    TEXT_33:
      "（中古）弊社は、商品到着後30日以内の保証を定めるものとします。31日以降の商品の修理及び修理の受付先は、製造者の定める条件によるものとします。",
    data: {
      constants: {},
    },
  },
  DETAILS_COM_SECTION3_INDEX: {
    TEXT_0: "サービス約款",
    TEXT_1: "サービス約款",
    TEXT_2: "第1章 総則",
    TEXT_3: "第1条 (約款の適用)",
    TEXT_4:
      "星和通商株式会社（以下「当社」といいます）は、本「IFモバイルサービス契約約款」（以下「本約款」といいます）を定め、これにより本サービス（第3条に定義します）を提供します。",
    TEXT_5: "第2条 (約款の変更)",
    TEXT_6:
      "1.当社は、この約款を変更することがあります。この場合には、料金その他の提供条件は、変更後の約款によります。",
    TEXT_7: "2.当社はこの約款を変更するときは、当社のホームページ",
    TEXT_8: "https://.ifmobile.jp",
    TEXT_9: "その他当社が別に定める方法により通知します。",
    TEXT_10: "第3条 (約款の公表)",
    TEXT_11: "当社は、当社のホームページ",
    TEXT_12: "https://.ifmobile.jp",
    TEXT_13: "その他当社が別に定める方法により、この約款を公表します。",
    TEXT_14: "第4条 (用語の定義)",
    TEXT_15: "この約款においては、次の用語はそれぞれ次の意味で使用します。",
    TEXT_16: "用語",
    TEXT_17: "用語の意味",
    TEXT_18: "IFモバイルサービス",
    TEXT_19:
      "この約款に基づいて提供される当社のサービス総称（以下、「本サービス」という）",
    TEXT_20: "IFモバイルサービス契約",
    TEXT_21: "本サービスの利用に関する契約",
    TEXT_22: "契約者",
    TEXT_23: "本サービスの契約者",
    TEXT_24: "課金開始日",
    TEXT_25:
      "本サービスの利用に係る料金（初期費用、一時費用を除く）の起算日として当社が指定する日",
    TEXT_26: "解約日",
    TEXT_27: "本サービス契約の解約の効力が生ずる日",
    TEXT_28: "IPv4アドレス",
    TEXT_29:
      "インターネットプロトコルバージョン6(IPv6）として定められている32bitのアドレス",
    TEXT_30: "IPアドレス",
    TEXT_31: "IPv4アドレスの総称",
    TEXT_32: "SIMカード",
    TEXT_33:
      "契約者識別番号その他の情報を記憶することが出来るICカードであって、当社が契約者に貸与するものをいう。",
    TEXT_34: "ユニバーサルサービス料",
    TEXT_35:
      "ユニバーサルサービス提供の確保のため必要な負担金として、その使用している電話番号の数に比例した額を、基礎的電気通信役務支援機関である一般社団法人電気通信事業者協会を通じて、東日本電信電話株式会社または西日本電信電話株式会社に支払うために、当社が本サービス館員からこの特約に定める方法および金額にて徴収する料金をいう。",
    TEXT_36: "課金開始日",
    TEXT_37:
      "本サービスの利用に係る料金（初期費用、一時費用を除く）の起算日として当社が指定する日",
    TEXT_38: "MNP",
    TEXT_39:
      "電気通信事業法第2条第5号に定める電気通信事業者（以下「電気通信事業者」という）のうち携帯音声通信サービスを提供する者（以下「携帯音声通話事業者」という）を携帯音声通信サービスの加入者が変更する際に、変更元で利用していた電話番号を変更先でも継続して利用できる仕組み（Mobile Number Portability：携帯電話番号ポータビリティ）をいう。",
    TEXT_40: "第5条 (本サービスの提供区域)",
    TEXT_41:
      "日本国内、且つ株式会社NTTドコモ（以下、「NTTドコモ」という）、au（以下、「au」という）が定める提供区域のうち当社が指定する提供区域とします。",
    TEXT_42: "第6条 (ID及びパスワード)",
    TEXT_43:
      "1.契約者は、当社が契約者に対し付与するID及びパスワードの管理責任を負うものとします。",
    TEXT_44: "2.契約者はIDなどを第三者に利用させないものとします。",
    TEXT_45: "第7条 (専属的合意管轄裁判所)",
    TEXT_46:
      "当社と契約者の間で訴訟の必要が生じた場合、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。",
    TEXT_47: "第8条 (準拠法)",
    TEXT_48: "本約款に関する準拠法は、日本法とします。",
    TEXT_49: "第2章 申込及び承諾等",
    TEXT_50: "第9条 (申込)",
    TEXT_51:
      "1.本サービス利用の申込（以下、「申込」といいます）は、本サービス内容を特定するために必要な事項を記載した当社指定の書面を提出して行うものとします。",
    TEXT_52:
      "2.本サービスにおいて、音声機能付SIMカード利用の申込をする者は、本人確認（携帯音声通信事業者による契約者などの本人確認等及び携帯音声通信役務の不正な利用防止に関する法律（平成17年31号）第9条の規定に基づくものであって、氏名、住所、生年月日等の契約者を特定する情報の確認を行うことをいいます。以下同じとします）のために当社が別途定める書類を提示する必要があります。",
    TEXT_53: "第10条 (申込の承諾等)",
    TEXT_54:
      "1.当社は、本サービスの利用の申込があった時は、次条(申込の拒絶)に定める申込の拒絶事由に該当する場合を除き、これを承諾するものとします。",
    TEXT_55:
      "2.申込に係る本サービスの提供は、申込を受け付けた順とします。ただし、当社は、必要と認めるときは、その順序を変更することがあります。",
    TEXT_56: "第11条 (申込の拒絶)",
    TEXT_57:
      "1.当社は、本サービスの申込者が次の各号に該当する場合には、本サービスの利用の申込を承諾しないことがあります。",
    TEXT_58:
      "a.本サービス利用のために契約者が満たすべき要件が満たされていないとき",
    TEXT_59:
      "b.申込に係る本サービスの提供又は当該サービスに係る装置の保守が技術上著しく困難な",
    TEXT_60: "とき",
    TEXT_61:
      "c.本サービスの申込者が、当該申込に係る本サービス契約上の債務の支払いを怠るおそれ",
    TEXT_62: "があると当社が判断するとき",
    TEXT_63:
      "d.申込者が現に締結し、又は、従前締結していた本サービス契約において、債務不履行又",
    TEXT_64:
      "は不法行為を行ったことがあるとき、若しくは当社により当該契約が解除されたことがあるとき",
    TEXT_65:
      "e.本サービスの利用の契約申込書に不備があるとき、若しくは虚偽の事実を記載したとき",
    TEXT_66:
      "f.違法、不当、公序良俗違反、当社若しくは当社のサービスの信用を毀損する、又は、当",
    TEXT_67:
      "社サービスを直接若しくは間接に利用する者に重大な支障をきたす等の態様で本サービ",
    TEXT_68: "スを利用するおそれがあるとき",
    TEXT_69: "g.その他当社が不適切と認めたとき",
    TEXT_70:
      "2.当社が前項の規定により、本サービスの利用の申込を拒絶したときは、当社は、申込者に対し、当社所定の方法によりその旨を通知するものとします。",
    TEXT_71: "第12条 (権利義務の譲渡制限)",
    TEXT_72:
      "契約者は、本サービス契約上の権利義務を譲渡、売買、質権の設定その他の担保に供する等の一切の処分をすることは出来ません。",
    TEXT_73: "第13条 (契約者)",
    TEXT_74:
      "契約者は本約款およびその他の本サービスに関する諸規定に従って本サービスを利用するものとし、個人に限るものとします。",
    TEXT_75: "第14条 (契約の単位)",
    TEXT_76:
      "当社は、電話番号一番号ごとに一の本サービス契約を締結します。この場合、本サービス契約者は、一の本サービス契約につき一人に限ります。",
    TEXT_77: "第3章 契約事項の変更",
    TEXT_78: "第15条 (本サービス内容の変更)",
    TEXT_79:
      "1.契約者は、本約款に定めがある場合には、本サービス契約の内容の変更を請求することができるものとします。",
    TEXT_80:
      "2.第11条(申込の拒絶)の規定は、前項の請求があった場合について準用します。この場合において、同条中「申込」とあるのは「変更の請求」と、「申込者」とあるのは「契約者」と読み替えるものとします。",
    TEXT_81: "第16条 (契約者の名称の変更等)",
    TEXT_82:
      "契約者は、その氏名若しくは名称又は住所若しくは居所その他当社が指定する事項に変更があったときは、当社に対し、速やかに当該変更の事実を証する書類を添えてその旨を届け出るものとします。",
    TEXT_83: "第17条 (個人の契約上の地位の引継)",
    TEXT_84:
      "1.契約者である個人(以下この項において「元契約者」といいます。)が死亡したときは、元契約者に係る本サービス契約は、終了します。ただし、相続開始の日から2週間を経過する日までに当社に申出をすることにより、相続人(相続人が複数あるときは、そのうち1人を当社に対する代表者と定めて申出ていただきます。これを変更したときも同様とします。又、その際、当社は当該代表者である旨を証明する書類の提出を求める場合があります。)は、引き続き当該契約に係る本サービスの提供を受けることができます。当該申出があったときは、当該相続人は、元契約者の当該契約上の地位(元契約者の当該契約上の債務を含みます。)を引き継ぐものとします。",
    TEXT_85:
      "2.第11条(申込の拒絶)の規定は、前項の場合について準用します。この場合において、同条中「申込」とあるのは「申出」と、「申込者」とあるのは「相続人」と、「本サービスの利用の契約申込書」とあるのは「申出書」とそれぞれ読み替えるものとします。",
    TEXT_86: "第4章 契約者の義務",
    TEXT_87: "第18条 (契約者の義務)",
    TEXT_88: "契約者は、本約款に定められた契約者の義務を遵守するものとします。",
    TEXT_89: "第19条 (禁止事項)",
    TEXT_90:
      "1.契約者は、次の各号のいずれかに該当する事項を行ってはならないものとします。",
    TEXT_91: "違法、不当、公序良俗に反する態様において本サービスを利用すること",
    TEXT_92:
      "2.当社又は当社のサービスの信用を毀損するおそれがある態様で本サービスを利用すること",
    TEXT_93:
      "3.当社のサービスを直接又は間接に利用する者の当該利用に対し支障を与える態様において本サービスを利用すること",
    TEXT_94:
      "4.第三者に対し、販売、又は利用を許諾する目的において本サービスを利用すること",
    TEXT_95: "5.その他以下に該当する行為をすること。",
    TEXT_96:
      "a.電子メールの送受信上の支障を生じさせるおそれのある電子メールを送信する行為",
    TEXT_97:
      "b.(a)のほか、当社若しくは他社のインターネット関連設備の利用若しくは運営、又は他の",
    TEXT_98:
      "契約者の平均的な利用の範囲に支障を与える行為又は与えるおそれがある行為",
    TEXT_99:
      "c.無断で他人に広告、宣伝若しくは勧誘する行為又は他人に嫌悪感を抱かせ、若しくは嫌",
    TEXT_100:
      "悪感を抱かせるおそれがある文章等を送信、記載若しくは転載する行為",
    TEXT_101: "d.他人になりすまして各種サービスを利用する行為",
    TEXT_102:
      "e.他人の著作権、肖像権、商標、特許権その他の権利を侵害する行為又は侵害するおそれ",
    TEXT_103: "がある行為",
    TEXT_104:
      "f.他人の財産、プライバシー等を侵害する行為、又は侵害するおそれがある行為",
    TEXT_105:
      "g.他人を差別若しくは誹膀中傷し、又はその名誉若しくは信用を毀損する行為",
    TEXT_106:
      "h.猥褻、虐待等、児童及び青少年に悪影響を及ぼす情報、画像、音声、文字、文書等を送",
    TEXT_107: "信、記載又は掲載する行為",
    TEXT_108:
      "i.無限連鎖講（ネズミ講）若しくはマルチまがい商法を開設し、又はこれを勧誘する行為",
    TEXT_109:
      "j.連鎖販売取引（マルチ商法）に関して特定商取引に関する法律（昭和51年法律第57号）",
    TEXT_110: "に違反する行為",
    TEXT_111:
      "k.インターネット接続機能により利用しうる情報を改ざんし、又は消去する行為",
    TEXT_112:
      "l.ウィルス等の有害なコンピュータープログラム等を送信し、又は掲載する行為",
    TEXT_113: "m.犯罪行為又はそれを誘発若しくは扇動する行為",
    TEXT_114: "n.(a)から(m)のほか、法令又は慣習に違反する行為",
    TEXT_115:
      "o.売春、暴力、残虐等、公序良俗に違反し、又は他人に不利益を与える行為",
    TEXT_116: "p.その他、当社のサービスの運営を妨げる行為",
    TEXT_117:
      "q.上記(p)までの禁止行為に該当するコンテンツヘのアクセスを助長する行為",
    TEXT_118: "第20条 (契約者の義務違反)",
    TEXT_119:
      "契約者が、第18条(契約者の義務)又は前条(禁止事項)に違反した場合にあっては、当社は、契約者に対してこれにより当社が被った損害の賠償請求をすることができるものとします。又、契約者が本サービスの利用に関して第三者に与えた損害につき当社が当該第三者に当該損害の賠償をしたときは、当社は、契約者に対し、当該賠償について求償することができるものとします。",
    TEXT_120: "第5章 品質保証、責任の限定等",
    TEXT_121: "第21条 (本サービスの品質保証又は保証の限定)",
    TEXT_122:
      "1.本サービスは、ドコモ、AUの移動無線通信に係る通信網において通信が著しく輻輳したとき、電波状況が著しく悪化した場合又はその他ドコモ、AUの定めに基づき、通信の全部又は一部の接続ができない場合や接続中の通信が切断される場合があり、当社は、当該場合において契約者又は第三者に発生した損害について何ら責任を負うものではありません。",
    TEXT_123:
      "2.前項に定める事項のほか、本サービスは、その通信の可用性、遅延時間その他通信の品質について保証するものではありません。",
    TEXT_124: "第22条 (当社の免責)",
    TEXT_125:
      "当社は、本約款において明示的に規定された場合を除き、前条(本サービスの品質保証又は保証の限定)によって定められた品質保証の違背、その他、契約者が本サービスの利用に関して被った損害(その原因の如何を問いません。)について賠償、返金、料金の減免等の責任を負わないものとします。",
    TEXT_126: "第22条 (当社の免責)",
    TEXT_127:
      "当社は、本約款において明示的に規定された場合を除き、前条(本サービスの品質保証又は保証の限定)によって定められた品質保証の違背、その他、契約者が本サービスの利用に関して被った損害(その原因の如何を問いません。)について賠償、返金、料金の減免等の責任を負わないものとします。",
    TEXT_128: "第6章 利用の制限、中止及び停止並びに本サービスの廃止",
    TEXT_129: "第23条 (利用の制限)",
    TEXT_130:
      "1.当社は、電気通信事業法第8条の規定に基づき、天災事変その他の非常事態が発生し、若しくは発生するおそれがあるときは、災害の予防若しくは救援、交通、通信若しくは電力の供給の確保又は秩序の維持に必要な通信その他の公共の利益のために緊急を要する通信を優先的に取り扱うため、本サービスの利用を制限する措置を採ることがあります。",
    TEXT_131:
      "2.当社は、児童買春、児童ポルノに係る行為等の処罰及び児童の保護等に関する法律（平成11年法律第52号）において定める児童ポルノを閲覧又は取得するための通信を制限する場合があります。",
    TEXT_132: "第24条 (利用の中止)",
    TEXT_133:
      "1.当社は、次に掲げる事由があるときは、本サービスの提供を中止することがあります。",
    TEXT_134: "a.当社の電気通信設備の保守又は工事のためやむを得ないとき",
    TEXT_135: "b.当社が設置する電気通信設備の障害等やむを得ない事由があるとき",
    TEXT_136:
      "2.当社は、本サービスの提供を中止するときは、契約者に対し、前項第1号により中止する場合にあっては、その14日前までに、同項第2号により中止する場合にあっては、事前に、その旨並びに理由及び期間を通知します。ただし、緊急やむを得ないときは、この限りではありません。",
    TEXT_137: "第25条 (利用の停止等)",
    TEXT_138:
      "1.当社は、契約者が次の各号に該当するときは、本サービスの提供を停止又は利用を制限することがあります。",
    TEXT_139:
      "a.本サービス契約上の債務の支払を怠り、又は怠るおそれがあることが明らかであるとき",
    TEXT_140:
      "b.本サービスに係る契約の申し込みに当たって当社所定の書面に事実に反する記載を行っ",
    TEXT_141: "たことが判明したとき",
    TEXT_142:
      "c.第16条（契約者の名称の変更等）に違反したとき、又は第16条（契約者の名称の変更",
    TEXT_143:
      "等）の規定により届け出た内容について事実に反することが判明したとき",
    TEXT_144:
      "d.第18条(契約者の義務)の規定に基づき定められた契約者の義務に違反したとき",
    TEXT_145: "e.第19条(禁止事項)の規定に違反したとき",
    TEXT_146: "f.その他本約款に違反したとき",
    TEXT_147: "g.本サービスの契約者への提供が適切でないと、当社が判断したとき",
    TEXT_148:
      "h.当社は、前項の規定による措置を講ずるときは、契約者に対し、あらかじめその理由及",
    TEXT_149:
      "び期間を通知します。ただし、緊急やむを得ないときは、この限りではありません。",
    TEXT_150: "第26条 (本サービスの廃止)",
    TEXT_151:
      "1.当社は、当社の判断により、本サービスの全部又は一部を廃止することがあります。",
    TEXT_152:
      "2.当社は、前項の規定により本サービスの全部又は一部を廃止するときは、契約者に対し、廃止する日の1ヶ月前までに、その旨を通知します。",
    TEXT_153:
      "3.NTTドコモ、auの電気通信サービスの提供が、契約の解除その他の理由により終了した場合、本サービスは自動的に廃止となります。",
    TEXT_154: "第7章 契約の解除",
    TEXT_155: "第27条 (当社による解除)",
    TEXT_156:
      "当社は次に掲げる事由があるときは、本サービス契約を解除することがあります。",
    TEXT_157: "第28条 (契約者による解約)",
    TEXT_158:
      "1.契約者は、本サービス契約を解約しようとするときは、解約の当月末を解約日とし、本約款若しくはwebサイトに定める手順に従い、当社指定の書面を当社の指定する場所に届け出ていただきます。この場合、本条第2項に基づき当社にSIMカードを返還します。",
    TEXT_159:
      "2.契約者が利用契約を解約する場合、貸与機器がある場合は当社に返還するものとします。",
    TEXT_160:
      "3.本条による解約の場合、解約月における全ての利用料その他債務を契約者が負担するものとする。",
    TEXT_161: "第29条 (機器の選定)",
    TEXT_162:
      "貸与機器は、契約回線数に応じて、又は契約者が指定できる種類等がある場合にはその種類等の中から、当社が選択して貸与するものとします。",
    TEXT_163: "第30条 (機器の管理)",
    TEXT_164:
      "1.契約者は当社が貸与する貸与機器につき、通常の用途以外の使用をしないこと。",
    TEXT_165:
      "2.当社は、第三者が貸与機器を利用した場合であっても、当該貸与機器の貸与を受けている",
    TEXT_166: "契約者が利用したものとみなして取り扱います。",
    TEXT_167:
      "3.本サービス契約が事由の如何を問わず終了した場合、その他貸与機器を利用しなくなった場合には、契約者は、遅滞なく貸与機器を当社に返還するものとします。",
    TEXT_168:
      "4.当社は、契約者との本サービス契約が理由の如何を問わず終了した場合、若しくは貸与機器を利用しなくなった場合は、当該本サービス契約にかかる貸与機器に登録された一切の情報を、当社が別に定める方法により消去するものとし、契約者は一切異議を述べないものとします。",
    TEXT_169: "第31条 (故障が生じた場合の措置等)",
    TEXT_170:
      "契約者は、貸与機器に故障が生じたときは、当該貸与機器を当社に返還するものとします。",
    TEXT_171:
      "当社は、代替貸与機器の送付を行います。貸与機器の故障が契約者の責によるものである場合には、契約者は、当社に対し、当該貸与機器の回復に要する費用として金額を支払うものとします。",
    TEXT_172: "第32条 (亡失品に関する措置)",
    TEXT_173:
      "契約者は、貸与機器を亡失した場合は可及的速やかに当社が定める方法により当社に通知す",
    TEXT_174:
      "るものとし、当社は、当該通知があったときは代替貸与機器の送付を行います。事由の如何",
    TEXT_175: "を問わず、契約者は、当社に対し亡失負担金を支払うものとします。",
    TEXT_176: "第33条 (ソフトウェアの利用)",
    TEXT_177:
      "1.契約者は、本サービスにおける通信を行う場合において、当社が提供するソフトウェアを利用すことができるものとします。",
    TEXT_178:
      "2.契約者は、前項の利用の場合において、別途当社が定めるソフトウェアに関する使用許諾条件を遵守するものとします。",
    TEXT_179: "第8章 料金等",
    TEXT_180: "第34条 (契約者の料金支払義務)",
    TEXT_181:
      "1.契約者は、当社に対し、本サービスの利用に関し、料金表に定めるところにより料金を支払うものとします。",
    TEXT_182:
      "2.この場合において、初期費用の支払義務は本サービスの申込を当社が承諾した時点で、月額利用料の支払義務は課金開始日に、一時費用の支払義務は当該一時費用の発生に係る契約内容変更の申込を当社が承諾した時点で、それぞれ発生するものとします。",
    TEXT_183:
      "3.本約款の他の条項及び個別規程で定める場合を除き、本サービスの利用に伴って継続的に課金される料金について、課金開始日が暦月の初日以外の日である場合にあっては当社が定める日割計算式を適用して算定するものとします。",
    TEXT_184:
      "4.第23条(利用の制限)、第24条(利用の中止)ならびに第25条(利用の停止等)の規定により、本サービスの利用が停止又は制限された場合の当該停止又は制限の期間における当該サービスに係る本サービスの料金の額の算出については、当該サービスの提供があったものとして取り扱うものとします。",
    TEXT_185: "第35条 (最低利用期間)",
    TEXT_186:
      "本サービス契約について、最低利用期間内における解除、契約内容の変更その他本約款で定める事由が発生した場合には、契約者は、別添「本サービスに関する料金・サービス内容」に定めるところにより、契約解除料を支払うものとします。",
    TEXT_187: "第36条 (料金の支払方法)",
    TEXT_188:
      "契約者は、本サービスの料金を、当社が指定する期日までに、当社が指定する方法により支払うものとします。",
    TEXT_189: "第37条 (割増金)",
    TEXT_190:
      "本サービスの料金の支払を不法に免れた契約者は、当社に対しその免れた金額の2倍に相当する金額を支払うものとします。",
    TEXT_191: "第38条 (遅延損害金)",
    TEXT_192:
      "契約者は、本サービスの料金その他本サービス契約上の債務の支払を怠ったときは、その支払うべきこととされた日の翌日から支払いの日の前日までの間の当社が定める日数について年14．6％の割合（年当たりの割合は、閏年の日を含む期間についても、365日当たりの割合とします）で算出した額の遅延損害金を支払うものとします。",
    TEXT_193: "第39条 (割増金等の支払方法)",
    TEXT_194:
      "第36条(料金の支払方法)の規定は、第37条(割増金)及び前条(遅延損害金)の場合について準用します。",
    TEXT_195: "第40条 (消費税)",
    TEXT_196:
      "契約者が当社に対し本サービスに関する債務を支払う場合において、消費税法(昭和63年法律第108号)及び同法に関する法令の規定により当該支払について消費税が賦課されるものとされているときは、契約者は、当社に対し、当該債務を支払う際に、これに対する消費税相当額を併せて支払うものとします。",
    TEXT_197: "第9章 契約者情報",
    TEXT_198: "第41条 (通信の秘密)",
    TEXT_199:
      "当社は、通信の秘密に係る契約者の情報について、電気通信事業法（昭和59年法律第86号）",
    TEXT_200: "第4条を遵守した取り扱いを行うものとします。",
    TEXT_201: "第42条 (営業秘密等)",
    TEXT_202:
      "契約者は、本サービスの利用に関し知り得た当社の技術情報、本サービスの内容、その他当社が秘密である旨指定して契約者に開示する場合の当該情報について、当社があらかじめ承諾した場合を除き、第三者に開示してはならないものとします。",
    TEXT_203: "第43条 (個人情報の取り扱い)",
    TEXT_204:
      "1.当社は、本サービスの提供に関して取得した個人情報を利用目的の範囲内において取り扱",
    TEXT_205: "うものとします。",
    TEXT_206:
      "2.当社は、代金の決済のためクレジットカード情報を取得する場合があります。当該情報は契約者がカード決済時にご利用された各カード会社を含む各種決済代行事業者に提供し、その後当社で控えとして当社で定めた期間保管した後削除いたします。",
    TEXT_207:
      "3.当社は、当社が指定する契約（以下、「対象契約」といいます）の契約者（申込者含む）に関する個人情報を、契約者の対象契約に関する契約及び継続可否審査または契約者の対象契約に関する代金の支払能力調査の目的で、当社が加盟する個人信用情報機関（以下、「加盟個人信用情報機関」といいます）ならびに、与信業務等に関して提携する企業（以下、「提携企業」といい、加盟個人信用情報機関と提携企業をあわせて「加盟個人信用情報機関等」といいます）に、契約者が当社に登録している情報を提供する場合があります。",
    TEXT_208:
      "4.当社は、本サービス契約の販売代理者または取次者（以下、「販売代理者等」といいます）に、販売代理者等への手数料等の支払い、当社と販売代理者等との業務の連携及び販売代理者等から契約者への連絡の目的で、契約者の氏名及び携帯電話番号を書面により提供します。",
    TEXT_209:
      "5.当社は、本サービスの提供に係る業務における個人情報の取扱いの全部又は一部を第三者に委託する場合にあっては、当社は、当社の監督責任下において個人情報を第三者に委託するものとします。",
    TEXT_210:
      "6.契約者による当社への個人情報の提出は任意ですが、本サービス契約の提供に必要な情報が提出されない場合は、本サービス契約の提供ができません。",
    TEXT_211:
      "7.契約者は、当社に対して契約者本人の個人情報に関して、利用目的の通知および個人情報の開示、訂正・追加・削除、利用停止・提供停止・消去の請求を当社のおこなうことができます。当該請求の方法は、「個人情報に関する公表事項」をご確認ください。",
    TEXT_212:
      "8.当社は、契約者の個人情報を、個人情報保護管理者（財務総務部 責任者 03-5155-4673）の統括の下、適切に管理するものとします。",
    TEXT_213: "第10章 雑則",
    TEXT_214: "第44条 (電磁的方法による意思表示)",
    TEXT_215:
      "当社及び契約者間の書面の交付、通知、提出等は、当社が定める範囲内において、電磁的方法により行うことができるものとします。",
    TEXT_216: "第45条 (業務委託)",
    TEXT_217:
      "当社は、本サービスの提供に必要な業務については、当社の指定する第三者に委託することができるものとします。",
    TEXT_218: "第46条 (本サービス利用に必要な役務等)",
    TEXT_219:
      "本サービスを利用するために必要な電気供給等の役務、装置等は、本約款において明示的に規定されている場合を除き、契約者の責任において調達するものとします。",
    TEXT_220: "第47条 (技術的事項)",
    TEXT_221:
      "本サービスにおける基本的な技術事項は、別途当社が定めるものとします。",
    TEXT_222: "第48条 (報告)",
    TEXT_223:
      "当社は、契約者に対し、必要に応じ合理的な範囲で、本サービスの利用の状況について報告を求めることができるものとします。この場合において、契約者は、速やかに当該報告を行うものとします。",
    TEXT_224: "第49条 (位置情報の送出)",
    TEXT_225:
      "携帯電話事業者または協定事業者がワイヤレスデータ通信にかかる当社との間に設置した接続点と、契約者回線との間の通信中にその当社にかかる電気通信設備から携帯事業者が別に定める方法により位置情報（その契約者回線に接続されている移動無線装置の所在にかかる情報をいう。以下、本条において同じとする）の要求があったときは、契約者があらかじめ当社への位置情報の送出にかかる設定を行った場合に限り、その接続点へ位置情報を送出することを、契約者は、あらかじめ承諾するものとします。",
    TEXT_226:
      "当社は前項の規定により送出された位置情報に起因する損害については、その原因の如何によらず、一切の責任を負わないものとします。",
    TEXT_227: "第50条 (情報の収集)",
    TEXT_228:
      "当社は、本サービスに関し、契約者に技術サポート等を提供するために必要な情報を収集、利用することがあります。契約者は、契約者から必要な情報が提供されないことにより、当社が十分な技術サポート等を提供できないことがあることをあらかじめ了承するものとします。",
    TEXT_229: "第51条 (反社会勢力に対する表明保証)",
    TEXT_230:
      "1.契約者は、サービス利用契約締結時および締結後において、自らが暴力団または暴力団関連企業・団体その他反社会的勢力（以下、総称して「反社会的勢力」という）ではないこと、反社会的勢力の支配・影響を受けていないことを表明し、保証するものとします。",
    TEXT_231:
      "2.契約者が次の各号のいずれかに該当することが合理的に認められた場合、当社はなんら催",
    TEXT_232:
      "告することなくサービス利用契約を解除することができるものとします。",
    TEXT_233: "a.反社会的勢力に属していること",
    TEXT_234: "b.反社会的勢力が経営に実質的に関与していること",
    TEXT_235: "c.反社会的勢力を利用していること",
    TEXT_236:
      "d.反社会的勢力に対して資金などを提供し、または便宜を提供するなどの関与をしている",
    TEXT_237: "こと",
    TEXT_238: "e.反社会的勢力と社会的に非難されるべき関係を有していること",
    TEXT_239:
      "f.自らまたは第三者を利用して関係者に対し、詐術、暴力的行為、または脅迫的言辞を用",
    TEXT_240: "いたこと",
    TEXT_241:
      "3.前項各号のいずれかに該当した契約者は、当社が当該解除により被った損害を賠償する責任を負うものとし、自らに生じた損害の賠償を当社に求めることはできないものとします。",
    TEXT_242: "第52条 (他の電気通信事業者への情報の通知)",
    TEXT_243:
      "契約者は、料金その他の債務の支払いをしない場合、または前条に定める契約者確認に応じない場合には、当社が、当社以外の電気通信事業者からの請求に基づき、氏名、住所、契約者識別番号、生年月日および支払い状況等の情報（契約者を特定するために必要なものおよび支払い状況に関するものであって、当社が別に定めるものに限る）を当該事業者に通知することにあらかじめ同意するものとします。",
    TEXT_244: "第53条 (本サービスの技術仕様等の変更等)",
    TEXT_245:
      "当社は、本サービスにかかわる技術仕様その他の提供条件の変更または電気通信設備の更改などに伴い、契約者が使用する本SIMカードの改造または撤去などを要することとなった場合であっても、その改造または撤去等に要する費用について負担しないものとします。",
    TEXT_246: "第54条 (分離性)",
    TEXT_247:
      "本規約の一部分が無効で強制力をもたないと判明した場合でも、本規約の残りの部分の有効性はその影響を受けず引き続き有効で、その条件に従って強制力を持ち続けるものとします。",
    TEXT_248: "第55条 (協議)",
    TEXT_249:
      "当社および契約者は、本サービスまたは本規約に関して疑義が生じた場合には、両者が誠意を持って協議のうえ解決するものとします。",
    TEXT_250: "第56条 (その他)",
    TEXT_251:
      "本規約から生じる当社の権利は、当社が権利を放棄する旨を契約者に対して明示的に通知しない限り、放棄されないものとします。",
    TEXT_252: "以上",
    data: {
      constants: {},
    },
  },
  DETAILS_COM_SECTION4_INDEX: {
    TEXT_0: "プライバシーポリシー",
    TEXT_1: "プライバシーポリシー",
    TEXT_2:
      "星和通商株式会社（以下「当社」といいます。）は、適切な業務サービスをご提供していくなかで、お客様に関わる個人情報を正しく扱うことは、重要な責務であると考えております。当社では、この個人情報保護方針に基づき、個人情報保護マネジメントシステムを確立するとともに実行し、事業活動にかかわるすべての従業員が一体となって、個人情報の適切な取り扱いを実現いたします。",
    TEXT_3: "1. 個人情報の適切な取得、利用、提供に関して",
    TEXT_4:
      "当社では、事業の内容および規模を考慮し、業務遂行に必要な範囲で個人情報を適切な方法で取得します。個人情報の利用は、特定した利用目的の範囲内とし、利用目的を変更する際には、あらかじめご本人に通知し、同意を得た上でおこないます。個人情報は、利用目的を遂行するために業務を委託する場合を除き、ご本人の同意なく、第三者に提供することはございません。目的外の利用や提供を防止するため、個人情報の利用および提供は、個人情報保護管理者が、その適正性を確認した上でおこなっています。また、自主点検、内部監査等の活動を通じた統制活動も強化しております。",
    TEXT_5: "2. 個人情報保護のための安全管理策",
    TEXT_6: "（１）個人情報の漏えい、滅失、き損等に対する予防処置",
    TEXT_7:
      "当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。また、これらを徹底するため、従業員への教育・監督をおこなっています。個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。",
    TEXT_8: "（２）個人情報の漏えい、滅失、き損等に対する是正処置",
    TEXT_9:
      "個人情報の漏えい、滅失、き損等が発生した場合は、被害拡大防止を最優先とし、速やかに是正処置を講じるとともに、原因究明をおこない、再発防止に努めます。また、事故に関する情報について、必要な範囲で関係者、関係機関に報告します。",
    TEXT_10: "3. 法令、国が定める指針、その他の規範の遵守",
    TEXT_11:
      "当社は、個人情報の取り扱いに関する法律および関連法令、国が定める指針、所属団体等の規範等を遵守します。なお、GDPRに対応しております。",
    TEXT_12: "4. 個人情報保護マネジメントシステムの継続的改善",
    TEXT_13:
      "当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。",
    TEXT_14: "5. 個人情報に関する苦情、相談への対応",
    TEXT_15:
      "当社に対して、個人情報の取り扱いに関する苦情、相談をおこなう場合は、下記の「個人情報お問い合わせ窓口」までご連絡ください。また、当社の個人情報保護に関するお問い合わせについても下記にて対応いたします。",
    TEXT_16: "【個人情報お問い合わせ窓口】",
    TEXT_17: "TEL：03-6383-4910",
    TEXT_18: "Mail： info@ifmobile.jp",
    TEXT_19:
      " 本プライバシーポリシーは、法令の変更等に伴い改訂されることがあります。",
    TEXT_20: "セイワツウショウカブシキガイシャ",
    TEXT_21: "代表取締役 宮沢千秋 ",
    TEXT_22: " 2023年7月15日",
    data: {
      constants: {},
    },
  },
  DETAILS_COM_SECTION5_INDEX: {
    TEXT_0: "個人情報の取り扱いについて",
    TEXT_1: "個人情報に関する公表事項",
    TEXT_2: "1. 個人情報の利用目的",
    TEXT_3:
      "星和通商株式会社（以下、「当社」という）が取り扱う個人情報の利用目的は、下表の通りです。（）が付された個人情報については、保有個人データとして、当社に対して利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止のご請求をおこなうことができます。",
    TEXT_4:
      "また、当社は、業務を円滑に進めるため、個人情報の取扱の全部または一部を委託する場合があります。この場合、当社は十分な保護水準を満たした者を選定し、当該委託先との間で個人情報の取扱いに関する契約の締結を始め、適切な監督を実施します。",
    TEXT_5: "個人情報の種類",
    TEXT_6: "利用目的",
    TEXT_7: "（不動産仲介事業）",
    TEXT_8: "・顧客情報",
    TEXT_9: "・家賃等支払い状況",
    TEXT_10: "・不動産オーナーの情報",
    TEXT_11: "• 物件情報提供のため",
    TEXT_12: "• 不動産賃貸の仲介のため",
    TEXT_13: "• 入居審査をおこなうため",
    TEXT_14: "• 賃貸物件管理のため",
    TEXT_15: "• 家賃等請求のため",
    TEXT_16: "• 引越し関連業者紹介のため",
    TEXT_17: "• 各種ご案内のため",
    TEXT_18: "（モバイル事業）",
    TEXT_19: "・顧客情報",
    TEXT_20: "・料金支払い状況",
    TEXT_21: "・顧客通話履歴情報",
    TEXT_22: "• サービス提供に係る業務遂行のため",
    TEXT_23: "• 契約変更のため",
    TEXT_24: "• 契約審査のため",
    TEXT_25: "• 課金計算のため",
    TEXT_26: "• 料金請求のため",
    TEXT_27: "• アフターサービスご提供のため",
    TEXT_28: "• 各種ご案内のため",
    TEXT_29: "（旅行業）",
    TEXT_30: "・顧客情報",
    TEXT_31:
      "• 運送・宿泊棟の提供するサービスの手配及びそれらのサービスの受領のための手続きのため",
    TEXT_32: "• 保険の手続きのため",
    TEXT_33: "• 土産店でのお客様の買い物の便宜に必要なため",
    TEXT_34: "• 各種ご案内のため",
    TEXT_35: "顧客の家族情報",
    TEXT_36: "通話音声データ",
    TEXT_37: "防犯カメラの映像",
    TEXT_38: "• 緊急時の連絡のため",
    TEXT_39: "• 電話受付・問い合わせの通話内容を確認するため",
    TEXT_40: "• 防犯上の安全管理のため",
    TEXT_41: "各種お問い合わせ情報",
    TEXT_42: "• 各種お問い合わせ対応のため",
    TEXT_43: "• 各種ご案内のため",
    TEXT_44: "採用応募者情報",
    TEXT_45: "在職者情報",
    TEXT_46: "退職者情報",
    TEXT_47: "• 弊社が必要とする人材の採用判断のため",
    TEXT_48: "• 人事管理業務および業務の連絡のため",
    TEXT_49:
      "• 法で定める退職者の人事管理業務および懇親等の必要に応じた連絡のため",
    TEXT_50: "2. 個人情報の取扱い",
    TEXT_51: "（１）個人情報の取得方法",
    TEXT_52:
      "ご来店または当社ウェブサイトからの予約、申込みおよび契約、アンケートの記入、緊急連絡先としてのご家族情報の確認によるお客様のご協力を通して、関係する法令等を遵守した適法かつ公正な手段によって、個人情報および個人を特定できない情報（「６．クッキー（cookie）について」参照）を取得いたします。",
    TEXT_53:
      "契約の履行等に必要な情報をご提供いただく必要はございますが、それ以外の情報は特に断りがない限り任意でご提供いただけます。",
    TEXT_54: "（２）個人情報の管理および保護",
    TEXT_55:
      "お客様の個人情報および個人を特定できない情報につきましては、Webサイトを通じた取得時に通信を暗号化するなど 、情報の紛失、破壊、改変、漏洩等を防止するために十分な安全保護に努めたうえで、個人情報については正確、最新なものとするよう常に必要かつ適切な措置を講じてまいります。なお当社は、保有する個人情報を取得目的および定めた保管期間を超えて保存することはありません。",
    TEXT_56: "3. 個人情報の利用および第三者への開示・提供",
    TEXT_57:
      "当社では、お客様の同意がない限り、お客様の個人情報を上記の目的以外に使用いたしません。また、以下の場合を除き、お客様の同意なく、お客様の個人情報を第三者に開示・提供することはありません。",
    TEXT_58:
      "• サービス提供のため、提携する不動産会社、人材紹介先企業、クレジットカード発行事業者、その他生活サポート事業のため必要な事業組織、旅行業者やホテル事業者、保険業者、航空会社、土産物店に提供する場合",
    TEXT_59: "• 法令の規定に基づく場合",
    TEXT_60:
      "• 人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難な場合、公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難な場合等法的な要請に基づく場合。",
    TEXT_61: "4. 保有個人データに関する周知事項",
    TEXT_62:
      "当社は、保有個人データの取り扱いにあたり、下記の事項を公表いたします。",
    TEXT_63: "（１）当社の名称",
    TEXT_64: "星和通商株式会社SEIWA TRADING CO.,LTD",
    TEXT_65: "（２）個人情報保護管理者",
    TEXT_66: "代表取締役社長  宮沢 千秋（03-6383-4910）",
    TEXT_67: "（３）すべての保有個人データの利用目的",
    TEXT_68: "「１．個人情報の利用目的」参照。",
    TEXT_69:
      "（４）保有個人データの取り扱いに関するお問い合わせ・苦情等の申し出先",
    TEXT_70: "申し出先の名称：個人情報保護管理者",
    TEXT_71: "住所：東京都新宿区高田馬場１丁目２８−３工新ビル5階",
    TEXT_72: "電話：03-6383-4910",
    TEXT_73: "FAX ：03-6383-4911",
    TEXT_74:
      "（５）当社が属する認定個人情報保護団体の名称および苦情の解決の申し出先",
    TEXT_75: "当社は認定個人情報保護団体に所属しておりません。",
    TEXT_76: "（６）開示等の請求方法",
    TEXT_77: "「３．個人情報の開示等のご請求に応じる手続き」参照。",
    TEXT_78: "（７）苦情申し立ての権利",
    TEXT_79:
      "当社に個人情報をご提供いただいたお客様は、いつでも監督当局（お客様の住所地、勤務先またはGDPRがあったとされるEU加盟国の監督当局）に苦情を申し立てる権利があります。その場合、当社としては、監督当局にご連絡頂く前に、苦情への対応の機会をいただきたく、上記の苦情等への申し出先まで事前のご連絡をお願いいたします。",
    TEXT_80: "5. 個人情報の開示等のご請求に応じる手続き",
    TEXT_81:
      "当社はお客様ご本人から申し出があった場合、個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去または第三者への提供停止のご請求および個人情報に関する苦情・ご相談については、PMS事務局（電話：03-6383-4910）にて承ります。ただし、ご請求いただいた個人情報につき、法令等の規定により個人情報を開示できない場合や当社の保管期間を過ぎた場合については、お客様の個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去、または第三者への提供停止に応じることができませんのでご了承ください。",
    TEXT_82: "ご送付いただくもの",
    TEXT_83: "（１）「個人情報お問い合せ申請書」",
    TEXT_84: "所定の用紙に以下の事項をご記入ください。",
    TEXT_85: "①ご本人様の住所・氏名・電話番号とご捺印",
    TEXT_86: "②請求日",
    TEXT_87: "③開示をお求めになる個人情報を特定するための情報",
    TEXT_88: "（２）ご本人様、代理人の方であることを示す証明書",
    TEXT_89: "ご本人様の場合",
    TEXT_90:
      "• 運転免許証、パスポート、健康保険の被保険者証、在留カードの写しのいずれか１つ",
    TEXT_91: "代理人の方の場合",
    TEXT_92: "• 代理人である事を示す委任状（必須）",
    TEXT_93:
      "• 委任状に押印された本人の印鑑証明書、代理人の本人確認書類のいずれか1つ",
    TEXT_94: "（３）手数料：1,000円（利用目的の通知または開示の場合のみ）",
    TEXT_95: "【ご送付先】",
    TEXT_96: "住所：〒169-0075 東京都新宿区高田馬場１丁目２８−３工新ビル5階",
    TEXT_97: "星和通商株式会社 個人情報保護管理者",
    TEXT_98:
      " 代理人の方がご請求される場合は、PMS事務局までお問い合わせください。なお、代理人請求ができる方は下記の方に限らせていただきます。",
    TEXT_99: "• 未成年者または成年被後見人の法定代理人",
    TEXT_100: "• 開示等の求めをすることにつき本人が委任した代理人",
    TEXT_101:
      "当社では、上記の請求を受領した場合は、必要な調査を実施した上で、2週間以内に書面にて回答致します。回答にあたっては、なりすましによる個人情報の漏えいを防止する目的で、本人限定受取郵便を用いますので、ご了承ください。",
    TEXT_102: "6. クッキー（cookie）について",
    TEXT_103:
      "当社ウェブサイトでは、ユーザのプライバシーの保護、および利便性の向上のため 、クッキーを使用します。また当社は、クッキーやJavaScript等の技術を利用して、ご提供いただいた情報のうち個人が特定できない属性情報や、サイト内におけるユーザの行動履歴を取得することがあります。ただし、クッキーおよび行動履歴には個人情報は一切含まれません。各ウェブサイトの閲覧またはサービス利用に際し取得に同意いただく必要がございます。お客様による各ウェブサイトの閲覧またはサービス利用を継続されることで同意いただいたとみなします。",
    TEXT_104:
      "お客様のご希望に応じて、ご使用のブラウザの設定によりクッキーの受け入れを拒否・無効に設定することも可能です。ただし、その場合当ウェブサイトの一部の機能をご利用いただけなくなります。",
    TEXT_105: "以上",
    data: {
      constants: {},
    },
  },
  DETAILS_LIGHT_SECTION1_INDEX: {
    TEXT_0: "光回線",
    TEXT_1: "IF光回線特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "光回線の設置例",
    TEXT_4: "光回線(光ファイバー)",
    TEXT_5: "ONU(通称モデム)",
    TEXT_6: "無線LANルーター",
    TEXT_7: "Wi-Fi",
    TEXT_8: "PCもつながる",
    TEXT_9: "ギガ使い放题",
    TEXT_10:
      "外の電柱から自宅まで光ファイバーというケーブルを引き込みます。そのケーブルを使ってインターネットを使うのが光回線です。無線ルーターを設置することで、スマホでWi-Fiが使えるようになります。",
    TEXT_11: "電柱",
    TEXT_12: "光回線を使うべき人",
    TEXT_13: "選ぶ理由",
    TEXT_14: "とにかく速い！",
    TEXT_15: "高速光回線",
    TEXT_16: "動画の視聴やオンラインゲームや音楽のダウンロードも楽々快適。",
    TEXT_17: "完全定額",
    TEXT_18: "使い放題！",
    TEXT_19: "どれだけ使っても利用料が変わらない、安心の定額プラン。",
    TEXT_20: "申込手続き簡単",
    TEXT_21: "海外審査可能、パスポートだけで申込可能、銀行口座なしでもOK。",
    TEXT_22: "安心サポート",
    TEXT_23: "お支払い方法多様、多国言語対応可能、スタッフが24時間対応可能。",
    TEXT_24: "SIMとセットで毎月IFポイントが貯まる",
    TEXT_25: "料金",
    TEXT_26: "一戸建て",
    TEXT_27: "マンション",
    TEXT_28: "契約期間",
    TEXT_29: "2年",
    TEXT_30: "事務手数料",
    TEXT_31: "3,300円",
    TEXT_32: "工事費",
    TEXT_33: "31,680円",
    TEXT_34: "宅内のみなら10,560円",
    TEXT_35: " 31,680円",
    TEXT_36: "無派遣なら2,200円",
    TEXT_37: "月額料金",
    TEXT_38: "月々のSIM割引",
    TEXT_39: "特典",
    TEXT_40: "5,720円",
    TEXT_41: "4,180円",
    TEXT_42: "毎月IFポイント300P",
    TEXT_43: "キャンペーン or  キャッシュバック",
    TEXT_44: "初月料金は日割となります。",
    TEXT_45:
      "敷設される光回線によって、ご契約手続きの際に選択いただいたタイプと異なるタイプでご利用いただく場合があります。",
    TEXT_46: "詳しい料金・仕様はこちら",
    TEXT_47: "IF ひかりご利用までの流れ",
    TEXT_48: "お申し込み",
    TEXT_49: "新規でお申込み ",
    TEXT_50: "（新規契約）",
    TEXT_51: "新規でご契約のお客様に、サービス",
    TEXT_52: "他社ひかりからのきりかえ",
    TEXT_53: "（事業者変更）",
    TEXT_54: "他社ひかりサービスをご利用中",
    TEXT_55: "申込",
    TEXT_56: "工事日決定",
    TEXT_57: "工事",
    TEXT_58: "開通",
    TEXT_59:
      " 本サービスは当社が指定する本サービス提供エリア内の方のみに提供します。",
    TEXT_60:
      "  電波の特性上、サービス提供エリア内であっても、サービスを提供できない場合があります。",
    TEXT_61:
      " 修理交換料金および未返却、紛失時には違約金を請求させていただきます。",
    TEXT_62:
      " 開通前レンタルご希望の方は、お申し込み情報入力画面の「同意して申し込む」をチェックしてください。",
    TEXT_63: "開通ま",
    TEXT_64: "1カ月",
    TEXT_65: "でかかる",
    TEXT_66: "ことも",
    TEXT_67: "流れ",
    TEXT_68: "01",
    TEXT_69: "お申し込み",
    TEXT_70:
      "IF光回線のお申し込みボタンからお手続きください。お申し込みにはご契約者様の本人確認が必要となります。",
    TEXT_71: "02",
    TEXT_72: "工事希望日の確認",
    TEXT_73:
      "お申し込み時に選択いただいたコンサルティング希望日にお電話にてご連絡し、工事日を調整します。",
    TEXT_74: "03",
    TEXT_75: "利用開始日確定幾器の受け取り",
    TEXT_76:
      "お客様宅に設置する機器は事前に送付します。工事担当者がお伺いする工事の場合は、原則工事担当者が持参します。",
    TEXT_77: "04",
    TEXT_78: "工事実施・開通",
    TEXT_79:
      "NTT担当者が工事にお伺いします。工事完了後、お客様にて宅内機器の設置と接続の設定を行っていただき、開通完了です。",
    TEXT_80: "事業者変更でのお申し込み",
    TEXT_81: "01",
    TEXT_82: "事業者変更承諾番号の取得",
    TEXT_83:
      "他社光コラボ事業者のホームページまたは電話で「事業者変更承諾番号」を取得してください。",
    TEXT_84: "02",
    TEXT_85: "お申し込み",
    TEXT_86:
      "IF 光回線のお申し込みボタンからお手続きください。お申し込みにはご契約者様の「事業者番号承諾番号」が必要です。",
    TEXT_87: "03",
    TEXT_88: "移行手続きのご案内",
    TEXT_89:
      "お申し込み時に選択いただいたコンサルティング日にご連絡いたします。その際、移行手続き時の注意事項等の案内や、回線の切り替え日の決定を行います。",
    TEXT_90: "04",
    TEXT_91: "機器の設定・開通",
    TEXT_92: "切り替え日になりましたら、機器の設定を変更すると開通完了です。",
    TEXT_93: "IF ひかりのお申し込み 〉",
    data: {
      loopData0: [
        {
          lanhutext0: "1",
          lanhutext1:
            "家族と暮らし、2年以内で引っ越し予定なし、外でWi-Fiをあまり使わない",
        },
        {
          lanhutext0: "2",
          lanhutext1:
            "よくオンラインゲームをする。ギガ使い放題（通信制限、速度低下なし）を利用したい",
        },
        {
          lanhutext0: "3",
          lanhutext1:
            "速度が速く、安定性も高い（品質が良い）のインターネット回線を利用したい",
        },
      ],
      loopData1: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPngb3d8f335194408d99d002367c0d82769ee6d844b754be75df5992ddea193bbbb.png"),
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPnga720b46a746a000482efa21d34e936c33ed5d0df04f64329ccf12caf76e04359.png"),
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPnge04a0823680afd9b832a43fcc8db97f9e707c4b3b21624c0a4182355a12808be.png"),
        },
      ],
      constants: {},
    },
  },
  DETAILS_LIGHT_SECTION2_INDEX: {
    TEXT_0: "光回線",
    TEXT_1: "IF光回線特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "初期費用",
    TEXT_4: "新しく光回線を利用する場合（新規）",
    TEXT_5: "税込 3300円（税込）",
    TEXT_6: "他社光コラボレーションサービスからお乗り換えの場合（事業者変更）",
    TEXT_7: "税込 3,300円（税込）",
    TEXT_8: "月額料金",
    TEXT_9: "一戸建て",
    TEXT_10: "マンション",
    TEXT_11: "契約期間",
    TEXT_12: "2年",
    TEXT_13: "事務手数料",
    TEXT_14: "3,300円",
    TEXT_15: "工事費",
    TEXT_16: "31,680円",
    TEXT_17: "宅内のみなら10,560円",
    TEXT_18: " 31,680円",
    TEXT_19: "無派遣なら2,200円",
    TEXT_20: "月額料金",
    TEXT_21: "5,720円",
    TEXT_22: "4,180円",
    TEXT_23: "月々のSIM割引",
    TEXT_24: "毎月IFポイント300P",
    TEXT_25: "新規でお申し込みの場合、別途工事費が発生します。",
    TEXT_26:
      "転用ならびに事業者変更によるお申し込みの場合、通常工事費は発生しません。",
    TEXT_27: "機器レンタル",
    TEXT_28: "機器レンタル料金",
    TEXT_29: "無料",
    TEXT_30:
      "機器レンタルはご利用開始日から工事完了日まで無料でご利用いただけます。",
    TEXT_31: "レンタルする機器の種類をお選びいただくことはできません。",
    TEXT_32: "工事費",
    TEXT_33: "01",
    TEXT_34: "派遣工事なしの場合",
    TEXT_35: "派遣なし工事",
    TEXT_36: "工事担当者がお客様宅に伺わないＮＴＴ局舎内の工事",
    TEXT_37: "お客様ご自身で設定",
    TEXT_38: "インターネット",
    TEXT_39: "NTT局舎",
    TEXT_40: "プロバイダ",
    TEXT_41: "電柱",
    TEXT_42: "光コンセント",
    TEXT_43: "回線終端装置",
    TEXT_44: "無線LAN",
    TEXT_45: "スマートフォン",
    TEXT_46: "パソコン",
    TEXT_47: "お客様宅（戸建・集合住宅共通）",
    TEXT_48: "02",
    TEXT_49: "派遣工事ありの場合",
    TEXT_50: "派遣工事",
    TEXT_51: "工事担当者がお客様宅にお伺いする工事",
    TEXT_52: "お客様ご自身で設定",
    TEXT_53: "インターネット",
    TEXT_54: "プロバイダ",
    TEXT_55: "NTT局舎",
    TEXT_56: "電柱",
    TEXT_57: "光コンセント",
    TEXT_58: "回線終端装置",
    TEXT_59: "無線LAN",
    TEXT_60: "スマートフォン",
    TEXT_61: "パソコン",
    TEXT_62: "お客様宅（戸建・集合住宅共通）",
    TEXT_63: "イラスト、配線図はイメージです。",
    TEXT_64:
      "派遣あり工事において工事担当者は機器の設置および開通確認を行いますが、インターネット・無線LANルータの設定はお客様ご自身で実施いただきます。",
    TEXT_65: "工事費(派遣あり)",
    TEXT_66: "(工事担当者がお客様宅内に伺う工事)",
    TEXT_67: "お客様宅内の配線設備を新設する場合",
    TEXT_68: "31,680円〈税込〉",
    TEXT_69: "（1,100円＊２４ヵ月）",
    TEXT_70: "工事費(派遣なし)",
    TEXT_71: "(工事担当者がお客様宅内に伺わない工事)）",
    TEXT_72:
      "客様宅内の配線設備等を再利用し、かつお客様ご自身で回線終端装置等を設置する場合",
    TEXT_73: "2,200円〈税込〉",
    TEXT_74:
      "具体的な工事費は回線工事に関するご連絡(コンサルティング)の際にご確認ください。",
    TEXT_75:
      "土日祝日の工事をご希望の場合や早朝・夜間の工事をご希望の場合等、工事実施の時間帯や内容によって上記以外の工事費が発生することがあります。",
    TEXT_76: "工事費は、一括で請求いたします。",
    TEXT_77: "最低利用期間",
    TEXT_78: "最低利用期間",
    TEXT_79: "利用開始日から24ヵ月",
    TEXT_80: "最低利用期間内解除金",
    TEXT_81: "10,450円(税込)",
    TEXT_82: "事業者変更（解約）手数料",
    TEXT_83: "3,300円(税込)",
    TEXT_84:
      "最低利用期間内にIFひかりを解約された場合、残りの期間にかかわらず発生いたします。",
    TEXT_85: "その他費用",
    TEXT_86: "事業者変更(解約)手数料",
    TEXT_87: "3,300円(税込)",
    TEXT_88: "レンタル機器の故障",
    TEXT_89: "未返却・紛失時の費用",
    TEXT_90: "対応型ルータの原価計算にご請求します。",
    TEXT_91:
      "事業者変更（解約）以外の手続きでIFひかりを解約された場合、上記費用は発生しません。",
    TEXT_92:
      "レンタル機器の区分ごとに定める最大請求金額を上限として、NTTが減価償却を考慮して当社に請求する額に相当する額について、",
    TEXT_93: "請求が発生する場合がございます。",
    TEXT_94: "請求発生後に機器を返却いただいた場合、返金はいたしかねます。",
    TEXT_95: "注意事項",
    TEXT_96:
      "1、各工事費は、工事が行われた月の翌月のご利用料金として請求いたします。また、当月通話料、機器レンタル料は翌月にあわせて請求いたします。",
    TEXT_97:
      "2、工事内容によっては、本ページに記載の料金以外の工事費等が発生する場合がございます。また、工事業者から直接お客様に料金を請求させていただく場合がございます。",
    TEXT_98:
      "3、本ページに記載の各種料金は、予告なく変更される場合がございます。",
    TEXT_99:
      "4、各種キャンペーン、割引に関しては、各キャンペーン等のページの内容もご確認ください。",
    data: {
      loopData0: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: "初期費用",
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "月額料金",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "機器レンタル",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "工事費",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "最低利用期間",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "その他費用",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION1_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "SIM",
    TEXT_7: "Wi-Fi",
    TEXT_8: "光回線",
    TEXT_9: "お問い合わせ",
    TEXT_10: "料金について",
    TEXT_11: "Q",
    TEXT_12: "支払い方法について教えてください。",
    TEXT_13: "A",
    TEXT_14:
      "クレジットカード決済・Alipaｙ国際決済・Wechatpay国際決済・銀行振込・コンビニ支払い等でお支払いできます。",
    TEXT_15: "Q",
    TEXT_16: "利用料金の請求日について教えてください。",
    TEXT_17: "A",
    TEXT_18:
      "毎月の請求金額が確定された後、15日前後にマイページに登録していただいたメールアドレスに請求メールをお送りします。",
    TEXT_19: "Q",
    TEXT_20: "支払い方法の変更方法について教えてください。",
    TEXT_21: "A",
    TEXT_22: "マイページから支払い方法のお手続きを行うことができます。",
    TEXT_23: "Q",
    TEXT_24: "初月料金の算定の仕方を教えてください。",
    TEXT_25: "A",
    TEXT_26: "利用開始月の料金は日割り計算を行わず、1ヵ月分満額発生します。",
    TEXT_27: "*解約した月は日割り計算できませんのでご注意ください。",
    TEXT_28: "Q",
    TEXT_29: "毎月の支払い期限はいつですか？",
    TEXT_30: "A",
    TEXT_31:
      "支払い期限は毎月28日までとなります。入金確認できない場合は月末停止いたします。ご了承ください。",
    TEXT_32: "Q",
    TEXT_33: "初期手続きに必要な手数料について教えてください。",
    TEXT_34: "A",
    TEXT_35:
      "初期事務手数料は3300円（税込）となります。SIMパッケージ料金は2200円（税込）となります。",
    TEXT_36: "SIMカードの再発行料金について教えてください。",
    TEXT_37: "SIMカードの再発行料金は3300円（税込）となります。",
    TEXT_38: "解約料金について教えてください。",
    TEXT_39: "一年未満解約の場合は 11000円(税込み)解約金が発生します。",
    TEXT_40: "MNP転出(他社への乗り換え)の料金について教えてください。",
    TEXT_41: "MNP手数料は3300円です。",
    TEXT_42: "お申し込みについて",
    TEXT_43: "Q",
    TEXT_44: "申し込みに必要なものは何ですか？",
    TEXT_45: "A",
    TEXT_46: "・本人確認書類",
    TEXT_47: "・MNPの場合はMNP予約番号が必要です。",
    TEXT_48:
      "＊MNP（モバイルナンバーポータビリティ）の予約番号は、事前に取得が必要です。現在ご利用中の携帯電話事業者にMNPご希望の旨をお申し出ください。その際、有効期限を必ずお控えください。",
    TEXT_49: "お申し込みには、有効期限が10日間以上ある必要がございます。",
    TEXT_50: "Q",
    TEXT_51: "未成年での契約はできますか？",
    TEXT_52: "A",
    TEXT_53: "未成年の方は、保護者の同意を得てからのお申込みができます。",
    TEXT_54: "Q",
    TEXT_55: "利用前に設定が必要でしょうか？",
    TEXT_56: "A",
    TEXT_57: "APNの設定完了次第、すぐ利用できます。",
    TEXT_58: "Q",
    TEXT_59: "申し込みをキャンセルする方法を教えてください",
    TEXT_60: "A",
    TEXT_61:
      "開通のお手続きの状況により、キャンセルの受付ができないことがあります。詳しくはお問い合わせください。",
    TEXT_62: "他社から（他社へ）の乗り換えについて",
    TEXT_63: "Q",
    TEXT_64: "他社に乗り換えられますか？",
    TEXT_65: "A",
    TEXT_66:
      "MNP転出(他社への乗り換え)が可能です。MNP転出の際にはMNP予約番号の発行が必要です。",
    TEXT_67: "Q",
    TEXT_68: "現在利用している電話番号を引き続き利用できますか？",
    TEXT_69: "A",
    TEXT_70:
      "ご利用中の通信会社であらかじめ「MNP予約番号」を取得いただき、ご契約時にご申告いただくことで、現在の電話番号を引き続きご利用いただけます。",
    TEXT_71: "Q",
    TEXT_72: "MNPの取得方法について教えてください。",
    TEXT_73: "A",
    TEXT_74: "MNP予約番号の発行は、マイページでお申込みください。",
    TEXT_75:
      "＊MNP転出(他社への乗り換え)はIF Mobileと解約することで、解約料金を請求する場合があります。また、MNP手数料を請求します。",
    TEXT_76:
      "ただし、指定店舗からMNP転出の場合は解約料金不要です。転出先はAU,UQ,Ymobile,ahamoに限られます。ご希望がありましたら、お客様サポートセンターにお問い合わせください。",
    TEXT_77: "オプションについて",
    TEXT_78: "Q",
    TEXT_79: "対応機種について教えてください。",
    TEXT_80: "A",
    TEXT_81:
      "iPhoneなどIOS搭載端末やAndroid搭載端末、ご利用いただけます。一部端末が対応できない場合はあります。",
    TEXT_82: "Q",
    TEXT_83: "通信料について",
    TEXT_84: "A",
    TEXT_85: "国内音声通話料：24円／30秒（税込）",
    TEXT_86:
      "また、IF Mobileを利用した定額かけ放題のオプションサービスをご提供しています。",
    TEXT_87: "Q",
    TEXT_88: "留守番電話サービスは使えますか？",
    TEXT_89: "A",
    TEXT_90:
      "留守番電話センター（録音料・再生料を含む）・0570（ナビダイヤル）/0180（テレドーム）など～始まる他社が料金を設定している番号への通信料・番号案内（104）等は無料通話対象外となります。",
    TEXT_91: "Q",
    TEXT_92: "余った通信容量は翌月に繰り越されますか？",
    TEXT_93: "A",
    TEXT_94:
      "当月に余ったデータ通信料は、毎月１日～月末までが利用期限です。翌月に繰越すことはできません。",
    TEXT_95: "Q",
    TEXT_96: "データチャージはできますか？",
    TEXT_97: "A",
    TEXT_98: "データチャージはできます。マイページでお申込みください。",
    TEXT_99: "解約について",
    TEXT_100: "Q",
    TEXT_101: "解約をしたい場合はどうすればいいですか？",
    TEXT_102: "A",
    TEXT_103:
      "マイページからお問い合わせや解約のお手続きを行うことができます。",
    TEXT_104: "Q",
    TEXT_105: "解約時に請求書が届いたのはなぜですか？",
    TEXT_106: "A",
    TEXT_107:
      "解約時の当月に届いた請求書は、解約月の前に発生した通話料金などをお支払いすることが必要がございます。",
    TEXT_108: "Q",
    TEXT_109: "解約後、マイページを使用することができますか？",
    TEXT_110: "A",
    TEXT_111: "IF会員はSIM解約してもログインできます。利用できます。",
    TEXT_112: "Q",
    TEXT_113: "解約すると、SIMカードはすぐに使用できなくなりますか？",
    TEXT_114: "A",
    TEXT_115: "解約日から利用できなくなります。",
    TEXT_116: "Q",
    TEXT_117: "解約時に発生する料金を教えてください",
    TEXT_118: "A",
    TEXT_119:
      "契約期間が１年未満の場合に11000円(税込み)の解約金がかかります。あらかじめご了承ください。",
    TEXT_120: "契約情報変更・確認について",
    TEXT_121: "Q",
    TEXT_122: "プラン等の変更方法について教えてください。",
    TEXT_123: "A",
    TEXT_124:
      "マイページより、プランの変更をお手続きください。変更後のプランは、お手続きをした翌月１日から適用となります。",
    TEXT_125: "＊ご利用開始月はプラン変更ができません",
    TEXT_126: "＊プラン変更は一ヶ月に一回のみ申請可能です",
    TEXT_127: "Q",
    TEXT_128: "契約情報の変更方法を教えてください。",
    TEXT_129: "A",
    TEXT_130: "マイページで変更することができます。",
    TEXT_131: "トラブルについて",
    TEXT_132: "Q",
    TEXT_133: "データ通信・通話ができないのはなぜですか？",
    TEXT_134: "A",
    TEXT_135:
      "・SIMカードが届いて一度も通信・通話ができていない場合                初期設定を完了していない場合、データ通信・通話ができません。または、初期設定が正しく完了出来ていない可能性があります。",
    TEXT_136: "・今まで利用できていたのに利用できなくなった場合",
    TEXT_137:
      "通信障害が発生していないか確認してください。（通話・通信の片方が障害になる場合もあります。）",
    TEXT_138: "SIMがうまく認識されていない場合があります。",
    TEXT_139:
      "一度電源をOFFにし、SIMを抜いて再度挿入してください。その後、端末の再起動をおこないます。",
    TEXT_140:
      "再起動後は通信・通話ができるかお試しください。それでも解決しない場合はAPNの再設定をおこなってください。",
    TEXT_141: "· APN設定を再度おこなう",
    TEXT_142:
      "APN設定を再度おこなってください（再設定により解決するケースがございます）。",
    TEXT_143: "APN設定方法はこちらにご確認ください。 ",
    TEXT_144:
      "· デュアルSIMの場合、まず他社のSIMカードを抜いて、APNを設定すること。",
    TEXT_144_1: "· 公衆Wi-Fiが自動に接続されたのが原因。",
    TEXT_144_2: "対策:公衆Wi-Fi利用しないでください。オン(ON)→オフ(OFF)",
    TEXT_145: "Q",
    TEXT_146: "速度が遅くなった・つながりにくくなったのは何ですか？",
    TEXT_147: "A",
    TEXT_148: "・通信制限による速度低下                ",
    TEXT_149:
      "プランによって定められた月間利用総量を超えてのご利用や、 ３日間５GBまでの利用制限などが適用されている可能性がございます。利用総量の確認はマイページより確認できます。       ",
    TEXT_150: "・端末の設定に問題がある                 ",
    TEXT_151: "通信制限による速度低下以外の場合、下記もお試しください。",
    TEXT_152: "ブラウザのキャッシュクリアを行う",
    TEXT_153: "起動中アプリを終了させる（複数起動しているものを閉じる）",
    TEXT_154: "ブラウザのページが複数開いていたら閉じる",
    TEXT_155: "端末を再起動する",
    TEXT_156: "Q",
    TEXT_157: "SIMカードが紛失・盗難の場合はどうすればいいですか？",
    TEXT_158: "A",
    TEXT_159:
      "SIMカードの再発行をご希望される場合はマイページでお申込みください。",
    TEXT_160: "その他の質問",
    TEXT_161: "Q",
    TEXT_162: "海外に電話することができますか？",
    TEXT_163: "A",
    TEXT_164: "海外に電話することはできます。費用かなり高いです。",
    TEXT_165: "Q",
    TEXT_166: "海外でSMSを受信することができますか？",
    TEXT_167: "A",
    TEXT_168: "できます。",
    TEXT_169: "Q",
    TEXT_170: "SIMフリーの端末とは何ですか？",
    TEXT_171: "A",
    TEXT_172:
      "SIMフリーとは、SIMロックがされていないスマートフォンを指します。SIMロックを解除し、端末をSIMフリー化してしまえば、好きな携帯電話会社のSIMカードを挿入して使用できます。",
    data: {
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION12_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "SIM",
    TEXT_7: "Wi-Fi",
    TEXT_8: "光回線",
    TEXT_9: "お問い合わせ",
    TEXT_10: "申込について",
    TEXT_11: "Q",
    TEXT_12: "口座とクレジットカードがなくても申し込みできますか？",
    TEXT_13: "A",
    TEXT_14: "はい、申し込むが可能です。",
    TEXT_15: "Q",
    TEXT_16: "18歳以下は申し込むことはできますか。",
    TEXT_17: "A",
    TEXT_18:
      "18歳以下の方はお申し込みできませんけれども、保護者名義でのお申し込みをご検討していただければ、問題ございません。",
    TEXT_19: "Q",
    TEXT_20:
      "外国人の場合、お申し込みに関して、必要な本人確認書類は何でしょうか？",
    TEXT_21: "A",
    TEXT_22:
      "外国人の場合、在留資格認定証明書や在留カード及びビザなどの提出が必要となります。",
    TEXT_23: "Q",
    TEXT_24: "お申し込みは本人確認書類が必要でしょうか？",
    TEXT_25: "A",
    TEXT_26: "はい、必要です。本人確認できない場合、お申し込みができません。",
    TEXT_27: "受取・返却について",
    TEXT_28: "Q",
    TEXT_29: "送料はかかりますか？",
    TEXT_30: "A",
    TEXT_31:
      "お申し込み時にご選択いただくお受取り方法・ご返却方法により、送料（880円）がかかります。",
    TEXT_32: "Q",
    TEXT_33: "今から申し込んで、当日に受取れますか？",
    TEXT_34: "A",
    TEXT_35:
      "郵送の場合は受け取れませんが、来店場合はお支払い完了後に受け取りが可能です。",
    TEXT_36: "Q",
    TEXT_37: "利用開始日の前日までに受け取れますか？",
    TEXT_38: "A",
    TEXT_39: "ご利用開始日より前の事前配達は行っておりません。",
    TEXT_40:
      "お申し込み時にご選択いただいた、ご利用開始日(お届け日)に配送します。",
    TEXT_41: "Q",
    TEXT_42: "機械の返送はいつまででよろしでしょうか？",
    TEXT_43: "A",
    TEXT_44:
      "機械はご利用完了日からご返送いただく感じになり、ご返送から３日間の間に弊社に届くようにお願い致します。",
    TEXT_45: "お支払い・利用料金について",
    TEXT_46: "Q",
    TEXT_47: "料金のお支払いについてはどうなっていますか？",
    TEXT_48: "A",
    TEXT_49:
      "お支払いは前払いとなり、毎月15日からに翌月分のご請求書を配布いたします。28日迄にお支払いが確認できない場合は一時停止となり、当月末日迄にお支払いが無い場合は、強制解約とります。あらかじめ、ご注意下さい。",
    TEXT_50: "Q",
    TEXT_51: "支払い方法は何ですか？",
    TEXT_52: "A",
    TEXT_53:
      "コンビニ支払い・振り込み・クレジットカード決済・Alipay国際決済・Wechatpay国際決済・来店支払い等でお支払いことができます。",
    TEXT_54: "Q",
    TEXT_55: "レンタルの場合、保証金がありますか？",
    TEXT_56: "A",
    TEXT_57: "はい、あります。金額としては10000円となります。",
    TEXT_58: "解約の際に機械を弊社にご返送してから、ご返金いたします。",
    TEXT_59: "Q",
    TEXT_60: "解約料金はありますか？",
    TEXT_61: "A",
    TEXT_62: "解約の場合は、解約料金はございません。ただし、ご連絡が必要です。",
    TEXT_63: "Q",
    TEXT_64: "初期費用について教えてください。",
    TEXT_65: "A",
    TEXT_66: "·初期事務手数料は3300円（税込）となります。",
    TEXT_67: "·COD（本体料金）7980円（税込）となります。",
    TEXT_68: "·郵送料880（税込）となります。",
    TEXT_69: "Q",
    TEXT_70: "初回料金と解約料金の計算は日割り計算でしょうか？",
    TEXT_71: "A",
    TEXT_72: "いいえ,日割り計算ではなく、１か月の満額料金を回収致します。",
    TEXT_73: "機器・通信について",
    TEXT_74: "Q",
    TEXT_75: "1台のWi-Fi機器で何台まで接続できますか？",
    TEXT_76: "A",
    TEXT_77: "最大15台まで接続できます。",
    TEXT_78: "Q",
    TEXT_79: "WI-FIの端末保証期間はどのぐらいでしょうか？",
    TEXT_80: "A",
    TEXT_81:
      "端末保証期間は 10 ヵ月となります。お客様の故意による不具合・故障以外は修理させて頂きます。弊社に端末が届いた日から約 1 週間で正常な端末を郵送いたします。",
    TEXT_82: "Q",
    TEXT_83: "データ通信制限はありますか？",
    TEXT_84: "A",
    TEXT_85: "データ量の利用制限がありません。",
    TEXT_86: "Q",
    TEXT_87: "データ容量は無制限ですか？",
    TEXT_88: "A",
    TEXT_89:
      "機種によっては、無制限でご利用いただける機種もご用意しております。",
    TEXT_90: "Q",
    TEXT_91: "データ通信ができないのはなぜですか？",
    TEXT_92: "A",
    TEXT_93: "·データ量がなくなり、低速モードになってしまいます。",
    TEXT_94: "·地下、信号がないところにいる限り、データ通信ができません。",

    TEXT_94_1: "エラーコード「12」が表示される場合：",
    TEXT_94_2:
      "現在ご利用されている場所での通信量の増大により、一時的に通信ができない状態です。 電源を入れたまま、しばらくお待ちいただくことで改善する場合もございます。",

    TEXT_95: "サービスについて",
    TEXT_96: "Q",
    TEXT_97: "最短契約期間はありますか？",
    TEXT_98: "A",
    TEXT_99:
      "契約期間の拘束はありません。いつでも解約することができます。但し、解約する前に必ずご連絡ください。",
    TEXT_100: "Q",
    TEXT_101: "海外で利用できますか？",
    TEXT_102: "A",
    TEXT_103: "海外で利用できますか、別途料金がかかります。設定が必要です。",
    TEXT_104: "Q",
    TEXT_105: "利用前に設定が必要でしょうか？",
    TEXT_106: "A",
    TEXT_107:
      "いいえ、設定の必要がありません。端末をつながるとすぐご利用いただきます。",
    TEXT_108: "Q",
    TEXT_109: "何時まで受付可能ですか？",
    TEXT_110: "A",
    TEXT_111: "10時～19時までの受付となっております。",
    data: {
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION13_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "SIM",
    TEXT_7: "Wi-Fi",
    TEXT_8: "光回線",
    TEXT_9: "お問い合わせ",
    TEXT_10: "申込について",
    TEXT_11: "Q",
    TEXT_12: "クレジットカードがなくても申し込みできますか？",
    TEXT_13: "A",
    TEXT_14: "クレジットカードがなくても申し込むことはできます。",
    TEXT_15: "Q",
    TEXT_16: "他社ひかりからのきりかえ（事業者変更）はできますか？",
    TEXT_17: "A",
    TEXT_18: "他社ひかりからのきりかえ（事業者変更）はできます。",
    TEXT_19: "Q",
    TEXT_20: "口座とクレジットカードがなくても申し込みできますか？",
    TEXT_21: "A",
    TEXT_22: "はい、申し込むが可能です。",
    TEXT_23: "Q",
    TEXT_24: "18歳以下は申し込むことはできます。",
    TEXT_25: "A",
    TEXT_26:
      "18歳以下の方はお申し込みできませんけれども、保護者名義でのお申し込みをご検討していただければ、問題ございません。",
    TEXT_27: "Q",
    TEXT_28:
      "外国人の場合、お申し込みに関して、必要な本人確認書類は何でしょうか？",
    TEXT_29: "A",
    TEXT_30:
      "外国人の場合、在留資格認定証明書や在留カード及びビザなどが必要となります。",
    TEXT_31: "Q",
    TEXT_32: "お申し込みは本人確認書類が必要でしょうか？",
    TEXT_33: "A",
    TEXT_34: "はい、必要です。本人確認できない場合、お申し込みができません。",
    TEXT_35: "お支払い・利用料金について",
    TEXT_36: "Q",
    TEXT_37: "最短契約期間はありますか？",
    TEXT_38: "A",
    TEXT_39:
      "はい、契約期間は２年になります。２年前に解約すると解約料金がかかります。",
    TEXT_40: "Q",
    TEXT_41: "解約金はありますか？",
    TEXT_42: "A",
    TEXT_43: "最低利用期間内解除金として10,450円(税込)をいただきます。",
    TEXT_44: "Q",
    TEXT_45: "事業者変更(解約)の手数料はかかりますか？",
    TEXT_46: "A",
    TEXT_47: "事業者変更(解約)の手数料として3,300円(税込)をいただきます。",
    TEXT_48: "Q",
    TEXT_49: "支払い方法は何ですか？",
    TEXT_50: "A",
    TEXT_51:
      "コンビニ支払い・振り込み・クレジットカード決済・Alipaｙ国際決済・Wechatpay国際決済・来店支払い等でお支払いできます。",
    TEXT_52: "Q",
    TEXT_53: "初月金額の計算方法はどうなっていますか？",
    TEXT_54: "A",
    TEXT_55: "初月は割引適用前金額の日割となります。",
    TEXT_56: "工事について",
    TEXT_57: "Q",
    TEXT_58: "工事費(派遣あり)とは？",
    TEXT_59: "A",
    TEXT_60:
      "お客様宅内の配線設備を新設する場合、工事担当者がお客様宅内に伺う工事のことです。31,680円(税込)（1,100円＊２４ヵ月）",
    TEXT_61: "Q",
    TEXT_62: "工事費(派遣なし)とは？",
    TEXT_63: "A",
    TEXT_64:
      "客様宅内の配線設備等を再利用し、かつお客様ご自身で回線終端装置等を設置する場合（工事担当者がお客様宅内に伺わない工事）2,200円(税込)",
    TEXT_65: "Q",
    TEXT_66: "工事日が決定するまでどのぐらいかかりますか？",
    TEXT_67: "A",
    TEXT_68:
      "基本的に2～3日以内ですが、お客様の登録状況や建物状況によっては前後する可能性がございます。",
    TEXT_69: "Q",
    TEXT_70: "工事日はいつになりますか？",
    TEXT_71: "A",
    TEXT_72:
      "お申し込み日から2週間以降から工事可能です。工事日決まったら、「ご利用開始のご案内」をメール（SMS）または書面でお届けしておきます。事前にご確認いただけます。",
    TEXT_73: "Q",
    TEXT_74: "Q.工事費最大31,680円(税込)ってなんですか？",
    TEXT_75: "A",
    TEXT_76:
      "建物や、ラック（配線設備）の状況によって金額の変動があるため、最大の金額をご案内しています。",
    TEXT_77: "Q",
    TEXT_78: "開通工事に要する時間はどのくらいですか？",
    TEXT_79: "A",
    TEXT_80: "約2時間程度です。（工事内容により変動します。）",
    TEXT_81: "Q",
    TEXT_82: "開通工事は休日に実施できますか？",
    TEXT_83: "A",
    TEXT_84:
      "可能です。ただし、工事を土曜日もしくは休日に実施した場合には、1工事あたり3,300円(税込)の割増工事費が必要です。",
    TEXT_85: "Q",
    TEXT_86: "開通の際に立ち会いは必要ですか？",
    TEXT_87: "A",
    TEXT_88:
      "光コンセントの取り付けなど、お客さま宅内での作業が必要となりますので、工事日にはお客さまに立ち会っていただく必要があります。なお、光コンセントの取り付けは、光ファイバーの引き込みをおこなう部屋と同一のお部屋となります。",
    TEXT_89: "Q",
    TEXT_90: "事前調査の立ち会いなどは必要になりますか？",
    TEXT_91: "A",
    TEXT_92:
      "基本的に、事前調査は行いません。 ただし、検討の結果、ご提供の可否が確認ができない場合に、必要に応じて実施させていただきます。",
    TEXT_93: "サービスについて",
    TEXT_94: "Q",
    TEXT_95: "最低利用期間はありますか？",
    TEXT_96: "A",
    TEXT_97: "利用開始日から24ヵ月の最低利用期間があります。",
    TEXT_98:
      "最低利用期間の解約の場合は最低利用期間内解除金3,000円(非課税)をいただきます。",
    TEXT_99: "Q",
    TEXT_100: "IF 光の通信制限がありますか？",
    TEXT_101: "A",
    TEXT_102: "利用制限無しで使い放題です。",
    TEXT_103: "Q",
    TEXT_104: "IF 光はどのようなサービスですか？",
    TEXT_105: "A",
    TEXT_106:
      "IF 光は、月額4,180円(税込)からご利用いただける超高速の自宅の光インターネット接続サービスです。",
    TEXT_107: "Q",
    TEXT_108: "引っ越しをする場合の手続きは？",
    TEXT_109: "A",
    TEXT_110:
      "まずは引越し先でIFひかりがお使いいただけるかどうかをお調べいたします。地域によって特典等が変わってまいりますので詳しくはお問合せください。",
    TEXT_111: "Q",
    TEXT_112: "お申し込みのキャンセルをしたいのですが。",
    TEXT_113: "A",
    TEXT_114:
      "まずは お問合せフォームもしくは電話予約フォームまでお問合せください。ご契約情報を確認した上で弊社にてお手続き可能かどうかご連絡いたします。",
    TEXT_115: "Q",
    TEXT_116: "解約したいのですが",
    TEXT_117: "A",
    TEXT_118:
      "ご契約のプロバイダまでご連絡ください。また、レンタル機器のご返却は、解約日以降にIF MOBILEから着払い伝票をお送りいたしますので、その伝票をご利用の上、ご返却ください。",
    TEXT_119: "Q",
    TEXT_120: "何時まで受付可能ですか？",
    TEXT_121: "A",
    TEXT_122: "10時～19時までの受付となっております。",
    data: {
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION14_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "SIM",
    TEXT_7: "Wi-Fi",
    TEXT_8: "光回線",
    TEXT_9: "お問い合わせ",
    TEXT_10: "お問い合わせ",
    TEXT_11:
      "If Mobileに関するご質問・ご相談は公式LINE・WECHATアカウントでも承ります。",
    TEXT_12: "LINE",
    TEXT_13: "WECHAT",
    TEXT_14: "LINE",
    TEXT_15: "WECHAT",
    TEXT_16:
      "以下については、Twitterでご質問いただいた場合でも、サポートセンターの窓口をご案内いたします。",
    TEXT_17: "• お客様の契約内容を参照しなければ回答できないご質問",
    TEXT_18: "• お客様に個人情報をお伺いしなければ回答できないご質問",
    TEXT_19: "• その他、お客様個別の事情をお伺いしなければならないご質問",
    TEXT_20: "電話によるお問い合わせ",
    TEXT_21: "If Mobileサポートセンター（10:00～19:00）",
    TEXT_22: "080-2889-4366",
    TEXT_23:
      "• ご契約内容に関わるお問い合わせにつきましては、契約者ご本人様からをお問い合わせください。",
    TEXT_24:
      "• If Mobileサポートセンターは、日本語、中国語、英語の対応となります。If Mobile Support Center is available in Japanese,Chinese,English.",
    TEXT_25: "メールによるお問い合わせ",
    TEXT_26: "info&#64;ifmobile.jp",
    TEXT_27: "• 契約者ご本人様の氏名、電話番号をご記入ください。",
    TEXT_28:
      "• ご返信が遅くなる場合がございますので、SNSからのご連絡をおすすめします。",
    data: {
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION2_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "本人確認手続きについて",
    TEXT_7:
      "1.日本の法律に基づき、音声データカードを購入する場合は、住所が記載された本人確認書類の提出が義務付けられております。また、残存有効期限が1ヶ月以上のものに限ります。",
    TEXT_8:
      "2.SIMカードのお届け先は、本人確認書類と一致させてください。本人確認書類と一致しない場合は、住所が証明できる補助書類を同時にアップロードしてください。発行日より6ヶ月以内のもの。SIMカードの受領時は、本人の署名が必要。",
    TEXT_9: "3.SIMカードの購入は1つの本人確認書類につき、1枚となります。",
    TEXT_10:
      "4.お客様本人が本人確認書類を手に持ち、手や物でお顔が隠れないように撮影した写真をアップロードください。その際、写真や記載内容がはっきりと読み取れるように注意して撮影ください。",
    TEXT_11:
      "5.正しい本人情報を入力してください。アップロードされた情報が上記の要件を満たしていない場合、SIMカードの購入はできません。",
    TEXT_12:
      "本人確認書類の偽造・加工修正は法令違反です。偽造や加工修正が確認された場合、ご契約をお断りさせていただきます。",
    TEXT_13: "本人確認対象必要書類（いずれか1点必要）+IDセルフィー",
    TEXT_14:
      "ご契約者様の氏名・住所・生年月日の記載があり、IF Mobileにご登録の情報と一致している書類が必要です。住所等の記載が裏面にある場合、裏面の画像も必要です。",
    TEXT_15: "日本",
    TEXT_16: "日本国外",
    TEXT_17: "個人",
    TEXT_18: "  日本国内パスポート",
    TEXT_19: "書面内の下記情報をご確認ください。",
    TEXT_20: "1.氏名の記載があること",
    TEXT_21: "2.生年月日の記載があること",
    TEXT_22: "3.有効期限内であること",
    TEXT_23: "4.「所持人記入欄」に氏名（漢字）および住所の記載があること",
    TEXT_24: "運転免許証",
    TEXT_25: "各都道府県公安委員会発行のものであること",
    TEXT_26: "国際免許証は除く",
    TEXT_27: "書面内の下記情報をご確認ください。",
    TEXT_28: "1.氏名、住所、生年月日の記載があること",
    TEXT_29: "2.有効期限内であること",
    TEXT_30:
      "3.裏面に記載がある場合、公安印が鮮明であることを確認し、裏面も撮影・アップロードしてください。",
    TEXT_31:
      "現住所が異なる場合や配送に必要な情報（建物名や部屋番号など）が記載されていない場合は補助書類も必ずご提出ください。",
    TEXT_32: "在留カード",
    TEXT_33: "書面内の下記情報をご確認ください。",
    TEXT_34: "1.氏名、住所、生年月日の記載があること",
    TEXT_35: "2.有効期限内であること",
    TEXT_36:
      "3.裏面に住所の記載がある場合、裏面も撮影・アップロードしてください。",
    TEXT_37:
      "現住所が異なる場合や配送に必要な情報（建物名や部屋番号など）が記載されていない場合は補助書類も必ずご提出ください。",
    TEXT_38: "法人",
    TEXT_39: "履歴事項全部証明書",
    TEXT_40: "日本",
    TEXT_41: "日本国外",
    TEXT_42: "身分証明書",
    TEXT_43: "書面内の下記情報をご確認ください。",
    TEXT_44: "1.氏名の記載があること",
    TEXT_45: "2.生年月日の記載があること",
    TEXT_46: "3.有効期限内であること",
    TEXT_47: "4.「所持人記入欄」に氏名（漢字）および住所の記載があること",
    TEXT_48: "外国パスポート",
    TEXT_49: "書面内の下記情報をご確認ください。",
    TEXT_50: "1.氏名の記載があること",
    TEXT_51: "2.生年月日の記載があること",
    TEXT_52: "3.有効期限内であること",
    TEXT_53: "4.「所持人記入欄」に氏名（漢字）および住所の記載があること",
    TEXT_54: "IDセルフィー",
    TEXT_55: "書面内の下記情報をご確認ください。",
    TEXT_56: "1.画像内にご本人の顔と顔写真付き身分証明書表面が同時に写っている",
    TEXT_57: "2.証明書の文字、顔写真が明確に判別できる",
    TEXT_58: "3.顔は正面を向いて、無帽で髪が目にかかっていない",
    TEXT_59: "他の人が写り込んでいない ",
    TEXT_60:
      "自撮りが難しい場合は上記条件を満たした上で、他の方に写真を撮影頂いても構いません。                                         ",
    TEXT_61:
      "画像が不鮮明な場合や不備がある場合は、再提出が必要となりますので予めご了承下さい。",
    TEXT_62: "本人確認対象補助書類（いずれか1点必要）",
    TEXT_63: "健康保険証",
    TEXT_64: "書面内の下記情報をご確認ください。",
    TEXT_65: "1.氏名、住所、生年月日が申し込み内容と同じであること",
    TEXT_66: "2.有効期限内であること",
    TEXT_67: "マイナンバーカード",
    TEXT_68: "書面内の下記情報をご確認ください。",
    TEXT_69: "1.裏面には個人番号が記載されていること",
    TEXT_70: "2.氏名、住所、生年月日が申し込み内容と同じであること",
    TEXT_71: "3.有効期限内であること",
    TEXT_72: "学生証",
    TEXT_73: "書面内の下記情報をご確認ください。",
    TEXT_74: "1.氏名、住所、生年月日の記載があること",
    TEXT_75: "2.有効期限内であること",
    TEXT_76: "在留許可通知書",
    TEXT_77: "書面内の下記情報をご確認ください。",
    TEXT_78: "1.氏名、学校、所属の記載があること",
    TEXT_79: "2.有効期限内であること",
    TEXT_80: "3.確認書類の氏名と同じであること",
    TEXT_81: "上陸証明書",
    TEXT_82: "書面内の下記情報をご確認ください。",
    TEXT_83: "1.氏名、都市、ターミナル、時間、予約番号の記載があること",
    TEXT_84: "2.有効期限内であること",
    TEXT_85: "3.確認書類の氏名と同じであること",
    TEXT_86: "本人確認書類の撮影・画像アップロードについて",
    TEXT_87:
      "本人確認書類はスマートフォンや携帯電話、デジタルカメラで撮影してください。",
    TEXT_88: "書類の記載内容が読みとれる画像であることを確認してください。",
    TEXT_89: "書類全体が入るように撮影・スキャンしてください。",
    TEXT_90:
      "SIMカード（音声/SMS/データ）をお申し込みの場合、アップロード可能な画像のファイル形式はJPEGのみとなります。",
    TEXT_91: "その他のファイルはアップロードできません。",
    TEXT_92: "撮影のポイント（NGとなりやすい例）",
    TEXT_93:
      "本人確認書類・補助書類の記載事項がうまく読み取れない場合、書類の再提出をお願いすることがあります。",
    TEXT_94:
      "下記提出書類確認NGとなりやすいパターンをご確認のうえ、ご用意いただいた画像に問題がないかご確認ください。",
    TEXT_95: "撮影のポイント（良い画像の例）",
    TEXT_96: "・書類全体が明瞭に撮影されている",
    TEXT_97: "・書類が写真の中心に撮影されている",
    TEXT_98: "・背景が黒などの濃い色で単色である",
    TEXT_99: "余白が少なく、書面が大きく写っている（余白目安：画像の1/3以下）",
    TEXT_100: "本人確認が不備になった場合",
    TEXT_101:
      "何らかの理由で本人確認が不備となった場合、再度本人確認手続きをしていただく必要があります。",
    TEXT_102: "メール記載の不備理由をご確認の上、",
    TEXT_103: "会員情報ページ",
    TEXT_104: "よりお手続きください。",
    TEXT_105: "本人確認書類にてよくある不備は",
    TEXT_106: "こちら",
    data: {
      showID: true,
      loopData1: [
        {
          lanhutext0: "一部が見切れている、または隠れている",
          lanhuimage0: require("@/assets/lxl/SketchPng9fbd9ce37f6526170708c2460680c40ca300ca2285ac63fbbf6c3f76c0bc4a55.png"),
        },
        {
          lanhutext0: "一部が見切れている、または隠れている",
          lanhuimage0: require("@/assets/lxl/SketchPng748af35da87a9fb6d45983b91b707f413fa1f1b46014e9bd6ab4343f48b9b419.png"),
        },
        {
          lanhutext0: "一部が見切れている、または隠れている",
          lanhuimage0: require("@/assets/lxl/SketchPngc815c49a71be691064c5a1edc59a187c96b91b6b46730373f1d3ef7ad08606f3.png"),
        },
        {
          lanhutext0: "一部が見切れている、または隠れている",
          lanhuimage0: require("@/assets/lxl/SketchPng17f75f61cee4bb33a0d450b2c98b95f1a56533031dfcc1445430362d6927c2d7.png"),
        },
      ],
      constants: {},
      loopData0: [
        {
          lanhuBg0: "rgba(255,255,255,1.000000)",
          lanhutext0: "本人確認対象必要書類",
          lanhufontColor0: "rgba(45,42,40,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "本人確認対象補助書類",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
        {
          lanhuBg0: "rgba(112,103,97,1.000000)",
          lanhutext0: "本人確認書類の撮影について",
          lanhufontColor0: "rgba(255,255,255,1)",
        },
      ],
      constants: {},
    },
  },
  DETAILS_SERVERS_SECTION3_INDEX: {
    TEXT_0: "サポート",
    TEXT_1: "Q＆A",
    TEXT_2: "本人確認",
    TEXT_3: "本人確認書類にてよくある不備",
    TEXT_4: "窓口",
    TEXT_5: "SNS",
    TEXT_6: "ご提出いただく本人確認書類にてよくある不備について",
    TEXT_7:
      "IFモバイルではお申し込み時に本人確認書類の提出をお願いしております。",
    TEXT_8:
      "ご提出いただいた本人確認書類に不備がある場合、改めてアップロードしていただく必要があるため、IFモバイルのサービスをご提供するまでにお時間をいただく場合がございます。",
    TEXT_9:
      "本人確認書類をご提出いただく前に、下記のよくある不備をご確認ください。",
    TEXT_10: "●本人確認書類と契約者情報の氏名に差異がある",
    TEXT_11: "●本人確認書類と契約者情報の住所に差異がある",
    TEXT_12: "●本人確認書類と契約者情報の生年月日に差異がある",
    TEXT_13: "●本人確認書類が不鮮明である",
    TEXT_14: "●本人確認書類の有効期限が切れている",
    TEXT_15: "●本人確認書類がマイナンバー通知カードである",
    TEXT_16: "●運転免許証の裏面に公印のない加筆・加工がある",
    TEXT_17:
      "●在留カード/在留許可通知書の提出がされていない（外国籍のお客様のみ）",
    TEXT_18: "●書類タイプが不一致である",
    TEXT_19: "●ご提出いただいた補助書類に不備がある",
    TEXT_20: "本人確認書類の撮影・画像アップロードについて",
    TEXT_21:
      "→氏名の読み方が同じでも、ご提出いただく本人確認書類と契約者情報の氏名の表記に差異がある場合は不備となります。",
    TEXT_22:
      "お申し込み画面で表示される契約者情報の表記とアップロード予定の本人確認書類の表記がすべて一致していることをご確認いただき、必要に応じて契約者情報の変更をしてください。",
    TEXT_23: "変更方法は「会員専用ページ",
    TEXT_24: "⇒",
    TEXT_25: "注文状況",
    TEXT_26: "⇒",
    TEXT_27: "未完成",
    TEXT_28: "⇒",
    TEXT_29: "処理」をご確認ください。",
    TEXT_30:
      "本人確認書類と契約者情報の氏名に差異があることにより、不備となる例は下記の通りです。",
    TEXT_31: "＜カタカナと漢字で差異がある場合＞ ",
    TEXT_32: "（例）スズキ、鈴木",
    TEXT_33: "＜ローマ字と漢字で差異がある場合＞",
    TEXT_34: "（例）Wang、王",
    TEXT_35: "＜ローマ字とカタカナで差異がある場合＞ ",
    TEXT_36: "（例）Kevin、ケヴィン",
    TEXT_37: "＜旧字体と新字体で差異がある場合＞ ",
    TEXT_38: "（例）髙橋、高橋",
    TEXT_39: "＜通称と本名で差異がある場合＞",
    TEXT_40:
      "外国籍のお客様は、在留カードまたは在留許可通知書上の氏名でご登録ください。",
    TEXT_41: "＜名義違いで差異がある場合＞",
    TEXT_42:
      "他社からお乗り換えの際はお乗り換え前の名義をお申し込み名義と一致させてください。",
    TEXT_43: "＜旧姓と現姓で差異がある場合＞",
    TEXT_44: "現在の姓が記載されている本人確認書類のご用意をお願いいたします。",
    TEXT_45: "＜ミドルネームの入力箇所が誤っている場合＞",
    TEXT_46:
      "「姓」の欄にファーストネーム、「名」の欄にラストネームからミドルネームをご入力ください。",
    TEXT_47: "(例)【氏名】IF Taro Mobile",
    TEXT_48: "姓：IF",
    TEXT_49: "名：Taro Mobile",
    TEXT_50: "＜ラストネームのみの入力箇所が誤っている場合＞",
    TEXT_51:
      "「姓」の欄にラストネームの2文字、「名」の欄にラストネームの3文字以降をご入力ください。",
    TEXT_52: "(例)【氏名】IFmobile",
    TEXT_53: "姓：IF",
    TEXT_54: "名：mobile",
    TEXT_55: "＜漢字とローマ字が併記されている場合＞",
    TEXT_56:
      "在留カードまたは在留許可通知書上の氏名が漢字とローマ字併記されている場合は、全てローマ字でご登録ください。",
    TEXT_57: "（例）氏名：佐藤 太郎 NAME：SATO TARO",
    TEXT_58: "正：SATO  TARO",
    TEXT_59: "誤：佐藤 太郎",
    TEXT_60: "本人確認書類と契約者情報の住所に差異がある",
    TEXT_61:
      "→ご提出いただいた本人確認書類とご登録いただいた契約者情報の住所が一致している必要がございます。",
    TEXT_62: "ご登録いただいた内容に不備がある場合の変更方法は",
    TEXT_63: "「会員専用ページ",
    TEXT_64: "⇒",
    TEXT_65: "注文状況",
    TEXT_66: "⇒",
    TEXT_67: "未完成",
    TEXT_68: "⇒",
    TEXT_69: "処理」をご確認ください。",
    TEXT_70:
      "また、本人確認書類に記載の住所が最新ではない場合は、健康保険証と補助書類をご提出いただくなど、最新の情報が確認できる本人確認書類を利用して、本人確認を行ってください。",
    TEXT_71: "詳しくは「本人確認書類」をご確認ください。",
    TEXT_72: "住所に差異がある場合の、よくある例は下記の通りです。",
    TEXT_73: "＜ご入力いただいた住所に抜けている箇所がある場合＞",
    TEXT_74: "市、区、お住いの棟、部屋番号などが抜けていないかご確認ください。",
    TEXT_75: "＜マンション等の部屋番号に差異がある場合＞",
    TEXT_76:
      "マンションやアパートにお住いのお客様は、部屋番号に誤りがないかご確認ください。",
    TEXT_77: "本人確認書類と契約者情報の住所に差異がある",
    TEXT_78:
      "→ご提出いただいた本人確認書類とご登録いただいた契約者情報の住所が一致している必要がございます。",
    TEXT_79: "ご登録いただいた内容に不備がある場合の変更方法は",
    TEXT_80: "「会員専用ページ",
    TEXT_81: "⇒",
    TEXT_82: "注文状況",
    TEXT_83: "⇒",
    TEXT_84: "未完成",
    TEXT_85: "⇒",
    TEXT_86: "処理」をご確認ください。",
    TEXT_87:
      "また、本人確認書類に記載の住所が最新ではない場合は、健康保険証と補助書類をご提出いただくなど、最新の情報が確認できる本人確認書類を利用して、本人確認を行ってください。",
    TEXT_88: "詳しくは「本人確認書類」をご確認ください。",
    TEXT_89: "住所に差異がある場合の、よくある例は下記の通りです。",
    TEXT_90: "＜ご入力いただいた住所に抜けている箇所がある場合＞",
    TEXT_91: "市、区、お住いの棟、部屋番号などが抜けていないかご確認ください。",
    TEXT_92: "＜マンション等の部屋番号に差異がある場合＞",
    TEXT_93:
      "マンションやアパートにお住いのお客様は、部屋番号に誤りがないかご確認ください。",
    TEXT_94: "本人確認書類と契約者情報の生年月日に差異がある",
    TEXT_95:
      "→本人確認書類と契約者情報の生年月日が一致している必要がございます。",
    TEXT_96: "生年月日の変更方法は「会員専用ページ",
    TEXT_97: "⇒",
    TEXT_98: "注文状況",
    TEXT_99: "⇒",
    TEXT_100: "未完成",
    TEXT_101: "⇒",
    TEXT_102: "処理」をご確認ください。",
    TEXT_103: "本人確認書類が不鮮明である",
    TEXT_104:
      "→本人確認書類の画像が不鮮明だと、内容が確認できない場合がございます。",
    TEXT_105:
      "アップロードをした本人確認書類が下記事項に当てはまっていないことをご確認ください。",
    TEXT_106:
      "・ご提出いただいた本人確認書類が不鮮明である（ぼやけている、ぶれている、画質が荒い等）",
    TEXT_107: "・ご提出いただいた本人確認書類の一部が反射している",
    TEXT_108:
      "カメラのフラッシュ機能をOFFにすると強い反射を抑えることができます",
    TEXT_109: "・ご提出いただいた本人確認書類の全体が確認できない",
    TEXT_110: "本人確認書類の有効期限が切れている",
    TEXT_111:
      "→恐れ入りますが、有効期限内の本人確認書類のご用意をお願いいたします。",
    TEXT_112:
      "ご利用いただける本人確認書類の一覧は「本人確認書類」をご確認ください。",
    TEXT_113: "本人確認書類がマイナンバー通知カードである",
    TEXT_114:
      "→マイナンバー通知カードは本人確認書類としてご利用いただけません。",
    TEXT_115:
      "なお、マイナンバーカードをお持ちの場合は氏名・住所・顔写真が確認できる表面のみのご提出をお願いいたします。（裏面および補助書類の添付は不要です）",
    TEXT_116:
      "なお、マイナンバー通知カードを本人確認書類として提出されたお客様で、マイナンバーカードをお持ちでない場合はマイナンバーカード以外の書類タイプをご選択の上、改めてアップロードをお願いいたします。",
    TEXT_117:
      "ご利用いただける本人確認書類の一覧は「本人確認書類」をご確認ください。",
    TEXT_118: "運転免許証の裏面に公印のない加筆・加工がある",
    TEXT_119:
      "備考欄に公印のない加筆が認められる運転免許証に関しては、有効な住所確認書類としてご利用いただけません。",
    TEXT_120: "恐れ入りますが、別の本人確認書類のご用意をお願いいたします。",
    TEXT_121:
      "ご利用いただける本人確認書類の一覧は「本人確認書類」をご確認ください。",
    TEXT_122:
      "在留カード/在留許可通知書の提出がされていない（外国籍のお客様のみ）",
    TEXT_123:
      "外国籍のお客様におかれましては、在留カードか在留許可通知書のご提出をお願いいたします。",
    TEXT_124:
      "なお、審査基準に満たない書類はお受付できませんので、その場合は在留カードまたは在留許可通知書を更新後、お申し込みください。",
    TEXT_125: "書類タイプが不一致である",
    TEXT_126:
      "お申し込みいただきました書類タイプとご提出いただきました書類に相違がないかご確認お願いいたします。",
    TEXT_127: "ご提出いただいた補助書類に不備がある",
    TEXT_128: "＜ご提出いただいた住民票に個人番号の記載がある場合＞",
    TEXT_129:
      "ご提出いただいた住民票に個人番号（マイナンバー）の記載がある場合は、住所確認書類として利用いただけません。",
    TEXT_130:
      "恐れ入りますが、紙などで本籍地および個人番号の箇所のみ隠した状態で画像を撮影、アップロードをお願いいたします。",
    TEXT_131: "その他ご提出いただいた補助書類でよくある不備",
    TEXT_132: "・ご提出いただいた補助書類が不鮮明である",
    TEXT_133:
      "・ご提出いただいた補助書類の全体が確認できない（部分的な画像になっている）",
    TEXT_134: "・ご提出いただいた補助書類が対象外書類である",
    data: {
      constants: {},
    },
  },
  DETAILS_SIM_SECTION3_INDEX: {
    TEXT_0: "SIM",
    TEXT_1: "IF SIMの特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "MNP",
    TEXT_4: "初期設定",
    TEXT_5: "支払方法",
    TEXT_6: "他社",
    TEXT_7: "IF Mobile",
    TEXT_8: "MNP",
    TEXT_9: "MNP（携帯電話番号ポータビリティ）とは",
    TEXT_10:
      "MNP＝携帯電話番号ポータビリティ（Mobile Number Portability）とは、携帯電話会社を変更した場合に電話番号はそのままで、変更後のサービスを利用できる制度です。",
    TEXT_11: "だから",
    TEXT_12: "IF Mobileに乗り換えば...今ご利用中の電話番号のままで",
    TEXT_13: "日本国内の大手キャリアよりも",
    TEXT_14: "毎月の基本料金が安く利用できます",
    TEXT_15: "15GBの料金で",
    TEXT_16: "50GBを利用できる！",
    TEXT_17: "15GB",
    TEXT_18: "更换",
    TEXT_19: "0",
    TEXT_20: "円",
    TEXT_21: "1GBプラン利用の場合",
    TEXT_22: "無料",
    TEXT_23: "15GBを申し込んだ方のみ限定となります。",
    TEXT_24:
      "２ヶ月間以降、１GB以外のプランを利用したい場合は、各プランの基本料金となります",
    TEXT_24_1:
      "他社から乗換え(MNP)の場合は,最初2ヶ月間は15GBを申し込んだ方限定、15GBの料金で50GBを利用できます。さらに、その以降の４ヶ月間は1GBプラン利用の場合、無料でご利用いただけます",
    TEXT_25: "35GB",
    TEXT_26: "無料",
    TEXT_27: "50GB",
    TEXT_28: "2ヶ月間で",
    TEXT_29: "4400円",
    TEXT_30: "お得!",
    TEXT_31: "最初の2ヶ月間",
    TEXT_32: "それ以降の４ヶ月間",
    TEXT_33: "MNPの流れ",
    TEXT_34: "01",
    TEXT_35: "02",
    TEXT_36: "03",
    TEXT_37: "04",
    TEXT_38: "05",
    TEXT_39: "06",
    TEXT_40: "07",
    TEXT_41: "08",
    TEXT_42: "契約必要な物を準備する",
    TEXT_43: "今の通信事業 者でMNP予約番号を取得する",
    TEXT_44: "IF MobileでMNP申し込みを行う",
    TEXT_45: "初回に必要な支払いをしてください",
    TEXT_46: "IFが本人確認審査をする",
    TEXT_47: "SIMカードが発送",
    TEXT_48: "お客様がSIMカードを受け取り",
    TEXT_49: "MNP切替手続きを行う",
    TEXT_50: "APN設定をして利用開始",
    TEXT_51: "今すぐ申込み！",
    TEXT_52: "09",
    TEXT_53: "IF Mobile音声通話機能付きSIM",
    TEXT_54: "1GB",
    TEXT_55: "980円",
    TEXT_56: "税込1078円",
    TEXT_57: "3GB",
    TEXT_58: "1380円",
    TEXT_59: "税込1518円",
    TEXT_60: "5GB",
    TEXT_61: "1780円",
    TEXT_62: "税込1958円",
    TEXT_63: "10GB",
    TEXT_64: "2280円",
    TEXT_65: "税込2508円",
    TEXT_66: "15GB",
    TEXT_67: "2780円",
    TEXT_68: "税込3058円",
    TEXT_69: "20GB",
    TEXT_70: "3280円",
    TEXT_71: "税込3608円",
    TEXT_72: "50GB",
    TEXT_73: "4780円",
    TEXT_74: "税込5258円",
    TEXT_75: "詳細",
    TEXT_76: "申込",
    TEXT_77: "契約可能な方は18歳以上のご本人様",
    TEXT_78:
      "本人確認書類は30日以上の有効期間かつ現住所が記載されたもの（免許証、パスポート、在留カードなど有効期間内の現住所記載された顔写真付き身分証明書）",
    TEXT_79: "初期費用（それぞれのプランで異なります）",
    TEXT_80:
      "MNP（ナンバーポータビリティ）予約番号（7日以上有効期間が残っているもの）",
    TEXT_81:
      "キャリア契約のスマートフォンについては事前にSIMロック解除を行ってください",
    TEXT_82: "契約に必要な物を確認する",
    TEXT_83: "MNP予約番号を取得する",
    TEXT_84: "MNP予約番号の有効期限は予約番号発行日を含め15日間です",
    TEXT_85: "お申込み時に7日以上有効期限が残っていないと受付できません",
    TEXT_86: "MNP予約番号の名義と申し込みの名義は同じ名義であることが必要です",
    TEXT_87: "（MNP予約番号の取得方法）",
    TEXT_88: "ご利用方法",
    TEXT_89: "受付窓口",
    TEXT_90: "ご利用方法",
    TEXT_91: "Docomo",
    TEXT_92: "AU",
    TEXT_93: "Softbank",
    TEXT_94: "Y！Mobile",
    TEXT_95: "携帯電話",
    TEXT_96: "一般電話",
    TEXT_97: "WEB",
    TEXT_98: "携帯電話/一般電話",
    TEXT_99: "WEB",
    TEXT_100: "携帯電話/一般電話",
    TEXT_101: "WEB",
    TEXT_102: "携帯電話/一般電話",
    TEXT_103: "WEB",
    TEXT_104: "151",
    TEXT_105: "0120-800-000",
    TEXT_106: "https://www.docomo.ne.jp/mydocomo/",
    TEXT_107: "0077-75470",
    TEXT_108: "https://www.au.com/my-au/",
    TEXT_109: "0800-100-5533",
    TEXT_110: "https://www.softbank.jp/mysoftbank/",
    TEXT_111: "0570-039-151",
    TEXT_112: "https://www.ymobile.jp/support/process/cancellation/",
    TEXT_113: "9:00-20:00",
    TEXT_114: "9:00-20:00",
    TEXT_115: "9:00-21:30",
    TEXT_116: "9:00-20:00",
    TEXT_117: "9:00-20:00",
    TEXT_118: "9:00-20:00",
    TEXT_119: "9:00-20:00",
    TEXT_120: "9:00-20:00",
    TEXT_121: "9:00-20:00",
    TEXT_122: "UQモバイル",
    TEXT_123:
      "オンライン:my UQ mobile 「ご契約内容」>　「契約者情報照会/変更」",
    TEXT_124: "楽天モバイル",
    TEXT_125:
      "オンライン:メンバ―ズステーション＞会員メニュー＞登録情報.設定変更＞MNP予約番号発行確認",
    TEXT_126: "詳細は各業者にご確認ください",
    TEXT_127: "お申込み～本人確認審査",
    TEXT_128: "IF MobileのMNPお申し込みページよりお申し込みください",
    TEXT_129: "- お申し込み完了後、初期費用をお支払いください。",
    TEXT_130:
      "- 現在契約中の携帯会社での名義とIF Mobileを契約する際の名義は同じ本人の名義である必要があります。（家族や友達の回線からの切り替えは出来ません）",
    TEXT_131:
      "- 入力された情報とMNPの必要情報（携帯電話番号、MNP予約番号、MNP予約番号の有効期限、名前、カタカナ名前、生年月日、性別）がすべて一致する必要があります。",
    TEXT_132:
      "- MNP切り替え希望日は申請日から4日後〜MNPの有効期限内で選択をしてください。",
    TEXT_133:
      "- 入力情報やMNPの情報、または本人確認情報が１つでも違う場合、SIMカードのお届けやお届け後のSIMの切り替えができません。その場合でも返金の対応はできません。",
    TEXT_134:
      "- MNP予約番号は申し込みの時に期日まで7日間以上残っていないと受付できません。７日以内の場合は再度MNP予約番号を取り直してください。",
    TEXT_135:
      "本人確認審査上、日数が掛かった際、切り替え希望日の再申請やお客様に再度予約番号を取り直していただく場合があります。",
    TEXT_136: "SIMカード到着後～ご利用開始",
    TEXT_137:
      "旧SIMにメッセージが送信されるURLにアクセスし、MNP切替開通をおこなってください。",
    TEXT_138: " 開通後、新SIMカードをセットしAPN初期設定をおこなってください。",
    TEXT_139: "APN初期設定案内",
    TEXT_140: "今すぐ申込み！",
    TEXT_141: "今の電話番号そのまま！",
    TEXT_141_1: "事務手数料が無し",
    TEXT_142: "1GB毎月利用料金0円～",
    TEXT_143: "事務手数料が無し",
    TEXT_144: "無料で50GBをアップグレード",
    TEXT_145: "ご購入・お申し込みはこちらへ",
    data: {
      constants: {},
    },
  },
  DETAILS_SIM_SECTION4_INDEX: {
    TEXT_0: "SIM",
    TEXT_1: "IF SIMの特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "MNP",
    TEXT_4: "初期設定",
    TEXT_5: "支払方法",
    TEXT_6: "SIMカードを受け取ったら、\n          初期設定をして利用開始！",
    TEXT_7: "MNP転入の回線切替（新規の方は不要）",
    TEXT_8: "",
    TEXT_9: "回線切替手続きの流れ",
    TEXT_10: "step.01",
    TEXT_11:
      "「MNP転入の手続きが完了」というメッセージに記載のURLにアクセスする",
    TEXT_12:
      "本手続きは必ずIF Mobileの新SIMカードに差し替える前に行ってください。",
    TEXT_13: "旧SIMにメッセージが届く",
    TEXT_14: "MNP転入の手続きが完了いたしました。",
    TEXT_15:
      "SIMカード受取後、本メッセージの後に送信されるURLにアクセスし、SIMカード記載の15桁の英数字と電話番号を入力して回線開通手続を実施ください。",
    TEXT_16: "https://mnpswitch.aclink-oem.com/",
    TEXT_17:
      "１．MNP転入を申請した後、こちらのメッセージが送信されます。新SIMカード受取までは回線切替開通操作は不要です。",
    TEXT_18:
      "２．新SIMカード受取後、送信されるURLにアクセスし、入力の画面になります。電話番号とSIMカードで記載された15桁英数字(ICCID)、MNP予約番号をご入力し、「認証」をクリックください。",
    TEXT_19:
      "https://mnpswitch.aclink-oem.com/   xxxxxxxxには対象回線のMNP予約番号が表示されます。",
    TEXT_20: "step.02",
    TEXT_21: "本登録をタップ",
    TEXT_22:
      "「本登録」をクリックすると、回線切替処理が開始されます、処理完了と同時にこれまでお使いのSIMカードは利用できなくなりますので注意ください。",
    TEXT_23: "本登録",
    TEXT_24:
      "「認証」をクリックし、こちらの画面になります。「本登録」をクリックしてください。回線切替処理は数分がかかります。",
    TEXT_25:
      "回線切替転入開通手続きを完了してから新しいSIMを装着してください。旧SIMは利用できなくなります。",
    TEXT_26:
      "回線切替処理時間は9:00～19:00となります。19:00以降登録の場合、翌日9:00から順次処理します。",
    TEXT_27:
      " 19:00以降回線切替を行ったら、翌日に新SIMを挿入してください。旧SIMが翌日までまだ利用可能です。",
    TEXT_28:
      "MNP予約番号有効期限の前日までに必ず開通のお手続きをお願いします。",
    TEXT_29: "step.03",
    TEXT_30: "10分以上後また翌日、新SIM挿入し、APN初期設定へ",
    TEXT_31: "APN（ネットワーク）初期設定は以下",
    TEXT_32: "iPhone【iOS】接続設定",
    TEXT_33:
      "「MNP転入の手続きが完了」というメッセージに記載のURLにアクセスする",
    TEXT_34: "●設定する際、Wi-Fiがある環境で必要です。",
    TEXT_35:
      "●デュアルSIMスロットの場合は、新しい SIM をカード スロット 1 に挿入し、古い SIM を引き出し、同時にインストールしないでください。",
    TEXT_36: "●他のAPNプロファイルを削除してください。",
    TEXT_37: "step.01",
    TEXT_38:
      "Safariで下記のURLからAPN構成プロファイルをダウンロードします。 http://bizimo.ne.jp/apn ",
    TEXT_39: "以下の",
    TEXT_40: "QRコード",
    TEXT_41: "または",
    TEXT_42: "こちら",
    TEXT_43: "へアクセスして下さい。",
    TEXT_44: "step.02",
    TEXT_45: "step.03",
    TEXT_46: "許可を選択",
    TEXT_47: "設定＞一般＞VPNとデバイス管理を選択",
    TEXT_48: "step.04",
    TEXT_49: "step.05",
    TEXT_50: "mvno.netを選択",
    TEXT_51: "インストールをタップ",
    TEXT_52: "以前ダウンロードしたAPNを削除する必要です。",
    TEXT_53: "step.06",
    TEXT_54: "step.07",
    TEXT_55: "設定した携帯ロック解除パスワードを入力、次へを押し",
    TEXT_56: "右上「インストール」をタップ",
    TEXT_57: "step.08",
    TEXT_58: "step.09",
    TEXT_59: "右上「完了」をタップ",
    TEXT_60:
      "「インストール完了」画面が表示され、インストールが完了した事をご確認下さい。",
    TEXT_61:
      "モバイルデータ通信を有効にして下さい。 設定＞モバイル通信、モバイルデータ通信→ON、ネットワーク選択→ 自動。",
    TEXT_62:
      "「インストール完了」画面が表示され、インストールが完了した事をご確認下さい。",
    TEXT_63: "step.10",
    TEXT_64: "Wi-Fi接続をオフにして、",
    TEXT_65:
      "safariで任意のウェブサイトにアクセスするなどしてインターネット接続が出来ている事をご確認ください。",
    TEXT_66: "日本国内の任意電話番号に電話できる事をご確認ください。",
    TEXT_67: "（APN初期設定案内）",
    TEXT_68:
      "IF Mobileで新しい電話番号を取得された方は、APN設定に進んでください。",
    TEXT_69: "【Android】接続設定",
    TEXT_70: "端末にSIMカードを挿し入れ",
    TEXT_71: "●設定する際、Wi-Fiがある環境で必要です。",
    TEXT_72:
      "●デュアルSIMスロットの場合は、新しい SIM をカード スロット 1 に挿入し、古い SIM を引き出し、同時にインストールしないでください。",
    TEXT_73: "●他のAPNプロファイルを削除してください。",
    TEXT_74: "APN",
    TEXT_75: "設定情報",
    TEXT_76: "ご利用の端末のAPN設定を以下のように設定して下さい。",
    TEXT_77: "名前(接続先名)",
    TEXT_78: "If Mobile",
    TEXT_79: "APN(接続先)",
    TEXT_80: "phone-sim.com",
    TEXT_81: "ユーザー名(認証ID)",
    TEXT_82: "mvno",
    TEXT_83: "パスワード",
    TEXT_84: "mvno",
    TEXT_85: "MCC",
    TEXT_86: "440",
    TEXT_87: "MNC",
    TEXT_88: "10",
    TEXT_89: "認証タイプ",
    TEXT_90: "PAPまたはCHAP",
    TEXT_91: "注意：設定をする際は全て小文字で入力してください。",
    TEXT_92: "step.01",
    TEXT_93: "step.02",
    TEXT_94: "設定＞モバイルネットワークを選択",
    TEXT_95: "モバイルデータ通信をタップ",
    TEXT_96: "「モバイルネットワーク」が表示されていない場合は",
    TEXT_97:
      "「もっと見る」などをタップして「モバイルネットワーク」項目をお探し下さい。",
    TEXT_98: "step.03",
    TEXT_99: "step.04",
    TEXT_100: "アクセスポイント名（APN）をタップ",
    TEXT_101: "APN］画面での右上のメニューなどから［新しいAPN］をタップ",
    TEXT_102: "step.05",
    TEXT_103: "step.06",
    TEXT_104: "APN情報各項目を入力",
    TEXT_105: "認定タイプPAP またはCHAPを選択し、メニューから「",
    TEXT_106: "⁞",
    TEXT_107: "」→設定を保存してください",
    TEXT_108: "step.07",
    TEXT_109: "step.08",
    TEXT_110:
      "APN一覧画面に 「If Mobile」が表示されている事を確認し、「If Mobile」を選択して下さい。",
    TEXT_111: "ネットワーク確認手順：",
    TEXT_112:
      "●設定→モバイルネットワーク→モバイルデータ通信→モバイルデータ通信とLTEを有効にして下さい。",
    TEXT_113: "●設定→モバイルネットワーク→SIM管理→SIM1をONにして下さい。",
    TEXT_114: "step.09",
    TEXT_115: "Wi-Fi接続をオフにして、",
    TEXT_116:
      "safariで任意のウェブサイトにアクセスするなどしてインターネット接続が出来ている事をご確認ください。",
    TEXT_117: "日本国内の任意電話番号に電話できる事をご確認ください。",
    TEXT_118: "ご利用開始",
    TEXT_119: "（APN初期設定案内）",
    TEXT_120:
      "IF Mobileで新しい電話番号を取得された方は、APN設定に進んでください。",
    data: {
      constants: {},
    },
  },
  DETAILS_SIM_SECTION5_INDEX: {
    TEXT_0: "SIM",
    TEXT_1: "IF SIMの特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "MNP",
    TEXT_4: "初期設定",
    TEXT_5: "支払方法",
    TEXT_6: "支払ガイド",
    TEXT_7: "クレジットカード",
    TEXT_8: "口座振替",
    TEXT_9: "銀行振込",
    TEXT_10: "IFポイント",
    TEXT_11: "方法一覧",
    TEXT_12: "（各項目をクリックし、詳細内容をご確認ください。）",
    TEXT_13: "コンビニエンスストア",
    TEXT_14:
      "案内方法：IF Mobileのマイページ内に掲載している番号（******桁）を用いてコンビニエンスストアでお支払いいただけます。",
    TEXT_15: "お支払いメール送信日：毎月5日-10日",
    TEXT_16: "お支払い期限日：毎月25日23:59まで",
    TEXT_17:
      "お支払いできるコンビニ：セブンイレブン、ファミリーマート、ローソン、ミニストップ",
    TEXT_17_1:
      "口座自動引落、クレジットカードからコンビニ支払いへお支払い方法を変更することはできません。",
    TEXT_18: "コンビニエンスストアによってお支払い方法は異なりますので。",
    TEXT_19: "詳細は後述の各マルチメディア端末の操作方法をご確認ください。",
    TEXT_20:
      "ローソン、ミニストップ、ファミリーマートでのスマートピットお支払い方法",
    TEXT_21: "各種クレジットカード",
    TEXT_22:
      "お申し込みページのお支払いでクレジットカードを選択して、必要事項をご入力のうえお支払いいただきます。",
    TEXT_23:
      "クレジットカードより毎月の料金を自動的に引き落とすことが可能です。",
    TEXT_24: "デビットカードをご利用できます。",
    TEXT_25:
      "毎月5日から15日の間にカード会社へご請求させていただきます。カード会社からお客様へのご請求日についてはクレジットカードの請求日、支払期日をご確認ください。",
    TEXT_26:
      "クレジットカードのお支払いが失敗された場合、次月請求よりコンビニ決済へ変更させていただきます。",
    TEXT_26_1:
      " 文字Visa、Mastercard、JCB、American Express、DinerClubのクレジットカードをご利用いただけます。Visa、Mastercard、JCBのデビットカードもご利用できます。",
    TEXT_27:
      "日本以外で発行のクレジットカードは国際支払業務（人民元/ドル）の開通が必要です。",
    TEXT_28: "ご利用できるクレジットカード",
    TEXT_29: "口座振替",
    TEXT_30:
      "銀行口座より毎月の料金を自動的に引き落とすことが可能です。あらかじめ銀行口座をご用意いただく必要があります。",
    TEXT_30_1:
      "初期費用のお支払い後、お申し込みページまたはマイページより口座自動引落登録のお手続きが必要になります。",
    TEXT_31:
      "口座引落の場合、毎月27日に銀行口座より請求額が引き落とされます。27日が土日祝日の場合、その次の銀行営業日に引き落とされます。",
    TEXT_32:
      "引落日に引落しが行われなかった場合、当月請求より他のお支払い方法へ変更させていただきます。",
    TEXT_32_1: "登録方法",
    TEXT_32_2:
      "方法①　毎月の支払いに口座自動引落を利用したい方、マイページのお支払方法に口座自動引落を選択し、下記の依頼書をダウンロードして、記入・捺印の上弊社までご返送ください。",
    TEXT_32_3:
      "方法②　初めての方、お申し込みページの今後お支払方法のところに口座自動引落を選択したら、口座自動引落を登録するための用紙と返送用の封筒を、商品と一緒に送付いたしますので、記入・捺印の上弊社までご返送ください。",

    TEXT_33: "口座振替依頼書と記入例は以下となります",
    TEXT_34: "口座振替依頼書（IF Mobile）.pdf",
    TEXT_35: "AP预金口座振替依頼書 记如例.pdf",
    TEXT_36: "印鑑の名前は必ず金融機関口座登録のものと一致する必要があります。",
    TEXT_36_1:
      "郵送先：169-0075 東京都新宿区高田馬場1-28-3工新ビル　503号室　IF Mobile　お客様サポートセンター",
    TEXT_36_2:
      "郵送時期：毎月16日までにご送付の場合には、来月から口座自動引落は可能です。",
    TEXT_36_3: "16日以降ご送付の場合には、再来月から口座自動引落は可能です。",
    TEXT_36_4: "必ず印鑑を押してください。",
    TEXT_36_5:
      "印鑑の名前は必ず金融機関口座登録のものと一致する必要があります。",
    TEXT_36_6:
      "口座自動引落からのお支払い方法の変更はクレジットカード支払いのみ可能です。",
    TEXT_36_7: "初期費用のお支払いはできません。",
    TEXT_36_8: "一度の引き落としにつき手数料150円がかかります。",
    // TEXT_36_6: "郵送先：169-0075 東京都新宿区高田馬場1-28-3工新ビル　503号室　IF Mobile　お客様サポートセンター",

    TEXT_37: "銀行振込",
    TEXT_38:
      "お振込み先情報：三井住友銀行  世田谷支店普通 5954038 セイワツウショウ(カ",
    TEXT_38_1: "支払方法はこちら  点击可跳转銀行振込方法",

    TEXT_39: "ご送金後、お客様サポートセンターまでご連絡お願い致します。",
    TEXT_40: "振込後、発行される明細票は保管いただきますようお願いいたします。",
    TEXT_41: "オンラインオート銀振サービスを利用可能です。",
    TEXT_41_1: "流れはこちら",
    TEXT_41_2:
      "お申し込みページまたはマイページよりお支払方法に「オンラインオート銀振サービス」を選択できます。",

    TEXT_42: "各種コード決済サービス",
    TEXT_43:
      "WeChat（微信）、アリペイ（支付宝）、Paypayでのお支払いは可能です。",
    TEXT_44:
      "お申し込みページまたはマイページよりお支払方法に「各種コード決済サービス」を選択し、バーコードをスキャンしてお支払いいただけます。",
    TEXT_44_1:
      "Wechatお支払いの場合はWechat APPでスキャンしてお支払いください。",
    TEXT_45: "IFポイント",
    TEXT_46: "IFポイントでのお支払いは可能です。",
    TEXT_47:
      "全額ポイント支払いのみ対応可能で、一部ポイント支払いはできません。",
    TEXT_48: "あらかじめマイページでポイント支払いの設定が必要です。",
    TEXT_49: "事前にマイページでIFポイントチャージが出来ます。",
    TEXT_50: "IFポイントより毎月の料金を自動的に引き落とすことが可能です。",
    TEXT_51:
      "初期費用、端末購入、Wi-Fiレンタル費用、データチャージ、解約金、MNP転出費用、各手数料のお支払いにはご利用いただけません。",
    TEXT_51_1: "利用流れはこちら",
    TEXT_52: "現金",
    TEXT_53: "毎月25日、営業時間まで店舗にてお支払いをお願いいたします。",
    TEXT_54:
      "店舗、代理店で現金でのお支払いが可能です。店舗営業時間は10：00～19：00です。代理店営業時間は各代理店にお問い合わせください。",
    TEXT_55: "現金は日本円のみとなります。",
    TEXT_56: "請求スケジュール",
    TEXT_57: "ご請求の内容",
    TEXT_58: "当月の基本料金",
    TEXT_59: "前々月通話料金",
    TEXT_60: "支払日",
    TEXT_61: "毎月15日〜25日",
    TEXT_62: "プラン変更",
    TEXT_63: "毎月14日までのお申し込みで翌月からプラン変更反映",
    TEXT_64: "解約時の請求",
    TEXT_65: "毎月25日までのお手続きで当月解約扱い",
    TEXT_66: "●解約月の請求",
    TEXT_67: "-当月の基本料金",
    TEXT_68: "-前々月から当月までの通話料金",
    TEXT_69: "-解約手数料",
    TEXT_70: "-（MNP転出の場合）MNP手数料",
    TEXT_71:
      "初期費用の内訳は「契約事務手数料 + 契約当月の基本料金」となります。",
    TEXT_72:
      "毎月5日～15日頃にお支払い料金についてメールでご連絡いたします。お支払い内容はマイページの未払い費用でもご確認いただけます。",
    TEXT_73: "（https://user.ifmobile.jp/ja/users/bill_detail)",
    TEXT_74: "会員登録画面",
    TEXT_75: "",
    TEXT_76: "毎月25日までにお支払いください。最後の期限が28日です。",
    TEXT_77: "",
    TEXT_78: "上記について未払いの場合は、29日からご利用できなくなります。",
    TEXT_79: "",
    TEXT_80:
      "28日まで未払いにより停止となりましても、当月末日13時までにお支払いいただければ再利用できます。",
    TEXT_81: "",
    TEXT_82:
      "当月末日13時以降請求料金を滞納した場合、お客様の契約回線を強制廃止させていただきます。強制廃止となった場合、同じ電話番号での再利用は出来ません。",
    TEXT_83: "",
    TEXT_84: "支払いスケジュール例",
    TEXT_85: "see more",
    TEXT_86: "毎月の料金",
    TEXT_87: "基本料金",
    TEXT_88: "当月に利用した分",
    TEXT_89: "が対象",
    TEXT_90: "• プラン基本料金",
    TEXT_91: "• オプション料金",
    TEXT_92: "• ユニバーサル料",
    TEXT_93: "• 電話リレーサービス料",
    TEXT_94: "通話料金",
    TEXT_95: "前々月に利用した分",
    TEXT_96: "が対象",
    TEXT_97: "• 通話利用した分",
    TEXT_98: "• SMS利用した分",
    TEXT_99: "確認方法",
    TEXT_100: "①毎月5日~15日にIF MOBILEから請求メールが届きます。",
    TEXT_101: "②",
    TEXT_102: "マイページ",
    TEXT_103: "でも請求明細や支払いの案内が確認できます。",
    TEXT_104:
      " メール受信されなかった場合やアプリで不具合があった場合はサポートまでお問い合わせください。",
    TEXT_105: "お申込の例",
    TEXT_106: "契約月",
    TEXT_107: "9月10日",
    TEXT_108: "初回請求",
    TEXT_109: "•9月の基本料金",
    TEXT_110: "(プラン・ユニバーサル・電話リレー料)",
    TEXT_111: "•初回事務手数料",
    TEXT_112: "9月15日",
    TEXT_113: "次の請求",
    TEXT_114: "•10月の基本料金",
    TEXT_115: "(プラン・ユニバーサル・電話リレー料)",
    TEXT_116: " ",
    TEXT_117: " ",
    TEXT_118: "10月15日",
    TEXT_119: "次の請求",
    TEXT_120: "11月15日",
    TEXT_121: "•11月の基本料金",
    TEXT_122: "(プラン・ユニバーサル・電話リレー料)",
    TEXT_123: "•9月の通話料金",
    TEXT_124: "(通話利用した分＋ＳＭＳ利用した分)",
    TEXT_125: "解約の例",
    TEXT_126: "解約月",
    TEXT_127: "9月",
    TEXT_128: "請求内容",
    TEXT_129: "•9月の基本料金",
    TEXT_130: "(プラン・ユニバーサル・電話リレー料)",
    TEXT_131: "•解約手数料",
    TEXT_132: "•（MNP転出の場合）MNP手数料",
    TEXT_133: "•7～9月の通話料金+SMS料金",
    TEXT_134:
      "•（8月と9月分の通話料金は計算未済のため、7月の通話料金の３倍になります。）",
    TEXT_135: "9月15日",
    TEXT_136: "精算",
    TEXT_137:
      "• 8月と9月分の通話料金を計算し、９月で請求した料金と相殺します。",
    TEXT_138: "11月15日",
    data: {
      constants: {},
    },
  },
  DETAILS_SIM_SECTION52_INDEX: {
    TEXT_0: "SIM",
    TEXT_1: "IF SIMの特徴",
    TEXT_2: "料金・プラン",
    TEXT_3: "MNP",
    TEXT_4: "初期設定",
    TEXT_5: "支払方法",
    TEXT_6: "支払ガイド",
    TEXT_7: "コンビニ",
    TEXT_8: "ネット銀行",
    TEXT_9: "銀行振込",
    TEXT_10: "IFポイント",
    TEXT_11: "クレジットカード",
    TEXT_12: "ローソン、ミニストップでのスマートピットお支払い方法",
    TEXT_13: "step.01",
    TEXT_14: "Loppi画面より「各種番号をお持ちの方」を選択してください。",
    TEXT_15: "step.02",
    TEXT_16:
      "番号検索画面にて「スマートピット番号（13桁）」を入力し「次へ」ボタンを押してください。",
    TEXT_17: "step.03",
    TEXT_18:
      "選択した請求情報を再度ご確認の上、「確定する」ボタンを押してください。",
    TEXT_19: "step.04",
    TEXT_20:
      "申込券が発券されますので、申込券をお取りになり「スマートピットお支払い申込券」と印字されていることをご確認の上、レジでお支払いください。",
    TEXT_21: "ファミリーマートでのスマートピットお支払い方法",
    TEXT_22:
      "ファミリーマートの店頭に設置しているマルチコピー機画面を操作の上、お支払いください。",
    TEXT_23:
      "ファミポートでのお支払いは7月末までとなり、順次マルチコピー機に移行されています。ファミポートでのお支払い方法が不明の場合はカスタマーサポートまでお問い合わせください。",
    TEXT_24: "step.01",
    TEXT_25:
      "マルチコピー機のトップメニューより「ファミマTカード／楽天Edy／WAON／スマートピット」を選択してください。",
    TEXT_26:
      "表示言語の変更は、右上「LANGUAGE」ボタンからできます。スマートピットサービスの対応言語は日本語・中国語・英語・韓国語・ポルトガル語・ロシア語の６ヵ国語です。",
    TEXT_27: "step.02",
    TEXT_28:
      "金融サービス／国際送金など画面より、「スマートピット」を選択してください。",
    TEXT_29: "step.03",
    TEXT_30:
      "領収書のご案内が表示されます。内容をご確認後、「次へ」ボタンを押して下さい。",
    TEXT_31: "step.04",
    TEXT_32:
      "「スマートピットお支払いのご案内」が表示されるため、内容をご確認後、「確認」ボタンを押してください。",
    TEXT_33: "step.05",
    TEXT_34:
      "「スマートピット番号（13桁）」を入力して、「OK」ボタンを押して下さい。",
    TEXT_35: "step.06",
    TEXT_36:
      "ご請求の情報が表示されます。お支払いする請求を選択して「OK」ボタンを押して下さい。",
    TEXT_37:
      "複数のお支払いが必要な場合は、選択済みの１件のお支払い終了後再操作を行っていただきます。",
    TEXT_38: "step.07",
    TEXT_39: "選択した請求情報を再度ご確認の上、「OK」ボタンを押して下さい。",
    TEXT_40: "step.08",
    TEXT_41:
      "申込券が発券されますので、申込券をお取りになり「商品名：スマートピットお支払い」と印字されていることをご確認の上、レジでお支払いください。",
    TEXT_42:
      "申込券は発券後30分間有効です。発券後、30分以内にお支払いください。",
    data: {
      constants: {},
    },
  },
  DETAILS_VIP_SECTION1_INDEX: {
    TEXT_0: "会員サービス",
    TEXT_1: "IF 会員",
    TEXT_2: "紹介制度",
    TEXT_3: "提携店舗・施設",
    TEXT_4: "イベント掲載",
    TEXT_5: "IFポイント",
    TEXT_6: "会員特別サービス",
    TEXT_7: "Get Money , Save Money",
    TEXT_8: "Enjoy Travel , Make Friends",
    TEXT_9: "紹介制度",
    TEXT_10: "01",
    TEXT_11:
      "IF会員が一人にIF Mobileに紹介され契約に至った場合、紹介料として3000ポイント(換金可)を付与する制度です。",
    TEXT_12: "詳細",
    TEXT_13: "提携店舗・施設",
    TEXT_14: "03",
    TEXT_15:
      "IF会員が提携レストランや旅行・日常サービスを利用した場合、最大50%以上OFF or 消費金額の最大20%がポイント(換金可)にて還元可能です。",
    TEXT_16: "詳細",
    TEXT_17: "紹介制度",
    TEXT_18: "03",
    TEXT_19:
      "旅⾏商品、講座、交流会、⽇常⽣活全般のお得な情報を提供し、「価値ある出会い」を創造しています。",
    TEXT_20: "詳細",
    data: {
      constants: {},
    },
  },
  DETAILS_VIP_SECTION2_INDEX: {
    TEXT_0: "会員サービス",
    TEXT_1: "IF 会員",
    TEXT_2: "紹介制度",
    TEXT_3: "提携店舗・施設",
    TEXT_4: "イベント掲載",
    TEXT_5: "IFポイント",
    TEXT_6: "IFモバイルをお友達にも紹介しよう！",
    TEXT_7:
      "お友達を1名紹介する毎に最大3,000円分のIFポイントをプレゼント！お友達にもプレゼント！",
    TEXT_8: "イベント掲載",
    TEXT_9:
      "IF Mobileを紹介して現金に交換できる、毎月のお支払いに利用できるIFポイントをGET！",
    TEXT_10:
      "IF Mobileで契約すると現金に交換できるIFポイント（1ポイント＝1円）をプレゼントします。",
    TEXT_11:
      "紹介者のあなたに3000ポイント、契約者に500～2000ポイントが貰えます。",
    TEXT_12: "お友達や知り合いに携帯電話の契約で困っている人はいませんか？",
    TEXT_13:
      "Wi-Fiや光回線の契約で困っているお友達にIF Mobileを紹介してください。",
    TEXT_14: "あなたの紹介からお友達が契約すると、",
    TEXT_15: "紹介者と契約者の両方ともIFポイントが貰える嬉しい仕組みです。",
    TEXT_16: "IFポイントの詳細",
    TEXT_17: "→",
    TEXT_18: "紹介者登録をしてあなた専用の紹介URLとQRコードを発行",
    TEXT_19: "→",
    TEXT_20:
      "紹介制度についてのお問い合わせは、お問い合わせフォームよりお願いいたします。",
    TEXT_21: "紹介方法",
    TEXT_22:
      "IF Mobileに会員登録後、あなただけの紹介専用URLをシェアするだけで簡単に紹介できます。",
    TEXT_23:
      "あなたのSNSやブログなど手元のスマートフォンですぐにご紹介いただけます。",
    TEXT_24: "01",
    TEXT_25: "流れ",
    TEXT_26: "02",
    TEXT_27: "具体的な方法",
    TEXT_28: "01",
    TEXT_29: "会員登録ページからメールアドレスを入力してユーザー登録",
    TEXT_30: "  加实际的操作图 -占位",
    TEXT_31: "02",
    TEXT_32: "証コードが届くので、メールに記載された認証コードを次の画面で入力",
    TEXT_33: "  加实际的操作图 -占位",
    TEXT_34: "02",
    TEXT_35:
      "会員専用ページのmy ポイント管理に表示された紹介用URLやバーコードを人にシェアしよう",
    TEXT_36: "  加实际的操作图 -占位",
    TEXT_37: "03",
    TEXT_38:
      "一人あたりSIM・Wi-Fi・光回線に契約した場合、あなたに3000ポイントを自動に付与",
    TEXT_39: "  加实际的操作图 -占位",
    TEXT_40: "紹介者登録をしてあなた専用の紹介URLとQRコードを発行",
    TEXT_41: "→",
    TEXT_42: "よくある質問",
    TEXT_43: "Q",
    TEXT_44: "IF Mobileを契約していなくても紹介できますか？",
    TEXT_45: "A",
    TEXT_46:
      "SIM・Wi-Fiなどのご契約がなくとも、IF Mobile会員にご登録いただくことで紹介が可能です。",
    TEXT_47: "Q",
    TEXT_48: "ログインできません。",
    TEXT_49: "A",
    TEXT_50: "登録したメールアドレスとパスワードでログインできます。",
    TEXT_51:
      "分からない場合はお問い合わせフォームから登録したお名前・メールアドレス・生年月日 をご記入の上お問い合わせください。",
    TEXT_52: "Q",
    TEXT_53: "複数のサービスを契約した場合もポイントプレゼントですか？",
    TEXT_54: "A",
    TEXT_55:
      "1名の新しいご契約者様に対してポイントプレゼントとさせていただいております。",
    TEXT_56: "Q",
    TEXT_57: "紹介専用URLはどうすれば見れますか？",
    TEXT_58: "A",
    TEXT_59: "マイページ→Myポイント で確認できます。",
    TEXT_60: "Q",
    TEXT_61: ".ポイント交換方法を教えてください。",
    TEXT_62: "A",
    TEXT_63: "マイページ→換金 からポイントを換金申請できます。",
    TEXT_64: "Q",
    TEXT_65: "自分で自分を紹介できますか？",
    TEXT_66: "A",
    TEXT_67:
      "対象外となります。自分で自分を紹介していることが発覚した場合は適用されません",
    TEXT_68: "お問い合わせ",
    data: {
      loopData0: [
        {
          lanhuimage0: require("@/assets/vip/4.svg"),
          lanhutext0: "01",
          lanhutext1:
            "IF会員登録<br/>マイページから紹介専用URLまたは<br/>QRコードをGET",
        },
        {
          lanhuimage0: require("@/assets/vip/5.svg"),
          lanhutext0: "02",
          lanhutext1:
            "SNSやメール、ネットなどで紹介専用URLまたはQRコードをシェア",
        },
        {
          lanhuimage0: require("@/assets/vip/6.svg"),
          lanhutext0: "03",
          lanhutext1:
            "誰かが紹介専用URLまたはQRコードから対象商品（SIM、Wi-Fi、光回線）をご契約すると、一人あたりに自動に3000ポイントが付与される",
        },
      ],
      constants: {},
    },
  },
  /* The above code is a comment in JavaScript. It is not performing any specific action or
  functionality in the code. It is used to provide information or explanations about the code to
  other developers or readers. */
  DETAILS_VIP_SECTION3_INDEX: {
    TEXT_0: "会員サービス",
    TEXT_1: "IF 会員",
    TEXT_2: "紹介制度",
    TEXT_3: "提携店舗・施設",
    TEXT_4: "イベント掲載",
    TEXT_5: "IFポイント",
    TEXT_6: "提携店舗・施設①",
    TEXT_7: "レストラン、観光施設などの利用で現金GET",
    TEXT_8: "ポイント還元 サービス",
    TEXT_9: "5%",
    TEXT_10: "～",
    TEXT_11: "20%",
    TEXT_12: "レストラン",
    TEXT_13: "提携店舗",
    TEXT_14: "R番号 レストラン",
    TEXT_15: "T番号 観光施設",
    TEXT_16: "E番号 娯楽",
    TEXT_17: "H番号 ホテル",
    TEXT_18: "S番号 サロン",
    TEXT_19: "C番号 チケット",
    TEXT_20: "店舗リスト →",
    TEXT_21: "step01",
    TEXT_22:
      "サービス申込からサービス番号・サービス名・利用日時・人数・金額を入力して、メールでユーザー登録",
    TEXT_23: "step02",
    TEXT_24:
      "お支払い方法に現金を選択した上で申込。事前にお支払が必要なサービスの場合には、お支払の上、申込へ",
    TEXT_25: "step03",
    TEXT_26:
      "会員専用ページの注文状況に完了表示また予約完了メールが届いたら、指定時間で店舗に行く",
    TEXT_27: "step04",
    TEXT_28:
      "レシート写真をメールに返事また会員専用ページの注文状況にアップデートして、 ○％ポイントが付与",
    TEXT_29: "step05",
    TEXT_30:
      "会員専用ページのmy ポイント管理にポイント履歴を確認でき、銀行口座振込やバーコードPAYで換金",
    TEXT_31: "提携店舗・施設②",
    TEXT_32: "提携店舗や通販で買い物で割り引き",
    TEXT_33: "OFF券  サービス",
    TEXT_34: "2%",
    TEXT_35: "～",
    TEXT_36: "10%OFF",
    TEXT_37: "買い物",
    TEXT_38: "提携店舗",
    TEXT_39: "免税店",
    TEXT_40: "デパート",
    TEXT_41: "一般店舗",
    TEXT_42: "通販",
    TEXT_43: "step01",
    TEXT_44: "会員登録ページからメールアドレスを入力してユーザー登録",
    TEXT_45: "step02",
    TEXT_46: "会員専用ページのHome画面から",
    TEXT_47: "OFF券取得CM写真",
    TEXT_48: "にアクセスして、OFF券やクーポン券を獲得",
    TEXT_49: "step03",
    TEXT_50:
      "OFF券やクーポン券をダウンロードして、店舗に提示した上で、商品を購入",
    TEXT_51: "利用方法→",
    TEXT_52: "会員登録→",
    TEXT_53: "提携店舗・施設③",
    TEXT_54: "不動産仲介や旅行ツアー、塾など安く利用",
    TEXT_55: "ポイント還元 サービス",
    TEXT_56: "10%",
    TEXT_57: "～",
    TEXT_58: "50%OFF",
    TEXT_59: "日常サービス",
    TEXT_60: "提携店舗",
    TEXT_61: "50%OFF",
    TEXT_62: "不動産仲介",
    TEXT_63: "20%OFF",
    TEXT_64: "日本国内旅行ツアー",
    TEXT_65: "10%OFF",
    TEXT_66: "進学塾",
    TEXT_67: "10～50％OFF",
    TEXT_68: "その他サービス",
    TEXT_69: "サービス内容 →",
    TEXT_70: "step01",
    TEXT_71: "step02",
    TEXT_72: "step03",
    TEXT_73:
      "サービス申込からサービス番号・サービス名・利用日時・人数・金額を入力して、メールでユーザー登録、会員情報の記入・連絡先が必要",
    TEXT_74:
      " お支払い方法に現金を選択した上で申込。事前にお支払が必要なサービスの場合には、お支払の上、申込へ",
    TEXT_75:
      "会員専用ページの注文状況に完了表示また予約完了メールが届いたら、関連サービス業者から本人にご連絡",
    data: {
      constants: {},
    },
  },
  DETAILS_VIP_SECTION4_INDEX: {
    TEXT_0: "会員サービス",
    TEXT_1: "IF 会員",
    TEXT_2: "紹介制度",
    TEXT_3: "提携店舗・施設",
    TEXT_4: "イベント掲載",
    TEXT_5: "IFポイント",
    TEXT_6: "交流会",
    TEXT_7: "C1",
    TEXT_8: "毎週土曜日午後2時",
    TEXT_9: "カフェで語学交流会＊日本語・英語・中国語",
    TEXT_10: "高田馬場",
    TEXT_11: "C2",
    TEXT_12: "毎週金曜日19時",
    TEXT_13: "異職交流会",
    TEXT_14: "池袋",
    TEXT_15: "C3",
    TEXT_16: "毎月第1週と第3週の土曜日18時",
    TEXT_17: "IF Change会 IF会員限定",
    TEXT_18: "高田馬場",
    TEXT_19: "講座",
    TEXT_20: "C4",
    TEXT_21: "毎週土曜日午後1時",
    TEXT_22: "進学・就職説明会",
    TEXT_23: "Online or 高田馬場",
    TEXT_24: "C5",
    TEXT_25: "毎週日曜日午後3時に",
    TEXT_26: "税務・助成金・投資関連講座",
    TEXT_27: "Online or 高田馬場",
    TEXT_28: "お得な情報",
    TEXT_29: "C6",
    TEXT_30: "引っ越しで部屋探し 最大仲介手数料無料キャンペーン中",
    TEXT_31: "(IF会員限定)",
    TEXT_32: "C7",
    TEXT_33: "進学塾学費 10%OFF券",
    TEXT_34: "(IF会員限定)",
    data: {
      constants: {},
    },
  },
  DETAILS_VIP_SECTION5_INDEX: {
    TEXT_0: "会員サービス",
    TEXT_1: "IF 会員",
    TEXT_2: "紹介制度",
    TEXT_3: "提携店舗・施設",
    TEXT_4: "イベント掲載",
    TEXT_5: "IFポイント",
    TEXT_6: "未找到对应页面",
    data: {
      constants: {},
    },
  },
  DETAILS_WIFI_SECTION1_INDEX: {
    TEXT_0: "Wi-Fi",
    TEXT_1: "IF Wi-Fi特徴",
    TEXT_2: "一括購入",
    TEXT_3: "レンタル",
    TEXT_4:
      "モバイルルーターという小型の端末を使うのがポケットWi-Fiです。いわゆるモバイル回線の電波を受信し、Wi-Fiを発信しています。パソコンやスマホをそのWi-Fiと繋げることで、インターネットができるようになるのです。",
    TEXT_5: "ポケットWi-Fiを使うべき人",
    TEXT_6: "IF ポケットWi-Fi",
    TEXT_7: "通信制限なし、契約期間なし",
    TEXT_8: "家だけでなく外でもどこでも使える",
    TEXT_9: "引っ越し先に持っていくだけでOK",
    TEXT_10: "工事・設定不要ですぐに使える",
    TEXT_11: "IF Wi-Fi特徴",
    TEXT_12: "トリブルエリア対応",
    TEXT_13: "3大キャリアSoftBank、Docomo、au全ての回線を無制限で利用できる。",
    TEXT_14: "数台同時接続可",
    TEXT_15: "最大稼働時間は12時間、同時接続台数は15台です。",
    TEXT_16: "安心サポート",
    TEXT_17: "お支払い方法多様、多国言語対応可能、スタッフが24時間対応可能",
    TEXT_18: "SIMとセットでお得",
    TEXT_19: "SIMとセットで利用すると、",
    TEXT_20: "毎月300PのIFポイント貯まる",
    TEXT_21: "IF Wi-Fiの料金",
    TEXT_22: "一括購入",
    TEXT_23: "3800",
    TEXT_24: "円",
    TEXT_25: "税込4180円",
    TEXT_26: "今すぐ申し込",
    TEXT_27: "今すぐ申し込",
    TEXT_28: "レンタル",
    TEXT_29: "540",
    TEXT_30: "円/日",
    TEXT_31: "税込590円/日",
    TEXT_32: "IF Wi-Fiご利用までの流れ",
    TEXT_33: "お申し込み",
    TEXT_34: "レンタル購入の流れ",
    TEXT_35: "01",
    TEXT_36: "お申込み",
    TEXT_37:
      "オンライン、門店申込み可能。オンラインの場合、WEBから簡単にお申込みできます。",
    TEXT_38: "02",
    TEXT_39: "支払い",
    TEXT_40: "支払い",
    TEXT_41: "初期費用のお支払い（機器保証金、使用料金、送料が含まれます。）",
    TEXT_42: "03",
    TEXT_43: "受け取り・利用開始",
    TEXT_44: "受け取り・利用開始",
    TEXT_45:
      "最短お申込み当日発送、地域によって到着日が異なります。電源を入れて即利用開始になります。",
    TEXT_46: "返却",
    TEXT_47:
      "「レターパック」に全て（端末・USBコード）入れて郵便ポストへ投函してください。",
    TEXT_48: "04",
    TEXT_49: "返却完了",
    TEXT_50: "端末が本店へ到着すれば返金機器保証金を返金します。",
    TEXT_51: "05",
    TEXT_52: "ご購入・お申し込みはこちら",
    data: {
      loopData0: [
        {
          lanhutext0: "1",
          lanhutext1: "一人暮らし、1年<br/>以内で引っ越し予定",
        },
        { lanhutext0: "2", lanhutext1: "オンラインゲーム<br/>をしない" },
        { lanhutext0: "3", lanhutext1: "外でもWi-Fi<br/>を使いたい" },
        { lanhutext0: "4", lanhutext1: "工事や立ち会い<br/>がイヤだ" },
        { lanhutext0: "5", lanhutext1: "設定するのがイヤだ" },
        { lanhutext0: "6", lanhutext1: "すぐに必要だ" },
        { lanhutext0: "7", lanhutext1: "工事費約2万円<br/>を払いたくない" },
        { lanhutext0: "8", lanhutext1: "2年解約料金1万円を払いたくない" },
      ],
      loopData1: [
        {
          lanhuimage0: require("@/assets/lxl/SketchPng2d1994093810b28e813264a948afb62a0e332b7b4a925003bae45b29fdd742fd.png"),
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPnge04a0823680afd9b832a43fcc8db97f9e707c4b3b21624c0a4182355a12808be.png"),
        },
        {
          lanhuimage0: require("@/assets/lxl/SketchPng5502ddf5cfb164998bfd446551e31d651472dae3d03c451d3e4d160e33490ce9.png"),
        },
      ],
      loopData2: [
        {
          lanhuimage0: require("@/assets/wifi/41.svg"),
          lanhutext0: "01",
          lanhutext1: "お申込み",
          lanhutext2:
            "オンライン、門店申込み可能。<br/>オンラインの場合、身分証明書などをアップロードが必要です。",
        },
        {
          lanhuimage0: require("@/assets/wifi/42.svg"),
          lanhutext0: "02",
          lanhutext1: "支払い",
          lanhutext2:
            "初期費用のお支払い（契約事務手数料、当月の月額基本料、機器料金と送料が含まれます。）",
        },
        {
          lanhuimage0: require("@/assets/wifi/43.svg"),
          lanhutext0: "03",
          lanhutext1: "受け取り",
          lanhutext2:
            "最短お申込み当日発送、地域によって到着日が異なります。<br/><br/><br/><br/>",
        },
        {
          lanhuimage0: require("@/assets/wifi/44.svg"),
          lanhutext0: "04",
          lanhutext1: "利用開始",
          lanhutext2: "電源を入れて即利用開始<br/>になります。",
        },
      ],
      constants: {},
    },
  },
  DETAILS_WIFI_SECTION2_INDEX: {
    TEXT_0: "Wi-Fi",
    TEXT_1: "IF Wi-Fi特徴",
    TEXT_2: "一括購入",
    TEXT_3: "レンタル",
    TEXT_4: "初期費用",
    TEXT_5: "一括購入月額費用",
    TEXT_6: "初期費用",
    TEXT_7: "一括購入",
    TEXT_8: "事務手数料",
    TEXT_9: "COD（機器料金）",
    TEXT_10: "送料",
    TEXT_11: "3,300円（税込）",
    TEXT_12: "7,980円（税込）",
    TEXT_13: "880円（税込）",
    TEXT_14: "一括購入月額費用",
    TEXT_15: "月額利用料金",
    TEXT_16: "100G プラン",
    TEXT_17: "4,180 円/月（税込）",
    TEXT_18: "月額利用料金",
    TEXT_19: "150G プラン",
    TEXT_20: "5,280 円/月（税込）",
    TEXT_21: "端末仕様",
    TEXT_22: "モデル名",
    TEXT_23: "NA01",
    TEXT_24: "サイズ",
    TEXT_25: "色",
    TEXT_26: "重さ",
    TEXT_27: "同時接続",
    TEXT_28: "最大稼働時間",
    TEXT_29: "126mm x 66mm x 12.6mm",
    TEXT_30: "ブラック",
    TEXT_31: "148g",
    TEXT_32: "15台",
    TEXT_33: "12時間",
    TEXT_34: "電圧バッテリー容量",
    TEXT_35: "3500mAh（typical）/3.8V",
    TEXT_36: "1電波状況や使用環境により変動します",
    TEXT_37: "安定・広範囲・快速へ進化する",
    TEXT_38: "お支払い方法",
    TEXT_39: "国内決済",
    TEXT_40: "クレジットカード、口座振込、コンビニ、Paypay、現金払い",
    TEXT_41: "国際決済",
    TEXT_42: "クレジットカード、Alipay 、Wechat pay",
    TEXT_43: "その他注意点",
    TEXT_44: "端末",
    TEXT_45: "当社指定機種",
    TEXT_46: "申し込み必要書類",
    TEXT_47: "身分証明書（有効期間3ヶ月以上あるもの）",
    TEXT_48: "プラン変更・延長",
    TEXT_49: "可（翌月適用）",
    TEXT_50: "回線",
    TEXT_51: "NTT Docomo、Soft Bank 、Au",
    TEXT_52: "注意事項",
    TEXT_53:
      "1.IF WI-FIの端末保証期間は10カ月となります。お客様の故意による不具合·故障以外は修理させて",
    TEXT_54:
      "頂きます。弊社に端末が届いた日から約1週間で正常な端末を郵送いたします。",
    TEXT_55:
      "2.月額料金は多種なお支払い方法から選択できます(クレジットカ-ド、コンビニ、Alipay WECHAT)",
    TEXT_56:
      "前払いとなり毎月 20日迄に翌月分をお支払い下さい。24日迄にお支払いが確認できない場合は25日に一時停止となり、28日迄にお支払いが無い場合は、当月末日に強制解約となりますのでご注意下さい。",
    TEXT_57: "3.上記2の一時停止から、ご入金後の利用再開は、3営業日かかります。",
    TEXT_58:
      "4.本プランは最低利用の契約期間はありません。解約のお申し出がない限り、ご契約は継続します。一時的な帰国などによる利用停止は可能ですが、料金は発生します。",
    TEXT_59:
      "5.解約の場合は、解約料金はございません。ただし、ご連絡が必要です。端末(故障、破損していない)を弊社に回収できます。本社へ到着後、2,000 円をお返金します。振込手数料はお客様負担となります。",
    TEXT_59_1: "6.お申込み日よりご利用いただけます。",

    TEXT_60:
      "7.ご契約完了後お客様のご都合による返品、交換、キャンセル等はお受けいたしかねます。",
    TEXT_61: "8.端末の種類をお選びいただくことはできません。",
    TEXT_62: "ご購入・お申し込みはこちら",
    data: {
      loopData0: [
        { lanhutext0: "端末仕様" },
        { lanhutext0: "お支払い方法" },
        { lanhutext0: "その他注意点" },
        { lanhutext0: "注意事項" },
      ],
      constants: {},
    },
  },
  DETAILS_WIFI_SECTION3_INDEX: {
    TEXT_0: "Wi-Fi",
    TEXT_1: "IF Wi-Fi特徴",
    TEXT_2: "一括購入",
    TEXT_3: "レンタル",
    TEXT_4: "初期費用",
    TEXT_5: "プラン費用 税込み",
    TEXT_6: "初期費用",
    TEXT_7: "レンタル",
    TEXT_8: "保証金",
    TEXT_9: "送料",
    TEXT_10: "10,000円（税込）",
    TEXT_11: "880円（税込）",
    TEXT_12: "レンタルプラン費用",
    TEXT_13: "端末仕様",
    TEXT_14: "モデル名",
    TEXT_15: "NA01",
    TEXT_16: "サイズ",
    TEXT_17: "色",
    TEXT_18: "重さ",
    TEXT_19: "同時接続",
    TEXT_20: "最大稼働時間",
    TEXT_21: "126mm x 66mm x 12.6mm",
    TEXT_22: "ブラック",
    TEXT_23: "148g",
    TEXT_24: "15台",
    TEXT_25: "12時間",
    TEXT_26: "電圧バッテリー容量",
    TEXT_27: "3500mAh（typical）/3.8V",
    TEXT_28: "電波状況や使用環境により変動します",
    TEXT_29: "安定・広範囲・快速へ進化する",
    TEXT_30: "お支払い方法",
    TEXT_31: "国内決済",
    TEXT_32: "クレジットカード、口座振込、コンビニ、Paypay、現金払い",
    TEXT_33: "国際決済",
    TEXT_34: "クレジットカード、Alipay 、Wechat pay",
    TEXT_35: "その他注意点",
    TEXT_36: "端末",
    TEXT_37: "当社指定機種",
    TEXT_38: "申し込み必要書類",
    TEXT_39: "身分証明書（有効期間3ヶ月以上あるもの）",
    TEXT_40: "回線",
    TEXT_41: "NTT Docomo、Soft Bank 、Au",
    TEXT_42: "注意事項",
    TEXT_43:
      "1.レンタル機器の故障未返却・紛失時の費用対応型ルータの原価計算にご請求します。",
    TEXT_44:
      "2.お客様の故意による不具合·故障以外は修理させて頂きます。弊社に端末が届いた日から約1週間",
    TEXT_45: "で正常な端末を郵送いたします。",
    TEXT_46:
      "3.レンタル保証金を返却の際、お客様より振込手数料がかかるので、予めご了承ください。",
    TEXT_47:
      "4.期日までに端末・アダプタの返却がない、またはルーター本体に破損・加工が発見された場合は11,000円(税込)の損害金が発生し請求いたします。",
    TEXT_48: "5.お申込み日よりご利用いただけます。",
    TEXT_49:
      "6.ご契約完了後お客様のご都合による返品、交換、キャンセル等はお受けいたしかねます。",
    TEXT_50: "7.レンタルする端末の種類をお選びいただくことはできません。",
    TEXT_51: "ご購入・お申し込みはこちら",
    data: {
      loopData0: [
        { lanhutext0: "端末仕様" },
        { lanhutext0: "お支払い方法" },
        { lanhutext0: "その他注意点" },
        { lanhutext0: "注意事項" },
      ],
      loopData1: [
        {
          lanhutext0: "１泊２日プラン",
          specialSlot: { lanhutext0: "¥1,180" },
          slot1: 1,
        },
        {
          lanhutext0: "2泊3日プラン",
          specialSlot: { lanhutext0: "¥1,770" },
          slot1: 1,
        },
        {
          lanhutext0: "3泊4日プラン",
          specialSlot: { lanhutext0: "¥2,360" },
          slot1: 1,
        },
        {
          lanhutext0: "4泊5日プラン",
          specialSlot: { lanhutext0: "¥2,950" },
          slot1: 1,
        },
        {
          lanhutext0: "5泊6日プラン",
          specialSlot: { lanhutext0: "¥3,540" },
          slot1: 1,
        },
        {
          lanhutext0: "6泊7日プラン",
          specialSlot: { lanhutext0: "¥4,130" },
          slot1: 1,
        },
        {
          lanhutext0: "7泊8日プラン",
          specialSlot: { lanhutext0: "¥4,720" },
          slot1: 1,
        },
        {
          lanhutext0: "8泊9日プラン",
          specialSlot: { lanhutext0: "¥5,310" },
          slot1: 1,
        },
        {
          lanhutext0: "9泊10日プラン",
          specialSlot: { lanhutext0: "¥5,900" },
          slot1: 1,
        },
        {
          lanhutext0: "10泊11日プラン",
          specialSlot: { lanhutext0: "¥6,490" },
          slot1: 1,
        },
        {
          lanhutext0: "11泊12日プラン",
          specialSlot: { lanhutext0: "¥7,080" },
          slot1: 1,
        },
        {
          lanhutext0: "12泊13日プラン",
          specialSlot: { lanhutext0: "¥7,670" },
          slot1: 1,
        },
        {
          lanhutext0: "13泊14日プラン",
          specialSlot: { lanhutext0: "¥8,260" },
          slot1: 1,
        },
        {
          lanhutext0: "1ヶ月バック",
          specialSlot: { lanhutext0: "¥8,850", lanhutext1: "（15日～31日）" },
          slot2: 2,
        },
        {
          lanhutext0: "2ヶ月バック",
          specialSlot: { lanhutext0: "¥15,750", lanhutext1: "（60泊61日）" },
          slot2: 2,
        },
        {
          lanhutext0: "3ヶ月バック",
          specialSlot: { lanhutext0: "¥22,000", lanhutext1: "（150泊151日）" },
          slot2: 2,
        },
        {
          lanhutext0: "4ヶ月バック",
          specialSlot: { lanhutext0: "¥27,600", lanhutext1: "（150泊151日）" },
          slot2: 2,
        },
        {
          lanhutext0: "5ヶ月バック",
          specialSlot: { lanhutext0: "¥30,600", lanhutext1: "（150泊151日）" },
          slot2: 2,
        },
        {
          lanhutext0: "6ヶ月バック",
          specialSlot: { lanhutext0: "¥33,600", lanhutext1: "（180泊181日）" },
          slot2: 2,
        },
      ],
      constants: {},
    },
  },
};
