
<template>
  <div class="com-m">
    <section>
      <div class="p-personal__basicInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("COMPANY_MANAGER.BASIC_INFORMATION.TITLE") }}
          </h1>
        </div>
        <el-alert
          style="margin: 0 0 20px"
          type="warning"
          left
          :closable="false"
          :show-icon="false"
        >
          <text-more :length="6">
            {{ $t("COMPANY_MANAGER.NOTE.TITLE") }}
            ※{{ $t("COMPANY_MANAGER.NOTE.TEXT1") }} ※{{
              $t("COMPANY_MANAGER.NOTE.TEXT2")
            }}
            ※{{ $t("COMPANY_MANAGER.NOTE.TEXT3") }}
          </text-more>
          <!-- <a
            href="#/details/servers/section2"
            style="color: #e6a23c; text-decoration: underline"
            >{{ $t("PERSONAL_MANAGER.NOTE.TEXT7") }}</a
          > -->
        </el-alert>
        <!-- <b-card class="note">
          <h3></h3>
          <p>{{ $t("COMPANY_MANAGER.NOTE.TEXT1") }}</p>
          <p>{{ $t("COMPANY_MANAGER.NOTE.TEXT2") }}</p>
          <p>{{ $t("COMPANY_MANAGER.NOTE.TEXT3") }}</p>
        </b-card> -->
        <div class="p-personal__contactInfo__inner">
          <div class="p-personal__contactInfo__inner__item sub_content">
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.BASIC_INFORMATION.CEO")).indexOf("*") >
                  -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.BASIC_INFORMATION.CEO")).indexOf("*") >
                  -1
                    ? $t($t("COMPANY_MANAGER.BASIC_INFORMATION.CEO")).slice(1)
                    : $t($t("COMPANY_MANAGER.BASIC_INFORMATION.CEO"))
                }}
                <!-- {{ $t("COMPANY_MANAGER.BASIC_INFORMATION.CEO") }} -->
              </p>
              <div class="col-3">
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  v-model="companyManager.chairman"
                  :state="chairman"
                  :placeholder="$t('COMPANY_MANAGER.PLACEHOLDER.PLA1')"
                  trim
                ></b-form-input>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.chairman == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.BASIC_INFORMATION.PDF")).indexOf("*") >
                  -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.BASIC_INFORMATION.PDF")).indexOf("*") >
                  -1
                    ? $t($t("COMPANY_MANAGER.BASIC_INFORMATION.PDF")).slice(1)
                    : $t($t("COMPANY_MANAGER.BASIC_INFORMATION.PDF"))
                }}

                <!-- {{ $t("COMPANY_MANAGER.BASIC_INFORMATION.PDF") }} -->
              </p>
              <div class="col-3">
                <abbr
                  :title="
                    fileName == ''
                      ? pdfName != null || pdfName != ''
                        ? pdfName
                        : ''
                      : fileName
                  "
                >
                  <b-form-file
                    style="width: 100% !important"
                    :disabled="detailChangeAbleFlg"
                    :class="
                      companyManager.companyLicenseUrl == null && alert
                        ? 'pdf_choose__validate'
                        : 'pdf_choose'
                    "
                    @change="onFileChangePDF($event)"
                    :state="filePdf"
                    accept="application/pdf,application/vnd.ms-excel"
                    :placeholder="
                      pdfName != null || pdfName != '' ? pdfName : 'PDF'
                    "
                  >
                  </b-form-file>
                </abbr>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.companyLicenseUrl == null"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-companyManager__part__container sub__image">
          <div
            class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
          >
            <p class="sub_title title-img">
              {{ $t("COMPANY_MANAGER.BASIC_INFORMATION.TITLE_ADD_IMG") }}
            </p>
            <div
              class="p-personal__basicInfo__uploadImage__text"
              @click="callFile('image')"
              for="image"
              :style="{
                'background-image': check_text_1
                  ? `url(${url1})`
                  : `url(${companyManager.businessCardUrl})`,
              }"
            >
              <i
                v-show="
                  (!check_text_1 && companyManager.businessCardUrl == '') ||
                  companyManager.businessCardUrl == null
                "
                class="fa fa-cloud-upload"
                style="font-size: 48px"
              ></i>
              <p
                v-show="
                  (!check_text_1 && companyManager.businessCardUrl == '') ||
                  companyManager.businessCardUrl == null
                "
              >
                {{ $t("COMPANY_MANAGER.BASIC_INFORMATION.TEXT_ADD_IMG") }}
              </p>
              <input
                :disabled="detailChangeAbleFlg"
                id="image"
                type="file"
                @change="onFileChange($event)"
                accept="image/x-png,image/gif,image/jpeg"
              />
            </div>
          </div>
          <div v-show="alert" class="validation-message vali">
            <span
              class="validation-message"
              v-show="companyManager.businessCardUrl == null"
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="p-personal__contactInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">{{ $t("COMPANY_MANAGER.CONTACTINFO.TITLE") }}</h1>
        </div>
        <div class="p-personal__contactInfo__inner">
          <div class="p-personal__contactInfo__inner__item sub_content">
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.PHONE1")).indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.PHONE1")).indexOf("*") > -1
                    ? $t($t("COMPANY_MANAGER.CONTACTINFO.PHONE1")).slice(1)
                    : $t($t("COMPANY_MANAGER.CONTACTINFO.PHONE1"))
                }}
                <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.PHONE1") }} -->
              </p>
              <div class="col-3">
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  @keyup="changeInfo"
                  type="text"
                  v-model="companyManager.companyTel"
                  :state="companyTel"
                  trim
                >
                </b-form-input>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.companyTel == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.ZIP_CODE")).indexOf("*") >
                  -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.ZIP_CODE")).indexOf("*") >
                  -1
                    ? $t($t("COMPANY_MANAGER.CONTACTINFO.ZIP_CODE")).slice(1)
                    : $t($t("COMPANY_MANAGER.CONTACTINFO.ZIP_CODE"))
                }}
                <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.ZIP_CODE") }} -->
              </p>
              <div class="col-3">
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  @keyup="changeInfo"
                  v-model="companyManager.companyPostalCode"
                  :state="companyPostalCode"
                  trim
                >
                </b-form-input>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.companyPostalCode == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
          </div>
          <div class="p-personal__contactInfo__inner__address sub_content">
            <p class="sub_title">
              <span style="color: red">{{
                $t($t("COMPANY_MANAGER.CONTACTINFO.ADDRESS")).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t($t("COMPANY_MANAGER.CONTACTINFO.ADDRESS")).indexOf("*") > -1
                  ? $t($t("COMPANY_MANAGER.CONTACTINFO.ADDRESS")).slice(1)
                  : $t($t("COMPANY_MANAGER.CONTACTINFO.ADDRESS"))
              }}
              <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.ADDRESS") }} -->
            </p>
            <div class="col-3">
              <b-form-textarea
                :disabled="detailChangeAbleFlg"
                class="form-input"
                @keyup="changeInfo"
                v-model="companyManager.companyAddress"
                :state="companyAddress"
                trim
              ></b-form-textarea>
              <div v-show="alert" class="validation-message">
                <span
                  class="validation-message"
                  v-show="companyManager.companyAddress == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                >
              </div>
              <span class="focus-border"></span>
            </div>
          </div>
          <div class="p-personal__contactInfo__inner__container">
            <div class="p-personal__contactInfo__inner__item sub_content">
              <div
                class="p-personal__contactInfo__inner__container__emergency__phone"
              >
                <p class="sub_title">
                  <span style="color: red">{{
                    $t($t("COMPANY_MANAGER.CONTACTINFO.NAME")).indexOf("*") > -1
                      ? "※"
                      : ""
                  }}</span>
                  {{
                    $t($t("COMPANY_MANAGER.CONTACTINFO.NAME")).indexOf("*") > -1
                      ? $t($t("COMPANY_MANAGER.CONTACTINFO.NAME")).slice(1)
                      : $t($t("COMPANY_MANAGER.CONTACTINFO.NAME"))
                  }}
                  <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.NAME") }} -->
                </p>
                <div class="col-3">
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    @keyup="changeInfo"
                    type="text"
                    v-model="companyManager.principalName"
                    :state="principalName"
                    trim
                  >
                  </b-form-input>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="companyManager.principalName == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                  <span class="focus-border"></span>
                </div>
              </div>
            </div>

            <div
              class="p-personal__contactInfo__inner__container__emergencyInfo sub_content"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.CONTACT")).indexOf("*") >
                  -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.CONTACT")).indexOf("*") >
                  -1
                    ? $t($t("COMPANY_MANAGER.CONTACTINFO.CONTACT")).slice(1)
                    : $t($t("COMPANY_MANAGER.CONTACTINFO.CONTACT"))
                }}
                <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.CONTACT") }} -->
              </p>
              <div class="col-3">
                <b-form-textarea
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  @keyup="changeInfo"
                  v-model="companyManager.principalAddress"
                  :state="principalTitle"
                  trim
                >
                </b-form-textarea>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.principalAddress == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>

            <div
              class="p-personal__contactInfo__inner__container__relationship sub_content"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.POSITION")).indexOf("*") >
                  -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("COMPANY_MANAGER.CONTACTINFO.POSITION")).indexOf("*") >
                  -1
                    ? $t($t("COMPANY_MANAGER.CONTACTINFO.POSITION")).slice(1)
                    : $t($t("COMPANY_MANAGER.CONTACTINFO.POSITION"))
                }}
                <!-- {{ $t("COMPANY_MANAGER.CONTACTINFO.POSITION") }} -->
              </p>
              <div class="col-3">
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  @keyup="changeInfo"
                  v-model="companyManager.principalTitle"
                  :state="principalAddress"
                  trim
                >
                </b-form-input>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="companyManager.principalTitle == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
            <div
              :hidden="!detailChangeAbleFlg"
              class="p-personal__contactInfo__inner__container__relationship sub_content"
            >
              <p class="sub_title">
                {{ $t("COMPANY_MANAGER.UPDATED_CONTACT.TITLE") }}
              </p>
            </div>
            <div
              :hidden="!detailChangeAbleFlg"
              class="p-personal__contactInfo__inner__container__relationship sub_content"
            >
              <div class="col-3">
                <b-form-textarea
                  class="form-input new_row"
                  style="margin: 0 !important"
                  :placeholder="
                    $t('COMPANY_MANAGER.UPDATED_CONTACT.PLACEHOLDER')
                  "
                  v-model="companyManager.changeDescribe"
                  :state="changeDescribe"
                  trim
                >
                </b-form-textarea>
                <div v-show="alert" class="validation-message new_row">
                  <span
                    class="validation-message"
                    v-show="companyManager.changeDescribe == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}f</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="p-personal__otherInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("PERSONAL_MANAGER.OTHERINFO.TITLE") }}
          </h1>
        </div>
        <div class="p-personal__otherInfo__SNS">
          <div class="p-personal__otherInfo__SNS__Advertisement">
            <p>{{ $t("PERSONAL_MANAGER.OTHERINFO.ADVERTISEMENT") }}</p>
            <!-- Checkbox SNS -->
            <div class="p-personal__otherInfo__SNS__Advertisement__content">
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.REFER_A_FRIEND"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_REFER_A_FRIEND.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.SCHOOL_INTRODUCTION"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_SCHOOL_INTRODUCTION.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.STUDY_ABROAD"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_INTRODUCTION.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.FROM_OTHER_WEBSITES"
                  v-model="companyManager.heardCode"
                  @click="
                    resetFormCt($CONST.BUYER.INFORMATION.FROM_OTHER_WEBSITES)
                  "
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_INTRODUCED_FROM.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    type="text"
                    v-model="companyManager.heardName"
                  />
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.FACEBOOK_ADS"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_FACEBOOK.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.YOUTUBE_ADS"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_YOUTUBE.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.GOOGLE_SEARCH"
                  v-model="companyManager.heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_GOOGLE.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.OTHER_ADVERTISEMENTS"
                  v-model="companyManager.heardCode"
                  @click="
                    resetFormCt($CONST.BUYER.INFORMATION.OTHER_ADVERTISEMENTS)
                  "
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_OTHER_ADVERTISEMENTS.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    v-model="companyManager.heardName"
                    type="text"
                  />
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.OTHERS"
                  v-model="companyManager.heardCode"
                  @click="resetFormCt($CONST.BUYER.INFORMATION.OTHERS)"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_OTHERS.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    v-model="companyManager.heardName"
                    type="text"
                  />
                </div>
              </label>
            </div>
            <!-- <div v-show="alert" class="validation-message">
              <span
                class="validation-message"
                v-show="
                  companyManager.heardCode === null ||
                  companyManager.heardCode === ''
                "
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
              </span>
            </div> -->
            <div
              v-show="alert"
              v-if="companyManager.heardCode"
              class="validation-message"
            >
              <span
                class="validation-message"
                v-show="
                  companyManager.heardName === null ||
                  companyManager.heardName === ''
                "
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
              >
            </div>
          </div>

          <div class="p-personal__otherInfo__SNS__Advertisement">
            <p>{{ $t("PERSONAL_MANAGER.OTHERINFO.USE.TITLE") }}</p>
            <!-- Checkbox SNS -->
            <div class="p-personal__otherInfo__SNS__Advertisement__content">
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
                v-for="(item, index) in options"
                :key="index"
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  class="item"
                  type="radio"
                  v-model="companyManager.toolCode"
                  name="contact"
                  @click="setContract(item.value)"
                  :value="item.value"
                />
                <div
                  :class="
                    detailchangeableflg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{ item.text }}
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    placeholder=""
                    type="text"
                    v-model="companyManager.toolAccount"
                    value=""
                  >
                  </b-form-input>
                </div>
              </label>
              <label
                :class="
                  detailchangeableflg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="contact"
                  @click="setContract(9)"
                  v-model="companyManager.toolCode"
                  value="9"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover sns'
                      : 'btn-radio-layout sns'
                  "
                >
                  {{ $t("INQUIRY.SNS") }}
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="sns-input"
                    v-show="companyManager.toolCode == 9"
                    :placeholder="$t('INQUIRY.SNS_NAME')"
                    type="text"
                    v-model="companyManager.toolName"
                  >
                  </b-form-input>
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="form-input sns-input"
                    type="text"
                    :placeholder="$t('INQUIRY.SNS_ID')"
                    v-model="companyManager.toolAccount"
                  >
                  </b-form-input>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="card-x">
        <div
          class="p-personal__contactInfo__inner__container__relationship sub_content"
        >
          <div class="foot-content" v-if="showCode">
            <p class="sub_title">{{ $t("COMPANY_MANAGER.REFERRAL_CODE") }}</p>
            <div class="col-3">
              <b-form-input
                :disabled="detailChangeAbleFlg"
                class="form-input"
                type="number"
                min="0"
                max="9"
                v-model="companyManager.introduceCode"
                :placeholder="$t('COMPANY_MANAGER.PLACEHOLDER.PLA2')"
              ></b-form-input>
              <span class="focus-border"></span>
            </div>
          </div>
        </div>

        <div
          class="p-personal__contactInfo__inner__container__relationship sub_content"
        >
          <div class="foot-content">
            <p class="sub_title">
              {{ $t("COMPANY_MANAGER.LANGUAGE") }}
            </p>
            <div class="col-3">
              <form>
                <div
                  class="multiselect"
                  style="width: 100% !important; padding-bottom: 60px"
                >
                  <div class="selectBox" @click="showLanguage()">
                    <div class="overSelect"></div>
                  </div>
                  <b-form-select
                    :disabled="detailChangeAbleFlg"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="companyManager.hopeLanguage"
                    :state="hopeLanguage"
                    trim
                  >
                    <option selected value="" disabled>
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.SELEST"
                        )
                      }}
                    </option>
                    <option value="0">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_JAPANESE.name"
                        )
                      }}
                    </option>
                    <option value="1">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_ENGLISH.name"
                        )
                      }}
                    </option>
                    <option value="2">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_CHINESE.name"
                        )
                      }}
                    </option>
                    <option value="3">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_VIETNAMESE.name"
                        )
                      }}
                    </option>
                    <option value="4">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_KOREAN.name"
                        )
                      }}
                    </option>
                    <option value="5">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_SRILANKAN.name"
                        )
                      }}
                    </option>
                    <option value="6">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_NEVALA.name"
                        )
                      }}
                    </option>
                  </b-form-select>
                  <!-- <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="companyManager.hopeLanguage == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div> -->
                </div>
              </form>
            </div>
            <div style="height: 20px"></div>
          </div>
        </div>
      </div>
      <button
        :disabled="checkError"
        v-on:click="btnSubmit(companyManager.heardCode)"
      >
        {{ $t("COMPANY_MANAGER.BTN") }}
      </button>
    </section>
  </div>
</template>

<script>
import { faL } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "cmm",
  data() {
    return {
      showCode: false,
      showErr: false,
      alert: false,
      checkError: false,
      filePdf: null,
      mainUrl: null,
      url1: null,
      check_text_1: false,
      check_text_2: false,
      check_text_3: false,
      check_text_4: false,
      check_text_5: false,
      companyManager: {
        businessCardUrl: "",
        chairman: "",
        changeDescribe: "",
        companyAddress: "",
        companyLicenseUrl: "",
        companyPostalCode: "",
        companyTel: "",
        heardCode: "",
        heardName: "",
        hopeLanguage: "0",
        introduceCode: null,
        principalAddress: "",
        principalName: "",
        // principalTel: "",
        principalTitle: "",
        toolAccount: "",
        toolCode: "",
        toolName: "",
        userId: "",
      },
      validate: {
        heardCode: null,
        heardName: null,
      },
      isClicked: false,
      pdfName: "",
      infoChange: false,
      detailChangeAbleFlg: true,
      fileName: "",
    };
  },
  created() {
    this.getData();
    this.options = this.$CONST.BUYER.WHICH_SNS;
  },
  computed: {
    chairman() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.chairman == null) {
          this.companyManager.chairman = "";
        }
        this.validate.chairman = this.companyManager.chairman.length > 0;
        return this.validate.chairman;
      }
      return null;
    },
    companyTel() {
      if (this.isClicked) {
        if (this.companyManager.companyTel == null) {
          this.companyManager.companyTel = "";
        }
        this.validate.companyTel = this.companyManager.companyTel.length > 0;
        return this.validate.companyTel;
      }
      return null;
    },
    companyPostalCode() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.companyPostalCode == null) {
          this.companyManager.companyPostalCode = "";
        }
        this.validate.companyPostalCode =
          this.companyManager.companyPostalCode.length > 0;
        return this.validate.companyPostalCode;
      }
      return null;
    },
    companyAddress() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.companyAddress == null) {
          this.companyManager.companyAddress = "";
        }
        this.validate.companyAddress =
          this.companyManager.companyAddress.length > 0;
        return this.validate.companyAddress;
      }
      return null;
    },
    principalName() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.principalName == null) {
          this.companyManager.principalName = "";
        }
        this.validate.principalName =
          this.companyManager.principalName.length > 0;
        return this.validate.principalName;
      }
      return null;
    },
    principalTitle() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.principalTitle == null) {
          this.companyManager.principalTitle = "";
        }
        this.validate.principalTitle =
          this.companyManager.principalTitle.length > 0;
        return this.validate.principalTitle;
      }
      return null;
    },
    principalAddress() {
      this.showErr = false;
      if (this.isClicked) {
        if (this.companyManager.principalAddress == null) {
          this.companyManager.principalAddress = "";
        }
        this.validate.principalAddress =
          this.companyManager.principalAddress.length > 0;
        return this.validate.principalAddress;
      }
      return null;
    },
    hopeLanguage() {
      if (this.isClicked) {
        if (this.companyManager.hopeLanguage == null) {
          this.companyManager.hopeLanguage = "";
        }
        this.validate.hopeLanguage =
          this.companyManager.hopeLanguage.length > 0;
        return this.validate.hopeLanguage;
      }
    },
    changeDescribe() {
      if (this.isClicked) {
        if (this.companyManager.changeDescribe == null) {
          this.companyManager.changeDescribe = "";
        }
        this.validate.changeDescribe =
          this.companyManager.changeDescribe.length > 0;
        return this.validate.changeDescribe;
      }
    },
  },
  methods: {
    checkInputText: function () {
      if (
        this.companyManager.companyTel == "" ||
        this.companyManager.chairman == "" ||
        this.companyManager.companyPostalCode == "" ||
        this.companyManager.companyAddress == "" ||
        this.companyManager.principalName == "" ||
        this.companyManager.principalTitle == "" ||
        this.companyManager.companyTel == null ||
        this.companyManager.companyLicenseUrl == null ||
        this.companyManager.companyLicenseUrl == "" ||
        this.companyManager.businessCardUrl == null ||
        this.companyManager.businessCardUrl == "" ||
        this.companyManager.chairman == null ||
        this.companyManager.companyPostalCode == null ||
        this.companyManager.companyAddress == null ||
        this.companyManager.principalName == null ||
        this.companyManager.principalTitle == null ||
        // this.companyManager.hopeLanguage == null ||
        this.companyManager.principalAddress == null ||
        this.companyManager.principalAddress == ""
        // this.companyManager.heardCode === "" ||
        // this.companyManager.heardCode === null ||
        // this.companyManager.heardName == "" ||
        // this.companyManager.heardName == null
      ) {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },
    btnSubmit() {
      this.isClicked = true;
      console.log(this.companyManager);
      var payLoad = {
        businessCardUrl: this.companyManager.businessCardUrl,
        chairman: this.companyManager.chairman,
        changeDescribe: this.companyManager.changeDescribe,
        companyAddress: this.companyManager.companyAddress,
        companyLicenseUrl: this.filePdf,
        companyPostalCode: this.companyManager.companyPostalCode,
        companyTel: this.companyManager.companyTel,
        heardCode: this.companyManager.heardCode,
        heardName: this.companyManager.heardName,
        hopeLanguage: this.companyManager.hopeLanguage,
        introduceCode: this.companyManager.introduceCode,
        principalAddress: this.companyManager.principalAddress,
        principalName: this.companyManager.principalName,
        principalTitle: this.companyManager.principalTitle,
        toolAccount: this.companyManager.toolAccount,
        toolCode: this.companyManager.toolCode,
        toolName: this.companyManager.toolName,
        userId: this.companyManager.userId,
        workType: 0,
        detailChangeAbleFlg: 1,
      };
      var check = this.checkInputText();
      if (check) {
        if (this.$CONST.DEV) {
          console.log("Debug API: Mypage/OK", payLoad);
          this.changePermission();
          if (this.$localStorage.get("pageBack") == "/order") {
            this.$router.push(this.$localStorage.get("pageBack"));
          } else {
            this.$localStorage.set("pageBack", "/home");
            this.$router.push({ path: "/success", query: { view: "9" } });
          }
        } else {
          this.$api.post("corporationDetail/save", payLoad).then((response) => {
            this.changePermission();
            if (response.success) {
              if (this.$localStorage.get("pageBack") == "/order") {
                this.$router.push(this.$localStorage.get("pageBack"));
                this.$localStorage.remove("pageBack");
              } else {
                this.$localStorage.set("pageBack", "/home");
                this.$router.push({
                  path: "/success",
                  query: { view: "9", suc: "3" },
                });
              }
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.FAIL"), {
                title: `Errors`,
                solid: true,
                variant: `danger`,
              });
            }
          });
        }
      } else {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `warning`,
        });
      }
    },

    changePermission() {
      window.localStorage.setItem("hascompanyManager", true);
    },

    getData() {
      if (this.$CONST.DEV) {
        this.companyManager = this.$CONST.COMMON.API_COMPANY.Response.result;
      } else {
        this.$api
          .post("corporationDetail/init", {
            userId: window.localStorage.getItem("userIdCode"),
          })
          .then((response) => {
            if (response.result != null) {
              this.companyManager = response.result;
              if (this.companyManager.companyLicenseUrl != null) {
                let name = this.companyManager.companyLicenseUrl.split("___");
                this.pdfName = name[1];
                if (response.result.introduceCodeChangeAbleFlg == false) {
                  this.showCode = false;
                } else this.showCode = true;
              }
              if (response.result.detailChangeAbleFlg == 1) {
                this.detailChangeAbleFlg = true;
              } else {
                this.detailChangeAbleFlg = false;
              }
            }
          });
      }
    },

    callFile(id) {
      document.getElementById(id)?.click();
    },
    setContract(value) {
      if (this.companyManager.toolCode != value) {
        this.companyManager.toolName = "";
        this.companyManager.toolAccount = "";
      }
    },
    resetFormCt(value) {
      if (this.companyManager.heardCode != value) {
        this.companyManager.heardName = "";
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.check_text_1 = true;
      let render = new FileReader();
      this.url1 = URL.createObjectURL(file);
      render.onloadend = (file) => {
        this.companyManager.businessCardUrl = render.result;
      };
      render.readAsDataURL(file);
    },
    onFileChangePDF(e) {
      const file = e.target.files[0];
      this.fileName = file.name;
      let render = new FileReader();
      render.onloadend = (file) => {
        this.filePdf = render.result + "___" + this.fileName;
        this.companyManager.companyLicenseUrl = this.filePdf;
      };
      render.readAsDataURL(file);
    },
    setName() {
      this.companyManager.heardName = " ";
    },
    changeInfo() {
      this.companyManager.changeDescribe = "";
      this.infoChange = true;
    },
  },
};
</script>

<style scoped lang="scss">
.form-control,
select {
  border-radius: 4px !important;
  font-size: 13px;
  height: 40px;
  max-height: 40px !important;
}

.p-personal__otherInfo__SNS__Advertisement {
  font-size: 13px;

  p {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .btn-radio-layout {
    border-radius: 4px !important;
    font-size: 13px;
    margin-bottom: 10px !important;
  }
}

.p-personal__basicInfo__uploadImage__text {
  p {
    font-size: 13px !important;
  }
}

.sns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  display: flex;

  .title-head {
    margin: 15px 0 20px 315px;
  }

  &_title {
    font-weight: 100;
    font-size: 16px;
    line-height: 28px;
    color: var(--newColor);
    font-family: "Noto Sans JP", sans-serif;
    // margin-left: 55px;
    font-weight: 600;
  }

  &_main {
    display: flex;
    width: 50%;

    &__first-title {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}

.birthday {
  display: flex;
  // gap: 10px;

  .year,
  .month,
  .day {
    display: flex;

    select {
      border-radius: 6px;
      height: 35px;
      margin: auto;
    }

    p {
      margin: auto;
      font-size: 13px;
    }
  }

  p {
    margin: auto;
    padding-right: 10px;
    padding-left: 5px;
  }
}

.img {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.note1,
.note2,
.note3,
.note4 {
  h3 {
    font-size: 17px;
    font-weight: 400;
  }

  p {
    font-size: 13px;
    line-height: 25px;
    // margin-bottom: 5px;

    a {
      color: red;
    }
  }
}

.note3 {
  h3 {
    color: red;
  }
}

.id {
  // width: 50%;
  display: flex;
  align-items: center;
}

.reset {
  text-align: center;
}

.foot-content {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  // // gap: 14px;
  align-items: center;
}

.radio-layout {
  width: calc(80% - 30px);
  text-align: left;
}

.btn-radio-layout {
  font-size: 16px;
  padding: 5px 10px;
  margin-top: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub,
.sub1 {
  width: 100% !important;
}

.sub {
  // margin-left: 15px;
}

.unhover {
  box-shadow: none !important;
  color: black !important;
}

.un-cursor {
  cursor: unset !important;
}
.p-personal__basicInfo {
  text-align: left;
  // margin-bottom: 90px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  .sub__image {
    padding-left: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    // margin-top: 2%;

    &__display {
      &__title {
        display: flex;
        align-items: center;
        width: 50%;
        // margin: 15px 0;
      }
    }

    &__item {
      display: flex;
      // // gap: 14px;
      width: 100%;

      &--gender {
        display: flex;
        // gap: 15%;
        width: 100%;

        input {
          // margin: 0 12px;
        }
      }
    }
  }
}

.p-personal__basicInfo__uploadImage {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // gap: 20px;

  &__text {
    width: 296px;
    max-height: 200px;
    min-height: 200px;
    padding: 5% 10px;
    flex-direction: column;
    justify-content: space-around;
    background-color: #f8f1ee;
    border: 1px solid #806c6c;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      display: flex;
      text-align: center;
      font-size: 15px;
      color: var(--newColor);
      line-height: 1.2rem;
    }

    input {
      display: none;
    }

    img {
      width: 30%;
    }
  }
}

.form-select {
  height: 35px;
  color: #816052;
  padding: 0px;
  width: 100%;
}

.form-input {
  width: 100%;
}

.necessary_text {
  font-size: 35px;
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-left: 0px;
  width: 30px;
  // margin-top: 1%;
}

.p-personal__contactInfo {
  text-align: left;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    &__title {
      display: flex;
      align-items: center;
      // margin: 4% 0 2% 5%;

      &__item {
        width: 0;
        height: 20px;
        border: 10px solid #f35f0d;
      }
    }

    &__item {
      display: flex;

      &__phoneNumber {
        display: flex;
        // // gap: 14px;
        width: 50%;

        input {
          width: 40%;
        }
      }

      &__postCode {
        display: flex;
        // // gap: 14px;
        width: 50%;

        input {
          width: 40%;
        }
      }
    }

    &__address {
      display: flex;
      // // gap: 14px;

      textarea {
        height: 70px;
        width: 100% !important;
      }
    }

    &__container {
      display: flex;
      // gap: 10px;
      flex-direction: column;

      &__emergency {
        display: flex;

        &__contact {
          display: flex;
          width: 50%;
        }

        &__phone {
          display: flex;
          // gap: 14px;
          width: 50%;
        }
      }

      &__emergencyInfo {
        display: flex;
        // gap: 14px;
      }

      &__relationship {
        display: flex;

        &__person {
          display: flex;
          // gap: 14px;
          width: 50%;
          height: fit-content;

          input {
            width: 40%;
            min-width: 120px;
          }
        }

        &__language {
          display: flex;
          // gap: 14px;
          width: 50%;
          // margin: 0 0 8% 10%;

          p {
            text-align: left;
            width: 150px;
          }

          input {
            width: 40%;
          }
        }
      }
    }
  }
}

.p-personal__workschoolInfo {
  text-align: left;
  // margin: 60px 0 60px 60px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  display: flex;
  // gap: 10px;
  flex-direction: column;

  &__namephone {
    display: flex;

    &__name {
      display: flex;
      // gap: 14px;
      width: 50%;

      input {
        width: 40%;
      }
    }

    &__numberphone {
      display: flex;
      // gap: 14px;
      width: 50%;

      input {
        width: 40%;
      }
    }
  }

  &__address {
    display: flex;
    // gap: 14px;
  }
}

.p-personal__otherInfo {
  text-align: left;
  // margin-bottom: 90px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  &__SNS {
    display: flex;
    justify-content: space-around;

    &__Advertisement {
      width: 50%;

      &__content {
        display: flex;
        flex-direction: column;
        // row-gap: 5px;
      }
    }

    p {
      color: #f35f0d;
    }
  }
}

button {
  text-align: center;
  padding: 7px 14px;
  width: 12%;
  height: 54px;
  background: var(--newColor);
  border-color: var(--newColor);
  color: #ffffff;
  font-size: 20px;
  line-height: 18px;
  border-radius: 6px;
  margin: auto;
  // margin-bottom: 50px;
}

button:hover {
  background-color: var(--success);
  border-color: var(--success);
}

.sub_title {
  font-weight: 100;
  font-size: 16px;
  line-height: 28px;
  color: var(--newColor);
  // margin-bottom: 2%;
  min-width: 230px;
  text-align: right;
  margin: auto 0px;
  font-family: "Noto Sans JP", sans-serif;
}

.pdf_choose {
  width: 100% !important;
}

.sub_inputText {
  width: 60% !important;
}

.b-form-btn-label-control.form-control {
  width: 60%;
}

// Selected checkbox
.multiselect {
  position: relative;
}

.drop-down {
  display: block;
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 100%;
}

.selectBox {
  position: relative;
}

.selectBox select {
  height: 35px;
  font-size: 14px;
  color: #9a9a9a;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#showIdentityVerification {
  display: none;
  border: 1px #dadada solid;
}

#showIdentityVerification label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#showIdentityVerification label:hover {
  background-color: #f8f1ee;
}

//  Checkbox Support Documents
#supportDocument {
  display: none;
  border: 1px #dadada solid;
}

#supportDocument label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#supportDocument label:hover {
  background-color: #f8f1ee;
}

// Checkbox show language
#showLanguage {
  display: none;
  border: 1px #dadada solid;
}

#showLanguage label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#showLanguage label:hover {
  background-color: #f8f1ee;
}

.sub_form form {
  width: 60%;
}

$wrapper-max-width: 400px;
$mb-breakpoint: 600px;
$breakpoint: 860px;
$large-breakpoint: 1100px;
$xl-breakpoint: 1920px;

body {
  font-family: "Noto Sans JP", sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
}

h1 {
  font-family: "Noto Sans JP", sans-serif;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

hr {
  // margin: 40px auto;
  max-width: 100px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.pen-intro {
  text-align: center;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Input
:focus {
  outline: none;
}

.col-3 {
  float: left;
  position: relative;
  flex: calc(100% - 214px);
  max-width: 100%;
  padding: unset;
}

.form-select {
  padding-left: 12px;
  border: none;
  border-bottom: 1px solid;
}

.radio-layout input:checked + .btn-radio-layout input {
  display: block;
}

.radio-layout input:checked + .btn-radio-layout {
  color: var(--success) !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
