export default {
    BUTTON: {
        PREVIOUS: 'Quay lại',
        NEXT: 'Thanh toán',
        MYPAGE: 'Trang của tôi',
        ORDER: 'Mua hàng ',
        LOGIN: 'Đăng nhập thành viên',
        LOGOUT: 'Đăng xuất',
        CANCEL: 'Hủy bỏ',
        REGISTER: 'Đăng ký thành viên',
        CHOOSE: 'Đăng ký',
        CONFIRM: 'Đăng ký gói này',
        PAYMENT_NEXT: "Thanh toán",
    },
  
    VALIDATION: {
        REQUIRED: 'Không sử dụng',
    },
  
    MENU: {
        SIM_SERVICE: "SIM",
        SIM_ORDER: "SIM",
        WIFI_SERVICE: "Wi-Fi",
        OTP_SERVICE: "Cáp quang",
        SELL_SERVICE: 'Dịch vụ . Thiết bị',
        TERMINAL: "Dịch vụ . Thiết bị",
        LOG_OUT: "Đăng xuất",
        INVESTIGATION: "Liên hệ",
        LOGIN: 'Đăng nhập',
        MYPAGE: 'Trang của tôi',
        MYPAGE_MENU: [
            
            {
                name: "Địa chỉ URL giới thiệu độc quyền",
                value: 8,
                url: "",
            },
            {
                name: "Tình  sử dụng",
                value: 4,
                url: "",
            },
            {
                name: "Thanh toán・Cước phí sử dụng",
                value: 6,
                url: "",
            },
            {
                name: "Gói cước hợp đồng",
                value: 5,
                url: "",
            },
            {
                name: "Tình trạng đăng ký",
                value: 3,
                url: "",
            },
            {
                name: "Thông tin thành viên",
                value: 0,
                url: "",
            },
            {
                name: "Quản lý điểm",
                value: 1,
                url: "",
            },
            {
                name: "Đổi lấy tiền quản lý",
                value: 2,
                url: "",
            },
            {
                name: "Thanh toán định kỳ",
                value: 7,
                url: "",
            },
            {
                name: "Tài khoản và bảo mật",
                value: 10,
                url: "",
            },
        ],
    },
  
    COMMON: {
        STRING: {
            IS_BLANK: "",
            IS_NULL: null,
            IS_INVALID: undefined,
            STRIKE_THROUGH: '—'
        },
        ITEM_TYPE: {
            NECESSARY: "Cần thiết",
            PRICE_MONTH: "(tháng %{price}yen)",
            PRICE_MONTH_STEP1: "tháng %{price}yen",
            PRICE_MONTH_STEP1_TAX: "(%{price}yen Giá đã bao gồm thuế)",
            ONLY_PRICE: "%{price} yen",
            VALIDATE: "Hạng mục bắt buộc"
        },
        NAVBAR: {
          STEP1: " Lựa chọn SIM",
          STEP2: "Lựa chọn đường truyền cáp quang",
          STEP3: "Lựa chọn Wi-Fi cầm tay",
          STEP35: "Thuê Wi-Fi",
          STEP4: "Phương thức nhận hàng",
          STEP5: "Xác nhận nội dung đăng ký",
          STEP6: "xác minh danh tính",
          STEP7: "Hợp đồng",
          STEP8: "Thanh toán",
      },
      SUC: {
          CONTENT1: "Xử lý đã hoàn tất. Vui lòng kiểm tra thông tin sau đây:",
          CONTENT2: "□ Xử lý đơn hàng: Vui lòng kiểm tra thông báo từ trang web dành riêng cho thành viên và email cho đến khi nhận được sản phẩm.",
          CONTENT3: "□ Đặt dịch vụ: Sau khi trạng thái hoàn thành được hiển thị trên trang web dành riêng cho thành viên hoặc nhận email xác nhận đặt dịch vụ, vui lòng đến cửa hàng vào thời gian đã chỉ định.",
          CONTENT4: "□ Thanh toán hàng tháng: Trong trường hợp gặp sự cố về thanh toán hoặc cần xử lý hoàn trả, vui lòng liên hệ hỗ trợ khách hàng.",
          CONTENT5: "□ Nạp dữ liệu: Sau khi mua hàng hoàn tất, dữ liệu sẽ được nạp ngay lập tức và chuyển sang chế độ tốc độ cao. Vui lòng kiểm tra lượng dữ liệu còn lại trên trang tình trạng sử dụng.",
          CONTENT6: "□ Thay đổi gói cước: Sau khi hoàn tất thay đổi, gói dữ liệu và gói tùy chọn sẽ được áp dụng vào tháng tiếp theo, gói thoại không giới hạn sẽ được áp dụng vào ngày hôm sau. Chỉ có thể thay đổi một lần trong tháng.",
          CONTENT7: "□ Cập nhật thông tin thành viên: Sau khi các tài liệu đã được thay đổi được phê duyệt, bạn không thể thay đổi hoặc xóa trong vòng 3 tháng kể từ thời điểm được phê duyệt.",
          CONTENT8: "□ Nạp điểm: Sau khi nạp hoàn tất, vui lòng chọn điểm IF trong phần phương thức thanh toán cho các thanh toán trong tương lai.",
          CONTENT9: "□ Yêu cầu đổi tiền: Nếu bạn muốn sử dụng thanh toán mã vạch, vui lòng liên hệ hỗ trợ khách hàng.",
          CONTENT10: "□ MNP, Hủy đăng ký, Tạm dừng/Kích hoạt lại: Sau khi hoàn tất đăng ký, vui lòng đợi liên lạc từ hỗ trợ khách hàng.",
          CONTENT11: "□ Liên hệ: Sau khi gửi yêu cầu, vui lòng chờ liên lạc từ hỗ trợ khách hàng.",
          ORDER_PAY_BUTTON: "Đến trang chủ",
          CHANGE_PAY_BUTTON: "Đến trang của tôi",
      },
      ERR: {
          CONTENT: [
              "Có lỗi phát sinh trong lúc di chuyển màn hình",
              "Bạn hãy đăng xuất, đóng trình duyệt, đợi một lúc rồi thử lại nhé",
              "Nếu vẫn còn lỗi, bạn vui lòng liên hệ đến trung tâm hỗ trợ IF MOBILE để được giúp đỡ.",
          ],
          REVER_ERR: "Quay về trang chủ",
          CODE_INPUT: 'Mã xác nhận không đồng nhất!!!',
      },
      PAYMENT: {
        　MESSAGE:"Vui lòng chọn phương thức thanh toán cho khoản phí ban đầu.",
          CHOOSE_PAY_WAY: "Vui lòng chọn phương thức thanh toán cho khoản phí ban đầu.",
          MESSAGE3:"Chúng tôi cung cấp hai phương thức thanh toán: thanh toán trực tuyến (thanh toán bằng thẻ tín dụng, thanh toán mã QR) và thanh toán ngoại tuyến (ngân hàng trực tuyến, tiền mặt, chuyển khoản qua ATM).",
          MESSAGE4:"□ Nếu bạn chọn thanh toán bằng thẻ tín dụng, vui lòng nhập thông tin thẻ tín dụng và nhấp vào nút 'Xác nhận' để hoàn tất thanh toán.",
          MESSAGE5:"□ Nếu bạn chọn các phương thức thanh toán mã QR khác nhau, vui lòng mở mã QR thanh toán tương ứng, quét mã QR để thanh toán và sau đó nhấp vào nút 'Xác nhận' để hoàn tất thanh toán.",
          MESSAGE6:"□ Nếu bạn chọn ngân hàng trực tuyến, vui lòng chọn và nhấp vào nút 'Xác nhận' trực tiếp để hoàn tất quy trình. Thông tin tài khoản thanh toán sẽ được gửi đến địa chỉ email đăng nhập của bạn. Vui lòng kiểm tra email của bạn và hoàn tất thanh toán thông qua ứng dụng ngân hàng trực tuyến.",
          MESSAGE7:"□ Nếu bạn chọn tiền mặt hoặc chuyển khoản qua ATM, vui lòng chụp ảnh hoặc chụp màn hình số tiền và tài khoản chuyển khoản sau khi chọn, sau đó nhấp vào nút 'Xác nhận'. Sau khi chuyển khoản bằng máy ATM, vui lòng gửi biên lai cho dịch vụ khách hàng hoặc đến cửa hàng trong thời gian quy định để thanh toán bằng tiền mặt Nhật Bản.",
          MESSAGE8:"※Nếu bạn muốn thiết lập trừ tiền tự động cho hóa đơn điện thoại trong tương lai (trừ tiền bằng thẻ tín dụng, trừ tiền tự động từ thẻ ngân hàng, trừ tiền từ điểm IF), vui lòng chọn ở dưới và sau đó nhấp vào nút 'Xác nhận' để hoàn tất quy trình.",
          ONLINE_PAY: "thanh toán trực tuyến",
          DEFERRED_PAY: "trả chậm",
          PAYMENT_AMOUNT: "Số tiền thanh toán:",
          CARD_TITLE: "Thẻ tín dụng",
          CARD_NO: "4 số cuối",
          TAX: "yên (đã bao gồm thuế)",
          BANKING: "Ngân hàng trực tuyến",
          BANKING_MESSAGE: "Nhận thông tin cần thiết để thanh toán",
          MESSAGE1:"Sau khi chọn ngân hàng trực tuyến, vui lòng nhấp vào nút 'Xác nhận' trực tiếp để hoàn tất quy trình. Thông tin tài khoản thanh toán sẽ được gửi đến địa chỉ email đăng ký của bạn.Vui lòng kiểm tra email của bạn và hoàn tất thanh toán thông qua ứng dụng ngân hàng trực tuyến.",
          MESSAGE2:"Vui lòng tham khảo các bước sau đây để biết hướng dẫn sử dụng.",

          CREDIT: {
              TITLE: "Thẻ tín dụng",
              RECOMMENT: "Đề xuất!",
              TEXT: "Phí hằng tháng sẽ tự động được khấu trừ từ thẻ tín dụng của bạn.Yêu cầu đăng ký.Thẻ ghi nợ cũng được chấp nhận.Thẻ tín dụng được phát hành bên ngoài Nhật Bản cũng có thể được sử dụng.Tuy nhiên, cần phải mở dịch vụ thanh toán quốc tế (RMB/USD).",
              PLA1: 'Mã số thẻ',
              PLA2: 'Thời hạn ( năm )',
              PLA3: 'Thời hạn ( tháng )',
              PLA4: 'Tên trên thẻ ( Tên)',
              PLA5: 'Tên trên thẻ ( Họ )',
              PLA6: 'Mã bảo mật',
          },
          CODE: {
              TITLE: "Các dịch vụ thanh toán mã khác nhau (WeChat Pay, Alipay，Paypay)",
              ALIPAY: 'Thanh toán quốc tế Alipay',
              WECHAT: 'Thanh toán quốc tế Wechatpay',
              MESSAGE: "Bạn có thể thanh toán bằng cách quét mã vạch.",
          },
          STORE: {
              TITLE: "Thanh toán tại cửa hàng tiện lợi(không khả dụng)",
              TEXT: 'Thanh toán tiền mặt tại cửa hàng tiện lợi. Vui lòng thanh toán trong vòng 7 ngày sau khi đặt hàng.',
              PLA1: 'Tên người dùng',
              PLA2: 'Số điện thoại',
              STORE_TYPE: 'Tên cửa hàng tiện lợi :',
          },
          CASH: {
              TITLE: "Tiền mặt hoặc chuyển khoản ngân hàng qua ATM",           
              TITLE1: "Tiền mặt",
              TITLE2: "chuyển khoản ngân hàng qua ATM",
              TEXT1: 'Thanh toán tiền mặt trực tiếp tại cửa hàng, đại lý.',
              TEXT2: 'Chúng tôi chỉ chấp nhận tiền mặt bằng Yên Nhật.',
              TEXT3: "Thông tin địa chỉ chuyển tiền: Ngân hàng Sumitomo Mitsui Chi nhánh Seitama, Bình thường 5954038.",
              TEXT4: "Sau khi gửi tiền, vui lòng gửi chi tiết đến trung tâm hỗ trợ khách hàng (ví dụ: LINE/WECHAT/Mail).",
              TEXT5: 'Phương thức thanh toán tại đây.',
          },
          CHOOSE_METHOD: {
              TITLE: "Vui lòng chọn phương thức thanh toán cho các khoản phí hằng tháng trong tương lai.",
              METHOD1: "Rút tiền tự động bằng thẻ tín dụng (khuyên dùng)",
              METHOD2: "Chuyển khoản Rút tiền tự động (khuyên dùng)",
              METHOD3: "Thanh toán bằng mã QR Wechat, Alipay、Paypay",
              METHOD4: "Cửa hàng tiện dụng",
              METHOD5: "Tiền mặt  Ngân hàng trực  tuyến chuyển khoản ngân hàng qua ATM",
              METHOD6: "Điểm IF",
          },
          WITHDRAW: {
              TITLE: "Tìm hiểu về rút tiền tự động từ tài khoản ngân hàng",
              CONTENT1: "Không thể thanh toán phí ban đầu.",
              CONTENT2: "Các khoản thanh toán hằng tháng sẽ được tự động khấu trừ từ tài khoản ngân hàng của bạn. Chúng tôi sẽ gửi cho bạn một mẫu đăng ký rút tiền tự động và một phong bì cùng với sản phẩm, vì vậy vui lòng điền vào mẫu và gửi lại cho chúng tôi.",
              PDF: "「XXXXXX.PDF」"
          },
          BUTTON: {
              CONFIRM: "Xác nhận",
              PAY: "Tiếp theo",
              RETURN: "Quay lại"
          },
          VALIDATE:{
              INPUT:"Chọn phương thức thanh toán",
              FAIL: 'Xử lý thất bại',
              CHECK_INPUT: 'Bạn chưa nhập đầy đủ thông tin',
              MESSAGE: 'Vui lòng thanh toán tiền trước',
          }
        },
      },
  
  SIM_SERVICE: {
      STEP1_PLAN_SELECT: {
          TITLE: "Lựa chọn SIM",
          QUESTIONS_PURCHASE_METHOD: {
              TITLE: 'Chú ý trước khi đăng ký',
              CARD1: {
                  TITLE: 'Phương thức thanh toán',
                  TEXT1: 'Các loại thẻ tín dụng khác nhau',
                  TEXT2: 'Chuyển khoản ngân hàng',
                  TEXT3: 'Thanh toán bằng mã QR Wechat, Alipay',
                  TEXT4: 'Cửa hàng tiện lợi',
                  LINK: 'Chi tiết về phương thức thanh toán',
              },
              CARD2: {
                  TITLE1: 'Tài liệu xác minh danh tính',
                  TITLE2: '(bằng lái xe, thẻ bảo hiểm, v.v.)',
                  TEXT1: 'Xác minh danh tính dựa trên Điều luật về việc ngăn chặn sử dụng số điện thoại trái phép, tài liệu xác minh danh tính bắt buộc',
                  TEXT2: '※ Hình ảnh đăng ký',
                  LINK: 'Tài liệu xác minh danh tính',
              },
              CARD3: {
                  TITLE1: 'Mã số đặt hẹn MNP',
                  TITLE2: '(chỉ dành cho những người thay đổi công ty mạng viễn thông)',
                  TEXT1: 'Nếu bạn muốn giữ lại số điện thoại đang sử dụng, cần lấy trước mã số đặt hẹn MNP từ nhà mạng đang sử dụng.',
                  TEXT2: '',
                  TEXT3: 'Vui lòng cho chúng tôi biết về nguyện vọng của bạn.',
                  LINK: 'Bấm vào đây để biết thêm chi tiết',
              },
              CARD4: {
                  TITLE: 'Địa chỉ email',
                  TEXT1: 'Để đăng ký thành viên và nhận tin nhắn',
                  TEXT2: 'Bạn hãy chuẩn bị địa chỉ email',
                  TEXT3: "Vui lòng cho chúng tôi biết về nguyện vọng của bạn",
              },
          },
          QUESTIONS_SELECT_TEL_NUMBER: {
              NEW_AGREEMENT: "Hợp đồng mới",
              ANOTHER_COMPANY: {
                  TITLE: "MNP",
                  TEL_PLACE_HOLDER: "Vui lòng nhập số điện thoại hiện tại",
                  MNP_PLACE_HOLDER: "Mã số đặt hẹn MNP",
                  DATE_PLACE_HOLDER: "Thời hạn của mã số đặt hẹn MNP ",
                  SIM_PLACE_HOLDER: "Vui lòng nhập số điện thoại nếu bạn biết",
                  TITLE_BANK: "Vui lòng chọn công ty viễn thông bạn đang sử dụng",
                  BANKS: [
                      { value: 0, text: 'DOCOMO' },
                      { value: 1, text: 'SOFTBANK' },
                      { value: 2, text: 'AU' },
                      { value: 3, text: 'UQ' },
                      { value: 4, text: 'Y!M' },
                      { value: 9, text: 'Khác' }
                  ],
              }
          },
          QUESTIONS_SET: {
              TITLE: " Chỉ mua SIM hoặc mua theo gói",
              SIM: " Chỉ có SIM",
              OTP: "Gói bao gồm mạng cáp quang và SIM",
              WIFI: " Gói gồm Wi-Fi bỏ túi và SIM",
          },
          QUESTIONS_RATE_PLAN: {
              TITLE: " Gói data tốc độ cao. Có thể thay đổi sau khi hoàn thành hợp đồng",
          },
          QUESTIONS_FLAT_RATE_CALL: {
              TITLE: "Gói cước thoại",
          },
          QUESTIONS_OPTION: {
              TITLE: "Lựa chọn khác",
              OPTION1: "Máy trả lời tự động",
              OPTION2: "Cuộc gọi chờ",
              OPTION3: "Không cần",
          },
          QUESTIONS_DESIRED_DATE: {
              TITLE: " Ngày mong muốn bắt đầu sử dụng",
          },
          SIM_CALL_QUOTA: [
              { value: '000011', text: 'Gọi miễn phí trong vòng 5 phút' },
              { value: '000012', text: 'Gọi miễn phí trong vòng 10 phút ' },
              { value: '000013', text: ' Không giới hạn thời gian cuộc gọi ' },
              { value: '000014', text: ' Không cần' },
          ],
          NONE: 'Không cần',
          OPTIONCHOSSE: [
                { value: '000016', text: 'Máy trả lời tự động' },
                { value: '000017', text: 'Cuộc gọi chờ' }
              ],
      },
      STEP2_CHOOSE_CABLE: {
          NAVBAR: "Chọn đường truyền cáp quang",
          TITLE: "Chọn đường truyền cáp quang",
          QUESTIONS_CHOOSE_PACKAGE: {
              TITLE: " Loại đường truyền cáp quang ",
          },
          QUESTIONS_START_DAY: {
              TITLE: "   Ngày mong muốn bắt đầu sử dụng  ",
          },
          RENT: {
              TITLE1: " Hợp đồng lần đầu ",
              TITLE2: " Đổi nhà mạng ",
              USAGE_STATUS: "Tình trạng sử dụng",
              QES: 'Thuê hộp mạng không dây',
              NEED: 'Mong muốn',
              NO_NEED: ' Không có mong muốn'
          },
          PHONE: {
              TITLE: 'Số điện thoại liên lạc trong ngày',
          },
          TIME: {
              TITLE: ' Khung giờ có thể liên lạc trong ngày',
          },
          IFM_COMMODITY: [
              { value: '000025', text: ' Hộ chung cư ', type: ' (Dành cho khu tập thể)' },
              { value: '000026', text: ' Hộ gia đình ', type: ' (Dành cho nhà riêng)' },
          ],
      },
      STEP3_WIFI_SELECTION: {
          REQUIRED1: " Gói data Wi-fi tốc độ cao",
          REQUIRED2: " Ngày mong muốn bắt đầu sử dụng",
      },
      STEP4_RECEIVE: {
          QUESTIONS_RADIO: {
              SELFIE: {
                  TITLE: 'Nhận tại cửa hàng',
                  TEXT1: 'Trụ sở chính',
                  TEXT2: 'Tên cửa hàng : IF Mobile',
                  TEXT3: ' Địa chỉ  : Tokyo, shinjuku, Takadababa 1-28-3',
                  TEXT4: 'Đại lý',
              },
              TIME_DELI: 'Thời điểm giao hàng mong muốn',
              OVERSEAS: "Gửi qua bưu điện",
              IN_JAPAN: "Trong nước Nhật ",
              OUTSIDE_JAPAN: "Nước ngoài",
              AM: "Sáng",
              PM: "Chiều",
          },
          QUESTIONS_TEXTINPUT: {
            　SELECT:"Chọn lựa",
              MAILING_NAME: "Tên",
              POSTAL_CODE: "Mã bưu chính",
              MAILING_CONTACT_PHONE: "Số điện thoại",
              MAILING_DETAILS_ADDRESS: "Địa chỉ",
              JA: "Trong nước Nhật    Phí vận chuyển %{price}yen",
              FOREIGN: "Nước ngoài    Phí vận chuyển %{price}yen",
              DELIVERY_TIME:"Thời điểm giao hàng mong muốn",
              VISIT:"Thời gian đến cửa hàng nhận",
              VISIT_DATE:"Vui lòng lựa chọn lịch ghé thăm",
              MESSAGE1: "Để quay lại trang trước đó, nhấp vào biểu tượng luồng mua hàng ở đầu trang.",
          },
          VISIT_TIME: [
                { value: "", text: 'Khung thời gian', disabled: true  },
                { value: '10:00 ～ 12:00', text: '10:00 ～ 12:00'},
                { value: '12:00 ～ 14:00', text: '12:00 ～ 14:00'},
                { value: '14:00 ～ 16:00', text: '14:00 ～ 16:00'},
                { value: '16:00 ～ 19:00', text: '16:00 ～ 19:00'},
            ]
      },
      STEP5_PURCHASE_CONFIRMATION: {
          TITLE: "Xác nhận nội dung gói cước và cước phí",
          TEXT: " Vui lòng xác nhận gói cước đã lựa chọn và cước phí và tiếp tục thao tác",
          COMMISSION: "Phí dịch vụ đăng ký",
          ANOTHER_COMMISSION: "Phí xử lý hợp đồng",
          PACKAGE_FEE: "Hộ chung cư",
          TAX: "Thuế",
          AMOUNT: 'Số tiền thanh toán',
          WITHOUT_TAX: "%{price} yen (Giá cước chưa bao gồm thuế)",
          WITH_TAX: "%{price} yen (Giá cước bao gồm thuế)",
          CONFIRM: " Vui lòng xác nhận nội dung  trên có chính xác hay không? Nếu bạn muốn chỉnh sửa, vui lòng chọn từng bước ở đầu trang, chỉnh sửa và quay lại trang sản phẩm.",
          FIXED_COSTS: "Phí cơ bản",
          QUANTITATIVE_FEE: "Phí sử dụng",
          TEXT1: "Cước phí gọi của hai tháng trước",
          UNSETTLED: "Không xác định",
          OVERSEAS_FEE: "Cước phí sử dụng nước ngoài",
          OPENING_COST: "Phí đi đường dây",
          INSTALLMENT_COST : "Trả góp phí đi đường dây",
          DEPOSIT: "Phí bảo hiểm",
          SIM_CARD_PURCHASE_DETAILS: {
              TITLE: "Cuộc gọi thoại đường dây số 1",
              CONTRACT_TYPE: "Loại hợp đồng",
              PHONE_NUMBER: "Số điện thoại",
              
              MNP_PHONE_NUMBER:"Số điện thoại hiện tại",
              MNP_CODE:"Mã số đặt hẹn MNP",
              MNP_DEADLINE:"Thời hạn của mã số đặt hẹn MNP",
              SIM_BRAND_TITLE:"Công ty viễn thông bạn đang sử dụng",
              SIM_BRAND_0:"DOCOMO",
              SIM_BRAND_1:"SOFTBANK",
              SIM_BRAND_2:"AU",
              SIM_BRAND_3:"UQ",
              SIM_BRAND_4:"Y!M",
              SIM_BRAND_9:"Khác",
  
              OPEN_DAY: "   Ngày mong muốn bắt đầu sử dụng",
              PLAN: " Gói data tốc độ cao",
              TOTAL_INITIAL_COST: "Tổng chi phí hàng tháng",
              SIM_DAMAGES: "Phí cấp thẻ SIM",
              UNIVERSERVE: "Phí dịch vụ phổ thông",
              RELAYSERVICE: "Phí dịch vụ chuyển tiếp điện thoại",
              FLAT: ' Gói cước thoại',
              MONTH_BASE: ' Phí điện thoại của hai tháng trước %{month}',
              SMS_BASE: ' Phí SMS của hai tháng trước %{month}',
              MONTH: '28 ngày',
              FIRST_MONTH_FEE: " Cước phí sử dụng của tháng đầu tiên",
              ONETIME_FEE:"Phí chỉ phát sinh lần đầu",
              DETAIL: "Xem chi tiết",
              INITIAL_COST:"Phí ban đầu",
              MONTHLY_FEE: "Cước phí tháng",
              NEXT_MONTH_FEE: "Cước phí từ tháng thứ 2",
              PAYMENT_PREVIOUS_MONTH: "Thanh toán đến hạn vào cuối tháng trước",
              MONTHLY_PAYMENT:"Phí thanh toán hằng tháng,",
              MONTHLY_PAYMENT_TYPE:"+phí điện thoại + SMS",
              SMS_FEE_LAST_MONTH: "Phí tin nhắn SMS của hai tháng trước",
          },
          LIGHT_PURCHASE_DETAILS: {
              TITLE: "Đường truyền cáp quang",
              PLAN: "Gói",
              OPEN_DAY: "  Ngày mong muốn bắt đầu sử dụng",
              TOTAL_INITIAL_COST: "Tổng chi phí ban đầu",
              USAGE_STATUS: "Tình trạng sử dụng",
          },
          WIFI_PURCHASE_DETAILS: {
              TITLE: "Wi-Fi di động",
              RENT_TITLE: "Cho thuê Wi-Fi",
              PLAN: "Gói",
              OPEN_DAY: " Ngày mong muốn  bắt đầu sử dụng",
              COD: "COD",
              DEVICE_PRICE: "Giá thiết bị Wi-Fi",
          },
          RECEIVE: {
              TITLE: "Phương thức nhận hàng",
              PRODUCT_ACQUISITION_METHOD: "Phương thức nhận hàng",
              AREA: "Khu vực",
              MAILING_NAME: "Tên",
              MAIL_CONTACT_PHONE: "Số điện thoại",
              LOCAL_POSTAL_CODE: "Mã bưu chính",
              MAILING_DETAILS_ADDRESS: "Địa chỉ"
          },
          TOTAL: {
              POSTAGE: "bưu phí",
              TOTAL_INITIAL_COST: "Tổng chi phí ban đầu",
          }
      },
      STEP6_INFOMATION_CONFIRM: {
          LOGIN_ALERT: "Vui lòng đăng nhập hoặc tạo một Trang của tôi.",
          INFO_DETAIL_ALERT: "Vui lòng thay đổi hoàn toàn thông tin !",
          NOTIFICATION: " Khi bạn đăng nhập vào[Trang chỉ dành cho hội viên] của IF Mobile ",
          NOTICE_CONTENT: "Bạn có thể kiểm tra trạng thái sử dụng, tính phí dung lượng dữ liệu, gói và thông tin khách hàng, v.v.",
          QUESTIONS_MEMBER_CONFIRM: {
              TITLE2: "Đối với khách hàng chưa đăng ký thành viên",
              TITLE1: "Đối với khách hàng chưa đăng nhập",
              MEMBER: "Đăng nhập hội viên",
              NOT_A_MENBER: "Đăng ký thành viên",
              SIGN_UP: 'Đăng ký mới',
              NONE_SIGN_UP: 'Nhập thông tin người dùng không cần đăng ký',
          },
          QUESTIONS_REGISTER_INFO: {
              TITLE: "Khách hàng đã đăng ký",
              REGISTER: "Đăng ký thông tin chi tiết của hội viên",
          }
      },
      STEP7_CONSENT_SIGN: {
          CONTENT: ' Nhấn vào Điều khoản và Điều kiện, kiểm tra nội dung trước khi đăng ký.',
          TITLE: 'Các điều khoản và điều kiện',
          NAVBAR: "Chữ kí xác nhận",
          CHECKBOX: "Tôi hiểu và đồng ý với các điều khoản và điều kiện trên.",
          TEMP1: "Điều khoản và Điều kiện",
          TEMP2: " Chi tiết hợp đồng và những chú ý",
          BTN: {
            BTN1: "SIM",
            BTN2: "Wi-Fi",
            BTN3: "Cáp quang"
          },
          PAYMENT_FORM: {
              FORM1: {
                  PLA1: 'Mã số thẻ',
                  PLA2: 'Thời hạn ( năm )',
                  PLA3: 'Thời hạn ( tháng )',
                  PLA4: 'Tên trên thẻ ( Tên )',
                  PLA5: 'Tên trên thẻ ( Họ )',
                  PLA6: 'Mã bảo mật',
              },
              FORM2: {
                  TITLE: 'Thanh toán quốc tế Alipay'
              },
              FORM3: {
                  TITLE: 'Thanh toán quốc tế Wechatpay'
              },
              FORM4: {
                  TITLE: 'Vui lòng xác nhận',
                  PLA1: 'Tên sử dụng',
                  PLA2: 'Số điện thoại',
                  PLA3: 'Loại cửa hàng tiện lợi:',
              }
          },
          PAYMENT_METHOD: '* Phương thức thanh toán:',
          SIGN: {
              TITLE: "Chỉ có chữ ký của người làm hợp đồng, không được ủy quyền",
              ACCEPT: "Xác nhận",
              CLEAN: "Chọn lại"
          },
          OPTION: [
              { value: null, text: ' Chọn phương thức thanh toán' },
              { value: '0', text: ' credit : Thanh toán bằng thẻ tín dụng' },
              { value: '1', text: 'alipay: Thanh toán quốc tế Alipay' },
              { value: '2', text: 'wechatpay: Thanh toán quốc tế Wechatpay' },
              { value: '3', text: ' unionpay: Thanh toán qua mạng lưới ngân hàng' },
              { value: '4', text: 'Thanh toán tại cửa hàng' },
              { value: '5', text: 'cửa hàng tiện dụng' }
          ],
          OPTION_STORE: [
              { text: 'Loại cửa hàng tiện lợi', value: null },
              { text: 'Seven Eleven', value: 'seven_eleven' },
              { text: 'Family Mart', value: 'family_mart' },
              { text: 'Lawson', value: 'lawson' },
              { text: 'Mini Stop', value: 'mini_stop' },
              { text: 'Seico Mart', value: 'seico_mart' },
              { text: 'Pay Easy', value: 'pay_easy' },
              { text: 'Circle K', value: 'circle_k' },
              { text: 'Sunkus', value: 'sunkus' },
              { text: 'Daily Yamazaki', value: 'daily_yamazaki' },
              { text: 'Yamazaki Daily Store', value: 'yamazaki_daily_store' },
          ],
      },
      GENERAL_MERCHADISE: {
        TITLE: 'Nhập nội dung đăng ký',
      }
  },
  
  FOOTER: {
      TITLE1: {
          TITLE: 'Sản phẩm',
          LINK1: 'SIM',
          LINK2: 'Wi-Fi',
          LINK3: 'Cáp quang',
          LINK4: 'Dịch vụ . Thiết bị',//tag
      },
      TITLE2: {
          TITLE: 'Hội viên IF',
          LINK1: 'Dành cho hội viên',
          LINK2: 'Đăng ký thành viên',
      },
      TITLE3: {
          TITLE: 'Hỗ trợ',
          LINK1: 'Q&A',
          LINK2: 'Quầy giao dịch',
          LINK3: 'Hướng dẫn cài đặt',
          LINK4: 'Phương thức thanh toán',
      },
      TITLE4: {
          TITLE: 'Thông tin công ty',
          LINK1: 'Công ty vận hành',
          LINK2: 'Kí hiệu dựa trên các giao dịch thương mại chỉ định',
          LINK3: 'Điều khoản và điều kiện',
          LINK4: 'Chính sách bảo mật',
      },
  },
  
  LOGIN: {
      TITLE: "Đăng nhập",
      USERNAME: "ID khách hàng: Địa chỉ Email",
      PASSWORD: "Mật khẩu",
      CODE: "Mã xác nhận",
      FORGET_MESSAGE: "※Tối thiểu 6 ký tự bán chiều có chữ cái hoặc số",
      FORGET_PASSWORD: "Nếu bạn quên mật khẩu,",
      RECOVER_PASSWORD: "chọn vào đây",
      NO_ACCOUNT: "Bạn sử dụng lần đầu? Hãy đăng ký mới",
      NEW_ACCOUNT: "chọn vào đây",
      CANCEL: ' Hủy bỏ',
      LOGIN: "Đăng nhập",
  },
  
  FORGET_PASSWORD: {
    TITLE: "Khách hàng quên mật khẩu",
    EMAIL_ADDRESS: "Địa chỉ email",
    SEND: "Gửi ",
    CODE: "Mã xác nhận",
    VALIDATE_CODE: "Mã xác nhận không chính xác!!!",
    VALIDATE_MAIL: "Đây không phải là một địa chỉ email hợp lệ",
    PASSWORD: "Mật khẩu mới ",
    CONFIRM_PASSWORD: "Mật khẩu mới (Xác nhận)",
    MESSAGE1: "Chúng tôi sẽ đặt lại mật khẩu cho trang của bạn và gửi email đến địa chỉ email đã đăng ký của bạn với mã xác thực.",
    MESSAGE2: 'Nếu bạn có cài đặt nhận email, vui lòng cho phép email từ "ifmobile.jp".',
    MESSAGE3: "Nếu bạn không nhận được email, vui lòng liên hệ Trung tâm khách hàng IF Mobile.",
    TITLE2:"ID khách hàng (địa chỉ email đã đăng ký)",
    BUTTON: {
      SUBMIT: "Yêu cầu cấp lại",
      CANCEL: "Quay lại",
    },
  },
  
  REGISTER: {
      TITLE: "Tạo trang của tôi ",
      INDIVIDUAL: "Cá nhân",
      CORPORATION: "Công ty ",
      EMAIL_ADDRESS: "Địa chỉ email",
      SEND_VERIFICATION_CODE: "Gửi mã",
      MESSAGE: "Hãy nhập địa chỉ email của bạn",
      PLACE_HOLDER: {
          PLH0: 'Địa chỉ email',
          PLH1: 'Mã xác nhận',
          PLH2: 'Họ và tên (viết giống như trên hộ chiếu)',
          PLH3: 'Họ và tên (bằng chữ Katakana)',
          PLH4: 'Mật khẩu (chữ và số bán khóa, ít nhất 6 ký tự)',
          PLH5: 'Nhập lại mật khẩu',
          PLH6: "Tên công ty (viết giống như bản sao đăng ký)",
          PLH7: "Tên công ty Tên công ty (bằng chữ Katakana)",
          MESSAGE1:"Địa chỉ email đã đăng ký sẽ trở thành ID khách hàng.",
          MESSAGE2:'Chúng tôi cũng sẽ gửi cho bạn các thông báo quan trọng, vì vậy hãy đảm bảo các thông tin đã đăng ký hoàn toàn chính xác.Vui lòng cho phép email từ "ifmobile.jp".',
          MESSAGE3:"Vui lòng tạo địa chỉ email có thể nhận được email và đăng ký nếu bạn chưa có địa chỉ email.",
          MESSAGE4:"Mật khẩu là bắt buộc khi đăng nhập, vì vậy vui lòng đảm bảo lưu lại mật khẩu.",      
      },
      BUTTON_RESGITER: "Tạo trang của tôi",
      BUTTON_RESGITER_CANCEL:"Quay lại",
      ERROR: {
          RESPASSWORD: ' Mật khẩu và mật khẩu xác nhận không giống nhau',
          EMAIL_EXIST: "Địa chỉ email đã tồn tại, vui lòng thay đổi.",
      }
  },
  
  PERSONAL_MANAGER: {
      NOTE: {
          TITLE: ' Xác nhận thông tin danh tính',
          TEXT1: ' Thông tin cá nhân bạn cung cấp sẽ chỉ được sử dụng để xác minh chính chủ.',
          TEXT2: '1、Dựa trên luật pháp Nhật Bản, khi mua thẻ sim, bắt buộc phải gửi tài liệu xác minh công ty có địa chỉ. Ngoài ra, nó chỉ giới hạn ở những người có ngày hết hạn còn lại từ 1 tháng trở lên.',
          TEXT3: '2、Vui lòng đảm bảo rằng địa chỉ giao hàng của thẻ SIM khớp với địa chỉ của tài liệu xác minh danh tính. Nếu các tài liệu xác minh danh tính không khớp, vui lòng tải lên các tài liệu hỗ trợ chứng minh địa chỉ của bạn cùng một lúc. * Trong vòng 6 tháng kể từ ngày cấp.',
          TEXT4: '3. Vui lòng tải lên ảnh do chính khách hàng chụp, cầm giấy tờ xác minh danh tính và không che mặt. Lưu ý rằng, hình ảnh phải rõ ràng để đọc được nội dung.',
          TEXT5: '4、Vui lòng nhập thông tin công ty chính xác. Nếu thông tin đăng tải không đáp ứng các yêu cầu trên, bạn sẽ không thể mua thẻ SIM.',
          TEXT6: 'Tài liệu xác minh danh tính ',
          TEXT7: 'Bấm vào đây để xem thêm chi tiết',
      },
      NOTE1: {
          TITLE: '※Vui lòng tải lên ảnh mặt trước và mặt sau của tài liệu xác minh danh tính của bạn và ảnh bạn đang cầm tài liệu đó trên tay.',
          TEXT1: '① Định dạng tệp là JPG/PNG và kích thước tệp tối đa là 4MB',
          TEXT2: '② Tải lên các tài liệu và ảnh chụp khuôn mặt của bạn.',
          TEXT3: 'Trong trường hợp người trên ảnh không giống với quý khách hoặc nếu không đáp ứng được các yêu cầu được quy định bởi công ty chúng tôi,',
          TEXT4: 'quý khách có thể không thể tiến hành thủ tục đăng ký được.',
      },
      NOTE2: {
        TEXT: 'Định dạng tệp là JPG/PNG và kích thước tệp tối đa là 4MB.',
        TEXT2: 'Vui lòng sửa lại thông tin sau khi bạn thay đổi loại xác minh danh tính hoặc thông tin liên hệ.'
      },
      BASICINFO: {
          CONTENT: "Vui lòng đảm bảo rằng địa chỉ giao thẻ SIM khớp với tài liệu xác minh danh tính. Nếu nó không khớp với tài liệu xác minh danh tính, vui lòng tải lên các tài liệu hỗ trợ chứng minh địa chỉ của bạn. ※ Trong vòng 6 tháng kể từ ngày cấp.",
          LINE:"----------------------------------------------------------------------------------------------------------------",
          BASIC_INFORMATION: " Vui lòng nhập thông tin người làm hợp đồng",
          TITLE: ' Tên người ký hợp đồng:',
          TITLE1: 'Phải khớp với giấy tờ tùy thân.(Kanji hoặc Romaji)',
          TITLE2: 'Tên khách hàng (full-width Kana):',
          HO: 'Họ',
          TEN: 'Tên',
          HO1: 'Họ',
          TEN1: 'Tên',
          PLACEHOLDER: {
              PLA1: 'nhập vào',
              PLA3: 'Vui lòng nhập số',
              PLA4: 'Nếu bạn có mã giới thiệu, hãy nhập nó.',
          },
          GENDER: "* Giới tính:",
          RADIO_MAN: "Nam",
          RADIO_WOMAN: "Nữ",
          YEAR:"Năm",
          MONTH:"Tháng",
          DAY:"Ngày",
          DATE_OF_BIRTH: "* Ngày sinh: ",
          IDENTITY_VERIFICATION_DOCUMENTS: "* Tài liệu xác minh danh tính: ",
          ID: '* Dãy số ID :',
          SELECT_IDENTITY_VERIFICATION_DOCUMENTS: {
              SELEST: "Vui lòng chọn một tùy chọn",
              OPTION_RESIDENCE_CARD: {
                  name: "Thẻ cư trú",
                  value: ''
              },
              OPTION_PASSPORT: {
                  name: "Hộ chiếu",
                  value: ''
              },
              OPTION_DRIVER_LICENSE: {
                  name: "Bằng lái xe",
                  value: ''
              },
          },
          COUNTRY_OF_CITIZENSHIP: "* Quốc tịch: ",
          CONTENT1: {
              TEXT1: "Vui lòng tải lên ảnh mặt trước của tài liệu xác minh danh tính của bạn.",
              TEXT2: "Vui lòng tải lên ảnh mặt sau của tài liệu xác minh danh tính của bạn.",
              TEXT3: "Vui lòng tải lên một bức ảnh của chính bạn, bao gồm cả khuôn mặt của bạn, với tài liệu xác minh danh tính của bạn đang cầm trên tay.",
          },
          SUPPORTING_DOCUMENTS: "Tài liệu hỗ trợ:",
          SELECT_SUPPORTING_DOCUMENTS: {
              SELECT: "Vui lòng chọn một tùy chọn",
              OPTION_HEALTH_INSURANCE_CARD: {
                  name: "Thẻ bảo hiểm y tế ",
                  value: ''
              },
              OPTION_MY_NUMBER_CARD: {
                  name: "Thẻ số của tôi",
                  value: ''
              },
              OPTION_STUDENT_CARD: {
                  name: "Thẻ sinh viên",
                  value: ''
              },
              OPTION_NOTIFICATION: {
                  name: "Thông báo giấy phép cư trú",
                  value: ''
              },
              OPTION_LANDING_CERTIFICATE: {
                  name: "Giấy chứng nhận nhập cảnh",
                  value: ''
              },
          },
          CONTENT2: {
              TEXT1: "Đăng tải tài liệu bổ sung ※ được phát hành trong 6 tháng gần nhất",
              TEXT2: "Đăng tải tài liệu bổ sung ※ được phát hành trong 6 tháng gần nhất",
          },
      },
      CONTACTINFO: {
          CONTACT_INFORMATION: " Thông tin liên hệ với chủ hợp đồng",
          CONTACT_PHONE_NUMBER: "*Số điện thoại liên lạc trong ngày:",
          POST_CODE: "*Mã bưu chính công ty:",
          ADDRESS: "*Địa chỉ: ",
          TITLE_REQUIRED: "Thông tin  liên lạc khẩn cấp, nếu là người nước ngoài hãy điền thông tin liên hệ của nước sở tại → Nếu không có thông tin sẽ không thụ lý hồ sơ",
          EMERGENCY_CONTACT: "*Liên lạc khẩn cấp:",
          EMERGENCY_CONTACT_INFORMATION: "*Địa chỉ liên hệ khẩn cấp:",
          EMERGENCY_CONTACT_PHONE: "*Số điện thoại liên hệ khẩn cấp :",
          EMERGENCY_RELATION: '* Mối quan hệ :',
          PREFERRED_LANGUAGE: "* Ngôn ngữ mong muốn:",
          SELECT_PREFERRED_LANGUAGE: {
              SELEST: "Vui lòng chọn một tùy chọn",
              CHECKBOX_JAPANESE: {
                  name: "Nhật bản",
                  value: ''
              },
              CHECKBOX_ENGLISH: {
                  name: "Anh",
                  value: ''
              },
              CHECKBOX_CHINESE: {
                  name: "Trung Quốc",
                  value: ''
              },
              CHECKBOX_VIETNAMESE: {
                  name: "Việt Nam",
                  value: ''
              },
              CHECKBOX_KOREAN: {
                  name: "Hàn",
                  value: ''
              },
              CHECKBOX_SRILANKAN: {
                  name: "Sri Lanka",
                  value: ''
              },
              CHECKBOX_NEVALA: {
                  name: "Nepal",
                  value: ''
              },
          },
      },
      WORKSCHOOLINFO: {
          TITLE: "Thông tin về công việc/trường học (không bắt buộc) Bắt buộc cho sinh viên quốc tế",
          NAMEPHONE__NAME: " Nơi làm việc/trường học:",
          NAMEPHONE__NUMBERPHONE: "Nơi làm việc/Số điện thoại trường:",
          ADDRESS: " Nơi làm việc/Địa chỉ trường học:",
      },
      OTHERINFO: {
          TITLE: "Thông tin khác",
          ADVERTISEMENT: "*Bạn biết về IFMOBILE từ đâu?",
          CHECKBOX__ROUNDED: {
              CHECKBOX_REFER_A_FRIEND: {
                  name: "Bạn bè giới thiệu",
                  value: ''
              },
              CHECKBOX_SCHOOL_INTRODUCTION: {
                  name: "Giới thiệu từ trường",
                  value: ''
              },
              CHECKBOX_INTRODUCTION: {
                  name: "Giới thiệu từ đại lý",
                  value: ''
              },
              CHECKBOX_INTRODUCED_FROM: {
                  name: "Giới thiệu từ các trang web khác",
                  value: ''
              },
              CHECKBOX_FACEBOOK: {
                  name: "Quảng cáo từ Facebook",
                  value: ''
              },
              CHECKBOX_YOUTUBE: {
                  name: "Quảng cáo từ Youtube",
                  value: ''
              },
              CHECKBOX_GOOGLE: {
                  name: "Quảng cáo từ Google",
                  value: ''
              },
              CHECKBOX_OTHER_ADVERTISEMENTS: {
                  name: "Quảng cáo từ nơi khác",
                  value: ''
              },
              CHECKBOX_OTHERS: {
                  name: "Khác",
                  value: ''
              },
          },
          USE: {
              TITLE: "Bạn đang sử dụng mạng xã hội nào?",
              CHECKBOX_FACEBOOK: {
                  name: "Facebook",
                  value: ''
              },
              CHECKBOX_LINE: {
                  name: "LINE",
                  value: ''
              },
              CHECKBOX_WECHAT: {
                  name: "WeChat",
                  value: ''
              },
              CHECKBOX_KAKAO: {
                  name: "Kakao Talk",
                  value: ''
              },
              CHECKBOX_SNS: {
                  name: "Vui lòng nhập tên SNS của bạn",
                  value: ''
              },
          },
      },
      REFERRAL_CODE: "Mã giới thiệu:",
      BTN: "Lưu",
      DELETE: 'Xóa'
  },
  
  COMPANY_MANAGER: {
      NOTE: {
          TITLE: 'xác nhận của công ty',
          TEXT1: 'Thông tin cá nhân bạn cung cấp sẽ chỉ được sử dụng để xác minh công ty.',
          TEXT2:  '1、Theo luật pháp Nhật Bản, khi mua thẻ SIM, yêu cầu phải nộp một tài liệu xác nhận công ty có địa chỉ được ghi lại. Điều kiện có thời hạn còn hiệu lực ít nhất 1 tháng.',
          TEXT3: '2、Vui lòng nhập thông tin công ty chính xác. Nếu thông tin đăng tải không đáp ứng các yêu cầu trên, bạn sẽ không thể mua thẻ SIM.',
      },
      BASIC_INFORMATION: {
          TITLE: 'Vui lòng cung cấp thông tin về công ty của bạn.',
          CEO: '* Người đại diện:',
          PDF: '* Giấy phép kinh doanh :',
          TITLE_ADD_IMG: 'Danh thiếp :',
          TEXT_ADD_IMG:  'Vui lòng tải ảnh danh thiếp của bạn',
      },
      CONTACTINFO: {
          TITLE: 'Thông tin liên hệ công ty',
          PHONE1: '* số điện thoại công ty:',
          ZIP_CODE: '* Mã bưu chính công ty:',
          ADDRESS: '* Địa chỉ công ty:',
          NAME: '* Tên người phụ trách:',
          CONTACT:  '* Số điện thoại liên lạc của người phụ trách',
          POSITION: '* Chức vụ:',
      },
      PLACEHOLDER: {
          PLA1: "Đúng với bản sao của giấy đăng ký công ty.",
          PLA2: "Nếu có mã giới thiệu, vui lòng nhập mã của bạn.",
      },
      REFERRAL_CODE: "Mã giới thiệu:",
      LANGUAGE: "Ngôn ngữ ưa thích:",
      BTN: "Lưu",
      UPDATED_CONTACT: {
          TITLE: "Vui lòng sửa lại thông tin sau khi bạn thay đổi loại xác minh danh tính hoặc thông tin liên hệ.",
          PLACEHOLDER: "Vui lòng nhập thông tin liên lạc mới nhất của bạn",
      }
  },
  
  INDEX: {
      LOGO: {
          ANIMATION1: "IF",
          ANIMATION2: "Mobile",
          ANIMATION3: "IF Mobile",
      },
      SLOGAN: "To Serve Your Needs",
      PLAN: {
          CONTENT: "Các gói dịch vụ",
          TITLE: "Hệ thống giá cước giá rẻ!",
          TITLE_TEXT1: "Kết hợp \"SIM thoại\" và \"Wi-Fi\" một cách tự do! Chọn kế hoạch giá cước phù hợp với cách sử dụng của bạn. ",
          TITLE_TEXT2: "Kế hoạch được khuyến nghị cho cuộc sống hàng tháng: SIM thoại 5G + 100G di động Wi-Fi phổ biến!",
          PLAN_BASIC: " Gói cước cơ bản",
          MONTHLY_FEE:"Phí hằng tháng",
          PRICE: "Đơn giá",
          BENEFITS: "Lợi ích",
          SIM: {
              PERMISSION: "Dịch vụ",
          },
          SELL_PHONE: {
            PLAN_BASIC: 'Loại',
            CONTENTS:"Nội dung",
          }
      },
      APPLY: {
          STEP1: {
              NAME: "STEP 1",
          },
          STEP2: {
              NAME: "STEP 2",
          },
          STEP3: {
              NAME: "STEP 3",
          },
      },
      INTRODUCTION: {
          CONTENT: "Thông minh và Đơn giản",
          TITLE: "IF Mobile là gì?",
          TEXT1: "●Không cần thẻ tín dụng, kiểm tra đơn giản, đáp ứng mọi yếu cầu.",
          TEXT2: "●Thương hiệu cung cấp điểm thưởng có thể đổi được (IF P) và nhiều dịch vụ thành viên, cung cấp giá trị vượt quá liên lạc.",
          BTN: "Xem chi tiết",
          INTRODUCTION_CARD: {
              CARD1: {
                  TITLE: "SIM thoại",
                  TEXT1: "Có 7 loại gói dung lượng, đơn giản và dễ hiểu. ",
                  TEXT2: "Bạn có thể tiếp tục sử dụng điện thoại thông minh hiện tại của mình và tận hưởng việc sử dụng dữ liệu không giới hạn.",
                  TEXT3: "Đồng thời còn có chương trình khuyến mãi đặc biệt cho chuyển mạng MNP.",
                  BTN2: "Lấy SIM",
              },
              CARD2: {
                  TITLE: "Wi-Fi",
                  TEXT1: "Không giới hạn tốc độ, hợp đồng vô thời hạn",
                  TEXT2: "Được sử dụng ngay lập tức mà không cần thiết lập hoặc cài đặt.",
                  TEXT3: "Mua hoặc thuê đều được",
                  BTN2: "Nhận Mobile Wi-fi",
              },
              CARD3: {
                  TITLE: "Cáp quang",
                  TEXT1: "Đường truyền tốc độ cao",
                  TEXT2: "Sử dụng không giới hạn dữ liệu (không giới hạn tốc độ và không hạn chế truy cập mạng)",
                  TEXT3: "Chưa đến một tuần để có thể sử dụng",
                  BTN2: "Đường truyền cáp quang",
              },
              CARD4: {
                  TITLE: "Dịch vụ  .  Thiết bị",
                  TEXT1: "Phiếu giảm giá dành riêng cho các cửa hàng hoặc du lịch dành cho thành viên.",
                  TEXT2: "Ăn tại nhà hàng đối tác sẽ tích lũy điểm IF, có thể đổi thành tiền mặt.",
                  TEXT3: "Có thể trả góp bằng cách mua một bộ bao gồm cả SIM và thiết bị (mới/cũ).",
                  BTN2: "Nhận thiết bị",
              },
          },
      },
      QUESTIONS: {
        CONTENT: "Let's talk with us",
        TITLE: "Contact Us",
        ADDRESS: "Our Office",
        ADDRESS_TEXT: "201 Oak Stress Building 27, Manchester, USA",
        CALL_US: "Call Us",
        PHONE: "+1 719-338-4628",
        EMAIL_US: "Email Us",
        EMAIL: "support@envato.com",
        INPUT: {
          NAME: 'Name※',
          EMAIL: 'Email※',
          PHONE: 'Phone※',
          MESS: 'Your Message※'
        }
    },
  },
  
  PAGINATION: {
      NUMBER_RECORD: "Kết quả hiển thị:",
  },
  
  INQUIRY: {
      TITLE: "Liên hệ",
      NOTE: "Nếu quý khách có câu hỏi, vui lòng điền nội dung dưới đây và gửi, chúng tôi sẽ liên hệ đến quý khách trong vòng 2 ngày làm việc",
      EMAIL: "Địa chỉ email:",
      CONTACT: "Loại liên hệ",
      SNS: "Khác",
      SNS_NAME: "Tên SNS",
      SNS_ID: "SNS ID",
      RETURN: "Quay lại",
      SEND: "Gửi",
  },
  
  ORDER_INFO: {
      NOTE: {
          TITLE: "[Chưa hoàn thành (phải được xử lý)]",
          CONTENT1: "Có thể xảy ra lỗi xử lý do thông tin xác minh cá nhân bị thiếu hoặc không khớp. Ngoài ra, còn có khả năng chưa thanh toán.",
          CONTENT2_1: "Trong trường hợp này, sẽ được gởi đến email đã đăng ký khi đăng ký",
          CONTENT2_2: "【Quan trọng】Yêu cầu tải lên lại tài liệu xác minh cá nhân IF Mobile hoặc yêu cầu thanh toán.",
          CONTENT2_3: "Mail đã được gửi đến",
          CONTENT3_1: "Sau khi xác nhận lí  do thiếu hụt",
          CONTENT3_2: " 【Xử lý】",
          CONTENT3_3: " Vui lòng bấm nút chọn và hoàn thành các thủ tục cần thiết",
          CONTENT4_1: "Kiểm tra chi tiết tại trang dưới đây",
          CONTENT4_2: " 'Không hoàn thành xác minh danh tính' ",
      },
      TITLE: "Hồ sơ đăng ký",
      ORDER_NUMBER: "Số đăng ký",
      ORDER_TYPE_TITLE: "Loại đăng ký",
      ORDER_PRICE: "Giá tiền( có thuế) yên",
      ORDER_DAY: "Ngày giờ đăng ký",
      EXPECT_DAY: "Ngày bắt đầu sử dụng",
      PAYMENT_METHOD: "Phương thức thanh toán",
      WAY_RECEIVE: "Phương thức nhận hàng",
      MAILING_AREA: "Khu vực chuyển phát",
      MAILING_NAME: "Tên chuyển phát",
      CONTACT_PHONE: "Số điện thoại chuyển phát",
      ADDRESS: "Địa chỉ chuyển phát cụ thể",
      PROCESSING: "Đến xử lý",
      OREDER_PAYMENT_OPTION: [
        { value: '0', text: 'Thẻ' },
        { value: '1', text: 'Wechat' },
        { value: '2', text: 'Ali' },
        { value: '3', text: 'cửa hàng tiện dụng' },
        { value: '4', text: 'Thanh toán tại cửa hàng' },
        { value: '5', text: 'Chuyển khoản ngân hàng' },
      ],
      ORDER_TYPE: [
            { value: '001', text: 'SIM+Wi-Fi' },
            { value: '002', text: 'SIM+Cáp quang' },
            { value: '003', text: 'SIM' },
            { value: '004', text: 'Wi-Fi' },
            { value: '005', text: 'Cáp quang' },
            { value: '006', text: 'Dịch vụ . Thiết bị' },//tag
            { value: '007', text: 'Thuê Wi-Fi' },
      ],
      ORDER_STATUS: [
            { value: '0', text: 'Đang kiểm tra ( đang thụ lý)' },
            { value: '1', text: 'Chưa hoàn thành (chưa xử lý)' },
            { value: '2', text: 'Đang gửi (Đang chuyển phát)' },
            { value: '3', text: 'Đang tiến hành (đã hoàn thành)' },
        ],
      ORDER_PHONE: {
          NUMBER: "Số dịch vụ",
          CONTENT: "Nội dung đăng ký",
          DATESTART: "Ngày bắt đầu sử dụng",
          NUMBER_OF_USERS: "Số người sử dụng",
          INTENDED_AMOUNT: "Số tiền dự tính",
          ACTUAL_AMOUNT: "Số tiền thực tế",
          UPLOAD_IMAGE: "Hãy đăng tải ảnh hóa đơn",
      }
  },
  
  BAR_MENU: {
      LOG_OUT: "Đăng xuất",
      POINT: "Số điểm tích lũy:",
      MYPAGE:"My IF Mobile",
  },
  
  CONTRACT_DETAIL: {
      TITLE: 'Nội dung hợp đồng',
      NOTE: 'Số SIM',
      CONTRACT_PHONE: "Số điện thoại hợp đồng",
      CONTRACT_STATUS: 'Trạng thái sử dụng',
      CONTRACT_PLAN: 'Gói cước tốc độ cao',
      DATESTART:'Ngày bắt đầu sử dụng',
      BTN_DETAIL: 'Xác nhận nội dung/Thay đổi',
      THIS_MONTH: 'Tháng hiện tại',
      NEXT_MONTH: 'Tháng tiếp theo',
      PLAN_DATA: 'Gói data',
      CALL_QUOTA: 'Giới hạn gọi điện',
      OPTION:  'Dịch vụ lựa chọn',
      //option amountPlan
      TUTORIAL1:  'Thay đổi gói sẽ được thực hiện vào tháng tiếp theo',
      TUTORIAL2:  'Phí dịch vụ thay đổi sẽ phát sinh thêm 220 yên. Có thể thay đổi một lần trong tháng ',
      TUTORIAL3: 'Chọn gói tháng tiếp theo',
      //option converseQuota
      TUTORIAL4:  'Thay đổi gói cước thoại sẽ được thực hiện vào tháng tiếp theo.',
      TUTORIAL5:  'Phí dịch vụ thay đổi 220 yên và cước phí giới hạn gọi điện của tháng hiện tại sẽ phát sinh',
      TUTORIAL6: 'Có thể thay đổi một lần trong tháng',
      //option ortherOption
      TUTORIAL7:  'Thay đổi gói sẽ được thực hiện vào tháng tiếp theo',
      TUTORIAL8: 'Phí dịch vu thay đổi sẽ phát sinh thêm 220 yên. ',
      TUTORIAL9: 'Có thể thay đổi một lần trong tháng',
      //MNP TUTORIAL
      MNPTUTORIAL1: 'Khi thay đổi MNP, nếu hủy hợp đồng trong thời hạn hợp đồng sẽ phát sinh phí hủy hợp đồng ',
      MNPTUTORIAL2: 'Phát sinh phí hủy hợp đồng 11000 yên và phí dịch vụ 3300 yên. ',
      MNPTUTORIAL3: 'Sau khi thủ tục thanh toán hoàn tất, số chuyển đổi MNP có thể được cấp ngay trong ngày.',
      //Cancellation TUTORIAL
      CANCELTUTORIAL1: 'Nếu hủy hợp đồng trong thời hạn hợp đồng sẽ phát sinh phí hủy hợp đồng ',
      CANCELTUTORIAL2: 'Phát sinh phí hủy hợp đồng 11000 yên.',
      CANCELTUTORIAL3: 'Sau khi hoàn tất thanh toán, có thể hủy trong ngày',
      OPEN: "Đang mở",
      CANCEL: "Hủy bỏ",
      MNP:"MNP",
      TEMPORARY_STOP: "Tạm dừng",
      GO_AGAIN: "Phát hành lại",
      ALL:"Tất cả",
      PAYMENT: 'Phí dịch vụ',
      CONFIRM: 'Xác nhận',
      BTN_CANCEL: ' Hủy bỏ',
      PLAN_CHANGE: 'Thay đổi gói',
      CALL_CHANGE:  'Thay đổi gói cước thoại',
      OPTION_CHANGE:  'Thay đổi dịch vụ lựa chọn',
      NOTE2:  'Hợp đồng cáp quang',
      NOTE3:  'Hợp đồng Wi-Fi mobile',
      CONTRACT_LINE_ID: 'Hợp đồng ID',
      CONTRACT_LINE_TYPE:"Loại cáp quang",
      CONTRACT_IMEI: 'Số IMEI',
      CANCEL_CONTRACT: 'Hủy bỏ',
      CANCEL_CONTRACT_TITLE: 'Đăng kí hủy',
      CHANGE_MNP_TITLE: 'Đổi MNP',
      CONTRACT_TYPE: 'Loại hợp đồng',
      EXPLANATORY_TEXT: 'Văn bản thuyết minh',
      EXPLANATORY_PLACEHOLDER: 'Bình luận',
      SELECT_OPTION1: 'Vui lòng chọn số điện thoại hợp đồng',
      SELECT_OPTION2: 'Vui lòng chọn IMEI',
      SELECT_OPTION3: 'Vui lòng chọn ID',
      YEN: 'yen',
      UPDATE_STATUS: {
          TITLE: " Dừng sử dụng・Cấp lại・Kết nối lại (Bị mất・Bị đánh cắp・ Tạm thời về nước, v.v.)",
          OPTION: "Vui lòng chọn thẻ SIM",
          OPTION1: "Cấp lại thẻ SIM mới",
          OPTION2: "Tạm khóa thẻ SIM",
          OPTION3: "Khởi động lại thẻ SIM",
          NOTICE1: "Thanh toán hằng tháng vẫn sẽ tiếp tục ngay cả trong thời gian tạm khóa",
          NOTICE2: "Trong trường hợp cấp lại thẻ, phát sinh chi phí 5,000 yen",
          BUTTON: "Xác nhận",
          UPDATE_FALSE: "Thao tác thất bại.",
      }
  },
  
  CASH: {
      GOLD_EXCHANGE_RELUES: " Quy đinh đổi tiền",
      NOTE: "① Có thể đổi từ 10000 điểm tích lũy",
      NOTE1: "② Có thể đổi bằng cách đến trực tiếp quầy, chuyển khoản (khách hàng  tự chi trả phí dịch vụ), từ mã QR(Paypal, Alipay, Wechatpay)",
      NOTE2: "③ Từ ngày 25 đến cuối tháng, bạn có thể đăng ký nhận tiền mặt từ Trang của tôi và nó sẽ được cấp từ ngày 28 đến cuối tháng tiếp theo.",
      NUMBER_OF_REDEMPTION_POINTS: "Số điểm đổi tiền",
      REDEEMABLE_POINTS: "Điểm có khả năng đổi tiền:",
      REDEMPTION_POINTS: "Điểm đổi tiền:",
      AMOUNTS: "Giá:",
      BANK_NAME: " ※Tên ngân hàng:",
      BRANCH_NAME: " ※Tên chi nhánh:",
      ACCOUNT_HOLDER: " ※Tên chủ tài khoản:",
      YEN: 'yen',
      JAPAN_BANK: "Tài khoản ngân hàng Nhật",
      BRANCH_NUMBER: " ※Số chi nhánh:",
      ACCOUNT_NUMBER: " ※Số tài khoản:",
      TELEPHONE_NUMBER: "※Số điện thoại:",
      BTN_BANK:"Tài khoản ngân hàng Nhật",
      BTN_PAY: "Mã xác địnhPAY",
      BTN_SURE: "Lưu",
      NOTICE: "Trường hợp muốn thay đổi thông tin ngân hàng đã lưu, vui lòng liên hệ đến trung tâm chăm sóc khách hàng"
  },
  
  REFERRAL_CODE: {
      TITLE: "Link giới thiệu ",
      QR: "QR Code",
      CODE: "ifmobile.com?id=2023323de34334234343434343434332324545",
      URL: "URL",
      TEXT: "Nhận ngay 3000 điểm với mỗi hợp đồng giới thiệu",
      TEXT1: "Chỉ cần chia sẻ URL giới thiệu chuyên dụng và kiếm tiền!",
      TEXT2: "Chi tiết",
  },
  
  FEE_INFO: {
      PDF: 'Tải chi tiết sử dụng　PDF',
      TITLE: 'Phí sử dụng',
      MONTH_TITLE: "Phí thanh toán",
      SIM: " SIM thoại",
      OPT: "Phí mạng cáp quang",
      WIFI: "Phí Wi-Fi",
      PHONE: "Số điện thoại hợp đồng",
      OTPID: "ID đường truyền:",
      IMEI: "IMEI",
      AMOUNT: "Phí sử dụng",
      PRICE: " yên ",
      PAYMENTFLAG: "Trạng thái thanh toán",
      UNPAID: "Chưa thanh toán",
      PAID: "Đã thanh toán",
      PAYDEADLINE: "Kỳ hạn thanh toán",
      PAY: "Thanh toán",
      DETAIL: "Xem chi tiết hóa đơn sử dụng",
      CREATE_FORM: "Tạo danh sách",
      CALL_DETAILS: "Chi tiết gọi của tháng   ( hai tháng trước )",
      MONTH_BASE: ' Phí điện thoại của tháng   ( hai tháng trước)',
      SMS_BASE: ' Phí SMS của tháng   ( hai tháng trước )',
      DOMESTIC_CALL: "Gọi trong nước: %{number} phút",
      INTERNATIONAL_CALL: "Gọi quốc tế: %{number} phút",
      SMS_DETAILS: "Chi tiết SMS của tháng   ( hai tháng trước )",
      DOMESTIC_SMS: "SMS quốc nội: %{number}",
      INTERNATIONAL_SMS: "SMS quốc tế: %{number}",
      //hiden form info
      FIVE_MINUTE: "Gọi miễn phí trong vòng 5 phút",
      TEN_MINUTE: "Gọi miễn phí trong vòng 10 phút ",
      COMPLETELY_MINUTE:"Không giới hạn thời gian cuộc gọi ",
      NO_MINUTE:"Không cần",
      ABSENCEPHONE: "Trả lời tự động",
      TELEPHONECHARGE: "Phí điện thoại",
      SMSFEE: "Phí SMS	",
      CATCHPHONE: "Cuộc gọi chờ",
      PLAN: "Phí gói sử dụng",
      TEXT1:"Vui lòng đảm bảo hoàn thành thanh toán trước ngày đáo hạn. Nếu chúng tôi không thể xác nhận được thanh toán trong một khoảng thời gian nhất định, chúng tôi sẽ tạm ngừng dịch vụ của bạn. Nếu thanh toán bị chậm trễ do lý do tạm thời trở về quê hương hoặc các lý do khác, vui lòng liên hệ với chúng tôi trước.",
      TEXT1:"Nếu quý khách muốn chi trả phí hàng tháng một cách tự động thông qua tài khoản ngân hàng, thẻ tín dụng hoặc điểm IF, vui lòng liên hệ với trung tâm hỗ trợ khách hàng của chúng tôi. Quý khách cũng có thể kiểm tra trang đăng ký để xác nhận.",
      MESSAGE1:"Hóa đơn tháng này bao gồm dữ liệu tốc độ cao của tháng này và phí cuộc gọi và tin nhắn từ hai tháng trước.",
      MESSAGE2:"Thanh toán phải được thực hiện trước, và hóa đơn tháng này là cho các chi phí của tháng sau.",
    },
  
  DATA_USAGE: {
      MESSAGE:  'Dữ liệu còn lại của tốc độ cao về 0,tốc độ mạng sẽ bị thấp xuống.Vui lòng mua thêm data↓',
      TITLE:  'Lượng sử dụng data tốc độ cao',
      CONTACT_PHONE_NUMBER:  'Số điện thoại hợp đồng',
      DATA_PACKAGES: ' Gói cước tốc độ cao',
      UPDATE_DATE: 'Ngày giờ thay đổi:',
      DATA_USAGE_THIS_MONTH: 'Tổng data của tháng này',
      TOTAL:  'Lượng dữ liệu còn lại của tháng này ',
      TO_USE: 'Lượng dữ liệu đã sử dụng',
      EXTANDT: 'Lượng dữ liệu còn lại ',
      BTN:  'Mua thêm dung lượng data tốc độ cao',
      ALL_DATA: " %{data}GB (+phần thêm data)",
      BARCHART_TITLE:"Chi tiết sử dụng của 4 ngày gần nhất,",
      REVENUESUMMARY: "Phí thêm của gói SIM",
      PACKET_CHARGE: {
          TITLE: '',
          GUIDE:  'Sau khi chọn lượng data, nhấn nút [Xác nhận]. Phí thêm ứng với 1GB là 550 yên. Giới hạn sử dụng: đến cuối tháng này',
          SIM_NUMBER:  'Số SIM:',
          FEE_AMOUNT: "Thêm data",
          AMOUNT_OF_MONEY: 'Số tiền（đã bao gồm thuế)',
          CONFIRM: 'Mua hàng',
          CANCEL: ' Hủy bỏ',
          NOTICE:'Sau khi chọn dung lượng, vui lòng nhấn vào nút [Mua hàng]. Ngay sau khi mua hàng hoàn tất, data sẽ được thêm vào và tốc độ sẽ nhanh trở lại',
      },
  },
  
  RECUR_PAYMENT: {
      BTN_SUBMIT: 'Xác nhận',
      BTN1: 'Đăng ký thông tin thẻ tín dụng',
      BTN2: 'Thay đổi thẻ định kỳ',
      TITLE: 'Thanh toán định kỳ',
      TITLE2: 'Chi trả định kỳ',
      MESSAGE1:"●Thẻ tín dụng",
      MESSAGE2:"Vui lòng đăng ký một thẻ tín dụng được sử dụng cho các khoản thanh toán hàng tháng.",
      MESSAGE3:"Thông tin như số thẻ tín dụng sẽ được đăng ký với dịch vụ thanh toán được cung cấp bởi Univapay và được quản lý an toàn.",
      MESSAGE4:"Lịch trình Áp dụng Thay đổi",
      MESSAGE5:"・Những thay đổi được thực hiện từ ngày 1 đến ngày 3 hàng tháng sẽ được áp dụng cho khoản thanh toán của tháng hiện tại.",
      MESSAGE6:"・Những thay đổi được thực hiện từ ngày 4 đến cuối tháng sẽ được áp dụng cho khoản thanh toán của tháng tiếp theo.",
      MESSAGE7:"●Chuyển khoản tự động",
      MESSAGE8_1:"Chuyển khoản tự động ",
      MESSAGE8_2:" Đang hạ cánh.",
      MESSAGE9:"Nếu bạn muốn sử dụng chuyển khoản tự động để thanh toán hàng tháng, vui lòng chọn chuyển khoản ngân hàng là phương thức thanh toán hàng tháng và tải xuống biểu mẫu yêu cầu dưới đây, điền thông tin và ký tên, sau đó gửi lại cho chúng tôi.",
      MESSAGE10:"Địa chỉ gửi thư: 169-0075 東京都新宿区高田馬場1-28-3工新ビル　504号室　IF Mobile　お客様サポートセンター ",
      MESSAGE11:"※Đăng ký chuyển khoản ngân hàng: có hiệu lực từ tháng sau ít nhất, và có hiệu lực từ tháng thứ hai sau khi đăng ký nếu đăng ký vào cuối tháng.",
      MESSAGE12:"※Thời gian gửi thư: Nếu bạn gửi trước ngày 16 hàng tháng, bạn có thể bắt đầu sử dụng chuyển khoản ngân hàng từ tháng tiếp theo.",
      MESSAGE13:"Nếu gửi sau ngày 16 hàng tháng, việc rút tiền tự động từ tài khoản sẽ có thể được thực hiện từ tháng tiếp theo.",
      MESSAGE14:"※Xin vui lòng nhớ đóng dấu.",
      MESSAGE15:"※Tên trên con dấu phải khớp hoàn toàn với tên đã đăng ký với cơ quan tài chính.",    
      MESSAGE16:"●IF điểm",
      MESSAGE17_1:"IF điểm (Để biết thêm chi tiết,",
      MESSAGE17_2:"vui lòng tham khảo lịch sử điểm IF",
      MESSAGE17_3:"）",
      MESSAGE18:"Nếu bạn muốn sử dụng điểm IF để tự động trừ các khoản phí hàng tháng, vui lòng chọn điểm IF là phương thức thanh toán cho các khoản phí hàng tháng trong tương lai và nạp",
      MESSAGE18_1:"IF điểm",
      MESSAGE18_2:"trong quản lý điểm của trang My Page.",
      MESSAGE19:"Điểm sở hữu",
      MESSAGE20:"Phí điểm 1 điểm = 1 yên",
      MESSAGE21:"Nhập điểm",
      MESSAGE22:"Sạc điện",
      MESSAGE23:"Chỉ hỗ trợ thanh toán đầy đủ bằng điểm, không hỗ trợ thanh toán một phần bằng điểm.",
      MESSAGE24:"Vui lòng lưu ý rằng điểm IF không thể được sử dụng để thanh toán cho các khoản nạp dữ liệu, phí hủy hợp đồng, phí chuyển mạng MNP hoặc bất kỳ phí xử lý nào.",
      SIM: {
          TITLE: 'Thanh toán định kỳ thẻ SIM',
          CONTRACT_PHONE_NUMBER: 'Số điện thoại hợp đồng',
      },
      WIFI: {
          TITLE: 'Thanh toán định kỳ',
          IMEI: 'IMEI',
      },
      HIKARI: {
          TITLE: 'Thanh toán định kỳ đường truyền cáp quang',
          LINE_ID: 'ID đường truyền',
      },
      CARD1: 'Thẻ tín dụng',
      CARD2: 'Chuyển khoản ngân hàng',
  },
  
  MYPAGE_HOME: {
      TITLE: "Thông báo quan trọng",
      NOTICE_CONTENT: "Thủ tục khi rút tiền tự động mỗi tháng từ tài khoản ngân hàng và thẻ tín dụng",
      TEXT_CONTENT1:"Điểm số được tích lũy khi giới thiệu người mới có thể quy đổi thành tiền! Xem chi tiết",
      LINK1: "ở đây",
      TEXT_CONTENT2:"Link giới thiệu ・Mã code : Vui lòng sao chép và tải xuống thông qua trang menu/ quản lý điểm tích lũy trước khi sử dụng.",
      LINK2: " Quản lý điểm tích lũy",
      INVOICE:"Hóa đơn tháng này (tháng %{month}) :",
      LINK3: "Để thanh toán",
      REMAIN_DATA: "Lượng dữ liệu còn lại của tháng này",
      LINK4: "Thêm data",
      MENU_TITLE: "Được sử dụng nhiều",
      MENU_TITLE1:"",
      MENU: [
      {
          name: " Quản lý điểm tích lũy ",
          value: 1,
          url: require("@/assets/historypoint.jpg"),
          icon: "clock-history",
      },
      {
          name: "Quản lí tiền",
          value: 2,
          url: require("@/assets/Cashmanagement.jpg"),
          icon: "cash-coin",
      },
      {
          name: "Thông tin đặt hàng",
          value: 3,
          url: require("@/assets/Orderinformation.jpg"),
          icon: "box-seam",
      },
      {
          name: "Tình trạng sử dụng dữ liệu",
          value: 4,
          url: require("@/assets/Datausage.jpg"),
          icon: "pie-chart",
      },
      {
          name: " Thông tin hợp đồng",
          value: 5,
          url: require("@/assets/Contractinformation.jpg"),
          icon: "receipt-cutoff",
      },
      {
          name: "Thông tin số tiền",
          value: 6,
          url: require("@/assets/Feeinformation.jpg"),
          icon: "list-check",
      },
      {
          name: "Thanh toán định kỳ",
          value: 7,
          url: require("@/assets/recurringbilling.jpg"),
          icon: "calendar2-check",
      },
      {
          name: "Tài khoản và bảo mật",
          value: 10,
          url: require("@/assets/AccountAndSecurity.png"),
          icon: "calendar2-check",
      },
      {
          name: "Đăng xuất",
          value: 99,
          url: require("@/assets/LogOut.png"),
          icon: "calendar2-check",
      }
    ],
  },
  
  POINT_HISTORY: {
      DATETIME: "Ngày giờ sử dụng",
      DIFFERENTIATION: "Khu vực",
      OPERATOR: "Số điểm tích lũy thao tác",
      STATUS: "Trạng thái",
      POINTOPERATE: "Người thao tác",
      DEPICT: "Chi tiết điểm tích lũy",
      TITLE: "Quản lí điểm tích lũy",
      CURRENT_POINT: "Điểm đang có",
      CHARGE_PRICE: "Quy đổi 1 điểm = 1 yen",
      ENTER_YOURSELF: "Nhập điểm tích lũy",
      SUBMIT: "Quy đổi",
      TABLE_TITLE:'Lịch sử điểm tích lũy',
      MESSAGE:"Xem cách tích lũy và sử dụng điểm thưởng",
      NOTICE: {
          TEXT1: "Vui lòng nhập chính xác số điểm",
          TEXT2: "1 điểm = 1 yen",
          TEXT3: "1000 đơn vị",
          TEXT4: "Số điểm quy đổi tối thiểu : 5000, Số điểm quy đổi tối đa : 100000",
          TEXT5: "Điểm tích lũy không thể được hoàn trả vì bất kỳ lý do gì.",
          IMPORTANT1:"*thông tin chi tiết",
          IMPORTANT2: "Vui lòng xác nhận ở",
          HIGHLIGHT1: "Quy tác sử dụng điểm IF",
          HIGHLIGHT2: " Chọn phương thức thanh toán ở đây",
      },
      POINT_TYPE: [
        { value: '0', text: 'Giới thiệu GET' },
        { value: '1', text: 'Phí GET' },
        { value: '2', text: 'Gói giảm giá GET' },
        { value: '3', text: 'Tự mua thêm' },
        { value: '4', text: 'Người quản lý mua thêm' },
        { value: '5', text: 'Phí sử dụng của tháng' },
        { value: '6', text: 'Sử dụng dịch vụ' },
        { value: '7', text: 'Đổi tiền' },
      ],
      POINT_STATUS: [
        { value: '01', text: 'Đang đợi chấp nhận' },
        { value: '02', text: 'Đã chấp nhận' },
        { value: '03', text: 'Không được chấp nhận' },
        { value: '04', text: 'Đã mua thêm' },
        { value: '05', text: 'Đã đổi tiền' },
      ],
      POINT_DETAIL: [
        { value: '0', text: 'Tài khoản ngân hàng Nhật' },
        { value: '1', text: 'Mã xác định PAY' },
      ],
  },
  
  SLIDE: [
      {
          url: require("@/assets/slide1.jpg"),
      },
      {
          url: require("@/assets/slide2.jpg"),
      },
      {
          url: require("@/assets/slide3.jpg"),
      },
    ],
  
  RENT_WIFI : {
      TITLE: "Cho thuê thiết bị Wi-Fi",
      NOTICE: " Vui lòng chọn gói  bạn muốn đăng ký và tiếp tục",
      NECESSARY_TITLE1: " Chọn gói mong muốn",
      NECESSARY_TITLE2: "Tiếp tục",
      NECESSARY_TITLE3: "Ngày bắt đầu thuê",
      OPTION:[
        { "name": "Gói 2 ngày 1 đêm", "price": "1180" },
        { "name": "Gói 3 ngày 1 đêm", "price": "1770" },
        { "name": "Gói 4 ngày 3 đêm", "price": "2360" },
        { "name": "Gói 5 ngày 4 đêm", "price": "2950" },
        { "name": "Gói 6 ngày 5 đêm", "price": "3540" },
        { "name": "Gói 7 ngày 6 đêm", "price": "4130" },
        { "name": "Gói 8 ngày 7 đêm", "price": "4720" },
        { "name": "Gói 9 ngày 8 đêm", "price": "5310" },
        { "name": "Gói 10 ngày 9 đêm", "price": "5900" },
        { "name": "Gói 11 ngày 10 đêm", "price": "6490" },
        { "name": "Gói 12 ngày 11 đêm", "price": "7080" },
        { "name": "Gói 13 ngày 12 đêm", "price": "7670" },
        { "name": "Gói 14 ngày 13 đêm", "price": "8260" },
        { "name": "Gói 1 tháng", "price": "8850", "date": "(Ngày 15-Ngày 31)" },
        { "name": "Gói 2 tháng", "price": "15750", "date": "(Gói 61 ngày 60 đêm)" },
        { "name": "Gói 3 tháng", "price": "22000", "date": "(Gói 91 ngày 90 đêm)" },
        { "name": "Gói 4 tháng", "price": "27600", "date": "(Gói 121 ngày 120 đêm)" },
        { "name": "Gói 5 tháng", "price": "30600", "date": "(Gói 151 ngày 150 đêm)" },
        { "name": "Gói 6 tháng", "price": "33600", "date": "(Gói 181 ngày 180 đêm)" }
    ],
  },
  
  TERMS: {
      SERVICE_CHARGE: "Phí dịch vụ tương thích",
  },
  
  ACCOUNT_SECURITY:{
      TITLE: "Thay đổi  mật khẩu",
      NOTICE:"*Cần thiết phải nhập tất cả hạng mục",
      CURRENT_PASSWORD:"Mật khẩu hiện tại *",
      NEW_PASSWORD: "Mật khẩu mới *",
      RE_ENTER_PASSWORD: "Nhập lại mật khẩu mới * ",
      CONFIRMATION_CODE: "Mã xác nhận",
      SAVE_CHANGES: "Lưu thay đổi",
      MESSAGE1: "Mật khẩu hiện tại không đúng",
      MESSAGE2: "Mật khẩu mới giống với mật khẩu hiện tại",
  },
  
  PLAN:{
    PL1: " đêm",
    PL2: " ngày ",
    PL3: " tháng",
    PL45: "SIM Free iPhone8[64GB]",
    PL46: "[Hàng đã qua sử dụng còn mới]",
    PL47: "Giá chưa thuế 20,000 yên",
    PL48: "(Giá đã bao gồm thuế 20,000 yên)",
    PL49: "SIM free iPhone13[128GB]",
    PL50: "[Hàng mới]",
    PL51: "Giá chưa thuế 98,000 yên",
    PL52: "(Giá đã bao gồm thuế 107,800 yên)",
    PL53: "",
    PL54: "",
    PL55: "",
    PL56: "Nhà hàng, du lịch, dịch vụ hàng ngày",
    PL57: "Giảm giá tối đa 50%",
    PL58: "iPhone / Android (mới, đã qua sử dụng), thiết bị IoT",
    PL59: "Giảm giá thành viên / Trả góp",
    OPTION1: [
      { "price": "20 yên/30 giây", "text": "Phí điện thoại" },
      { "price": "500 yên/GB", "text": "Thêm chuyển đổi" },
      { "price": "3 yên/ 1 tin nhắn", "text": "SMS quốc nội" },
      { "price": "100 yên/ 1 tin nhắn", "text": "SMS quốc tế" },
    ],
    OPTION2: [
      { "text": "Gọi miễn phí trong vòng 5 phút", "price": "500 yên" },
      { "text": "Gọi miễn phí trong vòng 10 phút", "price": "850 yên" },
      { "text": "Trả lời tự động", "price": "350 yên" },
      { "text": "Cuộc gọi chờ", "price": "250 yên" },
    ],
  },
  
  HEADER:{
    HD1: "Đăng ký",
    HD2: "Giá tiền・Gói sử dụng",
    HD3: "MNP",
    HD4: "Thiết lập ban đầu",
    HD5: "Phương pháp thanh toán",
    HD6: "Mua hàng một lần",
    HD7: "Thuê",
    HD8: "Giá tiền",
    HD9: "Chế độ giới thiệu",
    HD10: "Lợi ích",
    HD11: "Dịch vụ đối tác",
    HD12: "Q＆A",
    HD13: "SNS",
    HD14: "日本語",
    HD15: "中国",
    HD16: "English",
    HD17: "Tiếng Việt",
    MESSAGE: "Bạn có muốn đăng xuất không?",
    YES: "Đồng ý",
  },
  
  GENERAL:{
    TEXT1: "Số dịch vụ",
    TEXT2: "Nội dung đăng ký",
    TEXT3: "Ngày bắt đầu sử dụng",
    TEXT4: "Số người sử dụng",
    TEXT5: "Số tiền dự định (tiền tệ của Nhật Bản)",
    TEXT6: "Yên",
    LINK: "Dịch vụ  .  Thiết bị",
  },
  
  };
  