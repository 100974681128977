<template >
  <div class="main_container">
    <header-app class="header"/>
      <router-view class="viewContent"></router-view>
    <footer-app />
  </div>
</template>
<script>
// import Header from "@/components/common/Header";
import Header from "@/components/commonn/Header";
import Footer from "@/components/commonn/Footer";
import { mapActions } from 'vuex';
export default {
  name: "default",
  components: {
    "header-app": Header,
    "footer-app": Footer,
  },
  created(){
    this.init();
  },
  methods: {
    init() {
      this.setLang(this.$localStorage.get("language"));
    },
    ...mapActions(['setLang']),
  }
};
</script>
<style scoped>
.main_container {
  background-color:  rgb(249, 249, 249);
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.viewContent {
  padding-top: 4.17vw;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .viewContent {
  padding-top: 13.87vw;
}
  
}
</style>
