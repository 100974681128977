import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/store";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueConfirmDialog from "vue-confirm-dialog";
import {
  BootstrapVue,
  BIcon,
  BIconArrowUp,
  BIconArrowDown,
} from "bootstrap-vue";
import { BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

import "@/plugins/vant";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/ele-style/theme/index.css";

import locale from "element-ui/lib/locale/lang/ja";

Vue.use(ElementUI, { locale });

import Jump from "@/components/commonn/Jump/index.vue";
import TextMore from "@/components/commonn/TextMore/index.vue";

Vue.component("Jump", Jump);
Vue.component("TextMore", TextMore);

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });

/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */
library.add(fas, far, fab);

Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.component("BIcon", BIcon);
Vue.component("BIconArrowUp", BIconArrowUp);
Vue.component("BIconArrowDown", BIconArrowDown);
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

import Axios from "axios";
import api from "@/utils/axios";
import "@/assets/scss/main.scss";

Vue.prototype.$axios = Axios;

Vue.prototype.$api = api;
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";

Vue.use(Donut);
import ConstPlugin from "@/components/util/const_plugin";

Vue.config.productionTip = false;

// ------------- 多言語対応 --------------
import VueI18n from "vue-i18n";
// import messages from '@/config/languages/message';
import jaMessage from "@/config/languages/ja";
import enMessage from "@/config/languages/en";
import cnMessage from "@/config/languages/cn";
import viMessage from "@/config/languages/vi";
import VueLocalStorage from "vue-localstorage";
const messages = {
  ja: jaMessage,
  en: enMessage,
  cn: cnMessage,
  vi: viMessage,
};

Vue.use(VueLocalStorage);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ja",
  fallbackLocale: "ja",
  messages,
});
Vue.i18n = i18n;
export default i18n;
// ----------------------------------------

// 指令
import "./directive/click-outside.js";
import "./directive/sticky.js";
import "./directive/download.js";

const OFFSET_TOP = 100;
/** 定位跳转 */
Vue.prototype.$scrollToElement = function (elementId) {
  var element = document.getElementById(elementId);
  if (element) {
    var offsetTop = getOffsetTop(element);
    window.scrollTo({
      top: offsetTop - OFFSET_TOP,
      behavior: "smooth",
    });
  }
};

function getOffsetTop(element) {
  var rect = element.getBoundingClientRect();
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
}

const resize = function () {
  store.state.isMobile = window.document.body.clientWidth <= 768;
};
resize();
window.addEventListener("resize", resize);

const app = new Vue({
  el: "#app",
  router,
  i18n,
  store,
  template: "<App/>",
  components: { App },
  VueLocalStorage,
  VueConfirmDialog,
  render: (h) => h(App),
}).$mount("#app");
