<template>
    <section>
        <h1 class="main_title">プライバシーポリシー</h1>
        <b-card class="content__card">
            <p>星和通商株式会社（以下「当社」といいます。）は、適切な業務サービスをご提供していくなかで、お客様に関わる個人情報を正しく扱うことは、重要な責務であると考えております。当社では、この個人情報保護方針に基づき、個人情報保護マネジメントシステムを確立するとともに実行し、事業活動にかかわるすべての従業員が一体となって、個人情報の適切な取り扱いを実現いたします。
            </p>
            <ol>
                <li>
                    <h4 class="content__card__title-text">個人情報の適切な取得、利用、提供に関して</h4>
                </li>
                <p>当社では、事業の内容および規模を考慮し、業務遂行に必要な範囲で個人情報を適切な方法で取得します。個人情報の利用は、特定した利用目的の範囲内とし、利用目的を変更する際には、あらかじめご本人に通知し、同意を得た上でおこないます。個人情報は、利用目的を遂行するために業務を委託する場合を除き、ご本人の同意なく、第三者に提供することはございません。目的外の利用や提供を防止するため、個人情報の利用および提供は、個人情報保護管理者が、その適正性を確認した上でおこなっています。また、自主点検、内部監査等の活動を通じた統制活動も強化しております。
                </p>
                <li>
                    <h4 class="content__card__title-text">個人情報保護のための安全管理策</h4>
                </li>
                <h5 class="content__card__title-content">（１）個人情報の漏えい、滅失、き損等に対する予防処置</h5>
                <p>当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。また、これらを徹底するため、従業員への教育・監督をおこなっています。個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。
                </p>
                <h5 class="content__card__title-content">（２）個人情報の漏えい、滅失、き損等に対する是正処置</h5>
                <p>個人情報の漏えい、滅失、き損等が発生した場合は、被害拡大防止を最優先とし、速やかに是正処置を講じるとともに、原因究明をおこない、再発防止に努めます。また、事故に関する情報について、必要な範囲で関係者、関係機関に報告します。
                </p>
                <li>
                    <h4 class="content__card__title-text">法令、国が定める指針、その他の規範の遵守</h4>
                </li>
                <p>当社は、個人情報の取り扱いに関する法律および関連法令、国が定める指針、所属団体等の規範等を遵守します。なお、GDPRに対応しております。</p>
                <li>
                    <h4 class="content__card__title-text">個人情報保護マネジメントシステムの継続的改善</h4>
                </li>
                <p>当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。</p>
                <li>
                    <h4 class="content__card__title-text">個人情報に関する苦情、相談への対応</h4>
                </li>
                <p>当社に対して、個人情報の取り扱いに関する苦情、相談をおこなう場合は、下記の「個人情報お問い合わせ窓口」までご連絡ください。また、当社の個人情報保護に関するお問い合わせについても下記にて対応いたします。
                </p>
                <div class="content__card__title-background">
                    <p>【個人情報お問い合わせ窓口】<br>
                        TEL：03-6383-4910 <br>
                        Mail： <a href="">info@seiwao.com</a>
                    </p>
                </div>
                <div class="mb-5" style="text-align: end;">
                    <p>セイワツウショウカブシキガイシャ <br>
                        代表取締役　宮沢千秋 <br>
                        2023年7月15日
                    </p>
                </div>
                <p><span style="color: red;">※</span> 本プライバシーポリシーは、法令の変更等に伴い改訂されることがあります。</p>
            </ol>
            <h4 class="mt-5 mb-5" style="color: red;">個人情報に関する公表事項</h4>
            <ol>
                <li>
                    <h4 class="content__card__title-text">個人情報の利用目的</h4>
                </li>
                <p>星和通商株式会社（以下、「当社」という）が取り扱う個人情報の利用目的は、下表の通りです。（<span
                        style="color: red;">※</span>）が付された個人情報については、保有個人データとして、当社に対して利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止のご請求をおこなうことができます。
                </p>
                <br>
                <p>また、当社は、業務を円滑に進めるため、個人情報の取扱の全部または一部を委託する場合があります。この場合、当社は十分な保護水準を満たした者を選定し、当該委託先との間で個人情報の取扱いに関する契約の締結を始め、適切な監督を実施します。
                </p>

                <table class="table mt-5">
                    <tbody>
                        <tr>
                            <th class="content__card__table-title">個人情報の種類</th>
                            <td class="content__card__table-title">利用目的</td>
                        </tr>
                        <tr>
                            <th>
                                （不動産仲介事業）<br>
                                ・顧客情報<span style="color: red;">※</span> <br>
                                ・家賃等支払い状況<span style="color: red;">※</span> <br>
                                ・不動産オーナーの情報<span style="color: red;">※</span>
                            </th>
                            <td>
                                <p>•&emsp;&emsp; 物件情報提供のため
                                    <br>•&emsp;&emsp; 不動産賃貸の仲介のため
                                    <br>•&emsp;&emsp; 入居審査をおこなうため
                                    <br>•&emsp;&emsp; 賃貸物件管理のため
                                    <br>•&emsp;&emsp; 家賃等請求のため
                                    <br>•&emsp;&emsp; 引越し関連業者紹介のため
                                    <br>•&emsp;&emsp; 各種ご案内のため
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                （モバイル事業）<br>
                                ・顧客情報<span style="color: red;">※</span> <br>
                                ・料金支払い状況<span style="color: red;">※</span> <br>
                                ・顧客通話履歴情報<span style="color: red;">※</span>
                            </th>
                            <td>
                                <p>•&emsp;&emsp; サービス提供に係る業務遂行のため
                                    <br>•&emsp;&emsp; 契約変更のため
                                    <br>•&emsp;&emsp; 契約審査のため
                                    <br>•&emsp;&emsp; 課金計算のため
                                    <br>•&emsp;&emsp; 料金請求のため
                                    <br>•&emsp;&emsp; アフターサービスご提供のため
                                    <br>•&emsp;&emsp; 各種ご案内のため
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                （旅行業）<br>
                                ・顧客情報<span style="color: red;">※</span>
                            </th>
                            <td>
                                <p>•&emsp;&emsp; 運送・宿泊棟の提供するサービスの手配及びそれらのサービスの受領のための手続きのため
                                    <br>•&emsp;&emsp; 保険の手続きのため
                                    <br>•&emsp;&emsp; 土産店でのお客様の買い物の便宜に必要なため
                                    <br>•&emsp;&emsp; 各種ご案内のため
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>顧客の家族情報<span style="color: red;">※</span></th>
                            <td>•&emsp;&emsp; 緊急時の連絡のため
                            </td>
                        </tr>
                        <tr>
                            <th>通話音声データ</th>
                            <td>•&emsp;&emsp;電話受付・問い合わせの通話内容を確認するため</td>
                        </tr>
                        <tr>
                            <th>防犯カメラの映像</th>
                            <td>•&emsp;&emsp;防犯上の安全管理のため</td>
                        </tr>
                        <tr>
                            <th>各種お問い合わせ情報<span style="color: red;">※</span></th>
                            <td>
                                <p>
                                    •&emsp;&emsp; 各種お問い合わせ対応のため
                                    <br>•&emsp;&emsp; 各種ご案内のため
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>採用応募者情報<span style="color: red;">※</span></th>
                            <td>•&emsp;&emsp; 弊社が必要とする人材の採用判断のため</td>
                        </tr>
                        <tr>
                            <th>在職者情報<span style="color: red;">※</span></th>
                            <td>•&emsp;&emsp; 人事管理業務および業務の連絡のため</td>
                        </tr>
                        <tr>
                            <th>退職者情報<span style="color: red;">※</span></th>
                            <td>•&emsp;&emsp; 法で定める退職者の人事管理業務および懇親等の必要に応じた連絡のため</td>
                        </tr>
                    </tbody>
                </table>

                <li>
                    <h4 class="content__card__title-text">個人情報の取扱い</h4>
                </li>
                <h5 class="content__card__title-content">（１）個人情報の取得方法</h5>
                <p>ご来店または当社ウェブサイトからの予約、申込みおよび契約、アンケートの記入、緊急連絡先としてのご家族情報の確認によるお客様のご協力を通して、関係する法令等を遵守した適法かつ公正な手段によって、個人情報および個人を特定できない情報（「６．クッキー（cookie）について」参照）を取得いたします。<br>
                    契約の履行等に必要な情報をご提供いただく必要はございますが、それ以外の情報は特に断りがない限り任意でご提供いただけます。
                </p>
                <h5 class="content__card__title-content">（２）個人情報の管理および保護</h5>
                <p>お客様の個人情報および個人を特定できない情報につきましては、Webサイトを通じた取得時に通信を暗号化するなど
                    、情報の紛失、破壊、改変、漏洩等を防止するために十分な安全保護に努めたうえで、個人情報については正確、最新なものとするよう常に必要かつ適切な措置を講じてまいります。なお当社は、保有する個人情報を取得目的および定めた保管期間を超えて保存することはありません。
                </p>

                <li>
                    <h4 class="content__card__title-text">個人情報の利用および第三者への開示・提供</h4>
                </li>
                <p>当社では、お客様の同意がない限り、お客様の個人情報を上記の目的以外に使用いたしません。また、以下の場合を除き、お客様の同意なく、お客様の個人情報を第三者に開示・提供することはありません。</p>
                <ul style="list-style: initial;">
                    <li class="content__card__title-text__ul">
                        サービス提供のため、提携する不動産会社、人材紹介先企業、クレジットカード発行事業者、その他生活サポート事業のため必要な事業組織、旅行業者やホテル事業者、保険業者、航空会社、土産物店に提供する場合
                    </li>
                    <li class="content__card__title-text__ul">法令の規定に基づく場合</li>
                    <li class="content__card__title-text__ul">
                        人の生命、身体又は財産の保護のために必要がある場合であって、お客様の同意を得ることが困難な場合、公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難な場合等法的な要請に基づく場合
                    </li>
                </ul>
                <li>
                    <h4 class="content__card__title-text">保有個人データに関する周知事項</h4>
                </li>
                <p>当社は、保有個人データの取り扱いにあたり、下記の事項を公表いたします。</p>
                <h5 class="content__card__title-content">（１）当社の名称</h5>
                <p>星和通商株式会社SEIWA TRADING CO.,LTD</p>
                <h5 class="content__card__title-content">（２）個人情報保護管理者</h5>
                <p>代表取締役社長　　宮沢 千秋（03-6383-4910）</p>
                <h5 class="content__card__title-content">（３）すべての保有個人データの利用目的</h5>
                <p>個人情報の利用目的」参照。</p>
                <h5 class="content__card__title-content">（４）保有個人データの取り扱いに関するお問い合わせ・苦情等の申し出先</h5>
                <p>
                    申し出先の名称：個人情報保護管理者 <br>
                    住所：東京都新宿区高田馬場１丁目２８−３工新ビル5階 <br>
                    電話：03-6383-4910 <br>
                    FAX ：03-6383-4911
                </p>
                <h5 class="content__card__title-content">（５）当社が属する認定個人情報保護団体の名称および苦情の解決の申し出先</h5>
                <p><span style="color: red;">※</span>当社は認定個人情報保護団体に所属しておりません。</p>
                <h5 class="content__card__title-content">（６）開示等の請求方法</h5>
                <p>個人情報の開示等のご請求に応じる手続き」参照。</p>
                <h5 class="content__card__title-content">（７）苦情申し立ての権利</h5>
                <p>当社に個人情報をご提供いただいたお客様は、いつでも監督当局（お客様の住所地、勤務先またはGDPRがあったとされるEU加盟国の監督当局）に苦情を申し立てる権利があります。その場合、当社としては、監督当局にご連絡頂く前に、苦情への対応の機会をいただきたく、上記の苦情等への申し出先まで事前のご連絡をお願いいたします。
                </p>
                <li>
                    <h4 class="content__card__title-text">個人情報の開示等のご請求に応じる手続き</h4>
                </li>
                <p>当社はお客様ご本人から申し出があった場合、個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去または第三者への提供停止のご請求および個人情報に関する苦情・ご相談については、PMS事務局（電話：03-6383-4910）にて承ります。ただし、ご請求いただいた個人情報につき、法令等の規定により個人情報を開示できない場合や当社の保管期間を過ぎた場合については、お客様の個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去、または第三者への提供停止に応じることができませんのでご了承ください。
                </p>
                <h5>ご送付いただくもの</h5>
                <h5 class="content__card__title-content"><a :href="link1" style="color: #3ca1cd;">（１）「個人情報お問い合せ申請書」</a></h5>
                <p>所定の用紙に以下の事項をご記入ください。</p>
                <ul>
                    <li class="content__card__title-text__ul">ご本人様の住所・氏名・電話番号とご捺印</li>
                    <li class="content__card__title-text__ul">請求日</li>
                    <li class="content__card__title-text__ul">開示をお求めになる個人情報を特定するための情報</li>
                </ul>
                <h5 class="content__card__title-content">（２）ご本人様、代理人の方であることを示す証明書</h5>
                <table class="table mt-5">
                    <tbody>
                        <tr>
                            <th class="content__card__table-title">個人情報の種類</th>
                            <td class="content__card__table-title">利用目的</td>
                        </tr>
                        <tr>
                            <th>
                                •&emsp;&emsp; 運転免許証、パスポート、健康保険の被保険者証、在留カードの写しのいずれか１つ
                            </th>
                            <td>
                                <p>
                                    •&emsp;&emsp; 代理人である事を示す委任状（必須 <br>
                                    •&emsp;&emsp; 委任状に押印された本人の印鑑証明書、代理人の本人確認書類のいずれか1つ
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h5 class="content__card__title-content">（３）手数料：1,000円（利用目的の通知または開示の場合のみ）</h5>
                <div class="content__card__title-background">
                    <p>
                        【ご送付先】<br>
                        住所：〒169-0075　東京都新宿区高田馬場１丁目２８−３工新ビル5階 <br>
                        星和通商株式会社　個人情報保護管理者 <br>
                    </p>
                </div>
                <p><span style="color: red;">※</span> 代理人の方がご請求される場合は、PMS事務局までお問い合わせください。なお、代理人請求ができる方は下記の方に限らせていただきます。
                </p>
                <ul style="list-style: initial;">
                    <li class="content__card__title-text__ul">未成年者または成年被後見人の法定代理人</li>
                    <li class="content__card__title-text__ul">開示等の求めをすることにつき本人が委任した代理人</li>
                </ul>
                <p>当社では、上記の請求を受領した場合は、必要な調査を実施した上で、2週間以内に書面にて回答致します。回答にあたっては、なりすましによる個人情報の漏えいを防止する目的で、本人限定受取郵便を用いますので、ご了承ください。
                </p>
                <li>
                    <h4 class="content__card__title-text">クッキー（cookie）について</h4>
                </li>
                <p>
                    当社ウェブサイトでは、ユーザのプライバシーの保護、および利便性の向上のため
                    、クッキーを使用します。また当社は、クッキーやJavaScript等の技術を利用して、ご提供いただいた情報のうち個人が特定できない属性情報や、サイト内におけるユーザの行動履歴を取得することがあります。ただし、クッキーおよび行動履歴には個人情報は一切含まれません。各ウェブサイトの閲覧またはサービス利用に際し取得に同意いただく必要がございます。お客様による各ウェブサイトの閲覧またはサービス利用を継続されることで同意いただいたとみなします。
                    <br>
                    お客様のご希望に応じて、ご使用のブラウザの設定によりクッキーの受け入れを拒否・無効に設定することも可能です。ただし、その場合当ウェブサイトの一部の機能をご利用いただけなくなります。
                </p>
            </ol>
            <div class="mb-5 mr-5" style="text-align: end;">
                <p>以上</p>
            </div>
        </b-card>
    </section>
</template>

<script>
export default {
  data() {
    return {
      link1 : window.origin+'https://ifmobile.jp/download/Application_Form_For_Personal_Information.pdf',
    }
  },
  mounted() {

  },

  created() {
    this.toTop();
  },
  computed: {},
  methods: {
    toTop: function () {
      window.scrollTo(0,0);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
    width: 80%;
    text-align: left;
    margin: auto;

    .main_title {
        max-width: 420px;
        border: none;
        text-decoration: underline;
        text-underline-offset: 10px;
        margin: auto;
        text-align: center;
    }

    .content__card {
        min-width: 200px;
        background: #fff;
        border-radius: 5px;
        border: 2px solid var(--primary);
        width: 80%;
        margin: 50px auto 50px;

        &__title-content {
            margin: 20px 0;
            border: 3px solid red;
        }

        &__title-text {
            font-weight: bold;

            &__ul {
                font-weight: normal;
                font-size: 18px;
            }
        }

        &__title-background {
            background-color: #d0edfc;
            padding: 25px;
            margin: 30px 0;

            a {
                color: #3ca1cd;
            }
        }

        &__table-title {
            font-size: 20px;
            font-weight: bold;
            background: #f1f1f1;
        }
    }
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border: 1px solid #212529;
    font-size: 18px;
}

.table th {
    width: 30%;
    vertical-align: middle;
}

ol,
ul {
    list-style-type: decimal;
    list-style: auto;
    margin: 35px 25px;
    font-size: 1.5rem;
}

li {
    margin: 25px 0;
    font-weight: bold;
}

p {
    font-size: 18px;
}

@media screen and (max-width: 1000px) {

    section {
        width: 100%;
    }

    .content__card {
        width: 90% !important;
    }
}

@media screen and (max-width: 390px) {
    .content__card {
        width: 100% !important;
    }
}
</style>
