<template>
  <div class="jump" v-show="isScrolled &&  $store.state.isMobile">
    <ul>
      <li 
        v-for="(item, i) in list" 
        :class="{'active': item.active}"
        @click="jump(item)"
      ><span>{{ item.name }}</span></li>
    </ul>
  </div>
</template>

<script>
export default {
  // props: ['list'],
  data(){
    return {
      list: [],
      isScrolled: false,
    }
  },
  created() {
    setTimeout(() => {
      addEventListener('scroll', (e) => {
        this.isScrolled = document.documentElement.scrollTop > 10;
      })
      this.getJumpList()
    }, 500);
  },
  mounted() {},
  destroyed() {
    try {
      removeEventListener('scroll')
    } catch (error) {
      
    }
    
  },
  methods: {
    getJumpList() {
      let jumpElements = document.querySelectorAll('[id^="jump_"]');

      // 遍历每个元素并获取其文本内容
      jumpElements.forEach((element) => {
          let textContent = element.textContent;
          // console.log("Element ID:", element.id, "Text Content:", textContent);
          this.list.push({
            id: element.id,
            name: textContent.length > 6 ? `${textContent.slice(0,6)}...` : textContent,
            active: false,
          })
      });
      console.log(this.list)
      this.list[0].active = true;
    },
    jump(item) {
      this.list.forEach(v => {
        v.active = v.id == item.id
      })
      this.$scrollToElement(item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
.jump {
  position: fixed;
  top: 13.87vw;
  left: 0;
  right: 0;
  // height: 10vw;
  overflow-x: scroll;
  z-index: 3;

  ul {
    display: flex;
    // align-items: flex-center;
    justify-content: flex-start;
    // min-width: 100%;
    margin: 0;
     li {
      color: #fff;
      flex: 1 0 30%;
      max-width: 50%;
      max-height: 80px;
      padding: 10px 0;
      background: rgba(0, 0, 0, 0.5);
      white-space: wrap;
      overflow: hidden;
      font-size: 3.2vw;

      span {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
     }
  }

  .active {
    color: #000;
    background: rgba(255, 255, 255, 0.8);
  }
}
</style>
