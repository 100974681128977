<template>
  <section class="contractdetail">
    <div class="cd-container" v-if="!showPay && showDetail <= -1">
      <div class="title-x">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <h1 class="text_17">
          {{ $t("CONTRACT_DETAIL.TITLE") }}
        </h1>
      </div>
      <div v-show="resultSim == '' || resultSim == null" class="form_card">
        <b-alert show>
          <div class="logo-img-x">
            <img
              src="@/assets/lxl/SketchPng615a6cdc5562241b04387843460ec115269ba0f1a62178c4a07b995f6918b9b2.png"
              alt=""
            />
          </div>
          {{ $t("CONTRACT_DETAIL.NOTE") }}
        </b-alert>
      </div>
      <div class="form_card" v-for="(value, index) in resultSim" :key="index">
        <b-alert show>
          <div class="logo-img-x">
            <img
              src="@/assets/lxl/SketchPng615a6cdc5562241b04387843460ec115269ba0f1a62178c4a07b995f6918b9b2.png"
              alt=""
            />
          </div>
          {{ $t("CONTRACT_DETAIL.NOTE") }} {{ index + 1 }}
          <b-button
            v-show="value.contractStatus === 0 && !$store.state.isMobile"
            class="btn-secondary btn_fix content__card__form-submit__button"
            v-on:click="submitShowDetail(index)"
            size="lg"
            >{{ $t("CONTRACT_DETAIL.BTN_DETAIL") }}</b-button
          >
        </b-alert>
        <div class="content">
          <div class="content__table">
            <table class="table">
              <tbody>
                <tr>
                  <th>{{ $t("CONTRACT_DETAIL.CONTRACT_PHONE") }}</th>
                  <td>{{ value.contractPhoneNum }}</td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_STATUS") }}</td>
                  <td v-if="value.contractStatus == 0">
                    {{ $t("CONTRACT_DETAIL.OPEN") }}
                  </td>
                  <td v-if="value.contractStatus == 1">
                    {{ $t("CONTRACT_DETAIL.CANCEL") }}
                  </td>
                  <td v-if="value.contractStatus == 2">
                    {{ $t("CONTRACT_DETAIL.MNP") }}
                  </td>
                  <td v-if="value.contractStatus == 3">
                    {{ $t("CONTRACT_DETAIL.TEMPORARY_STOP") }}
                  </td>
                  <td v-if="value.contractStatus == 4">
                    {{ $t("CONTRACT_DETAIL.GO_AGAIN") }}
                  </td>
                  <td v-if="value.contractStatus == 9">
                    {{ $t("CONTRACT_DETAIL.ALL") }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_PLAN") }}</td>
                  <td>{{ value.amountPlanName }}</td>
                </tr>
                <tr class="order__item__content__row">
                  <td>{{ $t("ORDER_INFO.EXPECT_DAY") }}</td>
                  <td>{{ value.optStartYmd }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <b-button
          v-show="value.contractStatus === 0 && $store.state.isMobile"
          class="btn-display"
          v-on:click="submitShowDetail(index)"
          size="lg"
          >{{ $t("CONTRACT_DETAIL.BTN_DETAIL") }}</b-button
        >
      </div>
      <div class="form_card">
        <b-alert show>
          <div class="logo-img-x">
            <img
              src="@/assets/lxl/SketchPngc1648100c53587952c29fc4fb1109980ae865fd3a7ce1ee23947477a62218812.png"
              alt=""
            />
          </div>
          {{ $t("CONTRACT_DETAIL.NOTE2") }}
        </b-alert>
        <div v-for="item in resultFiber" :key="item.optLineId" class="content">
          <div class="content__table">
            <table class="table">
              <tbody>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_LINE_ID") }}</td>
                  <td>{{ item.optLineId }}</td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_STATUS") }}</td>
                  <td v-if="item.contractStatus == 0">
                    {{ $t("CONTRACT_DETAIL.OPEN") }}
                  </td>
                  <td v-if="item.contractStatus == 1">
                    {{ $t("CONTRACT_DETAIL.CANCEL") }}
                  </td>
                  <td v-if="item.contractStatus == 2">
                    {{ $t("CONTRACT_DETAIL.MNP") }}
                  </td>
                  <td v-if="item.contractStatus == 3">
                    {{ $t("CONTRACT_DETAIL.TEMPORARY_STOP") }}
                  </td>
                  <td v-if="item.contractStatus == 4">
                    {{ $t("CONTRACT_DETAIL.GO_AGAIN") }}
                  </td>
                  <td v-if="item.contractStatus == 9">
                    {{ $t("CONTRACT_DETAIL.ALL") }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_LINE_TYPE") }}</td>
                  <td
                    v-if="
                      item.optPlanCode ==
                      $t(
                        'SIM_SERVICE.STEP2_CHOOSE_CABLE.IFM_COMMODITY[0].value'
                      )
                    "
                  >
                    {{
                      $t("SIM_SERVICE.STEP2_CHOOSE_CABLE.IFM_COMMODITY[0].text")
                    }}
                  </td>
                  <td v-else>
                    {{
                      $t("SIM_SERVICE.STEP2_CHOOSE_CABLE.IFM_COMMODITY[1].text")
                    }}
                  </td>
                </tr>
                <tr class="order__item__content__row">
                  <td>{{ $t("ORDER_INFO.EXPECT_DAY") }}</td>
                  <td>{{ item.startYmd }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="form_card">
        <b-alert show>
          <div class="logo-img-x">
            <img
              src="@/assets/lxl/SketchPng62766c967f95192a83feb3f0b45a29705a9f418c0309e7dec6f0c812bb93b2d4.png"
              alt=""
            />
          </div>
          {{ $t("CONTRACT_DETAIL.NOTE3") }}
        </b-alert>
        <div v-for="item in resultWifi" :key="item.imei" class="content">
          <div class="content__table">
            <table class="table">
              <tbody>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_IMEI") }}</td>
                  <td>{{ item.imei }}</td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_STATUS") }}</td>
                  <td v-if="item.contractStatus == 0">
                    {{ $t("CONTRACT_DETAIL.OPEN") }}
                  </td>
                  <td v-if="item.contractStatus == 1">
                    {{ $t("CONTRACT_DETAIL.CANCEL") }}
                  </td>
                  <td v-if="item.contractStatus == 2">
                    {{ $t("CONTRACT_DETAIL.MNP") }}
                  </td>
                  <td v-if="item.contractStatus == 3">
                    {{ $t("CONTRACT_DETAIL.TEMPORARY_STOP") }}
                  </td>
                  <td v-if="item.contractStatus == 4">
                    {{ $t("CONTRACT_DETAIL.GO_AGAIN") }}
                  </td>
                  <td v-if="item.contractStatus == 9">
                    {{ $t("CONTRACT_DETAIL.ALL") }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("CONTRACT_DETAIL.CONTRACT_PLAN") }}</td>
                  <td>{{ item.wifiPlanName }}</td>
                </tr>
                <tr class="order__item__content__row">
                  <td>{{ $t("ORDER_INFO.EXPECT_DAY") }}</td>
                  <td>{{ item.startYmd }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <hr /> -->
      <div class="title-x" style="margin-bottom: 10px">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
      </div>
      <div class="form_card">
        <el-collapse>
          <!-- <b-alert show>{{
            $t("CONTRACT_DETAIL.UPDATE_STATUS.TITLE")
          }}</b-alert> -->
          <el-collapse-item name="1">
            <template slot="title">
              {{ $t("CONTRACT_DETAIL.UPDATE_STATUS.TITLE") }}
            </template>
            <div
              v-show="updateStatus.updateFalse"
              class="validation-message content__table"
            >
              <span>{{
                $t("CONTRACT_DETAIL.UPDATE_STATUS.UPDATE_FALSE")
              }}</span>
            </div>

            <el-select
              style="width: 100%"
              v-model="updateStatus.sim"
              :placeholder="$t('CONTRACT_DETAIL.UPDATE_STATUS.OPTION')"
            >
              <el-option
                v-for="(item, index) in resultSim"
                :key="item.value"
                :clearable="true"
                v-show="!(item.contractStatus == 3 || item.contractStatus == 4)"
                :label="item.contractPhoneNum"
                :value="item.contractPhoneNum"
              >
              </el-option>
            </el-select>
            <el-radio-group v-model="updateStatus.operateStatus">
              <el-radio label="4" border>{{
                $t("CONTRACT_DETAIL.UPDATE_STATUS.OPTION1")
              }}</el-radio>
              <el-radio label="2" border>{{
                $t("CONTRACT_DETAIL.UPDATE_STATUS.OPTION2")
              }}</el-radio>
              <el-radio label="3" border>{{
                $t("CONTRACT_DETAIL.UPDATE_STATUS.OPTION3")
              }}</el-radio>
            </el-radio-group>

            <b-button
              @click="update_Status()"
              class="btn-secondary content__card__form-submit__button"
              size="lg"
            >
              {{ $t("CONTRACT_DETAIL.UPDATE_STATUS.BUTTON") }}
            </b-button>
            <el-alert
              type="warning"
              style="text-align: left; background: #fff; margin-top: 20px"
              :closable="false"
            >
              <template>
                <div>{{ $t("CONTRACT_DETAIL.UPDATE_STATUS.NOTICE1") }}</div>
                <div>{{ $t("CONTRACT_DETAIL.UPDATE_STATUS.NOTICE2") }}</div>
              </template>
            </el-alert>
          </el-collapse-item>
          <hr />
          <el-collapse-item name="2">
            <template slot="title">
              {{ $t("CONTRACT_DETAIL.CANCEL_CONTRACT") }}
            </template>
            <div class="content">
              <div class="content__card">
                <div data-v-02c79747="" class="form_title">
                  <div
                    data-v-02c79747=""
                    class="arbitrarily_text"
                    style="width: 100%; text-align: left; font-size: 16px"
                  >
                    {{ $t("CONTRACT_DETAIL.CANCEL_CONTRACT_TITLE") }}
                  </div>
                </div>
                <div style="text-align: left">
                  <p class="content__card__text" style="margin-bottom: 0">
                    {{ $t("CONTRACT_DETAIL.CANCELTUTORIAL1") }}
                  </p>
                  <p class="content__card__text" style="margin-bottom: 0">
                    {{ $t("CONTRACT_DETAIL.CANCELTUTORIAL2") }}
                  </p>
                  <p class="content__card__text">
                    {{ $t("CONTRACT_DETAIL.CANCELTUTORIAL3") }}
                  </p>
                  <div
                    class="p-personal__contactInfo__inner__container__relationship__language sub_form"
                  >
                    <p
                      class="content__card__text"
                      style="
                        text-align: left;
                        font-weight: bold;
                        color: #ff7319;
                      "
                    >
                      {{ $t("CONTRACT_DETAIL.CONTRACT_TYPE") }}
                    </p>
                    <form>
                      <div class="multiselect">
                        <div class="selectBox">
                          <div class="overSelect"></div>
                        </div>
                        <el-select
                          style="width: 100%"
                          v-model="checkValue"
                          @change="ResetMnpSolutionPrice()"
                          :placeholder="
                            $t('CONTRACT_DETAIL.UPDATE_STATUS.OPTION')
                          "
                        >
                          <el-option
                            :value="0"
                            :label="$t('CONTRACT_DETAIL.NOTE')"
                          ></el-option>
                          <el-option
                            :value="2"
                            :label="$t('CONTRACT_DETAIL.NOTE3')"
                          ></el-option>
                          <el-option
                            :value="1"
                            :label="$t('CONTRACT_DETAIL.NOTE2')"
                          ></el-option>
                        </el-select>

                        <el-select
                          v-show="checkValue == 0"
                          style="width: 100%; margin-top: 10px"
                          v-model="submitItem"
                          @change="
                            AddMnpSolutionPrice(
                              resultSim[submitItem].solutionPrice,
                              resultSim[submitItem].id
                            )
                          "
                          :placeholder="$t('CONTRACT_DETAIL.SELECT_OPTION1')"
                        >
                          <el-option
                            v-show="value.contractStatus != 1"
                            v-for="(value, index) in resultSim"
                            :key="index"
                            :value="index"
                            :label="value.contractPhoneNum"
                          >
                          </el-option>
                        </el-select>

                        <el-select
                          v-show="checkValue == 1"
                          style="width: 100%; margin-top: 10px"
                          v-model="submitItem"
                          @change="
                            AddMnpSolutionPrice(
                              resultFiber[submitItem].solutionPrice
                            )
                          "
                          :placeholder="$t('CONTRACT_DETAIL.SELECT_OPTION3')"
                        >
                          <el-option
                            v-show="value.contractStatus != 1"
                            v-for="(value, index) in resultFiber"
                            :key="index"
                            :value="index"
                            :label="value.optLineId"
                          >
                          </el-option>
                        </el-select>

                        <el-select
                          v-show="checkValue == 2"
                          style="width: 100%; margin-top: 10px"
                          v-model="submitItem"
                          @change="AddMnpSolutionPrice(0)"
                          :placeholder="$t('CONTRACT_DETAIL.SELECT_OPTION2')"
                        >
                          <el-option
                            v-show="value.contractStatus != 1"
                            v-for="(value, index) in resultWifi"
                            :key="index"
                            :value="index"
                            :label="value.imei"
                          >
                          </el-option>
                        </el-select>

                        <div v-show="alert" class="validation-message">
                          <span v-show="submitItem == ''">{{
                            $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- <p class="content__card__text" style="text-align: left; font-weight: bold; color: #FF7319;">
                  {{ $t("CONTRACT_DETAIL.EXPLANATORY_TEXT") }}
                </p> -->
                <b-form-textarea
                  style="font-size: 12px"
                  v-model="resultMNP.terminateText"
                  class="content__card__area"
                  :placeholder="$t('CONTRACT_DETAIL.EXPLANATORY_PLACEHOLDER')"
                >
                </b-form-textarea>
                <br />
                <div
                  class="content__card__input-form"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 10px;
                  "
                >
                  <p style="margin: 0">{{ $t("CONTRACT_DETAIL.PAYMENT") }}</p>
                  <span style="color: rgb(255, 115, 25); font-weight: bold">
                    {{ resultMNP.terminatePrice
                    }}{{ $t("CONTRACT_DETAIL.YEN") }}
                  </span>
                </div>
                <hr />
                <div class="content__card__form-submit">
                  <b-button
                    v-on:click="submit(0)"
                    class="btn-secondary content__card__form-submit__button"
                    size="lg"
                    style="
                      background-color: rgb(255, 123, 29);
                      margin-top: 0 !important;
                    "
                  >
                    {{ $t("CONTRACT_DETAIL.CONFIRM") }}
                  </b-button>
                  <!-- <b-button
                    @click="cancelForm = !cancelForm"
                    class="btn-main"
                    size="lg"
                    style="min-height: auto !important; line-height: 1.5"
                  >
                    {{ $t("CONTRACT_DETAIL.BTN_CANCEL") }}
                  </b-button> -->
                </div>
              </div>
            </div>
          </el-collapse-item>
          <hr />
          <el-collapse-item name="3">
            <template slot="title">
              {{ $t("CONTRACT_DETAIL.CHANGE_MNP_TITLE") }}
            </template>
            <div class="content">
              <div class="content__card">
                <div data-v-02c79747="" class="form_title">
                  <div
                    data-v-02c79747=""
                    class="arbitrarily_text"
                    style="width: 100%; text-align: left; font-size: 16px"
                  >
                    {{ $t("CONTRACT_DETAIL.CHANGE_MNP_TITLE") }}
                  </div>
                </div>
                <div style="text-align: left">
                  <p class="content__card__text" style="margin-bottom: 0">
                    {{ $t("CONTRACT_DETAIL.MNPTUTORIAL1") }}
                  </p>
                  <p class="content__card__text" style="margin-bottom: 0">
                    {{ $t("CONTRACT_DETAIL.MNPTUTORIAL2") }}
                  </p>
                  <p class="content__card__text">
                    {{ $t("CONTRACT_DETAIL.MNPTUTORIAL3") }}
                  </p>
                  <div
                    class="p-personal__contactInfo__inner__container__relationship__language sub_form"
                  >
                    <p
                      class="content__card__text"
                      style="
                        text-align: left;
                        font-weight: bold;
                        color: #ff7319;
                      "
                    >
                      {{ $t("CONTRACT_DETAIL.CONTRACT_TYPE") }}
                    </p>
                    <form>
                      <div class="multiselect">
                        <div class="selectBox">
                          <div class="overSelect"></div>
                        </div>

                        <el-select
                          style="width: 100%"
                          v-model="checkValue"
                          @change="ResetMnpSolutionPrice()"
                          :placeholder="
                            $t('CONTRACT_DETAIL.UPDATE_STATUS.OPTION')
                          "
                        >
                          <el-option
                            :value="0"
                            :label="$t('CONTRACT_DETAIL.NOTE')"
                          ></el-option>
                          <el-option
                            :value="1"
                            :label="$t('CONTRACT_DETAIL.NOTE2')"
                          ></el-option>
                        </el-select>

                        <el-select
                          v-show="checkValue == 0"
                          style="width: 100%; margin-top: 10px"
                          v-model="submitItem"
                          @change="
                            AddMnpSolutionPrice(
                              resultSim[submitItem].mnpSolutionPrice
                            )
                          "
                          :placeholder="$t('CONTRACT_DETAIL.SELECT_OPTION1')"
                        >
                          <el-option
                            v-show="value.contractStatus != 2"
                            v-for="(value, index) in resultSim"
                            :key="index"
                            :value="index"
                            :label="value.contractPhoneNum"
                          >
                          </el-option>
                        </el-select>

                        <el-select
                          v-show="checkValue == 1"
                          style="width: 100%; margin-top: 10px"
                          v-model="submitItem"
                          @change="
                            AddMnpSolutionPrice(
                              resultFiber[submitItem].mnpSolutionPrice
                            )
                          "
                          :placeholder="$t('CONTRACT_DETAIL.SELECT_OPTION3')"
                        >
                          <el-option
                            v-show="value.contractStatus != 2"
                            v-for="(value, index) in resultFiber"
                            :key="index"
                            :value="index"
                            :label="value.optLineId"
                          >
                          </el-option>
                        </el-select>

                        <div v-show="alert" class="validation-message">
                          <span v-show="submitItem == ''">{{
                            $t("COMMON.ITEM_TYPE.VALIDATE")
                          }}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- <p class="content__card__text" style="text-align: left; font-weight: bold; color: #FF7319;">
                  {{ $t("CONTRACT_DETAIL.EXPLANATORY_TEXT") }}
                </p> -->
                <b-form-textarea
                  style="font-size: 12px"
                  v-model="resultMNP.terminateText"
                  class="content__card__area"
                  :placeholder="$t('CONTRACT_DETAIL.EXPLANATORY_PLACEHOLDER')"
                >
                </b-form-textarea>
                <br />
                <div
                  class="content__card__input-form"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 10px;
                  "
                >
                  <p style="margin: 0">{{ $t("CONTRACT_DETAIL.PAYMENT") }}</p>
                  <span style="color: rgb(255, 115, 25); font-weight: bold">
                    {{ resultMNP.terminatePrice
                    }}{{ $t("CONTRACT_DETAIL.YEN") }}
                  </span>
                </div>
                <hr />
                <div class="content__card__form-submit">
                  <b-button
                    v-on:click="submit(1)"
                    class="btn-secondary content__card__form-submit__button"
                    size="lg"
                    style="
                      background-color: rgb(255, 123, 29);
                      margin-top: 0 !important;
                    "
                  >
                    {{ $t("CONTRACT_DETAIL.CONFIRM") }}
                  </b-button>
                  <!-- <b-button
                    @click="changeMNP = !changeMNP"
                    class="btn-main"
                    size="lg"
                    style="min-height: auto !important; line-height: 1.5"
                  >
                    {{ $t("CONTRACT_DETAIL.BTN_CANCEL") }}
                  </b-button> -->
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="fix-x" v-if="!showPay && showDetail > -1">
      <div
        style="
          background: #fff;
          padding: 10px;
          border-radius: 8px;
          margin-bottom: 15px;
        "
      >
        <el-page-header
          @back="closeModal()"
          title="戻る"
          content="契約プラン"
        ></el-page-header>
      </div>

      <div class="content__x">
        <!-- <b-icon icon="x-lg" aria-hidden="true" @click="checkShowDetail()"></b-icon> -->
        <div class="fix-h">
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 1 }"
          >
            <div class="row">
              <div class="col md-6">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.PLAN_DATA") }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.THIS_MONTH") }}:
                  {{ resultSim[showDetail].amountPlanName }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.NEXT_MONTH") }}:
                  {{ resultSim[showDetail].nextAmountPlanName }}  
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInput(showDetail, '0')"
                v-model="option_radio"
                value="1"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.PLAN_CHANGE") }}
                &nbsp;<i class="iconfont icon-xiugaioryijian"></i>
              </div>
            </label>
          </div>
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 2 }"
          >
            <div class="row">
              <div class="col md-8">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.CALL_QUOTA") }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.THIS_MONTH") }}:
                  {{ resultSim[showDetail].converseQuotaName }}
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInput(showDetail, '1')"
                v-model="option_radio"
                value="2"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.CALL_CHANGE") }}
                &nbsp;<i class="iconfont icon-xiugaioryijian"></i>
              </div>
            </label>
          </div>
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 3 }"
          >
            <div class="row">
              <div class="col md-4">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.OPTION") }}
                </p>
              </div>
              <div
                class="col-md-4"
                v-if="resultSim[showDetail].ortherOptionNames != null"
              >
                <p
                  v-for="(text, count) in resultSim[
                    showDetail
                  ].ortherOptionNames.split(',')"
                  :key="count"
                  class="content__card__title"   
                >
                {{ $t("CONTRACT_DETAIL.THIS_MONTH") }}:
                  {{ text }}
                </p>
              </div>
              <div
                class="col-md-4"
                v-if="
                  resultSim[showDetail].ortherOptionNames == null ||
                  resultSim[showDetail].ortherOptionNames == ''
                "
              >
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.THIS_MONTH") }}:
                  {{ $t("VALIDATION.REQUIRED") }}
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInput(showDetail, '2')"
                v-model="option_radio"
                value="3"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.OPTION_CHANGE") }}
                &nbsp;<i class="iconfont icon-xiugaioryijian"></i>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div
        v-show="checkInput == showDetail.toString() + '1'"
        class="content__card"
      >
        <div data-v-02c79747="" class="form_title" style="position: relative">
          <div
            data-v-02c79747=""
            class="arbitrarily_text"
            style="width: 100%; text-align: left; font-size: 16px"
          >
            {{ $t("CONTRACT_DETAIL.PLAN_CHANGE") }}
          </div>
        </div>

        <div style="text-align: left">
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL1") }}
          </p>
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL2") }}
          </p>
          <el-alert
            center
            style="margin: 10px 0; font-weight: bold"
            :title="$t('CONTRACT_DETAIL.TUTORIAL3')"
            type="warning"
            :closable="false"
            :show-icon="false"
          >
          </el-alert>
        </div>

        <div class="content__card__choose">
          <label class="radio-layout" v-for="(item, i) in options" :key="i">
            <input
              :disabled="valueForOption1[i].value == checkOp1[showDetail]"
              class="item"
              type="radio"
              @change="changeMethod(showDetail)"
              v-model="resultSim[showDetail].amountPlanCode"
              :value="valueForOption1[i].value"
            />
            <div
              :style="
                valueForOption1[i].value == checkOp1[showDetail]
                  ? 'background: #e3e3e3'
                  : ''
              "
              class="btn-radio-layout"
            >
              {{ valueForOption1[i].text }}&nbsp;&nbsp;&nbsp;{{
                $t("COMMON.ITEM_TYPE.PRICE_MONTH", {
                  price: tax_price(item.price),
                })
              }}
            </div>
          </label>
        </div>
        <div class="content__x_ft">
          <p class="content__card__text" style="text-align: left">
            {{ $t("CONTRACT_DETAIL.PAYMENT") }}
          </p>
          <h2 class="content__card__price">
            {{ tax_price(price) }}&nbsp;{{ $t("CONTRACT_DETAIL.YEN") }}
          </h2>
        </div>
        <div class="content__card__form-submit">
          <b-button
            :disabled="resultSim[showDetail].planFlag == 1 || price == 0"
            @click="
              handelChange(
                resultSim[showDetail].amountPlanCode,
                price,
                changeText,
                resultSim[showDetail].converseQuotaCode,
                resultSim[showDetail].id,
                resultSim[showDetail].otherOptionsCode
              )
            "
            class="btn-secondary content__card__form-submit__button"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.CONFIRM") }}
          </b-button>
          <b-button
            @click="changeInputCopy('-1', '-1')"
            class="btn-main"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.BTN_CANCEL") }}
          </b-button>
        </div>
      </div>
      <div
        v-show="checkInput == showDetail.toString() + '2'"
        class="content__card"
      >
        <div data-v-02c79747="" class="form_title">
          <div
            data-v-02c79747=""
            class="arbitrarily_text"
            style="width: 100%; text-align: left; font-size: 16px"
          >
            {{ $t("CONTRACT_DETAIL.CALL_CHANGE") }}
          </div>
        </div>
        <div style="text-align: left">
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL4") }}
          </p>
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL5") }}
          </p>
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL6") }}
          </p>
          <el-alert
            center
            style="margin: 10px 0; font-weight: bold"
            :title="$t('CONTRACT_DETAIL.TUTORIAL3')"
            type="warning"
            :closable="false"
            :show-icon="false"
          >
          </el-alert>
        </div>
        <div class="content__card__choose">
          <template v-for="(item, index1) in options2">
            <label class="radio-layout" v-if="item.price" :key="index1">
              <input
                :disabled="
                  valueForOption2[index1].value == checkOp2[showDetail]
                "
                v-model="resultSim[showDetail].converseQuotaCode"
                class="item"
                type="radio"
                @change="changeVal(showDetail, item.price)"
                :value="valueForOption2[index1].value"
              />
              <div
                :style="
                  valueForOption2[index1].value == checkOp2[showDetail]
                    ? 'background: #e3e3e3'
                    : ''
                "
                class="btn-radio-layout"
              >
                {{ valueForOption2[index1].text }}&nbsp;&nbsp;&nbsp;{{
                  $t("COMMON.ITEM_TYPE.PRICE_MONTH", {
                    price: tax_price(item.price),
                  })
                }}
              </div>
            </label>
          </template>
          <label class="radio-layout">
            <input
              :disabled="valueForOption2[3].value == checkOp2[showDetail]"
              v-model="resultSim[showDetail].converseQuotaCode"
              class="item"
              type="radio"
              @change="changeVal(showDetail, 0)"
              :value="valueForOption2[3].value"
            />
            <div
              :style="
                valueForOption2[3].value == checkOp2[showDetail]
                  ? 'background: #e3e3e3'
                  : ''
              "
              class="btn-radio-layout"
            >
              {{ valueForOption2[3].text }}
            </div>
          </label>
        </div>
        <div class="content__x_ft">
          <p class="content__card__text">{{ $t("CONTRACT_DETAIL.PAYMENT") }}</p>
          <h2 class="content__card__price">
            {{ tax_price(option2Price) }}&nbsp;{{ $t("CONTRACT_DETAIL.YEN") }}
          </h2>
        </div>
        <div class="content__card__form-submit">
          <b-button
            :disabled="
              resultSim[showDetail].quotaFlag == 1 || option2Price == 0
            "
            @click="
              handelChange(
                resultSim[showDetail].amountPlanCode,
                option2Price,
                changeText,
                resultSim[showDetail].converseQuotaCode,
                resultSim[showDetail].id,
                resultSim[showDetail].otherOptionsCode
              )
            "
            class="btn-secondary content__card__form-submit__button"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.CONFIRM") }}
          </b-button>
          <b-button
            @click="changeInputCopy('-1', '-1')"
            class="btn-main"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.BTN_CANCEL") }}
          </b-button>
        </div>
      </div>
      <div
        v-show="checkInput == showDetail.toString() + '3'"
        class="content__card"
      >
        <div data-v-02c79747="" class="form_title">
          <div
            data-v-02c79747=""
            class="arbitrarily_text"
            style="width: 100%; text-align: left; font-size: 16px"
          >
            {{ $t("CONTRACT_DETAIL.OPTION_CHANGE") }}
          </div>
        </div>
        <div style="text-align: left">
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL4") }}
          </p>
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL5") }}
          </p>
          <p class="content__card__text">
            {{ $t("CONTRACT_DETAIL.TUTORIAL6") }}
          </p>
          <el-alert
            center
            style="margin: 10px 0; font-weight: bold"
            :title="$t('CONTRACT_DETAIL.TUTORIAL3')"
            type="warning"
            :closable="false"
            :show-icon="false"
          >
          </el-alert>
        </div>
        <div class="content__card__choose">
          <label class="radio-layout">
            <input
              @change="
                detailOption3(
                  showDetail,
                  $CONST.BUYER.SIM_OPTION.IFM_COMMODITY.SMART_ANSWER_MACHINE,
                  $event
                )
              "
              v-model="resultSim[showDetail].otherOptionsCode"
              class="item"
              type="checkbox"
              :value="
                $CONST.BUYER.SIM_OPTION.IFM_COMMODITY.SMART_ANSWER_MACHINE
              "
              name="group1"
            />
            <div class="btn-radio-layout">
              {{ $t("SIM_SERVICE.STEP1_PLAN_SELECT.QUESTIONS_OPTION.OPTION1") }}
              &emsp;
              {{
                $t("COMMON.ITEM_TYPE.PRICE_MONTH", {
                  price: tax_price(smartAnsweringPrice),
                })
              }}
            </div>
          </label>
          <label class="radio-layout"
            ><input
              @change="
                detailOption3(
                  showDetail,
                  $CONST.BUYER.SIM_OPTION.IFM_COMMODITY.CALL_WAITING,
                  $event
                )
              "
              class="item"
              v-model="resultSim[showDetail].otherOptionsCode"
              type="checkbox"
              :value="$CONST.BUYER.SIM_OPTION.IFM_COMMODITY.CALL_WAITING"
              name="group1"
            />
            <div class="btn-radio-layout">
              {{ $t("SIM_SERVICE.STEP1_PLAN_SELECT.QUESTIONS_OPTION.OPTION2") }}
              &emsp;
              {{
                $t("COMMON.ITEM_TYPE.PRICE_MONTH", {
                  price: tax_price(catchPhonePrice),
                })
              }}
            </div>
          </label>
        </div>
        <div class="content__x_ft">
          <p class="content__card__text" style="text-align: left">
            {{ $t("CONTRACT_DETAIL.PAYMENT") }}
          </p>
          <h2 class="content__card__price">
            {{ tax_price(option3Price) }}&nbsp;{{ $t("CONTRACT_DETAIL.YEN") }}
          </h2>
        </div>
        <div class="content__card__form-submit">
          <b-button
            :disabled="
              resultSim[showDetail].optionsFlag == 1 || option3Price == 0
            "
            @click="
              handelChange(
                resultSim[showDetail].amountPlanCode,
                option3Price,
                changeText,
                resultSim[showDetail].converseQuotaCode,
                resultSim[showDetail].id,
                resultSim[showDetail].otherOptionsCode
              )
            "
            class="btn-secondary"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.CONFIRM") }}
          </b-button>
          <b-button
            @click="changeInputCopy('-1', '-1')"
            class="btn-main"
            size="lg"
          >
            {{ $t("CONTRACT_DETAIL.BTN_CANCEL") }}
          </b-button>
        </div>
      </div>

      <div v-if="$store.state.isMobile" class="content__x_copy">
        <!-- <b-icon icon="x-lg" aria-hidden="true" @click="checkShowDetail()"></b-icon> -->
        <div class="fix-h">
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 1 }"
          >
            <div class="row">
              <div class="col md-6">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.PLAN_DATA") }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.THIS_MONTH") }}:
                  {{ resultSim[showDetail].amountPlanName }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.NEXT_MONTH") }}:
                  {{ resultSim[showDetail].nextAmountPlanName }}
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInputCopy(showDetail, '0')"
                v-model="option_radio"
                value="1"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.PLAN_CHANGE") }}
              </div>
            </label>
          </div>
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 2 }"
          >
            <div class="row">
              <div class="col md-8">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.CALL_QUOTA") }}
                </p>
              </div>
              <div class="col-md-4">
                <p class="content__card__title">
                  {{ resultSim[showDetail].converseQuotaName }}
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInputCopy(showDetail, '1')"
                v-model="option_radio"
                value="2"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.CALL_CHANGE") }}
              </div>
            </label>
          </div>
          <div
            class="fix-h-item"
            :class="{ 'fix-h-item_active': option_radio == 3 }"
          >
            <div class="row">
              <div class="col md-4">
                <p class="content__card__title">
                  {{ $t("CONTRACT_DETAIL.OPTION") }}
                </p>
              </div>
              <div
                class="col-md-4"
                v-if="resultSim[showDetail].ortherOptionNames != null"
              >
                <p
                  v-for="(text, count) in resultSim[
                    showDetail
                  ].ortherOptionNames.split(',')"
                  :key="count"
                  class="content__card__title"
                >
                  {{ text }}
                </p>
              </div>
              <div
                class="col-md-4"
                v-if="
                  resultSim[showDetail].ortherOptionNames == null ||
                  resultSim[showDetail].ortherOptionNames == ''
                "
              >
                <p class="content__card__title">
                  {{ $t("VALIDATION.REQUIRED") }}
                </p>
              </div>
            </div>
            <label class="radio-layout">
              <input
                type="radio"
                :name="showDetail"
                @change="changeInputCopy(showDetail, '2')"
                v-model="option_radio"
                value="3"
              />
              <div class="btn-radio-layout">
                {{ $t("CONTRACT_DETAIL.OPTION_CHANGE") }}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <Change
      v-if="showPay"
      @return-contract="returnContract"
      :dataChange="dataChange"
    ></Change>
    <Loading :showLoading="isLoading" />
  </section>
</template>
<script>
import Loading from "@/components/common/Loading";
import Change from "../Change/index.vue";

const DEF_OPTION_RADIO = -1;
const DEF_CHECKED_INPUT = "-1";

export default {
  components: {
    Change,
    Loading,
  },
  name: "Contract_detail",
  data() {
    return {
      dataChange: {
        change: {},
        terminate: {},
        type: 0,
        tax: 0,
      },
      showPay: false,
      checkModel: true,
      changePlan: null,
      methods: "",
      changeText: "",
      alert: false,
      option_radio: DEF_OPTION_RADIO,
      checkValue: 0,
      firtVal: [],
      amountVal: [],
      catchVal: [],
      isLoading: false,
      options: "",
      options2: "",
      option2Price: 0,
      option3Price: 0,
      options3: "",
      showDetail: -1,
      changeMNP: false,
      cancelForm: false,
      checkInput: DEF_CHECKED_INPUT,
      price: 0,
      details: {},
      detailsFiber: {},
      simOption: {},
      detailsWifi: {},
      submitItem: "",
      resultSimCurrent: [],
      resultSim: [],
      resultFiber: [],
      orderType: "",
      resultWifi: [],

      resultMNP: {
        contractPhoneNum: "",
        contractType: "",
        terminatePrice: 0,
        terminateText: "",
        terminateType: "",
      },
      taxrate: 0,
      smartAnsweringPrice: 0,
      catchPhonePrice: 0,
      revenueSummary: "",
      id: "",
      updateStatus: {
        alert: false,
        sim: "",
        operateStatus: "",
        updateFalse: false,
      },
      checkOp1: [],
      checkOp2: [],
    };
  },
  mounted() {},
  watch: {
    checkValue: function () {
      this.submitItem = "";
      this.alert = false;
    },
  },
  async created() {
    this.init();
    await this.getDataSim();
    await this.getDataFiber();
    await this.getDataWifi();
    this.valueForOption2 = this.$t(
      "SIM_SERVICE.STEP1_PLAN_SELECT.SIM_CALL_QUOTA"
    );
    this.valueForOption1 = this.$CONST.BUYER.SIM_PLAN.IFM_COMMODITY;
  },
  computed: {},
  methods: {
    async init() {
      this.isLoading = true;
      this.$api
        .post("simPurchase/init", {
          language: window.localStorage.getItem("languageCode"),
        })
        .then((response) => {
          this.options = response.result.amountPlan;
          this.options2 = response.result.converseQuota;
          this.options3 = response.result.ortherOption;
          for (let index = 0; index < this.options3.length; index++) {
            if (this.options3[index].commodityCode == "000016") {
              this.smartAnsweringPrice = this.options3[index].price;
            }
            if (this.options3[index].commodityCode == "000017") {
              this.catchPhonePrice = this.options3[index].price;
            }
          }
          this.taxrate = response.result.taxrate;
          this.dataChange.tax = response.result.taxrate;
          this.isLoading = false;
        });
    },
    submitShowDetail(val) {
      console.log(val);
      this.showDetail = val;
      this.resultSim[val].otherOptionsCode = this.catchVal[val];
      this.resultSim[val].converseQuotaCode = this.firtVal[val];
      this.resultSim[val].amountPlanCode = this.amountVal[val];
      this.option_radio = DEF_OPTION_RADIO;
      this.checkInput = `${val}${DEF_CHECKED_INPUT}`;
      this.toTop();
    },
    ResetMnpSolutionPrice() {
      this.resultMNP.terminatePrice = 0;
    },
    AddMnpSolutionPrice(value, id) {
      this.resultMNP.terminatePrice = value == null ? 0 : value;
      this.id = id;
    },
    handelChange(
      amountPlan,
      price,
      changeText,
      converseQuota,
      id,
      otherOptionsCode
    ) {
      console.log(
        amountPlan,
        price,
        changeText,
        converseQuota,
        id,
        otherOptionsCode
      );
      this.showPay = true;
      this.dataChange.change = {
        amountPlan: amountPlan,
        changeMethod: this.methods,
        changePrice: price,
        changeText: changeText,
        changeType: this.changeType,
        id: id,
        orderType: 0,
        revenueSummary: this.revenueSummary,
        converseQuota: converseQuota,
        ortherOptions: otherOptionsCode,
      };
      this.dataChange.type = 0;
      this.toTop();
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    returnContract() {
      this.showPay = false;
    },
    changeMethod(index) {
      this.methods = "";
      if (this.resultSim[index].amountPlanCode == this.amountVal[index]) {
        this.price = 0;
      } else {
        this.price = 220;
      }
      if (
        Number(this.resultSim[index].amountPlanCode) >
        Number(this.amountVal[index])
      ) {
        this.methods = "2";
      } else if (
        Number(this.resultSim[index].amountPlanCode) <
        Number(this.amountVal[index])
      ) {
        this.methods = "0";
      }
    },
    checkRevenueSummary(val) {
      if (val == 0) {
        if (this.checkValue == 0) {
          this.revenueSummary =
            this.$CONST.PAYMENT.REVENUE_SUMMARY.CANCEL_CONTRACT_SIM;
          this.orderType = 0;
        } else if (this.checkValue == 1) {
          this.revenueSummary =
            this.$CONST.PAYMENT.REVENUE_SUMMARY.CANCEL_CONTRACT_OTP;
          this.orderType = 1;
        } else {
          this.revenueSummary =
            this.$CONST.PAYMENT.REVENUE_SUMMARY.CANCEL_CONTRACT_WIFI;
          this.orderType = 2;
        }
      } else {
        if (this.checkValue == 0) {
          this.revenueSummary =
            this.$CONST.PAYMENT.REVENUE_SUMMARY.CHANGE_MNP_SIM;
          this.orderType = 0;
        } else if (this.checkValue == 1) {
          this.revenueSummary =
            this.$CONST.PAYMENT.REVENUE_SUMMARY.CHANGE_MNP_OTP;
          this.orderType = 1;
        }
      }
    },
    submit(val) {
      this.checkRevenueSummary(val);
      var contract;
      if (this.submitItem !== "") {
        if (this.checkValue == 0) {
          contract = this.resultSim[this.submitItem].contractNo;
        } else if (this.checkValue == 2) {
          contract = this.resultWifi[this.submitItem].contractNo;
        } else {
          contract = this.resultFiber[this.submitItem].contractNo;
        }
        this.dataChange.change = {
          changePrice: this.resultMNP.terminatePrice,
          changeText: this.resultMNP.terminateText,
          changeType: this.changeType,
          id: this.id,
          orderType: this.orderType,
          revenueSummary: this.revenueSummary,
        };
        this.toTop();
        this.dataChange.terminate = {
          contractNo: contract,
          contractPhoneNum:
            this.checkValue == 0
              ? this.resultSim[this.submitItem].contractPhoneNum
              : "",
          contractType: this.checkValue,
          email:
            this.checkValue == 0 ? this.resultSim[this.submitItem].email : "",
          id: this.checkValue == 0 ? this.resultSim[this.submitItem].id : "",
          imei:
            this.checkValue == 2 ? this.resultWifi[this.submitItem].imei : "",
          optLineId:
            this.checkValue == 1
              ? this.resultFiber[this.submitItem].optLineId
              : "",
          terminatePrice: this.resultMNP.terminatePrice,
          terminateText: this.resultMNP.terminateText,
          terminateType: val,
          language: window.localStorage.getItem("languageCode"),
        };
        this.dataChange.type = 1;
        this.alert = false;

        if (this.checkValue == 2) {
          this.$api
            .post("ifmContract/terminate", this.dataChange.terminate)
            .then((response) => {
              if (response.success) {
                this.toTop();
                this.$router.push({
                  path: "/success",
                  query: { view: "9", suc: "5" },
                });
              }
            });
        } else {
          this.showPay = true;
        }
      } else {
        this.alert = true;
      }
    },

    getDataWifi() {
      if (this.$CONST.DEV) {
        this.resultWifi =
          this.$CONST.COMMON.API_CONTRACT_WIFI.Response["result"]["records"];
        console.log("Data-Wifi: ", this.resultWifi);
      } else {
        this.$api
          .get("ifmContract/ifmContractWifiDetailed/list", {
            language: window.localStorage.getItem("languageCode"),
            email: window.localStorage.getItem("email"),
            contractStatus: 9,
          })
          .then((response) => {
            this.resultWifi = response.result.records;
          });
      }
    },

    getDataFiber() {
      if (this.$CONST.DEV) {
        this.resultFiber =
          this.$CONST.COMMON.API_CONTRACT_FIBER.Response["result"]["records"];

        console.log("Data-Fiber: ", this.resultFiber);
      } else {
        this.$api
          .get("ifmContract/ifmContractOptDetailed/list", {
            language: window.localStorage.getItem("languageCode"),
            email: window.localStorage.getItem("email"),
            contractStatus: 9,
          })
          .then((response) => {
            this.resultFiber = response.result.records;
          });
      }
    },

    getDataSim() {
      if (this.$CONST.DEV) {
        this.resultSim =
          this.$CONST.COMMON.API_CONTRACT_SIM.Response["records"];
        for (let index = 0; index < this.resultSim.length; index++) {
          if (
            this.resultSim[index].otherOptionsCode == "" ||
            this.resultSim[index].otherOptionsCode == null
          ) {
            this.resultSim[index].otherOptionsCode = [];
          } else {
            this.resultSim[index].otherOptionsCode =
              this.resultSim[index].otherOptionsCode.split(",");
          }
          this.firtVal[index] = this.resultSim[index].converseQuotaCode;
          this.amountVal[index] = this.resultSim[index].amountPlanCode;
          this.catchVal[index] = this.resultSim[index].otherOptionsCode;
        }
      } else {
        // get current day and month
        var d = new Date();
        const formatDay = d.toISOString().split("T")[0];
        var month = formatDay.slice(0, 7);

        this.$api
          .get("ifmContract/ifmContractSimDetailed/list", {
            language: window.localStorage.getItem("languageCode"),
            email: window.localStorage.getItem("email"),
            contractStatus: 9,
            targetYmd: month, //current day and month
          })
          .then((response) => {
            this.resultSim = response.result.records;
            for (let index = 0; index < this.resultSim.length; index++) {
              this.checkOp1[index] = this.resultSim[index].amountPlanCode;
              this.checkOp2[index] = this.resultSim[index].converseQuotaCode;
              this.resultSim[index].optStartYmd =
                response.result.records[index].optStartYmd.split(" ")[0];
              if (
                this.resultSim[index].otherOptionsCode == "" ||
                this.resultSim[index].otherOptionsCode == null
              ) {
                this.resultSim[index].otherOptionsCode = [];
              } else {
                this.resultSim[index].otherOptionsCode =
                  this.resultSim[index].otherOptionsCode.split(",");
              }
              this.firtVal[index] = this.resultSim[index].converseQuotaCode;
              this.amountVal[index] = this.resultSim[index].amountPlanCode;
              this.catchVal[index] = this.resultSim[index].otherOptionsCode;
            }
          });
      }
    },

    checkShowDetail() {
      this.showDetail = -1;
    },

    submitFormCancel() {
      this.changeMNP = false;
      this.cancelForm = true;
      this.submitItem = "";
      this.checkValue = 0;
      this.clearData();
    },
    submitFormChange() {
      this.cancelForm = false;
      this.changeMNP = true;
      this.submitItem = "";
      this.checkValue = 0;
      this.clearData();
    },

    clearData() {
      this.resultMNP.terminateText = "";
      this.resultMNP.contractType = "";
    },

    changeInputCopy(index, type) {
      document.documentElement.scrollTop = 0;
      if (index == -1) {
        // this.type = -1
        this.checkInput = "0";
        this.option_radio = -1;
        return;
      }
      this.changeInput(index, type);
    },

    changeInput(index, type) {
      this.resetInput();
      this.checkInput = index.toString() + this.option_radio.toString();
      console.log(this.checkInput);
      this.changeType = type;
      if (this.option_radio == 3) {
        this.resultSim[index].otherOptionsCode = this.catchVal[index];
        this.option3Price = 0;
      } else if (this.option_radio == 2) {
        this.resultSim[index].converseQuotaCode = this.firtVal[index];
        this.option2Price = 0;
      } else {
        this.resultSim[index].amountPlanCode = this.amountVal[index];
        this.price = 0;
      }
      if (type == 0) {
        this.revenueSummary =
          this.$CONST.PAYMENT.REVENUE_SUMMARY.CHANGE_DATA_PACKAGE;
      } else if (type == 1) {
        this.revenueSummary =
          this.$CONST.PAYMENT.REVENUE_SUMMARY.CHANGE_CALL_PACKAGE;
      } else {
        this.revenueSummary = this.$CONST.PAYMENT.REVENUE_SUMMARY.CHANGE_OPTION;
      }
    },
    resetInput() {
      this.changeText = "";
    },
    changeVal(index, price) {
      if (this.firtVal[index] == this.resultSim[index].converseQuotaCode) {
        this.option2Price = 0;
      } else if (
        this.firtVal[index] < this.resultSim[index].converseQuotaCode
      ) {
        this.option2Price = 220 + price;
        this.methods = "2";
        if (
          this.resultSim[index].converseQuotaCode ===
          this.$CONST.BUYER.SIM_CALL_QUOTA.IFM_COMMODITY[3].value
        ) {
          this.option2Price = 220;
          this.methods = "1";
        }
      } else {
        if (
          this.firtVal[index] ===
          this.$CONST.BUYER.SIM_CALL_QUOTA.IFM_COMMODITY[3].value
        ) {
          this.methods = "3";
          this.option2Price = 220 + price;
        } else {
          this.option2Price = 220;
          this.methods = "2";
        }
      }
    },
    diff(a1, a2) {
      return a1.concat(a2).filter(function (val, index, arr) {
        return arr.indexOf(val) === arr.lastIndexOf(val);
      });
    },
    detailOption3(index, value, e) {
      if (
        this.resultSim[index].ortherOptionNames?.lenght == 0 ||
        this.resultSim[index].ortherOptionNames == null
      ) {
        this.methods = "3";
      } else if (
        this.resultSim[index].otherOptionsCode.lenght >
        this.resultSim[index].ortherOptionNames.split(",").length
      ) {
        this.methods = "2";
      } else if (this.resultSim[index].otherOptionsCode.lenght == undefined) {
        this.methods = "1";
      } else {
        this.methods = "0";
      }
      if (this.catchVal[index].includes(value)) {
        if (e.target.checked && this.option3Price > 0) {
          if (
            this.diff(
              this.catchVal[index],
              this.resultSim[index].otherOptionsCode
            ).length == 0
          ) {
            this.option3Price = 0;
          }
        } else if (this.option3Price != 220) {
          if (
            this.catchVal[index].length == 2 &&
            this.resultSim[index].otherOptionsCode.length == 2
          ) {
            this.option3Price = 220;
          } else if (
            this.diff(
              this.catchVal[index],
              this.resultSim[index].otherOptionsCode
            ).length != 0 &&
            this.option3Price < 220
          ) {
            this.option3Price += 220;
          }
        }
      } else {
        if (
          value ==
          this.$CONST.BUYER.SIM_OPTION.IFM_COMMODITY.SMART_ANSWER_MACHINE
        ) {
          if (!e.target.checked && this.option3Price != 0) {
            if (
              this.diff(
                this.catchVal[index],
                this.resultSim[index].otherOptionsCode
              ).length == 0
            ) {
              this.option3Price = 0;
            } else {
              this.option3Price -= this.smartAnsweringPrice;
            }
          } else {
            if (this.option3Price < 220) {
              this.option3Price += 220;
            }
            if (
              this.diff(
                this.catchVal[index],
                this.resultSim[index].otherOptionsCode
              ).length != 0
            ) {
              this.option3Price += this.smartAnsweringPrice;
            }
          }
        } else {
          if (!e.target.checked && this.option3Price != 0) {
            if (
              this.diff(
                this.catchVal[index],
                this.resultSim[index].otherOptionsCode
              ).length == 0
            ) {
              this.option3Price = 0;
            } else {
              this.option3Price -= this.catchPhonePrice;
            }
          } else {
            if (this.option3Price < 220) {
              this.option3Price += 220;
            }
            if (
              this.diff(
                this.catchVal[index],
                this.resultSim[index].otherOptionsCode
              ).length != 0
            ) {
              this.option3Price += this.catchPhonePrice;
            }
          }
        }
      }
    },
    update_Status() {
      if (
        this.updateStatus.sim == "" ||
        this.updateStatus.operateStatus == ""
      ) {
        this.updateStatus.alert = true;
      } else {
        this.updateStatus.alert = false;
        this.$api
          .post("ifmContract/simUpdateStatus", {
            id: this.updateStatus.sim.id,
            operateStatus: this.updateStatus.operateStatus,
            simCd: this.updateStatus.sim.contractPhoneNum,
          })
          .then((response) => {
            if (response.success) {
              this.toTop();
              this.$router.push({ path: "/success", query: { view: "9" } });
            } else {
              this.updateStatus.updateFalse = true;
            }
          });
      }
    },
    tax_price: function (value) {
      return parseInt(value + (value * parseFloat(this.taxrate)) / 100);
    },
    closeModal() {
      this.checkInput = DEF_CHECKED_INPUT;
      this.option_radio = DEF_OPTION_RADIO;
      this.checkShowDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-page-header__left {
  background-color: red;
}

.validation-message {
  display: flex;
  margin: 1% 0%;
}
.form-control {
  width: 60%;
  height: 50px;
  margin: 10px auto;
}
section {
  .alert {
    background-color: var(--white);
    width: 100%;
    text-align: left;
    border: none;
    border-bottom: 2px solid var(--primary);
    font-size: 1.75rem;
    color: var(--newColor);
    border-radius: 0;
  }

  .form-submit {
    text-align: left;
    margin-left: 15%;

    &__change {
      text-align: left;
      font-size: 18px;
      margin: 0 3px 5px 3px;
      color: #878f8b;
      text-decoration: underline;
    }
  }
}

.b-icon.bi {
  font-size: 30px;
  margin-top: -10px;
  margin-right: -95%;
  cursor: pointer;
}

.btn-form {
  margin: 0 2% 1% 2%;
  width: 15%;
  min-width: 120px;
}

.btn-secondary {
  margin-right: 8px !important;
  margin-bottom: 5px !important;
  color: #fff;
  background-color: var(--newColor);
  border-color: var(--newColor);
}

.btn-secondary:hover {
  color: #fff;
  background-color: var(--success);
  border-color: var(--success);
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
