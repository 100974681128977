import Vue from 'vue';
import 'vant/lib/index.css';
import { NoticeBar, Button, Tag, RadioGroup, Radio, Cell, CellGroup, Stepper } from 'vant'

Vue.use(Button)
Vue.use(NoticeBar)
Vue.use(Tag);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Stepper);


