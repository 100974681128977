<template>
  <div>
    <div v-if="$store.state.isMobile">
      <div class="box_3 flex-col">
        <div class="image-text_1 flex-row justify-between" @click="goMypage()">
          <img class="single-avatar_1"  src="@/assets/lxl/SketchPng30c428ebeb7bfbb27cf25d285c07d8fd0e2cd415c282aaf8e3c44bf87b0eb94f.png" />
          <span class="text-group_1">{{ username }}</span>
        </div>
          <div class="box_4 flex-row">
            <div class="image-text_2 flex-row justify-between">
              <img class="icon_3"  src="@/assets/lxl/SketchPng11dead8fb4c268fec7f7038493a83252d384d5e544d0621b0a64ebb7458f468f.png" />
              <span class="text-group_2" >{{ $t("BAR_MENU.POINT") }}</span>
            </div>
            <span class="text_3">{{ point }}</span>
            
          </div>
       
        <div class="menu-row">
          <template v-for="(item, index) in categories">
            <div class="image-text_3 flex-col justify-between" @click="showPage(item.value)">
              <img class="icon_4"  :src="item.lanhuimage0 " />
              <span class="text-group_3" :class="{ 'active': item.value == viewsPage }">{{item.name}}</span>
            </div>
          </template>

          <div class="image-text_3 flex-col justify-between" @click="$router.push('/details/com/section6')">
            <img class="icon_4"  src="@/assets/menus/tishi.svg" />
            <span class="text-group_3">サポ-ト</span>
          </div>

          <div class="image-text_3 flex-col justify-between" @click="logOut()">
            <img class="icon_4"  src="@/assets/icon/icon_exit.svg" />
            <span class="text-group_3" >{{ $t("BAR_MENU.LOG_OUT") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="main" :class="{ 'animation': showMenu }">
      <div class="bar">
        <div class="bar__infor" @click="showPage(9)">
          <div class="bar__infor__header">
            <div class="bar__infor__header__img">
              <img src="@/assets/u165.svg" alt="" />
              <p>{{ username }}</p>
            </div>            
            <div class>            
            <div class="bar__infor__header__"></div>
          </div>
        </div>
        <div class="bar__infor__header__point flex-row " @click.stop="showPage(1)">
            <img class="thumbnail_4"  src="@/assets/lxl/SketchPng11dead8fb4c268fec7f7038493a83252d384d5e544d0621b0a64ebb7458f468f.png" />
            <span class="text_24">{{ $t("BAR_MENU.POINT") }}</span>
            <span class="text_25">{{ point }}</span>
        </div>
        <!-- <div class="bar__infor" @click="showPage(9)"></div>
            <a class="thumbnail_4" href="#/mypage?view=9">{{ $t("BAR_MENU.MYPAGE") }}</a>             -->
        </div>

        <template v-for="(item, index) in categories">
          <div class="bar__menu flex-row" @click="showPage(item.value)">
          <img
            class="thumbnail_5"
            :src="item.lanhuimage0"
          />
          <a class="button" :class="{ active: item.value == viewsPage }" >
            {{ item.name }}
          </a>
        </div>
        </template>

        <div class="bar__menu flex-row" @click="$router.push('/details/com/section6')">
          <img
            class="thumbnail_5"
            src="@/assets/menus/tishi.svg"
          />
          <a class="button" >
            サポ-ト
          </a>
        </div>

        <!-- <div class="image-text_3 flex-col justify-between" @click="$router.push('/details/com/section6')">
            <img class="icon_4"  src="@/assets/menus/tishi.svg" />
            <span class="text-group_3">サポ-ト</span>
          </div> -->
        
        <div class="bar__infor__header__logout">
          <b-button variant="success" @click="logOut()">
            <p>{{ $t("BAR_MENU.LOG_OUT") }}</p>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      point: 0,
      activeItem: 0,
      categories: this.$t("MENU.MYPAGE_MENU"),
      categoriesIcons: [],
      showMenu: false,
      loopData0: [
        {
          lanhutext0: '紹介専用紹介専用',
          lanhuimage0: require('@/assets/lxl/SketchPngc0ef33ddec80c269b27ffa7b9c5fef865d09b87723f18eb501ca47355a6ba291.png'),
        },
        {
          lanhuimage0: require('@/assets/lxl/SketchPng262fbaf9065c447b128f15ddeadfc5c00dd11ee1d4dff4d9a54b058e1c1ec686.png'),
          lanhutext0: 'データ利用状況',
        },
        {
          lanhuimage0: require('@/assets/lxl/SketchPngf38bcf191c088a2dfc7e2b5f9c781bbbd85121ac8d4f712ca5b51b54ac084f40.png'),
          lanhutext0: '料金情報',
        },
        {
          lanhuimage0: require('@/assets/lxl/SketchPngd03018963d2665ca3899648ddfe31432503836ced53ab7c662bfbf94af28af01.png'),
          lanhutext0: '契約情報',
        },
        {
          lanhuimage0: require('@/assets/lxl/SketchPngfe213a7f3d7bf29aedfd891ebe0420a666984417468239e4977ea5ecb508beca.png'),
          lanhutext0: 'オーダー情報',
        },
        {
          lanhutext0: 'アカウント詳细情報',
          lanhuimage0: require('@/assets/lxl/SketchPngb13e14680fae4d6e00376b4fcebd0a617cf66de8c20673d5bf72c6946cca690f.png'),
        },
        {
          lanhutext0: '紹介専用紹介専用',
          lanhuimage0: require('@/assets/lxl/SketchPngc0ef33ddec80c269b27ffa7b9c5fef865d09b87723f18eb501ca47355a6ba291.png'),
        },
        {
          lanhutext0: 'ポイント管理',
          lanhuimage0: require('@/assets/lxl/SketchPng26250880601b645069f8ded0c697a9884938f732bddb31ce89f881b4bd8a602a.png'),
        },
        {
          lanhuimage0: require('@/assets/lxl/SketchPngd03b45bec46a8edfb9ccf22eb0ed70697d96d3343dfb7cf2e65ed81e650ca95f.png'),
          lanhutext0: '換金管理',
        },
      
        {
          lanhuimage0: require('@/assets/lxl/SketchPng003cdf5c3bc5ce93cb3e561ca364990b05e2ffa8909a5afa1d997bd33a6ed642.png'),
          lanhutext0: '定期課金',
        },
      ],
    };
  },
  props: ["viewsPage"],
  created () {
    this.getPoint();
  },
  computed: {
    username () {
      if (window.localStorage.getItem('name')) {
        return window.localStorage.getItem('name');
      }
    }
  },
  methods: {
    goMypage () {
      this.$localStorage.set("menuItemHeader", 2);
      this.activeItem = 2;
      this.$router.push({
        path: '/mypage  ',
      });
      location.reload();
    },
    logOut: function () {
      var token = localStorage.getItem('token');
      if (token) {
        if (this.$CONST.DEV) {
          console.log('Debug API: userLogin/login');
          localStorage.removeItem('token');
          localStorage.removeItem('accountBy');
          localStorage.removeItem('name');
          localStorage.removeItem('nameKana');
          localStorage.removeItem('roleId');
          localStorage.removeItem('userId');
          localStorage.removeItem('points');
          localStorage.removeItem('email');
          localStorage.removeItem('userIdCode');
          localStorage.removeItem('registerUrl');
          sessionStorage.setItem("loginComplete", 1);
        }
        else {
          this.$api.post("userLogin/logout")
            .then((response) => {
              localStorage.removeItem('token');
              localStorage.removeItem('accountBy');
              localStorage.removeItem('name');
              localStorage.removeItem('nameKana');
              localStorage.removeItem('roleId');
              localStorage.removeItem('userId');
              localStorage.removeItem('email');
              localStorage.removeItem('userIdCode');
              localStorage.removeItem('registerUrl');
              sessionStorage.removeItem("loginComplete");
              sessionStorage.removeItem("clickToLogin");
              this.$router.push('/login');
            });
        }
      }
    },
    getPoint () {
      if (this.$CONST.DEV) {
        console.log('Debug API: Cash/init');
        this.point = this.$CONST.COMMON.API_CASH.Response.result.userId;
      } else {
        this.$api.get("pointCharge/showPoint").then((response) => {
          this.point = response.result.userPoint;
        });
      }
    },
    showPage: function (menuItem) {
      this.$emit('showPage')
      console.log('menuItem', menuItem)
      this.$localStorage.set("menuItemHeader", menuItem);
      this.toTop();
      // this.$parent.viewsPage = menuItem;
      this.$router.replace(`/mypage?view=${menuItem}`)
      // window.location.reload();
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>