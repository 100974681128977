<template>
  <div class="font_header container p-2">
    <h4 class="font_header__title mt-3 mb-3">
      {{ $t("INQUIRY.TITLE")}}
    </h4>
    <h6>{{ $t("INQUIRY.NOTE")}}</h6>
    <div class="font_boddy card mb-3">
      <div class="row card-body pr-lg-5 pl-lg-5 pb-lg-5">
        <div class="col-12 col-sm-12 col-md-6 pl-lg-3 pr-lg-3 font-weight-bolder">
          <div>
            <span>{{ $t("INQUIRY.NAME")}}</span>
            <b-input-group class="form-input" size="sm">
              <b-form-input class="form-input" placeholder="" type="text" v-model="inquiry.name" :state="nameState"
                trim>
              </b-form-input>
            </b-input-group>
          </div>

          <div class="mt-3">
            <span>{{ $t("INQUIRY.EMAIL")}}</span>
          </div>
          <b-input-group class="form-input" size="sm">
            <b-form-input class="form-input" placeholder="" type="text" v-model="inquiry.email" :state="emailState"
              trim>
            </b-form-input>
          </b-input-group>
          <div class="mt-3">
            <span>{{ $t("INQUIRY.PHONE")}}</span>
          </div>
          <b-input-group class="form-input" size="sm">
            <b-form-input class="form-input" placeholder="" type="text" v-model="inquiry.phone" :state="phoneState"
              trim>
            </b-form-input>
          </b-input-group>

          <div class="mt-3">
            <span>{{ $t("INQUIRY.CONTACT")}}</span>
          </div>
          <div class="choose">
            <label class="radio-layout" v-for="(item, index) in options" :key="index">
              <input  type="radio" name="contact" @click="setContract(item)" :value="item">

              <div class="btn-radio-layout">
                {{item.text}}
                  <b-form-input class="form-input" placeholder="" type="text" v-model="inquiry.contactLink">
                  </b-form-input>
              </div>
            </label>
            <label class="radio-layout">
              <input type="radio" name="contact"  @click="setContract(9)" />
              <div class="btn-radio-layout">
                <span v-show="inquiry.contact != 9">{{$t('INQUIRY.SNS')}}</span>
                  <b-form-input v-show="inquiry.contact == 9" class="form-input"  :placeholder="$t('INQUIRY.SNS')" type="text" v-model="inquiry.contactName">
                  </b-form-input>
                  <b-form-input class="form-input" type="text" v-model="inquiry.contactLink">
                  </b-form-input>
              </div>
            </label>
          </div>
        </div>
        <div class="col-12 col-md-6 pl-lg-3 pr-lg-3 mt-3 font-weight-bolder ">
          <span>{{ $t("INQUIRY.QUESTION_DETAILS")}}</span>
          <b-form-textarea type="text" class="form-input" v-model="inquiry.question_detail" :state="questionState" trim>
          </b-form-textarea>
        </div>
      </div>
    </div>
    <p class="font_header__success"> {{showSuccess}} </p>
    <div class="d-flex justify-content-center font_button">
      <button class="btn-success w-25 mr-2">{{ $t("INQUIRY.RETURN")}}</button>
      <button :disabled="checkError === false" v-on:click="btnSubmit()" class="btn-success w-25 ml-2">{{
      $t("INQUIRY.SEND")}}</button>
    </div>
    <div class="row mt-3 mb-3 font_text">
      <div class="col-md-12">
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      checkError: false,
      showSuccess: '',
      check: false,
      inquiry: {
        name: "",
        email: "",
        phone: "",
        contact: '',
        contactName: '',
        contactLink: '',
        question_detail: "",
      }
    };
  },
  created() {
    this.options = this.$CONST.BUYER.WHICH_SNS;
  },
  computed: {
    emailState() {
      return this.inquiry.email.length >= 1
    },
    phoneState() {
      return this.inquiry.phone.length >= 1
    },
    nameState() {
      return this.inquiry.name.length >= 1
    },
    questionState() {
      return this.inquiry.question_detail.length >= 1
    },
  },
  watch: {
    "inquiry.email": function () {
      this.checkInput();
    },
    "inquiry.phone": function () {
      this.checkInput();
    },
    "inquiry.name": function () {
      this.checkInput();
    },
    "inquiry.question_detail": function () {
      this.checkInput();
    },

    "inquiry.contactLink": function () {
      this.checkInput();
    },
  },
  methods: {
    checkInput: function () {
      if (this.inquiry.email == "" ||
        this.inquiry.phone == "" ||
        this.inquiry.name == "" ||
        this.inquiry.contact == "" ||
        this.inquiry.contactLink == "" ||
        this.inquiry.question_detail == "") {
        this.checkError = false;
      }
      else {
        this.checkError = true;
      };
    },
    setContract: function (value) {
      if (value == 9) {
        this.inquiry.contactName = "";
        this.inquiry.contact = value
      } else {
        this.inquiry.contactName = value.text
        this.inquiry.contact = value.value
      }

      this.inquiry.contactLink = "";
      this.checkInput();
    },
    btnSubmit() {
      var payLoad = {
        'name': this.inquiry.name,
        'email': this.inquiry.email,
        'phone': this.inquiry.phone,
        'contact': this.inquiry.contact,
        'contactName': this.inquiry.contactName,
        'contactLink': this.inquiry.contactLink,
        'question_detail': this.inquiry.question_detail,
      }
      if(this.checkError) {
        if (this.$CONST.DEV) {
          console.log('Debug API: Inquiry/OK');
          console.log(payLoad);
          this.showSuccess = '正常に送信されました';
          setTimeout(this.timeOut, 2000);
        } else {
          this.$api.post("Inquiry/init", payLoad).then((response) => {
          });
        }
      } else {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"), {
            title: `Errors`,
            solid: true,
            variant: `warning`
        })
      }
    },
    timeOut() {
      this.showSuccess = '';
    }
  },
};
</script>



<style lang="scss" scoped>
.small {
  width: 30%;
}

.radio-layout {
  width: calc(80% - 30px);
  text-align: left;
}

.btn-radio-layout {
  font-size: 16px;
  padding: 5px 10px;
  margin-top: 0px;
  text-align: left;
  input {
    margin-top: 5px;
  }
}
.btn-radio-layout input {
    margin-top: 5px;
  }
.radio-layout input:checked+.btn-radio-layout input {
  display: block;
}

.font_header {
  &__title {
    color: #816052;
    font-size: 32px;
    padding: 1.5rem !important;
  }

  &__success {
    color: rgb(20, 180, 20);
    font-weight: bold;
  }
}

.choose {
  padding: 10px 0;
  display: flex;
  row-gap: 5px;
  flex-direction: column;

}

.form-input {
  border-radius: 3px;
  padding: 0;
}

.btn-success {
  padding: 0.375rem 0.75rem;
}

.font_boddy {
  background-color: #fff;
  text-align: left;
  color: #816052;
  border: 2px solid #dd8250;
  border-radius: 20px;

  input {
    background-color: #fff;
    border: 0px solid #3f454b;
    box-shadow: none;
  }

  textarea {
    background-color: #ffffff;
    border: 1px solid #22242626;
    box-shadow: none;
    height: 408px;

  }
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.font-weight-bolder {
  margin-top: 0 !important;
}

.font_text {
  p {
    background-color: #f8f1ee;
    margin-bottom: 0px;
    border-radius: 2px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .font_boddy {
    textarea {
      height: 100px;
    }
  }

  .font-weight-bolder {
    padding-top: 10px !important;
  }
}
</style>
