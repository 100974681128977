<template>
  <div class="main" :class="{ 'animation': showMenu }">
    
    <!-- <div></div> -->
    <div class="bar">
        <div class="bar__menu" v-for="(item, index) in categories" :key="index">
          <a class="button" :class="{ active: item.value == viewsPage }" @click="showPage(item.value)">
            {{ item.name }}
          </a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      point: 0,
      activeItem: 0,
      categories: this.$t("MENU.MYPAGE_MENU"),
      categoriesIcons: [],
      showMenu: false,
    };
  },
  props: ["viewsPage"],
  created () {
    this.getPoint();
  },
  computed: {
    username () {
      if (window.localStorage.getItem('name')) {
        return window.localStorage.getItem('name');
      }
    }
  },
  methods: {
    logOut: function () {
      var token = localStorage.getItem('token');
      if (token) {
        this.$confirm(
          {
            message: this.$t("HEADER.MESSAGE"),
            button: {
              no: this.$t("BUTTON.CANCEL"),
              yes: this.$t("HEADER.YES")
            },
            /**
            * Callback Function
            * @param {Boolean} confirm
            */
            callback: confirm => {
              if (confirm) {
                if (this.$CONST.DEV) {
                  console.log('Debug API: userLogin/logout');
                  localStorage.removeItem('token');
                  localStorage.removeItem('accountBy');
                  localStorage.removeItem('name');
                  localStorage.removeItem('nameKana');
                  localStorage.removeItem('roleId');
                  localStorage.removeItem('userId');
                  localStorage.removeItem('hasUserDetail');
                  localStorage.removeItem('email');
                  localStorage.removeItem('userIdCode');
                  localStorage.removeItem('registerUrl');
                  sessionStorage.setItem("loginComplete", 1);

                  this.$router.push("/login");
                }
                else {
                  this.$api.post("userLogin/logout")
                    .then((response) => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('accountBy');
                      localStorage.removeItem('name');
                      localStorage.removeItem('nameKana');
                      localStorage.removeItem('roleId');
                      localStorage.removeItem('userId');
                      localStorage.removeItem('email');
                      localStorage.removeItem('userIdCode');
                      localStorage.removeItem('registerUrl');
                      sessionStorage.removeItem("loginComplete");
                      sessionStorage.removeItem("clickToLogin");

                      this.$router.push("/login");
                    });
                }
              }
            }
          }
        )
      }
    },
    getPoint () {
      if (this.$CONST.DEV) {
        console.log('Debug API: Cash/init');
        this.point = this.$CONST.COMMON.API_CASH.Response.result.userId;
      } else {
        this.$api.get("pointCharge/showPoint").then((response) => {
          this.point = response.result.userPoint;
        });
      }
    },
    showPage: function (menuItem) {
      console.log(menuItem)
      this.toTop();
      // this.$parent.viewsPage = menuItem;
      this.$emit('showPage')
      console.log('menuItem', menuItem)
      this.$localStorage.set("menuItemHeader", menuItem);
      this.$router.replace(`/mypage?view=${menuItem}`)
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>