import extja from "./extja";

export default {
  ...extja,
  BUTTON: {
    PREVIOUS: "戻る",
    NEXT: "次へ",
    MYPAGE: "マイページ",
    ORDER: "購入",
    LOGIN: "会員ログイン",
    LOGOUT: "ログアウト",
    CANCEL: "キャンセル",
    REGISTER: "会員登録",
    CHOOSE: "お申し込み",
    CONFIRM: " このプランで申し込む",
    PAYMENT_NEXT: " お支払いへ",
  },

  VALIDATION: {
    REQUIRED: "利用なし",
  },

  MENU: {
    SIM_SERVICE: "SIM",
    SIM_ORDER: "SIM",
    WIFI_SERVICE: "Wi-Fi",
    OTP_SERVICE: "光回線",
    SELL_SERVICE: "サービス • 端末",
    TERMINAL: "サービス • 端末",
    LOG_OUT: "ログアウト",
    INVESTIGATION: "お問い合わせ",
    LOGIN: "会員ログイン",
    MYPAGE: "マイページ",
    MYPAGE_MENU: [
      {
        name: "my IFモバイル",
        value: 9,
        url: "",
        lanhuimage0: require("@/assets/menus/if.svg"),
      },
      {
        name: "紹介専用URL　",
        value: 8,
        url: "",
        lanhuimage0: require("@/assets/menus/link.svg"),
      },
      {
        name: "利用状況",
        value: 4,
        url: "",
        lanhuimage0: require("@/assets/menus/tongji.svg"),
      },
      {
        name: "お支払い・ 利用料金",
        value: 6,
        url: "",
        lanhuimage0: require("@/assets/menus/feiyong.svg"),
      },
      {
        name: "契約プラン",
        value: 5,
        url: "",
        lanhuimage0: require("@/assets/menus/qiyue.svg"),
      },
      {
        name: "申込状況",
        value: 3,
        url: "",
        lanhuimage0: require("@/assets/menus/zhuangkuang.svg"),
      },
      {
        name: "会員情報",
        value: 0,
        url: "",
        lanhuimage0: require("@/assets/menus/huiyuan.svg"),
      },
      {
        name: "ポイント管理 ",
        value: 1,
        url: "",
        lanhuimage0: require("@/assets/menus/jifengl.svg"),
      },
      {
        name: "換金管理",
        value: 2,
        url: "",
        lanhuimage0: require("@/assets/menus/huanjin.svg"),
      },
      {
        name: "定期課金",
        value: 7,
        url: "",
        lanhuimage0: require("@/assets/menus/kejin.svg"),
      },
      {
        name: "アカウント",
        value: 10,
        url: "",
        lanhuimage0: require("@/assets/menus/anquan.svg"),
      },
    ],
  },

  COMMON: {
    STRING: {
      IS_BLANK: "",
      IS_NULL: null,
      IS_INVALID: undefined,
      STRIKE_THROUGH: "—",
    },
    ITEM_TYPE: {
      NECESSARY: "必須",
      PRICE_MONTH: "(月%{price}円)",
      PRICE_MONTH_STEP1: "月 %{price}円",
      PRICE_MONTH_STEP1_TAX: "(%{price}円 税込)",
      ONLY_PRICE: "%{price} 円",
      VALIDATE: "必須項目です",
    },
    NAVBAR: {
      STEP1: "音声SIMプラン選択",
      STEP2: "光回線選択",
      STEP3: "モバイルWi-Fi選択",
      STEP35: "レンタルWi-Fi",
      STEP4: "お受け取り方法",
      STEP5: "お申し込み内容確認",
      STEP6: "本人確認",
      STEP7: "規約",
      STEP8: "お支払い",
    },
    SUC: {
      CONTENT1: "処理が完了しました。以下の内容をご確認ください。",
      CONTENT2:
        "□オーダー処理：商品が届くまで、会員専用ページとメールからお知らせをご確認ください。",
      CONTENT3:
        "□サービス予約：会員専用ページの注文状況に完了表示または予約完了メールが届いたら、指定時間で店舗に行ってください。",
      CONTENT4:
        "□月額支払い：支払い不能や返金処理が発生した場合は、お客様サポートにご連絡ください。",
      CONTENT5:
        "□データチャージ：購入完了後、即座にチャージされ、高速モードになります。データ残量は利用状況ページでご確認ください。",
      CONTENT6:
        "□プラン変更：変更完了後、データプランとオプションプランは翌月に適用され、通話定額プランは翌日に適用されます。月に一度のみ変更できます。",
      CONTENT7:
        "□会員情報更新：変更後の書類が承認された時点で、本人から3ヶ月以内に変更・削除できません。",
      CONTENT8:
        "□ポイントチャージ：チャージ完了後、今後のお支払い方法のところでIFポイントを選択してください。",
      CONTENT9:
        "□換金申請：バーコードPAYを利用したい場合は、お客様サポートに連絡してください。",
      CONTENT10:
        "□MNP・解約・一時停止/解除：申請完了後、お客様サポートからご連絡をお待ちください。",
      CONTENT11:
        "□お問い合わせ：お問い合わせ後、お客様サポートからご連絡をお待ちください。",
      ORDER_PAY_BUTTON: "ホームページへ",
      CHANGE_PAY_BUTTON: "マイページへ",
    },
    ERR: {
      CONTENT: [
        "画面遷移中にエラーが発生しました。",
        "一度ログアウトをし、ブラウザを閉じて、しばらく経ってから再度お試しください。",
        "また、エラーが解消されない場合はIfMobileサポートセンターまでお問い合わせください。",
      ],
      REVER_ERR: "ホームページへ戻る",
      CODE_INPUT: "認証コードが不正です",
    },
    PAYMENT: {
      MESSAGE: "初期費用のお支払い方法を選択してください。",
      CHOOSE_PAY_WAY: "初期費用のお支払い方法を選択してください。",
      MESSAGE3:
        "オンライン支払い（クレジットカード支払い、QRコード支払い）とオフライン支払い（ネットバンキング、現金、ATM振込）の2つの方法を提供しています",
      MESSAGE4:
        "クレジットカード支払いを選択する場合は、クレジットカード情報を入力し、【確定】ボタンをクリックして支払いを完了してください",
      MESSAGE5:
        "各種QRコード支払いを選択する場合は、該当する支払い用QRコードを開いてスキャンし、支払いを行った後、【確定】ボタンをクリックして支払いを完了してください",
      MESSAGE6:
        "インターネットバンキングを選択する場合は、選択後にインターネットバンキングに移動し、直接【確定】ボタンをクリックして処理を完了してください。支払い口座情報はログイン用のメールアドレスに送信されます。メールを確認し、ネットバンキングのアプリで支払いを完了させてください",
      MESSAGE7:
        "現金またはATM振込を選択する場合は、選択後、金額と振込先のアカウントを撮影またはスクリーンショットで保存し、【確定】ボタンをクリックしてください。その後、ATMで振込を行い、領収書をカスタマーサービスに送付するか、設定された期限内に日本円現金で店舗に訪れて支払いを手続きしてください",
      MESSAGE8:
        "※将来の電話料金を自動引き落としで支払う場合（クレジットカード引き落とし、銀行カード自動引き落とし、IFポイント自動引き落とし）、下記で選択し、【確定】ボタンをクリックして処理を完了してください",
      ONLINE_PAY: "オンライン決済",
      DEFERRED_PAY: "後払い決済",
      PAYMENT_AMOUNT: "支払金額:",
      CARD_TITLE: "CreditCard",
      CARD_NO: "下4桁",
      TAX: "円（税込）",
      BANKING: "ネット銀行",
      BANKING_MESSAGE: "お支払いに必要な情報を取得",
      MESSAGE1:
        "インターネットバンキングを選択した後、直接【確定】ボタンをクリックして処理を完了してください。支払い口座情報はログイン用のメールアドレスに送信されます。メールを確認し、インターネットバンキングのアプリで支払いを完了させてください",
      MESSAGE2: "ご利用の手順はこちら",

      CREDIT: {
        TITLE: "クレジットカード",
        RECOMMENT: "おすすめ！",
        TEXT: "クレジットカードより毎月のご料金を自動的に引き落とします。登録が必要です。デビットカードも使用できます。日本以外で発行のクレジットカードも利用可能です。但し、国際支払業務（人民元/ドル）の開通が必要です。",
        PLA1: "カード番号",
        PLA2: "有効期限（年)",
        PLA3: "有効期限（月)",
        PLA4: "カード名（名前）",
        PLA5: "カード名",
        PLA6: "セキュリティコード",
      },
      CODE: {
        TITLE: "WeChat Pay、Alipay",
        ALIPAY: "Alipay 国際決済",
        WECHAT: "Wechatpay国際決済",
        MESSAGE: "バーコードをスキャンしてお支払いいただけます。",
      },
      STORE: {
        TITLE: "コンビニ支払い",
        TITLE_1: "(利用不可)",
        TEXT: "コンビニエンスストアで現金でのお支払い。注文してから7日以内にご支払いください。",
        PLA1: "ユーザー名",
        PLA2: "電話番号",
        STORE_TYPE: "便利店タイプ：",
      },
      CASH: {
        TITLE: "現金 or ATM銀行振込",
        TITLE1: "現金",
        TITLE2: "ATM銀行振込",
        TEXT1: "店舗、代理店で対面現金でのお支払いです。",
        TEXT2: "現金は日本円のみとなります。",
        TEXT3:
          "お振込み先情報：三井住友銀行  世田谷支店　普通 　5954038 セイワツウショウカ。",
        TEXT4:
          "ご送金後、必ずお客様サポートセンターまで振込明細をお送ってください（LINE/WECHAT/メールなど）。",
        TEXT5: "支払方法はこちら。",
      },
      CHOOSE_METHOD: {
        TITLE: "今後毎月のご料金のお支払い方法について",
        METHOD1: "クレジットカード　自動的に引き落とし（オススメ）",
        METHOD2: "口座振替　自動的に引き落とし（オススメ）",
        METHOD3: "各種コード決済 WeChat、Alipay、Paypay",
        METHOD4: "コンビニエンスストア",
        METHOD5: "店舗現金 ネット銀行 ATM銀行振込",
        METHOD6: "IFポイント",
      },
      WITHDRAW: {
        TITLE: "口座自動引落について",
        CONTENT1: "初期費用のお支払いはできません",
        CONTENT2:
          "銀行口座より毎月のご料金を自動的に引き落とします。口座自動引落を登録するための用紙と返送用の封筒を、商品と一緒に送付いたしますので、記入の上弊社までご返送ください。",
        PDF: "「XXXXXX.PDF」",
      },
      BUTTON: {
        CONFIRM: "確認",
        PAY: "次へ",
        RETURN: "戻る",
      },
      VALIDATE: {
        INPUT: "支払い方法選択",
        FAIL: "処理が失敗しました",
        CHECK_INPUT: "十分な情報を入力していません",
        MESSAGE: "先に支払してください",
      },
    },
  },

  SIM_SERVICE: {
    STEP1_PLAN_SELECT: {
      TITLE: "音声SIM選択",
      QUESTIONS_PURCHASE_METHOD: {
        TITLE: "お申し込みの前に必要なもの",
        CARD1: {
          TITLE: "支払い方法",
          TEXT1: "各種クレジットカード",
          TEXT2: "口座振替",
          TEXT3: "各種コード決済 WeChat、Alipay",
          TEXT4: "コンビニエンスストア",
          LINK: "支払い方法の詳細",
        },
        CARD2: {
          TITLE1: "本人確認書類",
          TITLE2: "（運転免許証、保険証など）",
          TEXT1:
            "携帯電話不正利用防止法に基づく本人確認のため、ご契約者さまご本人の確認書類が必要になります。",
          TEXT2: "※写真データを登録いただきます。",
          LINK: "本人確認の書類",
        },
        CARD3: {
          TITLE1: "MNP予約番号",
          TITLE2: "（電話番号乗り換えの方のみ）",
          TEXT1:
            "現在お使いの電話番号を、そのままでお使いになる場合、事前にMNP予約番号の取得が必要です。",
          TEXT2: "現在ご利用中の通信会社様にMNP,",
          TEXT3: "",
          LINK: "詳しくはこちら",
        },
        CARD4: {
          TITLE: "メールアドレス",
          TEXT1:
            "会員登録用とご連絡を受信できるメールアドレスをご用意ください。",
          TEXT2: "",
          TEXT3: "",
        },
      },
      QUESTIONS_SELECT_TEL_NUMBER: {
        NEW_AGREEMENT: "新規契約",
        ANOTHER_COMPANY: {
          TITLE: "MNP",
          TEL_PLACE_HOLDER: "現在の携帯電話の電話番号をご入力ください。",
          MNP_PLACE_HOLDER: "MNP予約番号",
          DATE_PLACE_HOLDER: "MNP予約番号の有効期限 ",
          SIM_PLACE_HOLDER: "電話番号がわかる方のみご入力ください",
          TITLE_BANK: "現在携帯電話会社を選んでください",
          BANKS: [
            { value: 0, text: "DOCOMO" },
            { value: 1, text: "SOFTBANK" },
            { value: 2, text: "AU" },
            { value: 3, text: "UQ" },
            { value: 4, text: "Y!M" },
            { value: 9, text: "その他" },
          ],
        },
      },
      QUESTIONS_SET: {
        TITLE: "SIMだけ or セットで購入",
        SIM: "SIMのみ",
        OTP: "光回線とSIMをセットで",
        WIFI: "モバイルWi-FiとSIMをセットで",
      },
      QUESTIONS_RATE_PLAN: {
        TITLE: "高速データプラン　契約後変更可能",
      },
      QUESTIONS_FLAT_RATE_CALL: {
        TITLE: "通話定額オプション",
      },
      QUESTIONS_OPTION: {
        TITLE: "その他オプション",
        OPTION1: "スマート留守電話",
        OPTION2: "キャッチフォン",
        OPTION3: "必要なし",
      },
      QUESTIONS_DESIRED_DATE: {
        TITLE: "開通希望日",
      },
      SIM_CALL_QUOTA: [
        { value: "000011", text: "5分かけ放題" },
        { value: "000012", text: "10分かけ放題" },
        { value: "000013", text: "完全かけ放題" },
        { value: "000014", text: "必要なし" },
      ],
      NONE: "必要なし",
      OPTIONCHOSSE: [
        { value: "000016", text: "留守番電話" },
        { value: "000017", text: "キャッチフォン" },
      ],
    },
    STEP2_CHOOSE_CABLE: {
      NAVBAR: "Select Fiber-optic internet",
      TITLE: "Select Fiber-optic internet",
      QUESTIONS_CHOOSE_PACKAGE: {
        TITLE: "光回線タイプ ",
      },
      QUESTIONS_START_DAY: {
        TITLE: " 開通希望日 ",
      },
      RENT: {
        TITLE1: " 新規 ",
        TITLE2: " 事業者変更 ",
        USAGE_STATUS: "ご利用状況",
        QES: "無線ルータレンタル",
        NEED: "希望",
        NO_NEED: "希望しない ",
      },
      PHONE: {
        TITLE: "昼間連絡先電話番号",
      },
      TIME: {
        TITLE: "日中連絡できる時間帯",
      },
      IFM_COMMODITY: [
        { value: "000025", text: "マンションタイプ", type: "(集合住宅向け)" },
        { value: "000026", text: "ファミリータイプ", type: "(戸建向け)" },
      ],
    },
    STEP3_WIFI_SELECTION: {
      REQUIRED1: "Wi-Fi高速データプラン",
      REQUIRED2: "開通希望日",
    },
    STEP4_RECEIVE: {
      QUESTIONS_RADIO: {
        SELFIE: {
          TITLE: "来店受け取り",
          TEXT1: "本社",
          TEXT2: "店舗名:　IF Mobile",
          TEXT3: "住所：　東京都新宿区高田馬場１－28－3工新ビル　５F　SEIWA",
          TEXT4: "各代理店",
        },
        TIME_DELI: "配送希望時間帯",
        OVERSEAS: "郵　送",
        IN_JAPAN: "日本国内",
        OUTSIDE_JAPAN: "海外",
        AM: "午前中",
        PM: "午後",
      },
      QUESTIONS_TEXTINPUT: {
        SELECT: "選択",
        MAILING_NAME: "名前",
        POSTAL_CODE: "郵便番号",
        MAILING_CONTACT_PHONE: "電話番号",
        MAILING_DETAILS_ADDRESS: "住所",
        JA: "日本国内　郵送代 %{price}円",
        FOREIGN: "海外　郵送代 %{price}円",
        DELIVERY_TIME: "配送希望時間帯",
        VISIT: "来店受け取り時間",
        VISIT_DATE: "来店日付を選択してください",
        MESSAGE1:
          "前のページに戻りたい場合は、上部の購入フローのアイコンをクリックしてください。",
      },
      VISIT_TIME: [
        { value: "", text: "時間", disabled: true },
        { value: "10:00 ～ 12:00", text: "10:00 ～ 12:00" },
        { value: "12:00 ～ 14:00", text: "12:00 ～ 14:00" },
        { value: "14:00 ～ 16:00", text: "14:00 ～ 16:00" },
        { value: "16:00 ～ 19:00", text: "16:00 ～ 19:00" },
      ],
    },
    STEP5_PURCHASE_CONFIRMATION: {
      TITLE: "プランと料金の確認",
      TEXT: "選択したプランと料金をご確認いただき、手続きを続けて下さい。",
      COMMISSION: "登録事務手数料",
      ANOTHER_COMMISSION: "事務手数料",
      PACKAGE_FEE: "マンションタイプ",
      TAX: "消費税",
      AMOUNT: "お支払額",
      WITHOUT_TAX: "%{price} 円(税抜)",
      WITH_TAX: "%{price} 円(税込)",
      CONFIRM:
        "上記の内容に誤りがないことを確認してください。修正が必要な場合は、購入フローの上部にあるアイコンをクリックし、必要な修正を行ってからお申し込み内容確認ページに戻ってください。",
      FIXED_COSTS: "基本料金",
      QUANTITATIVE_FEE: "従量料金",
      TEXT1: "前々月の通話料金",
      UNSETTLED: "未定",
      OVERSEAS_FEE: "海外使用料金",
      OPENING_COST: "開通工事費",
      INSTALLMENT_COST: "工事費分割払い",
      DEPOSIT: "保証金",
      SIM_CARD_PURCHASE_DETAILS: {
        TITLE: "音声SIM",
        CONTRACT_TYPE: " 契約タイプ",
        PHONE_NUMBER: "電話番号",

        MNP_PHONE_NUMBER: "現在の携帯電話の電話番号",
        MNP_CODE: "MNP予約番号",
        MNP_DEADLINE: "MNP予約番号の有効期限",
        SIM_BRAND_TITLE: "現在携帯電話会社",
        SIM_BRAND_0: "DOCOMO",
        SIM_BRAND_1: "SOFTBANK",
        SIM_BRAND_2: "AU",
        SIM_BRAND_3: "UQ",
        SIM_BRAND_4: "Y!M",
        SIM_BRAND_9: "その他",

        OPEN_DAY: " 開通希望日",
        PLAN: " 高速データプラン",
        TOTAL_INITIAL_COST: "月費用合計",
        SIM_DAMAGES: "SIMカード発行料",
        UNIVERSERVE: "ユニバーサルサービス料",
        RELAYSERVICE: "電話リレーサービス料",
        FLAT: " 通話定額オプション",
        MONTH_BASE: " 前々月%{month}通話量金",
        SMS_BASE: " 前々月%{month}SMS料金",
        MONTH: "28日",
        FIRST_MONTH_FEE: "利用開始月の料金",
        ONETIME_FEE: "初回のみ発生する費用です。",
        DETAIL: "明細はこちら",
        INITIAL_COST: "初期費用",
        MONTHLY_FEE: "月額使用料",
        NEXT_MONTH_FEE: "利用開始翌月の料金",
        PAYMENT_PREVIOUS_MONTH: "前月末でお支払いです。",
        MONTHLY_PAYMENT: "毎月の支払い料金です。",
        MONTHLY_PAYMENT_TYPE: "＋通话料金＋SMS料金",
        SMS_FEE_LAST_MONTH: "前々月のSMS料金",
      },
      LIGHT_PURCHASE_DETAILS: {
        TITLE: "光回線",
        PLAN: " プラン",
        OPEN_DAY: " 開通希望日",
        TOTAL_INITIAL_COST: "初期費用合計",
        USAGE_STATUS: "ご利用状況",
      },
      WIFI_PURCHASE_DETAILS: {
        TITLE: "モバイル　Wi-Fi",
        RENT_TITLE: "レンタルWi-Fi",
        PLAN: " プラン",
        OPEN_DAY: " 開通希望日",
        COD: "COD",
        DEVICE_PRICE: "Wi-Fi機器代金　",
      },

      RECEIVE: {
        TITLE: "受取り方法",
        PRODUCT_ACQUISITION_METHOD: " お受け取り方法",
        AREA: " 地域",
        MAILING_NAME: "名前",
        MAIL_CONTACT_PHONE: "電話番号",
        LOCAL_POSTAL_CODE: "郵便番号",
        MAILING_DETAILS_ADDRESS: "住所",
      },
      TOTAL: {
        POSTAGE: "郵送代",
        TOTAL_INITIAL_COST: "全て初期費用合計",
      },
    },
    STEP6_INFOMATION_CONFIRM: {
      LOGIN_ALERT: "ログインまたはマイページ開設してください",
      INFO_DETAIL_ALERT: "情報を完全に更新してください !",
      NOTIFICATION: "IF モバイル「会員専用ページ」にログインすると",
      LOGIN_NOTIFIC_ATION:
        "本人確認書類のアップロードにはマイページの開設が必要です。ユーザーID（メールアドレス）・パスワードをご登録ください。",
      NOTIFICATION:
        "IF モバイル「会員専用マイページ」にログインするとご利用状況の確認やデータ容量のチャージ、プラン変更、ユーザ情報の変更などが行うことができます。",
      NOTICE_CONTENT: "",
      QUESTIONS_MEMBER_CONFIRM: {
        TITLE2: " 新規",
        TITLE1: " 会員",
        MEMBER: "ログイン",
        NOT_A_MENBER: "マイページ開設",
        SIGN_UP: "新規登録",
        NONE_SIGN_UP: "登録せずに お客さま情報の入力",
      },
      QUESTIONS_REGISTER_INFO: {
        TITLE: " 登録済みのお客さま",
        REGISTER: "会員情報詳細登録",
      },
    },
    STEP7_CONSENT_SIGN: {
      CONTENT: "約款・注意事項をタップし内容をご確認の上、お申し込みください。",
      TITLE: "規約について",
      NAVBAR: "同意書サイン   ",
      CHECKBOX: "上記の約款と注意事項をご理解の上、同意しております。",
      TEMP1: "約款",
      TEMP2: "ご契約内容・注意事項 ",
      BTN: {
        BTN1: "SIM",
        BTN2: "Wi-Fi",
        BTN3: "光回線",
      },
      PAYMENT_FORM: {
        FORM1: {
          PLA1: "カード番号",
          PLA2: "有効期限（年)",
          PLA3: "有効期限（月)",
          PLA4: "カード名（名前）",
          PLA5: "カード名",
          PLA6: "セキュリティコード",
        },
        FORM2: {
          TITLE: "Alipay 国際決済",
        },
        FORM3: {
          TITLE: "Wechatpay国際決済",
        },
        FORM4: {
          TITLE: "コンビニ支払",
          PLA1: "ユーザー名",
          PLA2: "電話番号",
          PLA3: "便利店タイプ：",
        },
      },
      PAYMENT_METHOD: "* 支払方法：",
      SIGN: {
        TITLE: "サイン　　契約者本人のみ、代筆禁止",
        ACCEPT: "確定",
        CLEAN: "リセット",
      },
      OPTION: [
        { value: null, text: "Choose a payment method" },
        { value: "0", text: "credit：クレジットカード決済" },
        { value: "1", text: "alipay：Alipay 国際決済" },
        { value: "2", text: "wechatpay：Wechatpay国際決済" },
        { value: "3", text: "unionpay：銀聯ネット決済" },
        { value: "4", text: "来店支払" },
        { value: "5", text: "コンビニ" },
      ],
      OPTION_STORE: [
        { text: "便利店タイプ", value: null },
        { text: "Seven Eleven", value: "seven_eleven" },
        { text: "Family Mart", value: "family_mart" },
        { text: "Lawson", value: "lawson" },
        { text: "Mini Stop", value: "mini_stop" },
        { text: "Seico Mart", value: "seico_mart" },
        { text: "Pay Easy", value: "pay_easy" },
        { text: "Circle K", value: "circle_k" },
        { text: "Sunkus", value: "sunkus" },
        { text: "Daily Yamazaki", value: "daily_yamazaki" },
        { text: "Yamazaki Daily Store", value: "yamazaki_daily_store" },
      ],
    },
    GENERAL_MERCHADISE: {
      TITLE: "申し込み内容の入力",
    },
  },

  FOOTER: {
    TITLE1: {
      TITLE: "製品",
      LINK1: "SIM",
      LINK2: "Wi-Fi",
      LINK3: "光回線",
      LINK4: "サービス • 端末", //tag
    },
    TITLE2: {
      TITLE: "IF会員",
      LINK1: "会員サービス",
      LINK2: "会員登録",
    },
    TITLE3: {
      TITLE: "サポート",
      LINK1: "Q&A",
      LINK2: "窓口",
      LINK3: "設定方法",
      LINK4: "支払方法",
    },
    TITLE4: {
      TITLE: "会社情報",
      LINK1: "運営会社",
      LINK2: "特定商取引法に基づく表記",
      LINK3: "サービス約款",
      LINK4: "プライバシーポリシー",
    },
  },

  LOGIN: {
    TITLE: "会員ログイン",
    USERNAME: "お客さまID：メールアドレス",
    PASSWORD: "パスワード",
    CODE: "認証コード",
    FORGET_MESSAGE: "※半角英数字6文字以上",
    FORGET_PASSWORD: "パスワードを忘れた方は",
    RECOVER_PASSWORD: "こちら",
    NO_ACCOUNT: "初めてご利用ですか？新規登録は",
    NEW_ACCOUNT: "こちら",
    CANCEL: "キャンセル",
    LOGIN: "ログイン",
  },

  FORGET_PASSWORD: {
    TITLE: "パスワードをお忘れたお客さま",
    EMAIL_ADDRESS: "メールアドレス",
    SEND: "送信",
    CODE: "認証コード",
    認証コードが不正です: "認証コードが不正です",
    VALIDATE_MAIL: "正しいメールアドレスではありません",
    PASSWORD: "新しいパスワード",
    CONFIRM_PASSWORD: "新しいパスワード（確認）",
    MESSAGE1:
      "マイページのパスワードをリセットし、ご登録のメールアドレスに、認証コードを記載したメールをお送りします。",
    MESSAGE2:
      "※メール受信設定をされている場合は、「ifmobile.jp」からのメールを許可してください。",
    MESSAGE3:
      "※メールが届かないお客さまは、お手数ですが、 IF モバイルお客さまセンターまでお問い合わください。",
    TITLE2: "お客さまID (ご登録のメールアドレス)",
    BUTTON: {
      SUBMIT: "再発行申し込み",
      CANCEL: "戻る",
    },
  },

  REGISTER: {
    TITLE: "マイページ開設",
    INDIVIDUAL: "個人",
    CORPORATION: "法人",
    EMAIL_ADDRESS: "メールアドレス",
    SEND_VERIFICATION_CODE: "送信 ",
    MESSAGE: "あなたのメールアドレスを入力してください",
    PLACE_HOLDER: {
      PLH0: "メールアドレス",
      PLH1: "認証コード",
      PLH2: "姓  名（パスポートと同じ表記）",
      PLH3: "姓  名（カタカナ）",
      PLH4: "パスワード（半角英数字6文字以上）",
      PLH5: "パスワード再入力",
      PLH6: "会社名（謄本と同じ表記)",
      PLH7: "会社名（カタカナ）",
      MESSAGE1: "登録メールアドレスが、お客さまIDとなります。",
      MESSAGE2:
        "大切なお知らせもお送りしますので、間違いがないようにご登録ください。「ifmobile.jp」からのメールを許可してください。",
      MESSAGE3:
        "メールアドレスがない方は必ず受信できるメールアドレスを作成して登録してください。",
      MESSAGE4:
        "パスワードはログイン時に必要なので必ず保存しておいてください。",
    },
    BUTTON_RESGITER: "マイページを開設",
    BUTTON_RESGITER_CANCEL: "戻る",
    ERROR: {
      RESPASSWORD: "パスワード・パスワード確認が不一致です",
      EMAIL_EXIST: "メールは既に存在しますので、ご変更ください。",
    },
  },

  PERSONAL_MANAGER: {
    NOTE: {
      TITLE: "本人情報確認",
      TEXT1: "ご記入いただいた個人情報は、本人確認にのみ使用されます。",
      TEXT2:
        "1、日本の法律に基つき、音声デ-タカ-ドを購入する場合は、住所が記載された本人確認書類の提出が義務付けられております。また、残存有効期限が1ヶ月以上のものに限ります。",
      TEXT3:
        "2、SIMカ-ドのお届け先は、本人確認書類と一致させてください。本人確認書類と一致しない場合は、住所が証明てきる補助書類を同時にアップロ-ドしてください。※発行日より6ケ月以内のもの。",
      TEXT4:
        "3、お客様本人が本人確認書類を手に持ち、手や物でお顔が隱れないように撮影した写真をアップロ-ドください。その際,写真や記載内容がはっきりと読み取れるように注意して撮影くたさい。",
      TEXT5:
        "4、正しい本人情報を入力してください。アップロ-ドされた情報が上記の要件を満たしていない場合、SIMカ-ドの購入はできません。",
      TEXT6: "本人確認書類",
      TEXT7: "詳細はこちら",
    },
    NOTE1: {
      TITLE:
        "本人確認書類表面·裏面の写真及び書類をお客様本人が手に持って撮影した写真をアップロードしてください。",
      TEXT1: "①フアイル形式はJPG/PNGで、サイズは4MBまでになります。",
      TEXT2:
        "②本人確認書類と顔写真は必ずお客様ご本人のものをアップロードいただく必要がごさいます。",
      TEXT3:
        "万が一写真上の人物が異なる場合など弊社が定める要件を満たさないと判断した場合、",
      TEXT4: "お手続きができない場合がごさいます。",
    },
    NOTE2: {
      TEXT: "フアイル 形式IよJPG /PNGて サイズは4MBまてになリます",
      TEXT2:
        "以下は、本人確認種類、又は連絡情報などが変更した時に入力してください。",
    },
    BASICINFO: {
      CONTENT:
        "SIMカードのお届け先は、本人確認書類と一致させてください。本人確認書類と一致しない場合は、住所が証明できる補助書類を同時にアップロードしてください。※発行日より６ヵ月以内のもの。",
      BASIC_INFORMATION: "ご契約者に関する情報を入力してください",
      LINE: "----------------------------------------------------------------------------------------------------------------",
      TITLE: "ご契約者のお名前:",
      TITLE1: "身分証明書と一致（漢字又はローマ字）",
      TITLE2: "ご契約者様名（全角カナ):",
      HO: "姓",
      TEN: "名",
      HO1: "セイ",
      TEN1: "メイ",
      PLACEHOLDER: {
        PLA1: "入力",
        PLA3: "番号を入力してください。",
        PLA4: "紹介コードがあれば入力してください。",
      },
      GENDER: "* 性別 ：",
      RADIO_MAN: "男",
      RADIO_WOMAN: "女",
      YEAR: "年",
      MONTH: "月",
      DAY: "日",
      DATE_OF_BIRTH: "* 生年月日 ：",
      IDENTITY_VERIFICATION_DOCUMENTS: "* 本人確認書類 ：",
      ID: "* 本人確認書類の番号 :",
      SELECT_IDENTITY_VERIFICATION_DOCUMENTS: {
        SELEST: "選択肢一つを選択してください",
        OPTION_RESIDENCE_CARD: {
          name: "在留カード",
          value: "",
        },
        OPTION_PASSPORT: {
          name: "パスポート",
          value: "",
        },
        OPTION_DRIVER_LICENSE: {
          name: "運転免許証",
          value: "",
        },
      },
      COUNTRY_OF_CITIZENSHIP: "* 国籍 ：",
      CONTENT1: {
        TEXT1: "本人確認書類表面の写真をアップロードください",
        TEXT2: "本人確認書類裏面の写真をアップロードください",
        TEXT3:
          "本人確認書類をお客様本人が手に持って、お顔も含めて撮影した写真をアップロードください",
      },
      SUPPORTING_DOCUMENTS: "補助書類 ：",
      SELECT_SUPPORTING_DOCUMENTS: {
        SELECT: "選択肢一つを選択してください",
        OPTION_HEALTH_INSURANCE_CARD: {
          name: "健康保険証 ",
          value: "",
        },
        OPTION_MY_NUMBER_CARD: {
          name: "マイナンバーカード",
          value: "",
        },
        OPTION_STUDENT_CARD: {
          name: "学生証",
          value: "",
        },
        OPTION_NOTIFICATION: {
          name: "在留許可通知書",
          value: "",
        },
        OPTION_LANDING_CERTIFICATE: {
          name: "上陸証明書",
          value: "",
        },
      },
      CONTENT2: {
        TEXT1: "補助書類をアップロードする※発行日から6ヶ月以内のものに限る",
        TEXT2: "補助書類をアップロードする※発行日から6ヶ月以内のものに限る",
      },
    },
    CONTACTINFO: {
      CONTACT_INFORMATION: "ご契約様の連絡先情報",
      CONTACT_PHONE_NUMBER: "*連絡先電話番号 ：",
      POST_CODE: "*郵便番号 ：",
      ADDRESS: "*住所 ： ",
      TITLE_REQUIRED:
        "緊急連絡者 情報は、外国人の方の場合、所在国の連絡者情報を記入してください　→要否は要検討",
      EMERGENCY_CONTACT: "*緊急連絡者 ：",
      EMERGENCY_CONTACT_INFORMATION: "*緊急連絡者の住所 ：",
      EMERGENCY_CONTACT_PHONE: "*緊急連絡電話 ：",
      EMERGENCY_RELATION: "*続柄 :",
      PREFERRED_LANGUAGE: "* 希望言語 ：",
      SELECT_PREFERRED_LANGUAGE: {
        SELEST: "選択肢一つを選択してください",
        CHECKBOX_JAPANESE: {
          name: "日本語",
          value: "",
        },
        CHECKBOX_ENGLISH: {
          name: "英語",
          value: "",
        },
        CHECKBOX_CHINESE: {
          name: "中国語",
          value: "",
        },
        CHECKBOX_VIETNAMESE: {
          name: "ベトナム語",
          value: "",
        },
        CHECKBOX_KOREAN: {
          name: "韓国語",
          value: "",
        },
        CHECKBOX_SRILANKAN: {
          name: "スリランカ語",
          value: "",
        },
        CHECKBOX_NEVALA: {
          name: "ネバル語",
          value: "",
        },
      },
    },
    WORKSCHOOLINFO: {
      TITLE: "勤務先／学校情報（任意）  留学生 必須",
      NAMEPHONE__NAME: "勤務先／学校 ：",
      NAMEPHONE__NUMBERPHONE: "勤務先／学校の電話番号 ：",
      ADDRESS: "勤務先／学校の住所 ：",
    },
    OTHERINFO: {
      TITLE: "その他情報",
      ADVERTISEMENT: "*IF Mobileをどこで知りましたか？",
      CHECKBOX__ROUNDED: {
        CHECKBOX_REFER_A_FRIEND: {
          name: "友達紹介",
          value: "",
        },
        CHECKBOX_SCHOOL_INTRODUCTION: {
          name: "学校紹介",
          value: "",
        },
        CHECKBOX_INTRODUCTION: {
          name: "留学/留学/実習生エージェントの紹介",
          value: "",
        },
        CHECKBOX_INTRODUCED_FROM: {
          name: "他のWEBサイトからの紹介",
          value: "",
        },
        CHECKBOX_FACEBOOK: {
          name: "Facebook広告",
          value: "",
        },
        CHECKBOX_YOUTUBE: {
          name: "Youtube広告",
          value: "",
        },
        CHECKBOX_GOOGLE: {
          name: "Google広告",
          value: "",
        },
        CHECKBOX_OTHER_ADVERTISEMENTS: {
          name: "その他広告",
          value: "",
        },
        CHECKBOX_OTHERS: {
          name: "その他",
          value: "",
        },
      },
      USE: {
        TITLE: "どんなSNSを使っていますか？",
        CHECKBOX_FACEBOOK: {
          name: "Facebook",
          value: "",
        },
        CHECKBOX_LINE: {
          name: "LINE",
          value: "",
        },
        CHECKBOX_WECHAT: {
          name: "WeChat",
          value: "",
        },
        CHECKBOX_KAKAO: {
          name: "Kakao Talk",
          value: "",
        },
        CHECKBOX_SNS: {
          name: "SNS名を入力して下さい",
          value: "",
        },
      },
    },
    REFERRAL_CODE: "紹介コード:",
    BTN: "保存",
    DELETE: "削除",
  },

  COMPANY_MANAGER: {
    NOTE: {
      TITLE: "会社確認",
      TEXT1: "ご記入いただいた個人情報は、会社確認にのみ使用されます。",
      TEXT2:
        "1、日本の法律に基つき、音声デ-タカ-ドを購入する場合は、住所が記載された会社確認書類の提出が義務付けられております。また、残存有効期限が1ヶ月以上のものに限ります。",
      TEXT3:
        "2、正しい会社情報を入力してください。アップロ-ドされた情報が上記の要件を満たしていない場合、SIMカ-ドの購入はできません。",
    },
    BASIC_INFORMATION: {
      TITLE: "ご契約会社に関する情報を入力してください",
      CEO: "* 代表取締役 :",
      PDF: "* 会社謄本 :",
      TITLE_ADD_IMG: "名刺 :",
      TEXT_ADD_IMG: "名刺の写真をアップロードしください",
    },
    CONTACTINFO: {
      TITLE: "ご契約会社の連絡先情報",
      PHONE1: "* 会社電話番号:",
      ZIP_CODE: "* 会社郵便番号:",
      ADDRESS: "* 会社住所：",
      NAME: "* 担当者名:",
      CONTACT: "* 担当者連絡先電話番号:",
      POSITION: "* 職務:",
    },
    PLACEHOLDER: {
      PLA1: "会社謄本と一致",
      PLA2: "紹介コードがあれば入力してください。",
    },
    REFERRAL_CODE: "紹介コード:",
    LANGUAGE: "希望言語：",
    BTN: "保存",
    UPDATED_CONTACT: {
      TITLE:
        "以下は、本人確認種類、又は連絡情報が変更した時に入力してください。",
      PLACEHOLDER: "変更後の連絡情報を入力してください",
    },
  },

  INDEX: {
    LOGO: {
      ANIMATION1: "IF",
      ANIMATION2: "Mobile",
      ANIMATION3: "IF Mobile",
    },
    SLOGAN: "To Serve Your Needs",
    PLAN: {
      CONTENT: "The Plans",
      TITLE: "低価格の料金体系！",
      TITLE_TEXT1:
        "「音声SIM」と「Wi-Fi」を自由に組み合わせ！使い方にあわせて料金プランを選べます。",
      TITLE_TEXT2: "毎月の暮らしにオススメのプラン：",
      TITLE_TEXT2_1: "5G音声SIM ＋ 100G モバイルWi-Fi　人気！",

      PLAN_BASIC: "基本プラン",
      MONTHLY_FEE: "月額料金",
      PRICE: "単価",
      BENEFITS: "特典",
      SIM: {
        PERMISSION: "オプション",
      },
      SELL_PHONE: {
        PLAN_BASIC: "タイプ",
        CONTENTS: "内容",
      },
    },
    APPLY: {
      STEP1: {
        NAME: "STEP 1",
      },
      STEP2: {
        NAME: "STEP 2",
      },
      STEP3: {
        NAME: "STEP 3",
      },
    },
    INTRODUCTION: {
      CONTENT: "Smart and Simple",
      TITLE: "IF	モバイルって",
      TEXT1: "クレジットカード不要、審査簡単、自分のニーズに合わせる通信商品",
      TEXT2:
        "換金できるポイント(IF P)と会員サービスを多く揃え、通信以外の価値を提供するブランド",
      BTN: "詳細を見る",
      INTRODUCTION_CARD: {
        CARD1: {
          TITLE: "音声SIM",
          TEXT1: "容量プランは7種類、シンプルで分かりやすい",
          TEXT2: "今のスマホをそのまま使い、データ無制限利用できる",
          TEXT3: "MNP転入　特典キャンペーン",
          BTN2: "SIMを取得する",
        },
        CARD2: {
          TITLE: "モバイルWi-Fi",
          TEXT1: "通信制限なし、契約期間なし",
          TEXT2: "工事・設定不要ですぐに使える",
          TEXT3: "一括購入とレンタル両方OK",
          BTN2: "モバイルWi-Fiを取得する",
        },
        CARD3: {
          TITLE: "光回線",
          TEXT1: "NTT回線速度が速い",
          TEXT2: "ギガ使い放題（通信制限、速度低下なし）",
          TEXT3: "利用できるまでにかかる期間1週間以内",
          BTN2: "光回線を取得する",
        },
        CARD4: {
          TITLE: "サービス・端末", //tag
          TEXT1: "会員専用の店舗や旅行などの割引クーポン",
          TEXT2:
            "提携レストランで食事をすると、現金を交換できるIF ポイントが貯まる",
          TEXT3: "SIMと端末(新品/中古)セット購入で分割払い可能",
          BTN2: "端末を取得する",
        },
      },
    },
    QUESTIONS: {
      CONTENT: "Let's talk with us",
      TITLE: "Contact Us",
      ADDRESS: "Our Office",
      ADDRESS_TEXT: "169-0075 東京都新宿区高田馬場1-28-3 工新ビル5F SEIWA ",
      ADDRESS_TEXT_1: "IF Mobile お客様サポートセンター",
      CALL_US: "Call Us",
      PHONE: "080-2889-4366（10:00～19:00）",
      EMAIL_US: "Email Us",
      EMAIL: "info@ifmobile.jp",
      INPUT: {
        NAME: "Name※",
        EMAIL: "Email※",
        PHONE: "Phone※",
        MESS: "Your Message※",
      },
    },
  },

  PAGINATION: {
    NUMBER_RECORD: "表示件数:",
  },

  INQUIRY: {
    TITLE: "お問い合わせ",
    NOTE: "ご質問がございましたら、以下内容を提出して、2日間以内に貴方を連絡します！",
    EMAIL: "メールアドレス:",
    CONTACT: "お問い合わせ種類",
    SNS: "その他",
    SNS_NAME: "SNS名",
    SNS_ID: "SNS ID",
    RETURN: "戻る",
    SEND: "送信",
  },

  ORDER_INFO: {
    NOTE: {
      TITLE: "「 未完了（要処理）について 」",
      CONTENT1:
        "本人確認書類の不備・相違により処理が正しく完了していないことが考えられます。また、未払いです。",
      CONTENT2_1: "この場合、お申し込み時にご登録いただいたメールアドレス宛に ",
      CONTENT2_2:
        "「【重要】IF モバイル 本人確認書類の不備による再アップロードのお願い or お支払いお願い」",
      CONTENT2_3: "というメールをお送りしております。",
      CONTENT3_1: "不備理由をご確認のうえ、",
      CONTENT3_2: "【処理】",
      CONTENT3_3: "ボタンを押して、必要なお手続きをお願い致します。",
      CONTENT4_1: "以下のページより、詳細をご確認ください。",
      CONTENT4_2: "「本人確認が不備になった」 ",
    },
    TITLE: "申し込み履歴",
    ORDER_NUMBER: "申込番号　",
    ORDER_TYPE_TITLE: "お申し込み種類",
    ORDER_PRICE: "金額（税込）円",
    ORDER_DAY: "お申込み日時",
    EXPECT_DAY: "利用開始日",
    PAYMENT_METHOD: "支払方法",
    WAY_RECEIVE: "お受け取り方法",
    MAILING_AREA: "郵送地域",
    MAILING_NAME: "郵送名前",
    CONTACT_PHONE: "郵送連絡電話",
    ADDRESS: "郵送詳細住所",
    PROCESSING: "処理へ",
    OREDER_PAYMENT_OPTION: [
      { value: "0", text: "カード" },
      { value: "1", text: "Wechat" },
      { value: "2", text: "Ali" },
      { value: "3", text: "コンビニ" },
      { value: "4", text: "来店支払" },
      { value: "5", text: "銀行振込" },
    ],
    ORDER_TYPE: [
      { value: "001", text: "SIM+Wi-Fi" },
      { value: "002", text: "SIM+光回線" },
      { value: "003", text: "SIM" },
      { value: "004", text: "Wi-Fi" },
      { value: "005", text: "光回線" },
      { value: "006", text: "サービス • 端末" }, //tag
      { value: "007", text: "レンタルWi-Fi" },
    ],
    ORDER_STATUS: [
      { value: "0", text: "審査中（受理中)" },
      { value: "1", text: "未完了（要処理)" },
      { value: "2", text: "配送中（手配中)" },
      { value: "3", text: "開通中（完了）" },
    ],
    ORDER_PHONE: {
      NUMBER: "サービス番号",
      CONTENT: "お申込み内容",
      DATESTART: "利用開始日",
      NUMBER_OF_USERS: "利用人数",
      INTENDED_AMOUNT: "予定金額",
      ACTUAL_AMOUNT: "実際金額",
      UPLOAD_IMAGE: "領収書の写真をアップデートしてください",
    },
  },

  BAR_MENU: {
    LOG_OUT: "ログアウト",
    POINT: "ポイント数：",
    MYPAGE: "my IFモバイル",
  },

  CONTRACT_DETAIL: {
    TITLE: "契約内容",
    NOTE: "音声SIM回線",
    CONTRACT_PHONE: "契約電話番号",
    CONTRACT_STATUS: "利用状態",
    CONTRACT_PLAN: "高速データプラン",
    DATESTART: "利用開始日",
    BTN_DETAIL: "内容確認/変更",
    THIS_MONTH: "当月",
    NEXT_MONTH: "翌月",
    PLAN_DATA: "データプラン",
    CALL_QUOTA: "通話定額",
    OPTION: "オプション",
    //option amountPlan
    TUTORIAL1: "プランの変更により、翌月に適用されます。",
    TUTORIAL2: "変更手数料220円が発生します。月に一回のみ変更できます。",
    TUTORIAL3: "翌月プラン選択",
    //option converseQuota
    TUTORIAL4: "通話定額の変更により、翌日に適用されます。",
    TUTORIAL5: "変更手数料220円と当月の通話定額料金が発生します。",
    TUTORIAL6: "月に一回のみ変更できます。",
    //option ortherOption
    TUTORIAL7: "オプションの変更により、翌月に適用されます。",
    TUTORIAL8: "変更手数料220円が発生します。",
    TUTORIAL9: "月に一回のみ変更できます。",
    //MNP TUTORIAL
    MNPTUTORIAL1: "MNP転出により、契約期限内に解約費用が発生します。",
    MNPTUTORIAL2: "解約費用11000円と手数料3300円がかかります。",
    MNPTUTORIAL3: "お支払い手続きが完了後、当日にMNP転出番号が発行可能です。",
    //Cancellation TUTORIAL
    CANCELTUTORIAL1: "解約により、契約期限内に解約費用が発生します。",
    CANCELTUTORIAL2: "解約費用11000円がかかります。",
    CANCELTUTORIAL3: "お支払い手続きが完了後、当日に解約となります。",
    OPEN: "開通中",
    CANCEL: "解約",
    MNP: "MNP",
    TEMPORARY_STOP: "一时停止",
    GO_AGAIN: "再発行",
    ALL: "全て",
    PAYMENT: "変更手数料",
    CONFIRM: "確定",
    BTN_CANCEL: "キャンセル",
    PLAN_CHANGE: "プラン変更",
    CALL_CHANGE: "通話定額変更",
    OPTION_CHANGE: "オプション変更",
    NOTE2: "光回線契約",
    NOTE3: "モバイルWi-Fi契約",
    CONTRACT_LINE_ID: "回線ID",
    CONTRACT_LINE_TYPE: "タイプ",
    CONTRACT_IMEI: "IMEI番号",
    CANCEL_CONTRACT: "解約へ",
    CANCEL_CONTRACT_TITLE: "解約の申し込み",
    CHANGE_MNP_TITLE: "MNP転出",
    CONTRACT_TYPE: "契約の種類",
    EXPLANATORY_TEXT: "説明文",
    EXPLANATORY_PLACEHOLDER: "コメント",
    SELECT_OPTION1: "契約電話番号を選択してください",
    SELECT_OPTION2: "IMEIを選択してください",
    SELECT_OPTION3: "回線IDを選択してください",
    YEN: "円",
    UPDATE_STATUS: {
      TITLE: "利用停止・再発行・再開（紛失・盗難・一時帰国など）",
      OPTION: "対象SIMカードを選択してください",
      OPTION1: "新しいSIMカードを再発行する",
      OPTION2: "SIMカードを一時利用停止する",
      OPTION3: "SIMカードを再開する",
      NOTICE1: "利用停止期間中も、月々のご請求は引き続き発生します。",
      NOTICE2: "再発行の場合は、再発行費5000円が発生します。",
      BUTTON: "確定",
      UPDATE_FALSE: "操作が失敗しました",
    },
  },

  CASH: {
    GOLD_EXCHANGE_RELUES: "換金規則",

    NOTE: "① 10000ポイント単位からの交換です。（1ポイント＝1円）",
    NOTE_1: "10000ポイント単位からの交換です。",
    NOTE_2: "(1ポイント＝1円)",
    NOTE1:
      "② 口座振込（手数料はお客様負担）、各種コード決済（Paypay、PayPal、Alipay、WechatPay）、店舗来店で交換可能です。",
    NOTE2:
      "③ 25日～月末にマイページから換金申請で翌月28日～月末に付与されます。",
    NUMBER_OF_REDEMPTION_POINTS: "換金ポイント数",
    REDEEMABLE_POINTS: "換金可能ポイント：",
    REDEMPTION_POINTS: "換金ポイント：",
    AMOUNTS: "金額：",
    BANK_NAME: "※銀行名：",
    BRANCH_NAME: "※支店名称：",
    ACCOUNT_HOLDER: "※口座名義人：",
    YEN: "円",
    JAPAN_BANK: "日本銀行口座",
    BRANCH_NUMBER: "※支店番号：",
    ACCOUNT_NUMBER: "※口座番号：",
    TELEPHONE_NUMBER: "※電話番号：",
    BTN_BANK: "日本銀行口座",
    BTN_PAY: "バーコードPAY",
    BTN_SURE: "保存",
    NOTICE:
      "保存した銀行口座情報を変更したい場合、お客様サポートセンターに連絡してください。",
  },

  REFERRAL_CODE: {
    TITLE: "紹介専用URL",
    QR: "QR Code",
    CODE: "ifmobile.com?id=2023323de34334234343434343434332324545",
    URL: "URL",
    TEXT: "一人の契約あたり、自動に3000ポイント付与",
    TEXT1: "紹介専用URLをシェアするだけで、儲ける！",
    TEXT2: "詳細",
  },

  FEE_INFO: {
    PDF: "利用明細ダウウンロード　PDF",
    TITLE: "利用料金",
    MONTH_TITLE: "お支払料金",
    SIM: "音声SIM",
    OPT: "光回線料金",
    WIFI: "Wi-Fi料金",
    PHONE: "契約電話番号",
    OTPID: "回線ID:",
    IMEI: "IMEI",
    AMOUNT: "利用料金",
    PRICE: " 円",
    PAYMENTFLAG: "支払状態",
    UNPAID: "未支払",
    PAID: "支払済",
    PAYDEADLINE: "支払期限",
    PAY: "支払",
    DETAIL: "利用明細を見る",
    CREATE_FORM: "帳票作成",
    CALL_DETAILS: "前々月の通話詳細　",
    MONTH_BASE: " 前々月通話料金",
    SMS_BASE: " 前々月　SMS料金",
    DOMESTIC_CALL: "国内通話：%{number}　分",
    INTERNATIONAL_CALL: "海外通話：%{number}　分",
    SMS_DETAILS: "前々月のSMS詳細",
    DOMESTIC_SMS: "国内SMS：%{number}　件",
    INTERNATIONAL_SMS: "国際SMS：%{number}　件",
    //hiden form info
    FIVE_MINUTE: "5分かけ放題",
    TEN_MINUTE: "10分かけ放題",
    COMPLETELY_MINUTE: "完全かけ放題",
    NO_MINUTE: "必要なし",
    ABSENCEPHONE: "留守電話",
    TELEPHONECHARGE: "通話料金",
    SMSFEE: "SMS料金	",
    CATCHPHONE: "キャッチフォン",
    PLAN: "利用プラン料金",
    TEXT1:
      "お支払い期日までにご入金をお願いいたします。 一定期間、お支払いを確認できなかった場合、ご利用中の回線を停止いたします。一時帰国等の事情でお支払いが遅れる場合は必ず事前にご連絡ください。",
    TEXT2:
      "銀行口座、クレジットカードとIF ポイントより毎月のご利用料金を自動的に引き落としをご希望のお客様は、お客様サポートセンターにご連絡ください。また定期課金のページにご確認ください。",
    MESSAGE1: "今月は今月分の高速データと前々月分の通話とSMSを請求",
    MESSAGE2: "前払い　今月は翌月分を請求",
  },

  DATA_USAGE: {
    MESSAGE:
      "高速データ残量0GBになった後、低速モードになります。高速データのチャージ",
    TITLE: "高速データ利用量",
    CONTACT_PHONE_NUMBER: "契約電話番号",
    DATA_PACKAGES: " 高速データプラン",
    UPDATE_DATE: "更新日時：",
    DATA_USAGE_THIS_MONTH: "今月データ量合計",
    TOTAL: "今月データ残量",
    TO_USE: "利用済データ量",
    EXTANDT: "データ残量",
    BTN: "高速データ容量を追加購入する",
    ALL_DATA: "%{data}GB (+チャージ分)",
    BARCHART_TITLE: "直近4日のご利用内訳",
    REVENUESUMMARY: "SIMパケットチャッジ費用",
    PACKET_CHARGE: {
      TITLE: "",
      GUIDE:
        "データ量を選択の上、「購入」ボタンを押してください。１GBあたりの追加料金が550円です。利用期限：今月月末まで",
      SIM_NUMBER: "SIM番号",
      FEE_AMOUNT: "データチャージ",
      AMOUNT_OF_MONEY: "金額（税込み)",
      CONFIRM: "購入",
      CANCEL: "キャンセル",
      NOTICE:
        "容量を選択し、「購入」ボタンをクリックすると、購入が完了し即時にチャージされ、高速モードになります。",
    },
  },

  RECUR_PAYMENT: {
    BTN_SUBMIT: "確定",
    BTN1: "クレジットカード情報登録",
    BTN2: "定期カード変更",
    TITLE: "定期課金",
    TITLE2: "定期支払",
    MESSAGE1: "クレジットカード",
    MESSAGE2: "毎月の支払いに使用するクレジットカードを登録します。",
    MESSAGE3:
      "クレジットカード番号などの情報はUnivapayの提供する決済サービスに登録され安全に管理されます。",
    MESSAGE4: "変更適用スケジュール",
    MESSAGE5: "・毎月1～3日までに変更: 当月のお支払いに適用",
    MESSAGE6: "・毎月4～月末に変更: 翌月のお支払いに適用",
    MESSAGE7: "口座振替",
    MESSAGE8_1: "口座振替 ",
    MESSAGE8_2: " 登録中",
    MESSAGE9:
      "毎月の支払いに口座自動引落を利用したい方、今後毎月のご料金のお支払い方法に口座振替を選択し、下記の依頼書をダウンロードして、記入・捺印の上弊社までご返送ください。",
    MESSAGE10:
      "郵送先：169-0075 東京都新宿区高田馬場1-28-3工新ビル　503号室　IF Mobile　お客様サポートセンター ",
    MESSAGE11:
      "※口座振替の登録: 最短翌月に適用、月末登録した場合、翌々月に適用",
    MESSAGE12:
      "※郵送時期：毎月16日までにご送付の場合には、来月から口座自動引落は可能です。",
    MESSAGE13: "16日以降ご送付の場合には、再来月から口座自動引落は可能です。",
    MESSAGE14: "※必ず印鑑を押してください。",
    MESSAGE15:
      "※印鑑の名前は必ず金融機関口座登録のものと一致する必要があります。",
    MESSAGE16: "IF ポイント",
    MESSAGE17_1: "IF　ポイント（詳しくは",
    MESSAGE17_2: "IF ポイント履歴",
    MESSAGE17_3: "へ）",
    MESSAGE18:
      "IF ポイントより毎月の料金を自動的に引き落とすことを利用したい方、今後毎月のご料金のお支払い方法にIF ポイントを選択し、マイページのポイント管理で` ",
    MESSAGE18_1: "IF ポイント",
    MESSAGE18_2: "をチャージしてください。",
    MESSAGE19: "保有ポイント",
    MESSAGE20: "ポイントチャージ　1ポイント＝1円",
    MESSAGE21: "ポイント入力",
    MESSAGE22: "チャージ",
    MESSAGE23:
      "※全額ポイント支払いのみ対応可能で、一部ポイント支払いはできません。",
    MESSAGE24:
      "※データチャージ、解約金、MNP転出費用、各手数料のお支払いにはご利用いただけません。",
    SIM: {
      TITLE: "SIMカード定期課金",
      CONTRACT_PHONE_NUMBER: "契約電話番号",
    },
    WIFI: {
      TITLE: "Wi-Fi定期課金",
      IMEI: "IMEI",
    },
    HIKARI: {
      TITLE: "光定期課金",
      LINE_ID: "回線ID",
    },
    CARD1: "クレジットカード",
    CARD2: "口座振込",
  },

  MYPAGE_HOME: {
    TITLE: "重要なお知らせ",
    NOTICE_CONTENT:
      "銀行口座やクレジットカードより毎月のご料金を自動的に引き落としする際の手続き",
    TEXT_CONTENT1: "紹介制度で貯まるポイント、換金できる！",
    LINK1: "詳細",
    TEXT_CONTENT2: "紹介専用URL・バーコード",
    LINK2: "取得",
    INVOICE: "今月%{month}月ご請求 ",
    LINK3: "お支払いへ",
    REMAIN_DATA: "データ残量",
    LINK4: "データチャージへ",
    MENU_TITLE: "よく利用されている",
    MENU_TITLE1: "メニュー",
    MENU: [
      {
        name: "ポイント管理 ",
        value: 1,
        url: require("@/assets/historypoint.jpg"),
        icon: "clock-history",
      },
      {
        name: "換金管理",
        value: 2,
        url: require("@/assets/Cashmanagement.jpg"),
        icon: "cash-coin",
      },
      {
        name: "オーダー情報",
        value: 3,
        url: require("@/assets/Orderinformation.jpg"),
        icon: "box-seam",
      },
      {
        name: "データ利用状況",
        value: 4,
        url: require("@/assets/Datausage.jpg"),
        icon: "pie-chart",
      },
      {
        name: "契約情報",
        value: 5,
        url: require("@/assets/Contractinformation.jpg"),
        icon: "receipt-cutoff",
      },
      {
        name: "料金情報",
        value: 6,
        url: require("@/assets/Feeinformation.jpg"),
        icon: "list-check",
      },
      {
        name: "定期課金",
        value: 7,
        url: require("@/assets/recurringbilling.jpg"),
        icon: "calendar2-check",
      },
      {
        name: "アカウントとセキュリティ",
        value: 10,
        url: require("@/assets/AccountAndSecurity.png"),
        icon: "calendar2-check",
      },
      {
        name: "ログアウト",
        value: 99,
        url: require("@/assets/LogOut.png"),
        icon: "calendar2-check",
      },
    ],
  },

  POINT_HISTORY: {
    DATETIME: "日時",
    DIFFERENTIATION: "区分",
    OPERATOR: "操作ポイント数",
    STATUS: "状態",
    POINTOPERATE: "操作者",
    DEPICT: "ポイントの詳細",
    TITLE: "ポイント管理",
    CURRENT_POINT: "保有ポイント",
    CHARGE_PRICE: "ポイントチャージ　1ポイント＝1円",
    ENTER_YOURSELF: "ポイント入力",
    SUBMIT: "チャージ",
    TABLE_TITLE: "ポイント履歴",
    MESSAGE: "ポイント貯まる・使える方法を見る",
    NOTICE: {
      TEXT1: "正しいポイントを入力してください。",
      TEXT2: "1ポイント＝1円",
      TEXT3: "1000 P単位",
      TEXT4: "最低チャージポイント:5000 P、最大チャージポイント:100000 P",
      TEXT5: "チャージポイントについては如何なる理由でも返金はいたしかねます。",
      IMPORTANT1: "*詳しい情報は",
      IMPORTANT2: "でご確認ください。",
      HIGHLIGHT1: "IF ポイント利用規則",
      HIGHLIGHT2: "お支払い方法はこちら",
    },
    POINT_TYPE: [
      { value: "0", text: "紹介GET" },
      { value: "1", text: "消費GET" },
      { value: "2", text: "セット割引GET" },
      { value: "3", text: "自分チャージ" },
      { value: "4", text: "管理員チャージ" },
      { value: "5", text: "月料金利用" },
      { value: "6", text: "サービス利用" },
      { value: "7", text: "換金" },
    ],
    POINT_STATUS: [
      { value: "01", text: "承認待" },
      { value: "02", text: "承認済" },
      { value: "03", text: "承認拒否" },
      { value: "04", text: "チャッジ済" },
      { value: "05", text: "換金済" },
    ],
    POINT_DETAIL: [
      { value: "0", text: "日本銀行口座" },
      { value: "1", text: "バーコードPAY" },
    ],
  },

  SLIDE: [
    {
      url: require("@/assets/slide1.jpg"),
    },
    {
      url: require("@/assets/slide2.jpg"),
    },
    {
      url: require("@/assets/slide3.jpg"),
    },
  ],

  RENT_WIFI: {
    TITLE: "Wi-Fi機器レンタル",
    NOTICE: "ご希望のプランを選択してお進みください",
    NECESSARY_TITLE1: "ご希望のプランを選択して",
    NECESSARY_TITLE2: "お進みください",
    NECESSARY_TITLE3: "レンタル開始日",
    OPTION: [
      { name: "1泊2日プラン", price: "1180" },
      { name: "2泊3日プラン", price: "1770" },
      { name: "3泊4日プラン", price: "2360" },
      { name: "4泊5日プラン", price: "2950" },
      { name: "5泊6日プラン", price: "3540" },
      { name: "6泊7日プラン", price: "4130" },
      { name: "7泊8日プラン", price: "4720" },
      { name: "8泊9日プラン", price: "5310" },
      { name: "9泊10日プラン", price: "5900" },
      { name: "10泊11日プラン", price: "6490" },
      { name: "11泊12日プラン", price: "7080" },
      { name: "12泊13日プラン", price: "7670" },
      { name: "13泊14日プラン", price: "8260" },
      { name: "1ヶ月バック", price: "8850", date: "（15日～31日）" },
      { name: "2ヶ月バック", price: "15750", date: "（60泊61日）" },
      { name: "3ヶ月バック", price: "22000", date: "（90泊91日）" },
      { name: "4ヶ月バック", price: "27600", date: "（120泊121日）" },
      { name: "5ヶ月バック", price: "30600", date: "（150泊151日）" },
      { name: "6ヶ月バック", price: "33600", date: "（180泊181日）" },
    ],
  },

  TERMS: {
    SERVICE_CHARGE: "ユニバーサルサービス料",
  },

  ACCOUNT_SECURITY: {
    TITLE: "パスワードを変更",
    NOTICE: "※必須フィールドに入力してください",
    MESSAGE3: "※半角英数字6文字以上",
    CURRENT_PASSWORD: "現在のパスワード *",
    NEW_PASSWORD: "新しいパスワード *",
    RE_ENTER_PASSWORD: "新しいパスワードの再入力 * ",
    CONFIRMATION_CODE: "確認コード",
    SAVE_CHANGES: "変更を保存する",
    MESSAGE1: "現在のパスワードが不正です",
    MESSAGE2: "新パスワードが現在と同じです",
  },

  PLAN: {
    PL1: "泊",
    PL2: "日",
    PL3: "ヶ月",
    PL45: "SIMフリー iPhone 8 [64GB]",
    PL46: "【中古品 美品】",
    PL47: "本体価格20,000円",
    PL48: "（税込価格22,000円）",
    PL49: "SIMフリー iPhone 13 [128GB]",
    PL50: "【新品】",
    PL51: "本体価格98,000円",
    PL52: "（税込価格107,800円）",
    PL53: "バック",
    PL54: "音声",
    PL55: "モバイル",
    PL56: "レストランや旅行・日常サービス",
    PL57: "最大50％OFF",
    PL58: "iPhone/Android(新品、中古）、IoT 機器",
    PL59: "会員割引/分割払い",
    OPTION1: [
      { price: "20円/30秒", text: "通話料金" },
      { price: "500円/GB", text: "追加チャージ" },
      { price: "3円/通", text: "国内SMS" },
      { price: "100円/通", text: "国際SMS" },
    ],
    OPTION2: [
      { text: "5分かけ放題", price: "500円" },
      { text: "10分かけ放題", price: "850円" },
      { text: "留守番電話", price: "350円" },
      { text: "キャッチャフォン", price: "250円" },
    ],
  },

  HEADER: {
    HD1: "新規会員登録",
    HD2: "料金・プラン",
    HD3: "MNP",
    HD4: "初期設定",
    HD5: "支払方法",
    HD6: "一括購入",
    HD7: "レンタル",
    HD8: "料金",
    HD9: "紹介制度",
    HD10: "benefit",
    HD11: "提携サービス",
    HD12: "Q＆A",
    HD13: "SNS",
    HD14: "日本語",
    HD15: "中文",
    HD16: "English",
    HD17: "Tiếng Việt",
    MESSAGE: "ログアウトしてもよろしいですか？",
    YES: "同意",
  },

  GENERAL: {
    TEXT1: "サービス番号",
    TEXT2: "お申し込み内容",
    TEXT3: "利用開始日",
    TEXT4: "利用人数",
    TEXT5: "予定金額",
    TEXT6: "円",
    LINK: "サービス・端末",
  },
};
