<template>
  <div class="home">
    <!-- <Section1></Section1> -->
    <Banner key="banner" v-show="show"></Banner>
    <b-button class="btn-link btn-dot" @click="toIntroduction()">
      <span class="dot" :class="{ 'animate': showLogo }">&#x2022;</span>
    </b-button>
    <Section3 src="https://www.youtube.com/embed/M7pz_JgOdf0?si=S4PB0KAIn-KCOhay"></Section3>
    <Section2></Section2>
    <Section3 src="https://www.youtube.com/embed/lXxKrtmrwIs?si=c-f1u8hlRgj3d5K7"></Section3>
    <Section4 id="introduction"></Section4>
    <Section5></Section5>
    <!-- <Introduction id="introduction" key="introduction" v-show="show" class="group-future"></Introduction> -->
    <!-- <AdvCard key="card" v-show="show"></AdvCard> -->
    <!-- <Plan key="Plan" v-show="show"></Plan> -->
    <b-icon class="scroll_top" @click="toTop" v-show="scY > 200" font-scale="2" icon="arrow-up-circle-fill"></b-icon>
  </div>
</template>

<script>
import Banner from "./components/Banner.vue";
import Slideshow from "@/components/common/Slideshow.vue";
import Apply from "./components/Apply.vue";
import CardGroup from "@/components/common/CardGroup.vue";
import Feature from "./components/Feature.vue";
import Contact from "@/components/common/Contact.vue";
import GroupCard from "@/components/common/GroupCard.vue";
import Adv from "./components/adv.vue";
import Introduction from "./components/Introduction.vue";
import AdvCard from "./components/AdvCard.vue";
import Plan from "./components/Plan.vue";
import Section1 from "./components/Section1/index.vue"
import Section2 from "./components/Section2/index.vue"
import Section3 from "./components/Section3/index.vue"
import Section4 from "./components/Section4/index.vue"
import Section5 from "./components/Section5/index.vue"

export default {
  components: { 
    Section1, 
    Section2, 
    Section3, 
    Section4, 
    Section5, 
    Banner, 
    Apply, 
    Feature, 
    Contact, 
    Adv, 
    Slideshow, 
    GroupCard, 
    CardGroup, 
    Introduction,
     AdvCard, 
     Plan 
  },
  data() {
    return {
      showLogo: true,
      show: true,
      checkColor1: true,
      checkColor2: true,
      checkColor3: true,
      scTimer: 0,
      scY: 0,
    };
  },
  watch: {
    "showLogo": function () {
      setTimeout(() => this.showLogo = !this.showLogo, 1500)
    }
  },
  computed: {},
  created() {
    this.$localStorage.set("languageCode", this.$CONST.COMMON.LANGUAGE.JA);
    this.showLogo = false;
    this.$localStorage.remove("Answer");
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if(this.$route.query.inquiry){
      this.toDiv();
    }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    toDiv: function () {
      var scrollDiv = document.getElementById("Question").offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    },
    toIntroduction: function () {
      var scrollDiv = document.getElementById("introduction").offsetTop + 100;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  overflow: hidden;
}
.group-future {
  height: 100vh;
}

.scroll_top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}

.btn-dot:hover {
  background-color: #fff;
}

.dot {
  color: white;
  font-size: 45px;
  line-height: 0.5;
}

.animate {
  color: var(--newColor);
  line-height: 2;
  transition: line-height 2s, color 2s;
}

.btn-link {
  border-radius: 20px;
  background-color: white;
  padding: 0;
  border: 3px solid var(--newColor);
  margin-top: -13%;
  width: 40px;
  height: 70px;
}

.btn-secondary:hover {
  color: var(--success);
  text-decoration: none;

  .dot {
    color: var(--success);
  }
}

.group-card {
  width: 100vmax;
  margin: auto;
  margin-left: 25vmax;
  height: 90vh;
}

@media screen and (min-width: 1300px) {
  .banner__logo {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .group-card {
    margin-left: 0vmax;
  }

  .btn-link {
    margin-top: -17%;
  }

  .group-future {
    height: 60vh;
  }
}
</style>
