<template>
	<div>
		<section>
			<div class="footer">
				<div class="main">

					<div class="list-1">
						<div class="title">
							<h3>{{ $t("FOOTER.TITLE1.TITLE") }}</h3>
						</div>
						<div class="title_list">
							<a class="" href="">{{ $t("FOOTER.TITLE1.LINK1") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE1.LINK2") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE1.LINK3") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE1.LINK4") }}</a>
						</div>
					</div>

					<div class="list-2">
						<div class="title">
							<h3>{{ $t("FOOTER.TITLE2.TITLE") }}</h3>
						</div>
						<div class="title_list">
							<a class="" href="">{{ $t("FOOTER.TITLE2.LINK1") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE2.LINK2") }}</a>
						</div>
					</div>

					<div class="list-3">
						<div class="title">
							<h3>{{ $t("FOOTER.TITLE3.TITLE") }}</h3>
						</div>
						<div class="title_list">
							<a class="" href="">{{ $t("FOOTER.TITLE3.LINK1") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE3.LINK2") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE3.LINK3") }}</a>
							<a class="" href="">{{ $t("FOOTER.TITLE3.LINK4") }}</a>
						</div>
					</div>

					<div class="list-4">
						<div class="title">
							<h3>{{ $t("FOOTER.TITLE4.TITLE") }}</h3>
						</div>
						<div class="title_list">
							<div class="title_list_1">
								<router-link class="" to="/operating">{{ $t("FOOTER.TITLE4.LINK1") }}</router-link>
								<router-link class="" to="/notation">{{ $t("FOOTER.TITLE4.LINK2") }}</router-link>
								<router-link class="" to="/terms">{{ $t("FOOTER.TITLE4.LINK3") }}</router-link>
							</div>
							<div class="title_list_2">
								<router-link class="" to="/privacy">{{ $t("FOOTER.TITLE4.LINK4") }}</router-link>
							</div>
						</div>
					</div>

					<div class="list-5">
						<div class="icon1">
							<img src="@/assets/fb1.png" alt="" width="50px" height="50px">
							<img src="@/assets/ig1.png" alt="" width="50px" height="50px">
							<img src="@/assets/tw1.png" alt="" width="50px" height="50px">
							<img src="@/assets/yt1.png" alt="" width="50px" height="50px">
						</div>
						<div class="icon2">
							<img src="@/assets/mxh1.png" alt="" width="50px" height="50px">
							<img src="@/assets/toktok1.png" alt="" width="50px" height="50px">
							<img src="@/assets/tiktok1.png" alt="" width="50px" height="50px">
							<img src="@/assets/bf1.png" alt="" width="50px" height="50px">
						</div>
					</div>

				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: "app",
	components: {},
}
</script>

<style lang="scss" scoped>
.footer {
	padding: 45px 0;
	background: #000;
}

.main {
	display: flex;
	flex-direction: row;
	width: 95%;
	margin: auto;
	flex-wrap: nowrap;
	justify-content: space-evenly;
}

.list-4 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.title_list {

		&_1 {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&_2 {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		a {
			color: rgb(243, 151, 31) !important;
			margin: 5% 0;
		}
	}

}

.list-5 {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	img {
		margin: 25% 0;
	}

	.icon1 {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		margin-left: 15%;
	}

	.icon2 {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		margin-left: 15%;
	}
}

.title_list {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: flex-start;

	a {
		color: #fff !important;
		margin: 10% 0;
		font-weight: 400;
		font-size: 18px;
	}
}

.title {
	color: #fff;
	margin-bottom: 40px;
	h3 {
		text-decoration: underline;
		text-underline-offset: 10px;
		font-weight: 600;
	}
}



@media screen and (max-width: 800px) {
	h3 {
		font-size: 20px;
	}

	a {
		font-size: 12px !important;
	}

	img {
		width: 35px;
		height: 35px;
	}

	.option {
		font-size: 18px !important;
	}
}

@media screen and (max-width: 500px) {
	.main {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: space-evenly;
		align-items: flex-start;
	}

	.option {
		font-size: 16px !important;
	}

	.title {
		margin-bottom: 0;
	}

	.list-4 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		h3 {
			font-size: 23px;
		}

		.title_list {
			width: 100%;
			display: flex;
			flex-direction: column;

			&_1 {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				width: 100%;
			}

			&_2 {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				width: 100%;
			}

			a {
				margin: 4% 6% 4% 2%;
				font-size: 14px !important;
			}
		}
	}

	.list-1,
	.list-2,
	.list-3 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		h3 {
			font-size: 23px;
		}

		.title_list {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			a {
				margin: 4% 6% 4% 2%;
				font-size: 14px !important;

			}
		}
	}

	.list-5 {
		display: flex;
		flex-direction: column;
		width: 100%;

		.icon1 {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-around;
			margin-left: -5%;
			height: 70px;
			margin-top: -50px;

		}

		.icon2 {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-around;
			margin-left: -5%;
			margin-bottom: -50px;
		}
	}
}
</style>

