<template>
  <div class="mypage-i">
    <div class="page-solid" v-if="!$store.state.isMobile && viewsPage != 9">
      <div class="page-solid-x">
        <Menu class="content__itemMenu" :viewsPage="viewsPage"></Menu>
      </div>
    </div>
    <div class="page-x">
      <Home v-if="viewsPage == 9" :dataProp="homeData"></Home>
      <ReferralCode v-if="viewsPage == 8"></ReferralCode>
      <Personal_manager v-if="accountBy == 0 && viewsPage == 0" class="content__item-manager"></Personal_manager>
      <Company_manager v-if="accountBy == 1 && viewsPage == 0" class="content__item-manager"></Company_manager>
      <PointHistory v-if="viewsPage == 1" class="content__item"></PointHistory>
      <Cash v-if="viewsPage == 2"></Cash>
      <Order_info v-if="viewsPage == 3" ></Order_info>
      <Data_usage v-show="viewsPage == 4" @setData="setData"></Data_usage>
      <Contract_detail v-if="viewsPage == 5"></Contract_detail>
      <Fee_info v-show="viewsPage == 6"  @setData="setData"></Fee_info>
      <Recur_payment v-if="viewsPage == 7"></Recur_payment>
      <Account_security v-if="viewsPage == 10" class="content__item"></Account_security>
    </div>
    <Tabbar v-if="$store.state.isMobile"></Tabbar>
  </div>
</template>

<script>
import Tabbar from "../components/Tabbar/index.vue";
import Menu from "../components/Menu/index.vue";
import Home from "../components/Home/index.vue";
import ReferralCode from "../components/ReferralCode/index.vue";
import Personal_manager from "../components/PersonalManager/index.vue";
import Company_manager from "../components/CompanyManager/index.vue";
import PointHistory from "../components/PointHistory/index.vue";
import Cash from "../components/Cash/index.vue";
import Order_info from '../components/OrderInfo/index.vue'
import Data_usage from '../components/DataUsage/index.vue';
import Contract_detail from '../components/ContractDetail/index.vue';
import Fee_info from '../components/FeeInfo/index.vue';
import Recur_payment from "../components/RecurPayment/index.vue";
import Account_security from "../components/AccountSecurity/index.vue";
export default {
  data() {
    return {
      accountBy: '',
      // viewsPage: 9,
      homeData: {
        price : 0,
        remainingData: 0,
      }
    }
  },
  components: {
    Tabbar,
    Menu,
    Home,
    Personal_manager,
    Company_manager,
    PointHistory,
    Cash,
    Order_info,
    Data_usage,
    Contract_detail,
    Fee_info,
    Recur_payment,
    ReferralCode,
    Account_security
  },
  computed: {
    viewsPage() {
      return this.$route.query.view || 9
    }
  },
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin: function () {
      if (this.$localStorage.get("token")) {
        this.accountBy = localStorage.getItem('accountBy');
      } else {
        this.$router.push('/login');
      }
    },
    setData (value) {
      if (value.page == 4){
        this.homeData.remainingData = value.result
      }else {
        for (const key in value.result) {
          if (Object.hasOwnProperty.call(value.result, key)) {
           this.homeData.price += value.result[key].statistics;
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  background-color: #F4F4F4;
  min-height: calc(100vh - 385px);

  &__itemMenu {
    width: 30%;
    overflow: hidden;
    position: fixed;
    z-index: 4;
    // min-width: 200px;
  }

  &__item,
  &_item-manager {
    width: 80%;
    margin: inherit;
  }
}

// @media (max-width: 768px) {
//   .content {
//     &__itemMenu {
//       width: 60%;
//     }

//     &__item,
//     &__item-manager {
//       width: 100%;
//     }
//   }
// }

@media screen and (max-width: 500px) {
  .content {
    justify-content: unset;

    &__item {
      margin: auto;
    }

    &__item-manager {
      width: 90%;
    }

    &__itemMenu {
      width: 80%;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>