<template>
  <div class="container_fee">
    <div style="margin-bottom: 10px; text-align: left">
      <van-notice-bar
        wrapable
        v-if="$store.state.isMobile"
       :scrollable="false"
        >
        <text-more :length="16">
          {{ $t("FEE_INFO.TEXT1") }}
        </text-more>
        
        </van-notice-bar
      >
      <el-alert
        v-else
        :title="$t('FEE_INFO.TEXT1')"
        type="warning"
        show-icon
        :closable="false"
        :center="false"
      >
      </el-alert>
    </div>

    <div class="fee" v-if="!showPay">
      <div class="title-x">
        <img
          class="image_2"
          src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
        />
        <h1 class="text_17">
          {{ $t("FEE_INFO.TITLE") }}
        </h1>
      </div>

      <div class="fee__month_pagination">
        <b-icon
          @click="back"
          v-if="minMonth < checkDay"
          icon="play-fill"
          variant="success"
          font-scale="1.5"
        >
        </b-icon>
        <h4>
          {{ $t("FEE_INFO.MONTH_TITLE")
          }}<span>
            {{ checkDay.getMonth() + 1 }}/{{ checkDay.getFullYear() }}</span
          >
        </h4>
        <b-icon
          @click="next"
          v-if="
            String(checkDay.getMonth()) + String(checkDay.getFullYear()) !=
            String(currentDay.getMonth()) + String(currentDay.getFullYear())
          "
          icon="play-fill"
          variant="success"
          font-scale="1.5"
        >
        </b-icon>
      </div>
      <div v-show="month != 0" v-for="(item, index) in getFee" :key="index">
        <div class="fee__content" v-if="item.contractType == '0'">

          <div class="fee__content__type">
            <h3>{{ $t("FEE_INFO.SIM") }}</h3>
            <h5>{{ $t("FEE_INFO.PHONE") }}: {{ item.simPhoneNum }}</h5>
          </div>

          <div class="fee__content__info">
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.AMOUNT") }}
              </div>
              <div class="fee__content__info__row__right">
                {{ item.statistics }}{{ $t("FEE_INFO.PRICE") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYMENTFLAG") }}
              </div>
              <div
                v-if="
                  item.paymentFlag ==
                  $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
                "
                class="fee__content__info__row__right unpaid"
              >
                {{ $t("FEE_INFO.UNPAID") }}
              </div>
              <div v-else class="fee__content__info__row__right paid">
                {{ $t("FEE_INFO.PAID") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYDEADLINE") }}
              </div>
              <div class="fee__content__info__row__right">
                {{
                  $t(
                    "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.MONTH"
                  )
                }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <!-- <div class="fee__content__info__row__left">{{ $t('FEE_INFO.DETAIL') }}</div> -->
              <div
                class="fee__content__info__row__right btn-link"
                @click="hiddenInfo(item.id)"
              >
                {{ $t("FEE_INFO.DETAIL") }}
                <i class="iconfont icon-z044"></i>
              </div>
            </div>
            
            <b-button
            variant="success"
            v-if="
              item.paymentFlag ==
              $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
            "
            @click="pay(item)"
            class="pay_fix"
            >{{ $t("FEE_INFO.PAY") }}</b-button>

            <div v-show="hidden && activeDiv == item.id"
              :id="item.id"
              class="fee__content__info__hidden_info fix"
            >
              <div
                v-show="item.plan != '' && item.plan != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.PLAN") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.monthBase }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <!-- <div
                v-show="
                  item.telephoneCharge != 0 && item.telephoneCharge != null
                "
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.TELEPHONECHARGE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.telephoneCharge }}
                </div>
              </div> -->
              <div
                v-show="item.smsFee != 0 && item.smsFee != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.SMSFEE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.smsFee }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="
                  item.fiveMinutesFree != 0 && item.fiveMinutesFree != null
                "
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.FIVE_MINUTE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.fiveMinutesFree }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.tenMinutesFree != 0 && item.tenMinutesFree != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.TEN_MINUTE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.tenMinutesFree }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.absencePhone != 0 && item.absencePhone != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.ABSENCEPHONE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.absencePhone }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.catchPhone != 0 && item.catchPhone != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.CATCHPHONE") }}{{ $t("FEE_INFO.PRICE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.catchPhone }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.monthBase != 0 && item.monthBase != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.MONTH_BASE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.monthBase }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.smsFee != 0 && item.smsFee != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("FEE_INFO.SMS_BASE") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.smsFee }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="
                  item.universalService != 0 && item.universalService != null
                "
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{
                    $t(
                      "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.UNIVERSERVE"
                    )
                  }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.universalService }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.relayService != 0 && item.relayService != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{
                    $t(
                      "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.RELAYSERVICE"
                    )
                  }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.relayService }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.consumptionTax != 0 && item.consumptionTax != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.TAX") }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.consumptionTax }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div
                v-show="item.subtotal != 0 && item.subtotal != null"
                class="fee__content__info__hidden_info_row"
              >
                <div class="fee__content__info__hidden_info__text">
                  {{
                    $t(
                      "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.TOTAL_INITIAL_COST"
                    )
                  }}
                </div>
                <div class="fee__content__info__hidden_info__text">
                  {{ item.subtotal }}{{ $t("FEE_INFO.PRICE") }}
                </div>
              </div>
              <div class="fee__content__info__hidden_info__call_detail">
                <div class="fee__content__info__hidden_info__call_detail__item">
                  <h5>{{ $t("FEE_INFO.CALL_DETAILS") }}</h5>
                  <div
                    class="fee__content__info__hidden_info__call_detail__item_row"
                  >
                    <div>
                      {{
                        $t("FEE_INFO.DOMESTIC_CALL", {
                          number: item.talkTimeDomestic,
                        })
                      }}
                    </div>
                    <div>
                      {{
                        $t("FEE_INFO.INTERNATIONAL_CALL", {
                          number: item.talkTimeOverseas,
                        })
                      }}
                    </div>
                  </div>
                </div>
                <div class="fee__content__info__hidden_info__call_detail__item">
                  <h5>{{ $t("FEE_INFO.SMS_DETAILS") }}</h5>
                  <div
                    class="fee__content__info__hidden_info__call_detail__item_row"
                  >
                    <div>
                      {{
                        $t("FEE_INFO.DOMESTIC_SMS", {
                          number: item.smsNumberDomestic,
                        })
                      }}
                    </div>
                    <div>
                      {{
                        $t("FEE_INFO.INTERNATIONAL_SMS", {
                          number: item.smsNumberInternational,
                        })
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="hidden && activeDiv == item.id"
              :id="item.id"
              class="fee__content__info__hidden_info"
            >
              <b-button @click="createPDF(item)">{{
                $t("FEE_INFO.PDF")
              }}</b-button>
                <!-- <b-button
                  v-if="
                    item.paymentFlag ==
                    $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
                  "
                  variant="outline-secondary"
                  @click="create_form(item.id)"
                >
                  {{ $t("FEE_INFO.CREATE_FORM") }}</b-button
                > -->
            </div>
          </div>

          <el-alert
            :title="$t('FEE_INFO.MESSAGE1')"
            type="info"
            show-icon
            :center="false"
            :closable="false"
            style="
              text-align: left; 
              background: rgba(82, 87, 102, 0.05);
              margin: 10px 0 10px;"
          ></el-alert>
        </div>
        <div class="fee__content" v-if="item.contractType == '1'">
          <div class="fee__content__type">
            <h3>{{ $t("FEE_INFO.OPT") }}</h3>
            <h5>{{ $t("FEE_INFO.OTPID") }} {{ item.simPhoneNum }}</h5>
          </div>

          <div class="fee__content__info">
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.AMOUNT") }}
              </div>
              <div class="fee__content__info__row__right">
                {{ item.statistics }}{{ $t("FEE_INFO.PRICE") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYMENTFLAG") }}
              </div>
              <div
                v-if="
                  item.paymentFlag ==
                  $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
                "
                class="fee__content__info__row__right unpaid"
              >
                {{ $t("FEE_INFO.UNPAID") }}
              </div>
              <div v-else class="fee__content__info__row__right paid">
                {{ $t("FEE_INFO.PAID") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYDEADLINE") }}
              </div>
              <div class="fee__content__info__row__right">
                {{
                  $t(
                    "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.MONTH"
                  )
                }}
              </div>
            </div>
            <b-button
              v-if="
                item.paymentFlag ==
                $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
              "
              variant="success"
              @click="pay(item)"
              class="pay_fix"
              >{{ $t("FEE_INFO.PAY") }}</b-button
            >
          </div>

          <el-alert
            :title="$t('FEE_INFO.MESSAGE2')"
            type="info"
            show-icon
            :center="false"
            :closable="false"
            style="
              text-align: left; 
              background: rgba(82, 87, 102, 0.05);
              margin: 10px 0 10px;"
          ></el-alert>
        </div>
        <div class="fee__content" v-if="item.contractType == '2'">
          <div class="fee__content__type">
            <h3>{{ $t("FEE_INFO.WIFI") }}</h3>
            <h5>{{ $t("FEE_INFO.IMEI") }}: {{ item.simPhoneNum }}</h5>
          </div>
          
          <div class="fee__content__info">
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.AMOUNT") }}
              </div>
              <div class="fee__content__info__row__right">
                {{ item.statistics }}{{ $t("FEE_INFO.PRICE") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYMENTFLAG") }}
              </div>
              <div
                v-if="
                  item.paymentFlag ==
                  $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
                "
                class="fee__content__info__row__right unpaid"
              >
                {{ $t("FEE_INFO.UNPAID") }}
              </div>
              <div v-else class="fee__content__info__row__right paid">
                {{ $t("FEE_INFO.PAID") }}
              </div>
            </div>
            <div class="fee__content__info__row">
              <div class="fee__content__info__row__left">
                {{ $t("FEE_INFO.PAYDEADLINE") }}
              </div>
              <div class="fee__content__info__row__right">
                {{
                  $t(
                    "SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.MONTH"
                  )
                }}
              </div>
            </div>
            <b-button
              v-if="
                item.paymentFlag ==
                $CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
              "
              variant="success"
              @click="pay(item)"
              class="pay"
              >{{ $t("FEE_INFO.PAY") }}</b-button>
          </div>

          <el-alert
            :title="$t('FEE_INFO.MESSAGE2')"
            type="info"
            show-icon
            :center="false"
            :closable="false"
            style="
              text-align: left; 
              background: rgba(82, 87, 102, 0.05);
              margin: 10px 0 10px;"
          ></el-alert>
        </div>
      </div>
    </div>

    <Kyokin v-if="showPay" @return-fee="returnFee" :amount="amount"></Kyokin>
    
    <div style="margin: 10px 0; text-align: center; background-color: #fff; padding: 10px 0;">
      <el-alert
        :title="$t('FEE_INFO.TEXT2')"
        type="warning"
        :show-icon="false"
        :center="false"
        :closable="false"
        style="background: #fff; text-align: left;"
      >
      </el-alert>

      <el-button 
        @click="$router.replace(`/mypage?view=${7}`)"
        size="small"
        round
        style="
          border: 1px solid #E6A23C; 
          color: #E6A23C; 
          margin: 15px 10px; 
          line-height: 1;
          padding-left: 30px; 
          padding-right: 20px;"
      >
        <span>{{ $t('RECUR_PAYMENT.TITLE') }}</span> 
        <i class="iconfont icon-jinrujiantou" style="position: relative; top: 2px;"></i>
      </el-button>
    </div>
  </div>
</template>
<script>
import Kyokin from "../Kyokin/index.vue";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
export default {
  components: {
    Kyokin,
  },
  data() {
    return {
      currentDay: new Date(),
      checkDay: new Date(),
      fee: [],
      mailing: [],
      month: 0,
      getMonth: 0,
      hidden: false,
      activeDiv: 0,
      showPay: false,
      amount: 0,
      ApiData: {
        code: 0,
        message: "",
        result: {
          current: 0,
          pages: 0,
          records: [
            {
              absencePhone: 111,
              availablePoints: "98765",
              cancellationFee: 111,
              catchPhone: 111,
              consumptionTax: 0,
              contractType: "1",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 111,
              fullFree: 111,
              id: "1111111",
              month: "2022-11",
              monthBase: 11111,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 0,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 111,
              relayService: 111,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 1111,
              simPhoneNum: "345635463456",
              smsFee: 1111,
              smsNumberDomestic: 11,
              smsNumberInternational: 1111,
              statistics: 11,
              subtotal: 11,
              talkTimeDomestic: 111,
              talkTimeOverseas: 1111,
              telephoneCharge: 111,
              tenMinutesFree: 11,
              universalService: 1111,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
            {
              absencePhone: 0,
              availablePoints: "2222",
              cancellationFee: 0,
              catchPhone: 0,
              consumptionTax: 0,
              contractType: "0",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 0,
              fullFree: 0,
              id: "22222222",
              month: "2022-11",
              monthBase: 0,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 1,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 0,
              relayService: 0,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 0,
              simOpenDate: "",
              simPhoneNum: "563463456354",
              smsFee: 0,
              smsNumberDomestic: 0,
              smsNumberInternational: 0,
              statistics: 0,
              subtotal: 0,
              talkTimeDomestic: 0,
              talkTimeOverseas: 0,
              telephoneCharge: 0,
              tenMinutesFree: 0,
              universalService: 0,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
            {
              absencePhone: 333,
              availablePoints: "33333",
              cancellationFee: 333,
              catchPhone: 3333,
              consumptionTax: 0,
              contractType: "1",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 3333,
              fullFree: 3333,
              id: "333333333",
              month: "2022-10",
              monthBase: 33333,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 0,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 333,
              relayService: 33333,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 333,
              simPhoneNum: "23454634564",
              smsFee: 3333,
              smsNumberDomestic: 3333,
              smsNumberInternational: 33333,
              statistics: 333,
              subtotal: 333,
              talkTimeDomestic: 333,
              talkTimeOverseas: 333,
              telephoneCharge: 3333,
              tenMinutesFree: 3333,
              universalService: 333,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
            {
              absencePhone: 444,
              availablePoints: "44444",
              cancellationFee: 444,
              catchPhone: 4444,
              consumptionTax: 0,
              contractType: "2",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 4444,
              fullFree: 4444,
              id: "44444444",
              month: "2022-08",
              monthBase: 44444,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 1,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 4444,
              relayService: 4444,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 4444,
              simPhoneNum: "35462456345724",
              smsFee: 444,
              smsNumberDomestic: 4444,
              smsNumberInternational: 4444,
              statistics: 4444,
              subtotal: 4444,
              talkTimeDomestic: 4444,
              talkTimeOverseas: 44444,
              telephoneCharge: 44444,
              tenMinutesFree: 4444,
              universalService: 4444,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
            {
              absencePhone: 555,
              availablePoints: "55555",
              cancellationFee: 555,
              catchPhone: 5555,
              consumptionTax: 0,
              contractType: "1",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 5555,
              fullFree: 5555,
              id: "5555555555",
              month: "2022-07",
              monthBase: 5555,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 1,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 5555,
              relayService: 5555,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 5555,
              simPhoneNum: "345245635463456",
              smsFee: 555,
              smsNumberDomestic: 5555,
              smsNumberInternational: 555,
              statistics: 5555,
              subtotal: 5555,
              talkTimeDomestic: 5555,
              talkTimeOverseas: 5555,
              telephoneCharge: 5555,
              tenMinutesFree: 555,
              universalService: 5555,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
            {
              absencePhone: 6666,
              availablePoints: "6666666",
              cancellationFee: 66,
              catchPhone: 666,
              consumptionTax: 0,
              contractType: "Loại hợp đồng",
              createBy: "người tạo",
              createTime: "ngày tạo",
              email: "katakuti@chalote.com",
              fiveMinutesFree: 666,
              fullFree: 666,
              id: "666666666666",
              month: "2022-06",
              monthBase: 666,
              paymentDeadline: "thời hạn thanh toán",
              paymentFlag: 0,
              paymentMethods: "phương thức thanh toán",
              paymentTime: "ngày thanh toán",
              plan: "kế hoạch",
              registrationOfficeFee: 666,
              relayService: 666,
              settlementNumber: "Số tiền phải trả",
              settlementType: "Hình thức thanh toán",
              simDamage: 666,
              simPhoneNum: "567345634563",
              smsFee: 666,
              smsNumberDomestic: 666,
              smsNumberInternational: 666,
              statistics: 666,
              subtotal: 666,
              talkTimeDomestic: 666,
              talkTimeOverseas: 666,
              telephoneCharge: 6666,
              tenMinutesFree: 666,
              universalService: 666,
              updateBy: "người cập nhật",
              updateTime: "cập nhật ngày tháng",
            },
          ],
          size: 0,
          total: 0,
        },
        success: true,
        timestamp: 0,
      },
    };
  },
  created() {
    this.month = new Date();
    this.getFeeInfo(this.month);

    this.minMonth;
    this.getMonth = this.month.getMonth() + 1;
  },
  computed: {
    minMonth() {
      let d = new Date();
      let month = d.getMonth();
      let new_month = month - 5;
      d.setMonth(new_month);
      return d;
    },
    getFee() {
      this.$emit("setData", {
        result: this.fee,
        page: 6,
      });
      return this.fee;
    },
  },
  watch: {
    month() {
      this.getFeeInfo(this.month);
    },
    fee() {
      return this.fee;
    },
  },
  methods: {
    next() {
      this.month = new Date(this.month);
      let getMonth = this.month.getMonth();
      let new_month = getMonth + 1;
      this.month.setMonth(new_month);
      this.checkDay = this.month;
    },
    back() {
      this.month = new Date(this.month);
      let getMonth = this.month.getMonth();
      let new_month = getMonth - 1;
      this.month.setMonth(new_month);
      this.checkDay = this.month;
    },
    hiddenInfo(index) {
      if (this.activeDiv == index && this.hidden) {
        this.hidden = false;
      } else {
        this.activeDiv = index;
        this.hidden = true;
      }
    },
    pay(value) {
      if (
        value.paymentFlag ==
        this.$CONST.MANAGEMENT.PAYMENT_STATUS.PAY_STATUS.UNPAID
      ) {
        this.amount = value;
      }
      this.showPay = true;
    },
    returnFee() {
      this.showPay = false;
    },
    getFeeInfo(month) {
      const formatDay = month.toISOString().split("T")[0];
      month = formatDay.slice(0, 7);
      if (this.$CONST.DEV) {
        console.log("Debug API: feeManage");
        let data = this.ApiData.result.records.filter(
          (item) => item.month === month
        );
        this.fee = data;
      } else {
        let params = {
          email: localStorage.getItem("email"),
          month: month,
          language: window.localStorage.getItem("languageCode"),
        };
        this.$api.get("ifmCost/list", params).then((response) => {
          this.fee = response.result.records;
        });
      }
    },
    create_form(value) {
      this.$api
        .get("ifmCost/ticketGenerated", {
          id: value,
        })
        .then((response) => {});
    },
    createPDF(item) {
      this.$api
        .get("ifmCost/ticketGenerated", {
          id: item.id,
        })
        .then((response) => {
          if (response.success) {
            // Insert a link that allows the user to download the PDF file
            var link = document.createElement("a");
            link.innerHTML = "Download PDF file";
            link.download = item.simPhoneNum + "_" + item.createTime + ".pdf";
            link.href =
              "data:application/octet-stream;base64," + response.result;
            link.click();
          }
        });
    },
    twoMonthAgo(value) {
      if (value == 2) {
        return 12;
      } else if (value == 1) {
        return 11;
      } else {
        return value - 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
