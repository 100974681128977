<template>
  <div class="personalmanager" style="font-size: 13px">
    <section>
      <div class="p-personal__basicInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("PERSONAL_MANAGER.BASICINFO.BASIC_INFORMATION") }}
          </h1>
        </div>

        <div class="name" style="margin-bottom: 0">
          <p class="name_title title-head">
            {{ $t("PERSONAL_MANAGER.BASICINFO.TITLE1") }}
          </p>
        </div>

        <div class="name">
          <p class="name_title">
            <span style="color: red">※</span
            >{{ $t("PERSONAL_MANAGER.BASICINFO.TITLE") }}
          </p>
          <div class="name_main">
            <div class="name_main__first-title">
              <!-- ご契約者のお名前:姓 -->
              <b-form-input
                :disabled="detailChangeAbleFlg"
                type="text"
                :placeholder="$t('PERSONAL_MANAGER.BASICINFO.HO')"
                v-model="userDetail.lastName"
                :state="lastName"
              />
              <div v-show="alert" class="validation-message idvalid">
                <span
                  class="validation-message"
                  v-show="userDetail.lastName == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
                </span>
              </div>
            </div>
            <!-- ご契約者のお名前:名 -->
            <div class="name_main__first-title">
              <b-form-input
                :disabled="detailChangeAbleFlg"
                type="text"
                :placeholder="$t('PERSONAL_MANAGER.BASICINFO.TEN')"
                v-model="userDetail.firstName"
                :state="firstName"
              />
              <div v-show="alert" class="validation-message idvalid">
                <span
                  class="validation-message"
                  v-show="userDetail.firstName == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="name">
          <p class="name_title">
            {{ $t("PERSONAL_MANAGER.BASICINFO.TITLE2") }}
          </p>
          <div class="name_main">
            <div class="name_main__first-title">
              <!-- ご契約者様名（全角カナ):セイ -->
              <b-form-input
                :disabled="detailChangeAbleFlg"
                type="text"
                :placeholder="$t('PERSONAL_MANAGER.BASICINFO.HO1')"
                v-model="userDetail.lastNameKana"
                :state="lastNameKana"
              />
              <!-- <div v-show="alert" class="validation-message idvalid">
                <span
                  class="validation-message"
                  v-show="userDetail.lastNameKana == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
                </span>
              </div> -->
            </div>

            <div class="name_main__first-title">
              <!-- ご契約者様名（全角カナ):メイ -->
              <b-form-input
                :disabled="detailChangeAbleFlg"
                type="text"
                :placeholder="$t('PERSONAL_MANAGER.BASICINFO.TEN1')"
                v-model="userDetail.firstNameKana"
                :state="firstNameKana"
              />
              <!-- <div v-show="alert" class="validation-message idvalid">
                <span
                  class="validation-message"
                  v-show="userDetail.firstNameKana == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
                </span>
              </div> -->
            </div>
          </div>
        </div>

        <div class="p-personal__basicInfo__inner">
          <div style="width: 100%">
            <div class="p-personal__basicInfo__inner__item">
              <p class="sub_title">
                <span style="color: red">{{
                  $t("PERSONAL_MANAGER.BASICINFO.GENDER").indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t("PERSONAL_MANAGER.BASICINFO.GENDER").indexOf("*") > -1
                    ? $t("PERSONAL_MANAGER.BASICINFO.GENDER").slice(1)
                    : $t("PERSONAL_MANAGER.BASICINFO.GENDER")
                }}
              </p>
              <!-- 性別  -->
              <el-radio-group v-model="userDetail.sex" size="small">
                <el-radio label="1" border :disabled="detailChangeAbleFlg">{{
                  $t("PERSONAL_MANAGER.BASICINFO.RADIO_MAN")
                }}</el-radio>
                <el-radio label="2" border :disabled="detailChangeAbleFlg">{{
                  $t("PERSONAL_MANAGER.BASICINFO.RADIO_WOMAN")
                }}</el-radio>
              </el-radio-group>

              <!-- <div class="p-personal__basicInfo__inner__item--gender">
                <div>
                  <label>
                    <input
                      :disabled="detailChangeAbleFlg"
                      type="radio"
                      name="gender"
                      v-model="userDetail.sex"
                      :state="sex"
                      value="1"
                    />{{ $t("PERSONAL_MANAGER.BASICINFO.RADIO_MAN") }}
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      :disabled="detailChangeAbleFlg"
                      type="radio"
                      name="gender"
                      v-model="userDetail.sex"
                      :state="sex"
                      value="2"
                    />{{ $t("PERSONAL_MANAGER.BASICINFO.RADIO_WOMAN") }}
                  </label>
                </div>
              </div> -->
            </div>

            <div v-show="alert" class="validation-message vali">
              <span class="validation-message" v-show="userDetail.sex == ''">{{
                $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>
          </div>

          <div style="width: 100%">
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
              style="width: 100%"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("PERSONAL_MANAGER.BASICINFO.DATE_OF_BIRTH")).indexOf(
                    "*"
                  ) > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("PERSONAL_MANAGER.BASICINFO.DATE_OF_BIRTH")).indexOf(
                    "*"
                  ) > -1
                    ? $t($t("PERSONAL_MANAGER.BASICINFO.DATE_OF_BIRTH")).slice(
                        1
                      )
                    : $t($t("PERSONAL_MANAGER.BASICINFO.DATE_OF_BIRTH"))
                }}
              </p>
              <div class="birthday">
                <span class="year">
                  <!-- 生年 -->
                  <select
                    :disabled="detailChangeAbleFlg"
                    v-model="getYear"
                    :state="birthday"
                  >
                    <option
                      v-for="(value, index) in listYear.years"
                      :key="index"
                      :value="value.name"
                    >
                      {{ value.name }}
                    </option>
                  </select>
                  <p>{{ $t("PERSONAL_MANAGER.BASICINFO.YEAR") }}</p>
                </span>
                <span class="month">
                  <!-- 生月 -->
                  <select
                    :disabled="detailChangeAbleFlg"
                    name="month"
                    v-model="getMonth"
                    :state="birthday"
                  >
                    <option
                      v-for="(value, index) in listYear.months"
                      :key="index"
                      :value="value.name"
                    >
                      {{ value.name }}
                    </option>
                  </select>
                  <p>{{ $t("PERSONAL_MANAGER.BASICINFO.MONTH") }}</p>
                </span>
                <span class="day">
                  <!-- 生日 -->
                  <select
                    :disabled="detailChangeAbleFlg"
                    name="day"
                    v-model="getDay"
                    :state="birthday"
                  >
                    <option
                      v-for="(value, index) in listYear.days"
                      :key="index"
                      :value="value.name"
                    >
                      {{ value.name }}
                    </option>
                  </select>
                  <p>{{ $t("PERSONAL_MANAGER.BASICINFO.DAY") }}</p>
                </span>
              </div>
            </div>
            <div v-show="alert" class="validation-message idvalid">
              <span
                class="validation-message"
                v-show="
                  getYear == '' ||
                  getMonth == '' ||
                  getDay == '' ||
                  getYear == null ||
                  getMonth == null ||
                  getDay == null
                "
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
              >
            </div>
          </div>
        </div>

        <div class="p-personal__basicInfo__inner__display">
          <div
            class="p-personal__basicInfo__inner__display__title"
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div class="country">
              <p class="sub_title">
                <span style="color: red">{{
                  $t(
                    $t("PERSONAL_MANAGER.BASICINFO.COUNTRY_OF_CITIZENSHIP")
                  ).indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t(
                    $t("PERSONAL_MANAGER.BASICINFO.COUNTRY_OF_CITIZENSHIP")
                  ).indexOf("*") > -1
                    ? $t(
                        $t("PERSONAL_MANAGER.BASICINFO.COUNTRY_OF_CITIZENSHIP")
                      ).slice(1)
                    : $t(
                        $t("PERSONAL_MANAGER.BASICINFO.COUNTRY_OF_CITIZENSHIP")
                      )
                }}
              </p>
              <!-- 国籍 -->
              <b-form-input
                :disabled="detailChangeAbleFlg"
                class="form-input nation"
                type="text"
                name="city"
                list="listCountry"
                v-model="userDetail.nationality"
                :state="nationality"
              />
              <datalist id="listCountry">
                <option
                  :disabled="detailChangeAbleFlg"
                  v-for="(item, index) in countries"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </datalist>
            </div>

            <div v-show="alert" class="validation-message vali">
              <span
                class="validation-message"
                v-show="userDetail.nationality == ''"
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
              >
            </div>
          </div>
          <el-alert
            style="margin: 20px 0 10px"
            type="warning"
            left
            :closable="false"
            :show-icon="false"
          >
            <text-more :length="6">
              {{ $t("PERSONAL_MANAGER.NOTE.TITLE") }}
              ※{{ $t("PERSONAL_MANAGER.NOTE.TEXT1") }} ※{{
                $t("PERSONAL_MANAGER.NOTE.TEXT2")
              }}
              ※{{ $t("PERSONAL_MANAGER.NOTE.TEXT3") }} ※{{
                $t("PERSONAL_MANAGER.NOTE.TEXT4")
              }}
              ※{{ $t("PERSONAL_MANAGER.NOTE.TEXT5") }} ※{{
                $t("PERSONAL_MANAGER.NOTE.TEXT6")
              }}
            </text-more>
            <a
              href="#/details/servers/section2"
              style="color: #e6a23c; text-decoration: underline"
              >{{ $t("PERSONAL_MANAGER.NOTE.TEXT7") }}</a
            >
          </el-alert>
          <div class="p-personal__basicInfo__inner__display__title sub_form">
            <p class="sub_title">
              <span style="color: red">{{
                $t(
                  $t(
                    "PERSONAL_MANAGER.BASICINFO.IDENTITY_VERIFICATION_DOCUMENTS"
                  )
                ).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t(
                  $t(
                    "PERSONAL_MANAGER.BASICINFO.IDENTITY_VERIFICATION_DOCUMENTS"
                  )
                ).indexOf("*") > -1
                  ? $t(
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.IDENTITY_VERIFICATION_DOCUMENTS"
                      )
                    ).slice(1)
                  : $t(
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.IDENTITY_VERIFICATION_DOCUMENTS"
                      )
                    )
              }}
            </p>
            <form class="sub">
              <div class="multiselect">
                <div class="selectBox" @click="showIdentityVerification()">
                  <div class="overSelect"></div>
                </div>
                <!-- 本人確認書類 -->
                <b-form-select
                  :disabled="detailChangeAbleFlg"
                  class="form-select form-input"
                  @change="changeInfo"
                  aria-label="Default select example"
                  v-model="userDetail.mainCertificateType"
                  :state="mainCertificateType"
                >
                  <option value="0">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_IDENTITY_VERIFICATION_DOCUMENTS.OPTION_PASSPORT.name"
                      )
                    }}
                  </option>
                  <option value="1">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_IDENTITY_VERIFICATION_DOCUMENTS.OPTION_DRIVER_LICENSE.name"
                      )
                    }}
                  </option>
                  <option value="2">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_IDENTITY_VERIFICATION_DOCUMENTS.OPTION_RESIDENCE_CARD.name"
                      )
                    }}
                  </option>
                </b-form-select>
              </div>

              <div v-show="alert" class="validation-message">
                <span
                  class="validation-message"
                  v-show="userDetail.mainCertificateType == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                >
              </div>
            </form>
          </div>

          <div class="id">
            <p class="sub_title">
              <span style="color: red">{{
                $t($t("PERSONAL_MANAGER.BASICINFO.ID")).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t($t("PERSONAL_MANAGER.BASICINFO.ID")).indexOf("*") > -1
                  ? $t($t("PERSONAL_MANAGER.BASICINFO.ID")).slice(1)
                  : $t($t("PERSONAL_MANAGER.BASICINFO.ID"))
              }}
            </p>
            <!-- 本人確認書類の番号  -->
            <b-form-input
              :disabled="detailChangeAbleFlg"
              v-model="userDetail.mainCertificateCode"
              class="id_input"
              type="text"
              :placeholder="$t('PERSONAL_MANAGER.BASICINFO.PLACEHOLDER.PLA3')"
              :state="mainCertificateCode"
            />
          </div>
          <div v-show="alert" class="validation-message idvalid">
            <span
              class="validation-message"
              v-show="userDetail.mainCertificateCode == ''"
              >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div>
        </div>
        <!-- 補助書類 -->
        <div class="p-personal__basicInfo__uploadImage">
          <!-- 本人確認書類の写真1  -->
          <div
            class="p-personal__basicInfo__uploadImage__text"
            @click="callFile('image')"
            for="image"
            :style="{
              'background-image': check_text_1
                ? `url(${url1})`
                : `url(${userDetail.mainUrl1})`,
            }"
          >
            <i
              v-show="
                (!check_text_1 && userDetail.mainUrl1 == '') ||
                (!check_text_1 && userDetail.mainUrl1 == null)
              "
              class="fa fa-cloud-upload"
              style="font-size: 48px"
            ></i>
            <p
              v-show="
                (!check_text_1 && userDetail.mainUrl1 == '') ||
                (!check_text_1 && userDetail.mainUrl1 == null)
              "
            >
              {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT1.TEXT1") }}
            </p>
            <input
              :disabled="detailChangeAbleFlg"
              id="image"
              type="file"
              @change="onFileChange($event, 1)"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          <!-- 本人確認書類の写真2  -->
          <div
            class="p-personal__basicInfo__uploadImage__text"
            @click="callFile('image2')"
            for="image2"
            :style="{
              'background-image': check_text_2
                ? `url(${url2})`
                : `url(${userDetail.mainUrl2})`,
            }"
          >
            <i
              v-show="
                (!check_text_2 && userDetail.mainUrl2 == '') ||
                (!check_text_2 && userDetail.mainUrl2 == null)
              "
              class="fa fa-cloud-upload"
              style="font-size: 48px"
            ></i>
            <p
              v-show="
                (!check_text_2 && userDetail.mainUrl2 == '') ||
                (!check_text_2 && userDetail.mainUrl2 == null)
              "
            >
              {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT1.TEXT2") }}
            </p>
            <input
              :disabled="detailChangeAbleFlg"
              id="image2"
              type="file"
              @change="onFileChange($event, 2)"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
          <!-- 本人確認書類の写真3  -->
          <div
            class="p-personal__basicInfo__uploadImage__text"
            @click="callFile('image3')"
            for="image3"
            :style="{
              'background-image': check_text_3
                ? `url(${url3})`
                : `url(${userDetail.mainUrl3})`,
            }"
          >
            <i
              v-show="
                (!check_text_3 && userDetail.mainUrl3 == '') ||
                (!check_text_3 && userDetail.mainUrl3 == null)
              "
              class="fa fa-cloud-upload"
              style="font-size: 48px"
            ></i>
            <p
              v-show="
                (!check_text_3 && userDetail.mainUrl3 == '') ||
                (!check_text_3 && userDetail.mainUrl3 == null)
              "
            >
              {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT1.TEXT3") }}
            </p>
            <input
              :disabled="detailChangeAbleFlg"
              id="image3"
              type="file"
              @change="onFileChange($event, 3)"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </div>

        <div v-show="alert" class="validation-message vali1">
          <span
            class="validation-message"
            v-show="
              userDetail.mainUrl1 == null ||
              userDetail.mainUrl2 == null ||
              userDetail.mainUrl3 == null ||
              userDetail.mainUrl1 == '' ||
              userDetail.mainUrl2 == '' ||
              userDetail.mainUrl3 == ''
            "
          >
            {{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
          </span>
        </div>

        <el-alert
          style="margin: 10px 0 30px 0"
          type="warning"
          left
          :closable="false"
          :show-icon="false"
        >
          <text-more :length="20">
            {{ $t("PERSONAL_MANAGER.NOTE1.TITLE") }}
            ※{{ $t("PERSONAL_MANAGER.NOTE1.TEXT1") }} ※{{
              $t("PERSONAL_MANAGER.NOTE1.TEXT2")
            }}
            ※{{ $t("PERSONAL_MANAGER.NOTE1.TEXT3") }} ※{{
              $t("PERSONAL_MANAGER.NOTE1.TEXT4")
            }}
          </text-more>
        </el-alert>

        <!-- <div style="height: 1px; width: 100%; transform: scaleY(0.5); background: red; margin-bottom: 20px;"></div> -->

        <!-- <b-card class="note4">
          <p>{{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT") }}</p>
        </b-card> -->

        <div class="p-personal__basicInfo__inner">
          <div
            class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
          >
            <p class="sub_title" style="margin-bottom: 0 !important">
              {{
                $t(
                  $t("PERSONAL_MANAGER.BASICINFO.SUPPORTING_DOCUMENTS")
                ).indexOf("*") > -1
                  ? $t(
                      $t("PERSONAL_MANAGER.BASICINFO.SUPPORTING_DOCUMENTS")
                    ).slice(1)
                  : $t($t("PERSONAL_MANAGER.BASICINFO.SUPPORTING_DOCUMENTS"))
              }}

              <span class="sub_title-r" v-b-toggle.collapse-1
                ><i class="iconfont icon-wenbenbianji"></i
              ></span>
            </p>
          </div>
        </div>

        <b-collapse id="collapse-1">
          <div
            style="
              width: 100%;
              background-color: rgb(239 239 239 / 55%);
              margin: 15px 0;
              padding: 15px;
              border-radius: 8px;
            "
          >
            <el-alert
              style="margin-bottom: 10px"
              :closable="false"
              type="warning"
            >
              <text-more :length="20">
                {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT") }}
              </text-more>
            </el-alert>
            <form class="sub1">
              <div class="multiselect">
                <div class="selectBox" @click="showSupportDocument()">
                  <div class="overSelect"></div>
                </div>
                <!-- 補助書類 -->
                <select
                  :disabled="detailChangeAbleFlg"
                  class="form-select form-input"
                  aria-label="Default select example"
                  v-model="userDetail.otherCertificateType"
                >
                  <option value="3">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_SUPPORTING_DOCUMENTS.OPTION_HEALTH_INSURANCE_CARD.name"
                      )
                    }}
                  </option>
                  <option value="4">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_SUPPORTING_DOCUMENTS.OPTION_MY_NUMBER_CARD.name"
                      )
                    }}
                  </option>
                  <option value="0">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_SUPPORTING_DOCUMENTS.OPTION_STUDENT_CARD.name"
                      )
                    }}
                  </option>
                  <option value="1">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_SUPPORTING_DOCUMENTS.OPTION_NOTIFICATION.name"
                      )
                    }}
                  </option>
                  <option value="2">
                    {{
                      $t(
                        "PERSONAL_MANAGER.BASICINFO.SELECT_SUPPORTING_DOCUMENTS.OPTION_LANDING_CERTIFICATE.name"
                      )
                    }}
                  </option>
                </select>
              </div>
            </form>
            <div style="width: 100%; height: 10px"></div>
            <div class="p-personal__basicInfo__uploadImage">
              <div class="img">
                <!-- 補助書類url1 -->
                <div
                  class="p-personal__basicInfo__uploadImage__text"
                  @click="callFile('image4')"
                  :style="{
                    'background-image': check_text_4
                      ? `url(${url4})`
                      : `url(${userDetail.otherUrl1})`,
                  }"
                >
                  <i
                    v-show="
                      (!check_text_4 && userDetail.otherUrl1 == '') ||
                      userDetail.otherUrl1 == null
                    "
                    class="fa fa-cloud-upload"
                    style="font-size: 48px"
                  ></i>
                  <p
                    v-show="
                      (!check_text_4 && userDetail.otherUrl1 == '') ||
                      userDetail.otherUrl1 == null
                    "
                  >
                    {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT2.TEXT1") }}
                  </p>
                  <input
                    :disabled="detailChangeAbleFlg"
                    id="image4"
                    type="file"
                    @change="onFileChange($event, 4)"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
                <div class="reset">
                  <button
                    :disabled="detailChangeAbleFlg"
                    class="btn_delete"
                    @click="clear(1)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      style="margin-left: 2%"
                    />{{ $t("PERSONAL_MANAGER.DELETE") }}
                  </button>
                </div>
              </div>

              <div class="img">
                <!-- 補助書類url2 -->
                <div
                  class="p-personal__basicInfo__uploadImage__text"
                  @click="callFile('image5')"
                  :style="{
                    'background-image': check_text_5
                      ? `url(${url5})`
                      : `url(${userDetail.otherUrl2})`,
                  }"
                >
                  <i
                    v-show="
                      (!check_text_5 && userDetail.otherUrl2 == '') ||
                      userDetail.otherUrl2 == null
                    "
                    class="fa fa-cloud-upload"
                    style="font-size: 48px"
                  ></i>
                  <p
                    v-show="
                      (!check_text_5 && userDetail.otherUrl2 == '') ||
                      userDetail.otherUrl2 == null
                    "
                  >
                    {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT2.TEXT2") }}
                  </p>
                  <input
                    :disabled="detailChangeAbleFlg"
                    id="image5"
                    type="file"
                    @change="onFileChange($event, 5)"
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </div>
                <div class="reset">
                  <button
                    :disabled="detailChangeAbleFlg"
                    class="btn_delete"
                    @click="clear(2)"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash" />{{
                      $t("PERSONAL_MANAGER.DELETE")
                    }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <div v-show="alert" class="validation-message vali2">
            <span
              class="validation-message"
              v-show="
                userDetail.otherUrl1 == null ||
                userDetail.otherUrl2 == null ||
                userDetail.otherUrl1 == '' ||
                userDetail.otherUrl2 == ''
              "
              v-if="userDetail.otherCertificateType"
            >
              {{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
            >
          </div> -->

            <el-alert
              style="margin-bottom: 10px"
              :title="$t('PERSONAL_MANAGER.NOTE2.TEXT')"
              type="warning"
              :closable="false"
            >
            </el-alert>
          </div>

          <!-- <div class="note3">
          <h3>{{ $t("PERSONAL_MANAGER.NOTE2.TEXT") }}</h3>
        </div> -->
          <div
            style="
              width: 100%;
              background-color: rgb(239 239 239 / 55%);
              margin: 15px 0;
              padding: 15px;
              border-radius: 8px;
            "
          >
            <div class="">
              <!-- 変更後の連絡情報 -->
              <b-form-textarea
                style="margin: 10px 0 10px 0"
                id="textarea-rows"
                :placeholder="$t('COMPANY_MANAGER.UPDATED_CONTACT.PLACEHOLDER')"
                rows="3"
                v-model="userDetail.changeDescribe"
                trim
              ></b-form-textarea>
              <span class="focus-border"></span>
            </div>

            <el-alert
              v-if="detailChangeAbleFlg"
              :title="$t('PERSONAL_MANAGER.NOTE2.TEXT2')"
              type="warning"
              :closable="false"
            >
            </el-alert>

            <!-- <div :hidden="!detailChangeAbleFlg" class="note3">
          <h5>{{ $t("PERSONAL_MANAGER.NOTE2.TEXT2") }}</h5>
        </div> -->

            <div :hidden="!detailChangeAbleFlg" class="another-input">
              <div class="p-personal__basicInfo__uploadImage">
                <div class="img">
                  <div
                    class="p-personal__basicInfo__uploadImage__text"
                    @click="callFile('image6')"
                    for="image6"
                    :style="{
                      'background-image': check_text_6
                        ? `url(${url6})`
                        : `url(${userDetail.changeUrl1})`,
                    }"
                  >
                    <i
                      v-show="
                        (!check_text_6 && userDetail.changeUrl1 == '') ||
                        (!check_text_6 && userDetail.changeUrl1 == null)
                      "
                      class="fa fa-cloud-upload"
                      style="font-size: 48px"
                    ></i>
                    <p
                      v-show="
                        (!check_text_6 && userDetail.changeUrl1 == '') ||
                        (!check_text_6 && userDetail.changeUrl1 == null)
                      "
                    >
                      {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT2.TEXT1") }}
                    </p>
                    <input
                      id="image6"
                      type="file"
                      @change="onFileChange($event, 6)"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                  </div>
                  <div class="reset">
                    <button class="btn_delete" @click="clear(6)">
                      <font-awesome-icon icon="fa-solid fa-trash" />{{
                        $t("PERSONAL_MANAGER.DELETE")
                      }}
                    </button>
                  </div>
                </div>
                <div class="img">
                  <div
                    class="p-personal__basicInfo__uploadImage__text"
                    @click="callFile('image7')"
                    for="image7"
                    :style="{
                      'background-image': check_text_7
                        ? `url(${url7})`
                        : `url(${userDetail.changeUrl2})`,
                    }"
                  >
                    <i
                      v-show="
                        (!check_text_7 && userDetail.changeUrl2 == '') ||
                        (!check_text_7 && userDetail.changeUrl2 == null)
                      "
                      class="fa fa-cloud-upload"
                      style="font-size: 48px"
                    ></i>
                    <p
                      v-show="
                        (!check_text_7 && userDetail.changeUrl2 == '') ||
                        (!check_text_7 && userDetail.changeUrl2 == null)
                      "
                    >
                      {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT2.TEXT2") }}
                    </p>
                    <input
                      id="image7"
                      type="file"
                      @change="onFileChange($event, 7)"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                  </div>
                  <div class="reset">
                    <button class="btn_delete" @click="clear(7)">
                      <font-awesome-icon icon="fa-solid fa-trash" />{{
                        $t("PERSONAL_MANAGER.DELETE")
                      }}
                    </button>
                  </div>
                </div>
                <div class="img">
                  <div
                    class="p-personal__basicInfo__uploadImage__text"
                    @click="callFile('image8')"
                    for="image8"
                    :style="{
                      'background-image': check_text_8
                        ? `url(${url8})`
                        : `url(${userDetail.changeUrl3})`,
                    }"
                  >
                    <i
                      v-show="
                        (!check_text_8 && userDetail.changeUrl3 == '') ||
                        (!check_text_8 && userDetail.changeUrl3 == null)
                      "
                      class="fa fa-cloud-upload"
                      style="font-size: 48px"
                    ></i>
                    <p
                      v-show="
                        (!check_text_8 && userDetail.changeUrl3 == '') ||
                        (!check_text_8 && userDetail.changeUrl3 == null)
                      "
                    >
                      {{ $t("PERSONAL_MANAGER.BASICINFO.CONTENT2.TEXT2") }}
                    </p>
                    <input
                      id="image8"
                      type="file"
                      @change="onFileChange($event, 8)"
                      accept="image/x-png,image/gif,image/jpeg"
                    />
                  </div>
                  <div class="reset">
                    <button class="btn_delete" @click="clear(8)">
                      <font-awesome-icon icon="fa-solid fa-trash" />{{
                        $t("PERSONAL_MANAGER.DELETE")
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </section>

    <section>
      <div class="p-personal__contactInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("PERSONAL_MANAGER.CONTACTINFO.CONTACT_INFORMATION") }}
          </h1>
        </div>
        <div class="p-personal__contactInfo__inner">
          <div class="p-personal__contactInfo__inner__item sub_content">
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t(
                    $t("PERSONAL_MANAGER.CONTACTINFO.CONTACT_PHONE_NUMBER")
                  ).indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t(
                    $t("PERSONAL_MANAGER.CONTACTINFO.CONTACT_PHONE_NUMBER")
                  ).indexOf("*") > -1
                    ? $t(
                        $t("PERSONAL_MANAGER.CONTACTINFO.CONTACT_PHONE_NUMBER")
                      ).slice(1)
                    : $t(
                        $t("PERSONAL_MANAGER.CONTACTINFO.CONTACT_PHONE_NUMBER")
                      )
                }}
              </p>
              <div class="col-3">
                <!-- 連絡先電話番号 -->
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  @keypress="isNumber($event)"
                  @keyup="changeInfo"
                  v-model="userDetail.tel"
                  :state="tel"
                  trim
                >
                </b-form-input>
                <div v-show="alert" class="validation-message">
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="userDetail.tel == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                </div>

                <span class="focus-border"></span>
              </div>
            </div>
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t($t("PERSONAL_MANAGER.CONTACTINFO.POST_CODE")).indexOf(
                    "*"
                  ) > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t($t("PERSONAL_MANAGER.CONTACTINFO.POST_CODE")).indexOf(
                    "*"
                  ) > -1
                    ? $t($t("PERSONAL_MANAGER.CONTACTINFO.POST_CODE")).slice(1)
                    : $t($t("PERSONAL_MANAGER.CONTACTINFO.POST_CODE"))
                }}
              </p>
              <div class="col-3">
                <!-- 郵便番号  -->
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  @keyup="changeInfo"
                  v-model="userDetail.postalCode"
                  :state="postalCode"
                  trim
                ></b-form-input>
                <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="userDetail.postalCode == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div>
                <span class="focus-border"></span>
              </div>
            </div>
          </div>
          <div class="p-personal__contactInfo__inner__address sub_content">
            <p class="sub_title">
              <span style="color: red">{{
                $t($t("PERSONAL_MANAGER.CONTACTINFO.ADDRESS")).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t($t("PERSONAL_MANAGER.CONTACTINFO.ADDRESS")).indexOf("*") > -1
                  ? $t($t("PERSONAL_MANAGER.CONTACTINFO.ADDRESS")).slice(1)
                  : $t($t("PERSONAL_MANAGER.CONTACTINFO.ADDRESS"))
              }}
            </p>
            <div class="col-3">
              <!-- 住所  -->
              <b-form-textarea
                :disabled="detailChangeAbleFlg"
                class="form-input"
                @keyup="changeInfo"
                v-model="userDetail.address"
                :state="address"
                trim
              >
              </b-form-textarea>
              <div v-show="alert" class="validation-message">
                <span
                  class="validation-message"
                  v-show="userDetail.address == ''"
                  >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                >
              </div>
              <span class="focus-border"></span>
            </div>
          </div>

          <div class="p-personal__contactInfo__inner__container">
            <div
              class="p-personal__contactInfo__inner__container__emergency sub_content"
            >
              <div
                class="p-personal__contactInfo__inner__container__emergency__phone"
              >
                <p class="sub_title" style="margin-bottom: 0 !important">
                  {{
                    $t(
                      $t("PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT")
                    ).indexOf("*") > -1
                      ? $t(
                          $t("PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT")
                        ).slice(1)
                      : $t($t("PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT"))
                  }}

                  <span class="sub_title-r" v-b-toggle.collapse-2
                    ><i class="iconfont icon-wenbenbianji"></i
                  ></span>
                </p>
              </div>
            </div>

            <b-collapse id="collapse-2">
              <div
                class="p-personal__contactInfo__inner__container__emergency sub_content"
              >
                <div
                  class="p-personal__contactInfo__inner__container__emergency__phone"
                >
                  <el-alert
                    :closable="false"
                    style="margin: 0 0 20px; padding: 5px"
                    title="外国人のみ　必須"
                    type="warning"
                  >
                    <div>
                      <div>
                        日本に住所がない方（ビジネスビザ、旅行ビザなどの方）　☛日本の緊急連絡者
                      </div>
                      　
                      <div>
                        日本に住所がある方（留学ビザ、就労ビザなどの方）☛母国の緊急連絡者
                      </div>
                    </div>
                  </el-alert>
                  <div class="col-3">
                    <!-- 緊急連絡者 -->
                    <b-form-input
                      :disabled="detailChangeAbleFlg"
                      class="form-input"
                      @keyup="changeInfo"
                      v-model="userDetail.emergencyName"
                      type="text"
                      :state="null"
                      trim
                    >
                    </b-form-input>
                    <!-- <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="userDetail.emergencyName == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div> -->
                    <span class="focus-border"></span>
                  </div>
                </div>

                <div
                  class="p-personal__contactInfo__inner__container__emergency__phone"
                >
                  <p class="sub_title">
                    {{
                      $t(
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_PHONE"
                        )
                      ).indexOf("*") > -1
                        ? $t(
                            $t(
                              "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_PHONE"
                            )
                          ).slice(1)
                        : $t(
                            $t(
                              "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_PHONE"
                            )
                          )
                    }}
                  </p>
                  <!-- 緊急連絡電話 -->
                  <div class="col-3">
                    <b-form-input
                      :disabled="detailChangeAbleFlg"
                      class="form-input"
                      @keypress="isNumber($event)"
                      @keyup="changeInfo"
                      v-model="userDetail.emergencyTel"
                      type="text"
                      :state="null"
                      trim
                    >
                    </b-form-input>
                    <!-- <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="userDetail.emergencyTel == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div> -->
                    <span class="focus-border"></span>
                  </div>
                </div>
              </div>

              <div
                class="p-personal__contactInfo__inner__container__emergencyInfo sub_content"
              >
                <p class="sub_title">
                  {{
                    $t(
                      $t(
                        "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_INFORMATION"
                      )
                    ).indexOf("*") > -1
                      ? $t(
                          $t(
                            "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_INFORMATION"
                          )
                        ).slice(1)
                      : $t(
                          $t(
                            "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_CONTACT_INFORMATION"
                          )
                        )
                  }}
                </p>
                <!-- 緊急連絡者の住所  -->
                <div class="col-3">
                  <b-form-textarea
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    @keyup="changeInfo"
                    v-model="userDetail.emergencyAddress"
                    :state="null"
                    trim
                  >
                  </b-form-textarea>
                  <!-- <div v-show="alert" class="validation-message">
                  <span
                    class="validation-message"
                    v-show="userDetail.emergencyAddress == ''"
                    >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                  >
                </div> -->
                  <span class="focus-border"></span>
                </div>
              </div>

              <div
                class="p-personal__contactInfo__inner__container__relationship sub_content"
              >
                <div
                  class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
                >
                  <p class="sub_title">
                    {{
                      $t(
                        $t("PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_RELATION")
                      ).indexOf("*") > -1
                        ? $t(
                            $t(
                              "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_RELATION"
                            )
                          ).slice(1)
                        : $t(
                            $t(
                              "PERSONAL_MANAGER.CONTACTINFO.EMERGENCY_RELATION"
                            )
                          )
                    }}
                  </p>
                  <div class="col-3">
                    <!-- 続柄 -->
                    <b-form-input
                      :disabled="detailChangeAbleFlg"
                      class="form-input"
                      @keyup="changeInfo"
                      type="text"
                      v-model="userDetail.relation"
                      :state="null"
                      trim
                    ></b-form-input>
                    <!-- <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="userDetail.relation == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div> -->
                    <span class="focus-border"></span>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="p-personal__workschoolInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("PERSONAL_MANAGER.WORKSCHOOLINFO.TITLE") }}
          </h1>
        </div>
        <div class="p-personal__workschoolInfo__namephone sub_content">
          <div
            class="p-personal__contactInfo__inner__container__emergency__phone"
          >
            <p class="sub_title">
              <span style="color: red">{{
                $t(
                  $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                ).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t(
                  $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                ).indexOf("*") > -1
                  ? $t(
                      $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                    ).slice(1)
                  : $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME"))
              }}
              <span class="sub_title-r" v-b-toggle.collapse-3
                ><i class="iconfont icon-wenbenbianji"></i
              ></span>
            </p>
          </div>
        </div>
        <b-collapse id="collapse-3">
          <div class="p-personal__workschoolInfo__namephone sub_content">
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t(
                    $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                  ).indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t(
                    $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                  ).indexOf("*") > -1
                    ? $t(
                        $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME")
                      ).slice(1)
                    : $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NAME"))
                }}
                <span class="sub_title-r" v-b-toggle.collapse-3
                  ><i class="iconfont icon-wenbenbianji"></i
                ></span>
              </p>
              <div class="col-3">
                <!-- 勤務先／学校  -->
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  v-model="userDetail.workSchoolName"
                >
                </b-form-input>
                <span class="focus-border"></span>
              </div>
            </div>
            <div
              class="p-personal__contactInfo__inner__container__emergency__phone"
            >
              <p class="sub_title">
                <span style="color: red">{{
                  $t(
                    $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NUMBERPHONE")
                  ).indexOf("*") > -1
                    ? "※"
                    : ""
                }}</span>
                {{
                  $t(
                    $t("PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NUMBERPHONE")
                  ).indexOf("*") > -1
                    ? $t(
                        $t(
                          "PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NUMBERPHONE"
                        )
                      ).slice(1)
                    : $t(
                        $t(
                          "PERSONAL_MANAGER.WORKSCHOOLINFO.NAMEPHONE__NUMBERPHONE"
                        )
                      )
                }}
              </p>
              <div class="col-3">
                <!-- 勤務先／学校の電話番号 -->
                <b-form-input
                  :disabled="detailChangeAbleFlg"
                  class="form-input"
                  type="text"
                  v-model="userDetail.workSchoolTel"
                >
                </b-form-input>
                <span class="focus-border"></span>
              </div>
            </div>
          </div>
          <div
            class="p-personal__contactInfo__inner__container__emergency__phone contact-title"
          >
            <p class="sub_title">
              <span style="color: red">{{
                $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.ADDRESS")).indexOf("*") >
                -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.ADDRESS")).indexOf("*") >
                -1
                  ? $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.ADDRESS")).slice(1)
                  : $t($t("PERSONAL_MANAGER.WORKSCHOOLINFO.ADDRESS"))
              }}
            </p>

            <div class="col-3">
              <!-- 勤務先／学校の住所 -->
              <b-form-textarea
                :disabled="detailChangeAbleFlg"
                class="form-input"
                v-model="userDetail.workSchoolAddress"
              ></b-form-textarea>
              <span class="focus-border"></span>
            </div>
          </div>
        </b-collapse>
      </div>
    </section>

    <section>
      <div class="p-personal__otherInfo">
        <div class="title-x">
          <img
            class="image_2"
            src="@/assets/lxl/SketchPng78fcf108f2468db967576cab93d2f1a0081457c1ac8710c957c913827840a09c.png"
          />
          <h1 class="text_17">
            {{ $t("PERSONAL_MANAGER.OTHERINFO.TITLE") }}
          </h1>
        </div>
        <div class="p-personal__otherInfo__SNS">
          <div class="p-personal__otherInfo__SNS__Advertisement">
            <p>{{ $t("PERSONAL_MANAGER.OTHERINFO.ADVERTISEMENT") }}</p>
            <!-- Checkbox SNS -->
            <div class="p-personal__otherInfo__SNS__Advertisement__content">
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <!-- IF Mobileをどこで知りましたか -->
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.REFER_A_FRIEND"
                  v-model="heardCode"
                  v-on:click="setName($CONST.BUYER.INFORMATION.REFER_A_FRIEND)"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_REFER_A_FRIEND.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.SCHOOL_INTRODUCTION"
                  v-model="heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_SCHOOL_INTRODUCTION.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.STUDY_ABROAD"
                  v-model="heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_INTRODUCTION.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.FROM_OTHER_WEBSITES"
                  v-model="heardCode"
                  @click="
                    resetFormCt($CONST.BUYER.INFORMATION.FROM_OTHER_WEBSITES)
                  "
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_INTRODUCED_FROM.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    type="text"
                    v-model="userDetail.heardName"
                  />
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.FACEBOOK_ADS"
                  v-model="heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_FACEBOOK.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.YOUTUBE_ADS"
                  v-model="heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_YOUTUBE.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.GOOGLE_SEARCH"
                  v-model="heardCode"
                  v-on:click="setName()"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_GOOGLE.name"
                    )
                  }}
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.OTHER_ADVERTISEMENTS"
                  v-model="heardCode"
                  @click="
                    resetFormCt($CONST.BUYER.INFORMATION.OTHER_ADVERTISEMENTS)
                  "
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_OTHER_ADVERTISEMENTS.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    v-model="userDetail.heardName"
                    type="text"
                  />
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="group1"
                  :value="$CONST.BUYER.INFORMATION.OTHERS"
                  v-model="heardCode"
                  @click="resetFormCt($CONST.BUYER.INFORMATION.OTHERS)"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{
                    $t(
                      "PERSONAL_MANAGER.OTHERINFO.CHECKBOX__ROUNDED.CHECKBOX_OTHERS.name"
                    )
                  }}
                  <input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    v-model="userDetail.heardName"
                    type="text"
                  />
                </div>
              </label>
            </div>
            <div v-show="alert" class="validation-message">
              <span
                class="validation-message"
                v-show="heardCode === null || heardCode === ''"
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}
              </span>
            </div>
            <div v-show="alert" v-if="heardCode" class="validation-message">
              <span
                class="validation-message"
                v-show="
                  userDetail.heardName === null || userDetail.heardName === ''
                "
                >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
              >
            </div>
          </div>

          <div class="p-personal__otherInfo__SNS__Advertisement">
            <p>{{ $t("PERSONAL_MANAGER.OTHERINFO.USE.TITLE") }}</p>
            <!-- Checkbox SNS -->
            <div class="">
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
                v-for="(item, index) in options"
                :key="index"
              >
                <!-- どんなSNSを使っていますか？ -->
                <input
                  :disabled="detailChangeAbleFlg"
                  class="item"
                  type="radio"
                  name="contact"
                  v-model="userDetail.toolCode"
                  @click="setContract(item.value)"
                  :value="item.value"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover'
                      : 'btn-radio-layout'
                  "
                >
                  {{ item.text }}
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="form-input"
                    placeholder=""
                    type="text"
                    v-model="userDetail.toolAccount"
                  >
                  </b-form-input>
                </div>
              </label>
              <label
                :class="
                  detailChangeAbleFlg
                    ? 'radio-layout un-cursor'
                    : 'radio-layout'
                "
              >
                <input
                  :disabled="detailChangeAbleFlg"
                  type="radio"
                  name="contact"
                  v-model="userDetail.toolCode"
                  class="item"
                  @click="setContract(9)"
                  value="9"
                />
                <div
                  :class="
                    detailChangeAbleFlg
                      ? 'btn-radio-layout unhover sns'
                      : 'btn-radio-layout sns'
                  "
                >
                  {{ $t("INQUIRY.SNS") }}
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="sns-input"
                    v-show="userDetail.toolCode == 9"
                    :placeholder="$t('INQUIRY.SNS_NAME')"
                    type="text"
                    v-model="userDetail.toolName"
                  >
                  </b-form-input>
                  <b-form-input
                    :disabled="detailChangeAbleFlg"
                    class="form-input sns-input"
                    type="text"
                    :placeholder="$t('INQUIRY.SNS_ID')"
                    v-model="userDetail.toolAccount"
                  >
                  </b-form-input>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="card-x">
        <div
          class="p-personal__contactInfo__inner__container__relationship sub_content"
        >
          <div class="foot-content" v-if="showCode">
            <p class="sub_title">
              <span style="color: red">{{
                $t($t("PERSONAL_MANAGER.REFERRAL_CODE")).indexOf("*") > -1
                  ? "※"
                  : ""
              }}</span>
              {{
                $t($t("PERSONAL_MANAGER.REFERRAL_CODE")).indexOf("*") > -1
                  ? $t($t("PERSONAL_MANAGER.REFERRAL_CODE")).slice(1)
                  : $t($t("PERSONAL_MANAGER.REFERRAL_CODE"))
              }}
            </p>
            <div class="col-3">
              <!-- 紹介コード -->
              <b-form-input
                :disabled="detailChangeAbleFlg"
                class="form-input"
                @keypress="isNumber($event)"
                v-model="userDetail.introduceCode"
                :placeholder="$t('PERSONAL_MANAGER.BASICINFO.PLACEHOLDER.PLA4')"
              >
              </b-form-input>
              <span class="focus-border"></span>
            </div>
          </div>
        </div>

        <div
          class="p-personal__contactInfo__inner__container__relationship sub_content"
        >
          <div class="foot-content">
            <p class="sub_title">
              {{
                $t(
                  $t("PERSONAL_MANAGER.CONTACTINFO.PREFERRED_LANGUAGE")
                ).indexOf("*") > -1
                  ? $t(
                      $t("PERSONAL_MANAGER.CONTACTINFO.PREFERRED_LANGUAGE")
                    ).slice(1)
                  : $t($t("PERSONAL_MANAGER.CONTACTINFO.PREFERRED_LANGUAGE"))
              }}
            </p>
            <div class="col-3">
              <form>
                <div class="multiselect">
                  <div class="selectBox" @click="showLanguage()">
                    <div class="overSelect"></div>
                  </div>
                  <!-- 希望言語 -->
                  <b-form-select
                    :disabled="detailChangeAbleFlg"
                    class="form-select"
                    aria-label="Default select example"
                    v-model="hopeLanguage"
                    :state="hopeLanguage"
                  >
                    <option value="0">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_JAPANESE.name"
                        )
                      }}
                    </option>
                    <option value="1">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_ENGLISH.name"
                        )
                      }}
                    </option>
                    <option value="2">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_CHINESE.name"
                        )
                      }}
                    </option>
                    <option value="3">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_VIETNAMESE.name"
                        )
                      }}
                    </option>
                    <option value="4">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_KOREAN.name"
                        )
                      }}
                    </option>
                    <option value="5">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_SRILANKAN.name"
                        )
                      }}
                    </option>
                    <option value="6">
                      {{
                        $t(
                          "PERSONAL_MANAGER.CONTACTINFO.SELECT_PREFERRED_LANGUAGE.CHECKBOX_NEVALA.name"
                        )
                      }}
                    </option>
                  </b-form-select>
                  <div v-show="alert" class="validation-message">
                    <span
                      class="validation-message"
                      v-show="hopeLanguage == ''"
                      >{{ $t("COMMON.ITEM_TYPE.VALIDATE") }}</span
                    >
                  </div>
                </div>
              </form>
              {{ errOption }}
            </div>
          </div>
        </div>
      </div>
      <button :disabled="checkError" v-on:click="btnSubmit(heardCode)">
        {{ $t("PERSONAL_MANAGER.BTN") }}
      </button>
    </section>
  </div>
</template>

<script>
import states from "@/assets/json/country.json";
import date from "@/assets/json/date.json";
import moment from "moment";
export default {
  data() {
    return {
      hopeLanguage: "0",
      heardCode: 0,
      listYear: date,
      getDay: "",
      getMonth: "",
      getYear: "",
      testImage: "",
      showCode: false,
      showErr: false,
      alert: false,
      checkError: false,
      data: states,
      expanded: false,
      url2: null,
      url3: null,
      url4: null,
      url5: null,
      url6: null,
      url7: null,
      url8: null,
      check_text_1: false,
      check_text_2: false,
      check_text_3: false,
      check_text_4: false,
      check_text_5: false,
      check_text_6: false,
      check_text_7: false,
      check_text_8: false,
      userDetail: {
        mainCertificateCode: "",
        address: "",
        birthday: "",
        changeDescribe: "",
        emergencyAddress: "",
        emergencyName: "",
        emergencyTel: "",
        heardCode: "",
        heardName: "",
        hopeLanguage: "0",
        introduceCode: "",
        mainCertificateType: "",
        mainUrl1: "",
        mainUrl2: "",
        mainUrl3: "",
        nationality: "",
        otherCertificateType: "",
        otherUrl1: "",
        otherUrl2: "",
        changeUrl1: "",
        changeUrl2: "",
        changeUrl3: "",
        postalCode: "",
        relation: "",
        sex: "",
        tel: "",
        toolAccount: "",
        toolCode: null,
        toolName: "",
        userId: "",
        workSchoolAddress: "",
        workSchoolName: "",
        workSchoolTel: "",
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
      },
      detail: [],
      countries: [],
      validate: {
        workSchoolAddress: null,
        workSchoolTel: null,
        workSchoolName: null,
        tel: null,
        postalCode: null,
        address: null,
        emergencyName: null,
        emergencyTel: null,
        emergencyAddress: null,
        heardCode: null,
        heardName: null,
        relation: null,
        hopeLanguage: "0",
        nationality: null,
        mainCertificateType: null,
        sex: null,
        birthday: null,
      },
      isClicked: false,
      errOption: "",
      infoChange: false,
      detailChangeAbleFlg: false,
    };
  },
  created() {
    this.countries = this.data.countries;
    this.options = this.$CONST.BUYER.WHICH_SNS;
    this.getData();
    this.options = this.$CONST.BUYER.WHICH_SNS;
  },
  computed: {
    tel() {
      if (this.isClicked) {
        if (this.userDetail.tel == null) {
          return false;
        }
        return this.userDetail.tel.length > 0;
      }
    },
    mainCertificateCode() {
      if (this.isClicked) {
        if (this.userDetail.mainCertificateCode == null) {
          return false;
        }
        return this.userDetail.mainCertificateCode.length > 0;
      }
    },
    postalCode() {
      if (this.isClicked) {
        if (this.userDetail.postalCode == null) {
          return false;
        }
        return this.userDetail.postalCode.length > 0;
      }
    },
    address() {
      if (this.isClicked) {
        if (this.userDetail.address == null) {
          return false;
        }
        return this.userDetail.address.length > 0;
      }
    },
    emergencyName() {
      if (this.isClicked) {
        if (this.userDetail.emergencyName == null) {
          return false;
        }
        return this.userDetail.emergencyName.length > 0;
      }
    },
    emergencyTel() {
      if (this.isClicked) {
        if (this.userDetail.emergencyTel == null) {
          return false;
        }
        return this.userDetail.emergencyTel.length > 0;
      }
    },
    emergencyAddress() {
      if (this.isClicked) {
        if (this.userDetail.emergencyAddress == null) {
          return false;
        }
        return this.userDetail.emergencyAddress.length > 0;
      }
    },
    relation() {
      if (this.isClicked) {
        if (this.userDetail.relation == null) {
          return false;
        }
        return this.userDetail.relation.length > 0;
      }
    },
    hopeLanguage() {
      if (this.isClicked) {
        if (this.hopeLanguage == null) {
          return false;
        }
        return this.hopeLanguage.length > 0;
      }
    },
    nationality() {
      if (this.isClicked) {
        if (this.userDetail.nationality == null) {
          return false;
        }
        return this.userDetail.nationality.length > 0;
      }
    },
    mainCertificateType() {
      if (this.isClicked) {
        if (this.userDetail.mainCertificateType == null) {
          return false;
        }
        return this.userDetail.mainCertificateType.length > 0;
      }
    },
    sex() {
      if (this.isClicked) {
        if (this.userDetail.sex == null) {
          return false;
        }
        return this.userDetail.sex.length > 0;
      }
    },
    birthday() {
      if (this.isClicked) {
        if (this.getDay == null) {
          return false;
        }
        if (this.getYear == null) {
          return false;
        }
        if (this.getMonth == null) {
          return false;
        }
        this.validate.birthday =
          this.getDay.length > 0 &&
          this.getYear.length > 0 &&
          this.getMonth.length > 0;
        return this.validate.birthday;
      }
    },
    firstName() {
      if (this.isClicked) {
        if (this.userDetail.firstName == null) {
          return false;
        }
        return this.userDetail.firstName.length > 0;
      }
    },
    firstNameKana() {
      if (this.isClicked) {
        if (this.userDetail.firstNameKana == null) {
          return false;
        }
        return this.userDetail.firstNameKana.length > 0;
      }
    },
    lastName() {
      if (this.isClicked) {
        if (this.userDetail.lastName == null) {
          return false;
        }
        return this.userDetail.lastName.length > 0;
      }
    },
    lastNameKana() {
      if (this.isClicked) {
        if (this.userDetail.lastNameKana == null) {
          return false;
        }
        return this.userDetail.lastNameKana.length > 0;
      }
    },
  },
  methods: {
    checkInputText: function () {
      debugger;
      if (
        this.userDetail.mainCertificateCode == null ||
        this.userDetail.mainCertificateCode == "" ||
        this.userDetail.tel == "" ||
        this.userDetail.tel == null ||
        this.userDetail.postalCode == "" ||
        this.userDetail.postalCode == null ||
        // this.userDetail.emergencyName == "" ||
        // this.userDetail.emergencyName == null ||
        // this.userDetail.emergencyTel == "" ||
        // this.userDetail.emergencyTel == null ||
        // this.userDetail.emergencyAddress == "" ||
        // this.userDetail.emergencyAddress == null ||
        // this.heardCode === "" ||
        // this.heardCode === null ||
        this.userDetail.mainUrl1 == "" ||
        this.userDetail.mainUrl1 == null ||
        this.userDetail.mainUrl2 == "" ||
        this.userDetail.mainUrl2 == null ||
        this.userDetail.mainUrl3 == "" ||
        this.userDetail.mainUrl3 == null ||
        // this.userDetail.relation == "" ||
        // this.userDetail.relation == null ||
        // this.hopeLanguage == "" ||
        // this.hopeLanguage == null ||
        this.userDetail.nationality == "" ||
        this.userDetail.nationality == null ||
        this.userDetail.mainCertificateType == "" ||
        this.userDetail.mainCertificateType == null ||
        this.userDetail.birthday == "" ||
        this.userDetail.birthday == null ||
        this.userDetail.sex == "" ||
        this.userDetail.sex == null ||
        this.userDetail.address == null ||
        this.userDetail.address == "" ||
        // this.userDetail.heardName == "" ||
        // this.userDetail.heardName == null ||
        this.userDetail.lastName == "" ||
        this.userDetail.lastName == null ||
        // this.userDetail.lastNameKana == "" ||
        // this.userDetail.lastNameKana == null ||
        this.userDetail.firstName == "" ||
        this.userDetail.firstName == null
        // this.userDetail.firstNameKana == "" ||
        // this.userDetail.firstNameKana == null
      ) {
        // if (
        //    //補助書類
        //   this.userDetail.otherCertificateType !== null &&
        //   this.userDetail.otherCertificateType !== ""
        // ) {
        //   if (
        //     //補助書類写真1
        //     this.userDetail.otherUrl1 == "" ||
        //     this.userDetail.otherUrl1 == null ||
        //     //補助書類写真2
        //     this.userDetail.otherUrl2 == "" ||
        //     this.userDetail.otherUrl2 == null
        //   ) {
        //     this.alert = true;
        //     return false;
        //   }
        // }
        this.alert = true;
        return false;
      } else {
        // if (
        //   //補助書類
        //   this.userDetail.otherCertificateType !== null &&
        //   this.userDetail.otherCertificateType !== ""
        // ) {
        //   if (
        //     //補助書類写真1
        //     this.userDetail.otherUrl1 == "" ||
        //     this.userDetail.otherUrl1 == null ||
        //     //補助書類写真2
        //     this.userDetail.otherUrl2 == "" ||
        //     this.userDetail.otherUrl2 == null
        //   ) {
        //     this.alert = true;
        //     return false;
        //   } else {
        //     this.alert = false;
        //     return true;
        //   }
        // } else {
        //   this.alert = false;
        //   return true;
        // }
        this.alert = false;
        return true;
      }
    },
    dateTime(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    btnSubmit() {
      this.isClicked = true;
      this.userDetail.birthday =
        this.getMonth + "/" + this.getDay + "/" + this.getYear;
      var payLoad = {
        mainCertificateCode: this.userDetail.mainCertificateCode,
        address: this.userDetail.address,
        birthday: this.dateTime(Date.parse(this.userDetail.birthday)),
        changeDescribe: this.userDetail.changeDescribe,
        emergencyAddress: this.userDetail.emergencyAddress,
        emergencyName: this.userDetail.emergencyName,
        emergencyTel: this.userDetail.emergencyTel,
        heardCode: this.heardCode,
        heardName: this.userDetail.heardName,
        hopeLanguage: this.hopeLanguage,
        introduceCode: this.userDetail.introduceCode,
        mainCertificateType: this.userDetail.mainCertificateType,
        mainUrl1: this.userDetail.mainUrl1,
        mainUrl2: this.userDetail.mainUrl2,
        mainUrl3: this.userDetail.mainUrl3,
        nationality: this.userDetail.nationality,
        otherCertificateType: this.userDetail.otherCertificateType,
        otherUrl1: this.userDetail.otherUrl1,
        otherUrl2: this.userDetail.otherUrl2,
        postalCode: this.userDetail.postalCode,
        relation: this.userDetail.relation,
        sex: this.userDetail.sex,
        tel: this.userDetail.tel,
        toolAccount: this.userDetail.toolAccount,
        toolName: this.userDetail.toolName,
        toolCode: this.userDetail.toolCode,
        userId: this.userDetail.userId,
        workSchoolAddress: this.userDetail.workSchoolAddress,
        workSchoolName: this.userDetail.workSchoolName,
        workSchoolTel: this.userDetail.workSchoolTel,
        firstName: this.userDetail.firstName,
        lastName: this.userDetail.lastName,
        firstNameKana: this.userDetail.firstNameKana,
        lastNameKana: this.userDetail.lastNameKana,
        changeUrl1: this.userDetail.changeUrl1,
        changeUrl2: this.userDetail.changeUrl2,
        changeUrl3: this.userDetail.changeUrl3,
        workType: 0,
        detailChangeAbleFlg: 1,
      };
      var check = this.checkInputText();
      if (check) {
        if (this.$CONST.DEV) {
          console.log("Debug API: Mypage/OK ", payLoad);
          this.changePermission();
          if (this.$localStorage.get("pageBack") == "/order") {
            this.$router.push(this.$localStorage.get("pageBack"));
          } else {
            this.$localStorage.set("pageBack", "/home");
            this.$router.push({ path: "/success", query: { view: "0" } });
          }
        } else {
          this.$api.post("personalDetail/save", payLoad).then((response) => {
            this.toTop();
            if (response.success) {
              if (this.$localStorage.get("pageBack") == "/order") {
                this.$router.push(this.$localStorage.get("pageBack"));
                this.$localStorage.remove("pageBack");
              } else {
                this.$localStorage.set("pageBack", "/home");
                this.$router.push({
                  path: "/success",
                  query: { view: "0", suc: "3" },
                });
              }
            } else {
              this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.FAIL"), {
                title: `Errors`,
                solid: true,
                variant: `danger`,
              });
            }
          });
        }
      } else {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `warning`,
        });
      }
    },

    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    getData() {
      if (this.$CONST.DEV) {
        this.userDetail = this.$CONST.COMMON.API_TEMP.Response.result;
      } else {
        this.$api
          .post("personalDetail/init", {
            userId: window.localStorage.getItem("userIdCode"),
          })
          .then((response) => {
            if (response.result != null) {
              this.userDetail = response.result;
              this.CurrentUser = response.result;
              this.getMonth = response.result.month;
              this.getDay = response.result.day;
              this.getYear = response.result.year;
              if (response.result.detailChangeAbleFlg == 1) {
                this.detailChangeAbleFlg = true;
              } else {
                this.detailChangeAbleFlg = false;
              }
              if (response.result.introduceCodeChangeAbleFlg == false) {
                this.showCode = false;
              } else this.showCode = true;
            }
          });
      }
    },

    setContract(value) {
      if (this.userDetail.toolCode != value) {
        this.userDetail.toolName = "";
        this.userDetail.toolAccount = "";
      }
    },
    resetFormCt(value) {
      if (this.heardCode != value) {
        this.userDetail.heardName = "";
      }
    },
    onFileChange(e, num) {
      const file = e.target.files[0];
      if (num == 1) {
        this.check_text_1 = true;
        let render = new FileReader();
        this.url1 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.mainUrl1 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 2) {
        this.check_text_2 = true;
        let render = new FileReader();
        this.url2 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.mainUrl2 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 3) {
        this.check_text_3 = true;
        let render = new FileReader();
        this.url3 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.mainUrl3 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 4) {
        this.check_text_4 = true;
        let render = new FileReader();
        this.url4 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.otherUrl1 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 5) {
        this.check_text_5 = true;
        let render = new FileReader();
        this.url5 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.otherUrl2 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 6) {
        this.check_text_6 = true;
        let render = new FileReader();
        this.url6 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.changeUrl1 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 7) {
        this.check_text_7 = true;
        let render = new FileReader();
        this.url7 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.changeUrl2 = render.result;
        };
        render.readAsDataURL(file);
      }
      if (num == 8) {
        this.check_text_8 = true;
        let render = new FileReader();
        this.url8 = URL.createObjectURL(file);
        render.onloadend = (file) => {
          this.userDetail.changeUrl3 = render.result;
        };
        render.readAsDataURL(file);
      }
    },
    clear(index) {
      if (index == 1) {
        this.url4 = "";
        this.userDetail.otherUrl1 = "";
        this.check_text_4 = false;
      } else if (index == 2) {
        this.url5 = "";
        this.userDetail.otherUrl2 = "";
        this.check_text_5 = false;
      } else if (index == 6) {
        this.url6 = "";
        this.userDetail.changeUrl1 = "";
        this.check_text_6 = false;
      } else if (index == 7) {
        this.url7 = "";
        this.userDetail.changeUrl2 = "";
        this.check_text_7 = false;
      } else if (index == 8) {
        this.url8 = "";
        this.userDetail.changeUrl3 = "";
        this.check_text_8 = false;
      }
    },
    callFile(id) {
      document.getElementById(id)?.click();
    },
    setName() {
      this.userDetail.heardName = "none";
    },
    changeInfo() {
      this.userDetail.changeDescribe = "";
      this.infoChange = true;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-control,
select {
  border-radius: 4px !important;
  font-size: 13px;
  height: 40px;
  max-height: 40px !important;
}

.p-personal__otherInfo__SNS__Advertisement {
  font-size: 13px;

  p {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .btn-radio-layout {
    border-radius: 4px !important;
    font-size: 13px;
    margin-bottom: 10px !important;
  }
}

.p-personal__basicInfo__uploadImage__text {
  p {
    font-size: 13px !important;
  }
}

.sns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  display: flex;

  .title-head {
    margin: 15px 0 20px 315px;
  }

  &_title {
    font-weight: 100;
    font-size: 16px;
    line-height: 28px;
    color: var(--newColor);
    font-family: "Noto Sans JP", sans-serif;
    // margin-left: 55px;
    font-weight: 600;
  }

  &_main {
    display: flex;
    width: 50%;

    &__first-title {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }
}

.birthday {
  display: flex;
  // gap: 10px;

  .year,
  .month,
  .day {
    display: flex;

    select {
      border-radius: 6px;
      height: 35px;
      margin: auto;
    }

    p {
      margin: auto;
      font-size: 13px;
    }
  }

  p {
    margin: auto;
    padding-right: 10px;
    padding-left: 5px;
  }
}

.img {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.note1,
.note2,
.note3,
.note4 {
  h3 {
    font-size: 17px;
    font-weight: 400;
  }

  p {
    font-size: 13px;
    line-height: 25px;
    // margin-bottom: 5px;

    a {
      color: red;
    }
  }
}

.note3 {
  h3 {
    color: red;
  }
}

.id {
  // width: 50%;
  display: flex;
  align-items: center;
}

.reset {
  text-align: center;
}

.foot-content {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  // // gap: 14px;
  align-items: center;
}

.radio-layout {
  width: calc(80% - 30px);
  text-align: left;
}

.btn-radio-layout {
  font-size: 16px;
  padding: 5px 10px;
  margin-top: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub,
.sub1 {
  width: 100% !important;
}

.sub {
  // margin-left: 15px;
}

.unhover {
  box-shadow: none !important;
  color: black !important;
}

.un-cursor {
  cursor: unset !important;
}
.p-personal__basicInfo {
  text-align: left;
  // margin-bottom: 90px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  .sub__image {
    padding-left: 30px;
  }

  &__inner {
    display: flex;
    align-items: center;
    // margin-top: 2%;

    &__display {
      &__title {
        display: flex;
        align-items: center;
        width: 50%;
        // margin: 15px 0;
      }
    }

    &__item {
      display: flex;
      // // gap: 14px;
      width: 100%;

      &--gender {
        display: flex;
        // gap: 15%;
        width: 100%;

        input {
          // margin: 0 12px;
        }
      }
    }
  }
}

.p-personal__basicInfo__uploadImage {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // gap: 20px;

  &__text {
    width: 296px;
    max-height: 200px;
    min-height: 200px;
    padding: 5% 10px;
    flex-direction: column;
    justify-content: space-around;
    background-color: #f8f1ee;
    border: 1px solid #806c6c;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      display: flex;
      text-align: center;
      font-size: 15px;
      color: var(--newColor);
      line-height: 1.2rem;
    }

    input {
      display: none;
    }

    img {
      width: 30%;
    }
  }
}

.form-select {
  height: 35px;
  color: #816052;
  padding: 0px;
  width: 100%;
}

.form-input {
  width: 100%;
}

.necessary_text {
  font-size: 35px;
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-left: 0px;
  width: 30px;
  // margin-top: 1%;
}

.p-personal__contactInfo {
  text-align: left;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    &__title {
      display: flex;
      align-items: center;
      // margin: 4% 0 2% 5%;

      &__item {
        width: 0;
        height: 20px;
        border: 10px solid #f35f0d;
      }
    }

    &__item {
      display: flex;

      &__phoneNumber {
        display: flex;
        // // gap: 14px;
        width: 50%;

        input {
          width: 40%;
        }
      }

      &__postCode {
        display: flex;
        // // gap: 14px;
        width: 50%;

        input {
          width: 40%;
        }
      }
    }

    &__address {
      display: flex;
      // // gap: 14px;

      textarea {
        height: 70px;
        width: 100% !important;
      }
    }

    &__container {
      display: flex;
      // gap: 10px;
      flex-direction: column;

      &__emergency {
        display: flex;

        &__contact {
          display: flex;
          width: 50%;
        }

        &__phone {
          display: flex;
          // gap: 14px;
          width: 50%;
        }
      }

      &__emergencyInfo {
        display: flex;
        // gap: 14px;
      }

      &__relationship {
        display: flex;

        &__person {
          display: flex;
          // gap: 14px;
          width: 50%;
          height: fit-content;

          input {
            width: 40%;
            min-width: 120px;
          }
        }

        &__language {
          display: flex;
          // gap: 14px;
          width: 50%;
          // margin: 0 0 8% 10%;

          p {
            text-align: left;
            width: 150px;
          }

          input {
            width: 40%;
          }
        }
      }
    }
  }
}

.p-personal__workschoolInfo {
  text-align: left;
  // margin: 60px 0 60px 60px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  display: flex;
  // gap: 10px;
  flex-direction: column;

  &__namephone {
    display: flex;

    &__name {
      display: flex;
      // gap: 14px;
      width: 50%;

      input {
        width: 40%;
      }
    }

    &__numberphone {
      display: flex;
      // gap: 14px;
      width: 50%;

      input {
        width: 40%;
      }
    }
  }

  &__address {
    display: flex;
    // gap: 14px;
  }
}

.p-personal__otherInfo {
  text-align: left;
  // margin-bottom: 90px;

  h1 {
    font-family: "Noto Sans JP", sans-serif;
    font-style: normal;
    // font-weight: 400;
    // line-height: 44px;
  }

  &__SNS {
    display: flex;
    justify-content: space-around;

    &__Advertisement {
      width: 50%;

      &__content {
        display: flex;
        flex-direction: column;
        // row-gap: 5px;
      }
    }

    p {
      color: #f35f0d;
    }
  }
}

button {
  text-align: center;
  padding: 7px 14px;
  width: 12%;
  height: 54px;
  background: var(--newColor);
  border-color: var(--newColor);
  color: #ffffff;
  font-size: 20px;
  line-height: 18px;
  border-radius: 6px;
  margin: auto;
  // margin-bottom: 50px;
}

button:hover {
  background-color: var(--success);
  border-color: var(--success);
}

.sub_title {
  font-weight: 100;
  font-size: 16px;
  line-height: 28px;
  color: var(--newColor);
  // margin-bottom: 2%;
  min-width: 230px;
  text-align: right;
  margin: auto 0px;
  font-family: "Noto Sans JP", sans-serif;
}

.pdf_choose {
  min-width: 220px;
}

.sub_inputText {
  width: 60% !important;
}

.b-form-btn-label-control.form-control {
  width: 60%;
}

// Selected checkbox
.multiselect {
  position: relative;
}

.drop-down {
  display: block;
  position: absolute;
  z-index: 100;
  background-color: white;
  width: 100%;
}

.selectBox {
  position: relative;
}

.selectBox select {
  height: 35px;
  font-size: 14px;
  color: #9a9a9a;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#showIdentityVerification {
  display: none;
  border: 1px #dadada solid;
}

#showIdentityVerification label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#showIdentityVerification label:hover {
  background-color: #f8f1ee;
}

//  Checkbox Support Documents
#supportDocument {
  display: none;
  border: 1px #dadada solid;
}

#supportDocument label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#supportDocument label:hover {
  background-color: #f8f1ee;
}

// Checkbox show language
#showLanguage {
  display: none;
  border: 1px #dadada solid;
}

#showLanguage label {
  display: flex;
  // gap: 14px;
  padding: 4px 16px;
}

#showLanguage label:hover {
  background-color: #f8f1ee;
}

.sub_form form {
  width: 60%;
}

$wrapper-max-width: 400px;
$mb-breakpoint: 600px;
$breakpoint: 860px;
$large-breakpoint: 1100px;
$xl-breakpoint: 1920px;

body {
  font-family: "Noto Sans JP", sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
}

h1 {
  font-family: "Noto Sans JP", sans-serif;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

hr {
  // margin: 40px auto;
  max-width: 100px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.pen-intro {
  text-align: center;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Input
:focus {
  outline: none;
}

.col-3 {
  float: left;
  position: relative;
  flex: calc(100% - 214px);
  max-width: 100%;
  padding: unset;
}

.form-select {
  padding-left: 12px;
  border: none;
  border-bottom: 1px solid;
}

.radio-layout input:checked + .btn-radio-layout input {
  display: block;
}

.radio-layout input:checked + .btn-radio-layout {
  color: var(--success) !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "./index.response.scss";

@media (max-width: 768px) {
  @import "./index.response.m.scss";
}
</style>
