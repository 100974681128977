<template>
  <div>
    <section class="p-form-login">
      <div>

        <div class="p-form-login__logo">
          <h3>{{ $t("REGISTER.TITLE") }}</h3>
        </div>

        <div class="p-form-login__input-area">

          <div class="p-form-login__input-area__radio">
            <b-form-radio name="some-radios" @change="checkradio(0)" value="0" v-model="register.accountBy">{{
                $t("REGISTER.INDIVIDUAL")
            }} </b-form-radio>
            <b-form-radio name="some-radios" @change="checkradio(1)" value="1" v-model="register.accountBy">{{
                $t("REGISTER.CORPORATION")
            }} </b-form-radio>
          </div>

          <b-input-group size="lg" class="code">
            <b-form-input id="input-email" type="email" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH0')"
              :state="emailState" v-model="register.email" name="email" v-on:input="checkInputMail()" />

            <b-input-group-prepend>
              <b-button variant="success" type="button" v-on:click="sendMail()"
                class="p-form-login__input-area__btn-mail" :disabled="counting">
                <p v-if="counting" :time="60000" @end="onCountdownEnd">{{ timerCount }}</p>
                <span v-else>{{ $t("REGISTER.SEND_VERIFICATION_CODE") }}</span>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
          <div class="validation-message">
                      <span class="validation-message" v-show="show">{{ $t("FORGET_PASSWORD.VALIDATE_MAIL") }}</span>
                  </div>
          <div v-if="isViewInput" class="p-form-register__form--input">

            <b-input-group size="lg" class="code">
              <b-form-input id="input-email" type="email" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH1')"
                v-model="register.smscode" name="code" :state="smscodeState" />
            </b-input-group>
            <div v-show="alert" class="validation-message">
              <span class="validation-message" v-show="register.smscode == ''">{{ $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>

            <b-input-group size="lg" class="code">
              <b-form-input id="input-valid" type="text" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH2')"
                v-model="register.realname" :state="realnameState">
              </b-form-input>
            </b-input-group>
            <div v-show="alert" class="validation-message">
              <span class="validation-message" v-show="register.realname == ''">{{ $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>

            <b-input-group size="lg" class="code">
              <b-form-input id="input-valid" type="text" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH3')"
                v-model="register.realnameLower" :state="realnameLowerState">
              </b-form-input>
            </b-input-group>
            <div v-show="alert" class="validation-message">
              <span class="validation-message" v-show="register.realnameLower == ''">{{ $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>

            <b-input-group size="lg" class="code">
              <b-form-input id="input-pass" type="password" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH4')"
                v-model="register.password" :state="passwordState">
              </b-form-input>
            </b-input-group>
            <div v-show="alert" class="validation-message">
              <span class="validation-message" v-show="register.password == ''">{{ $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>

            <b-input-group size="lg" class="code">
              <b-form-input id="input-valid" type="password" :placeholder="$t('REGISTER.PLACE_HOLDER.PLH5')"
                v-model="register.repassword" :state="repasswordState">
              </b-form-input>
            </b-input-group>
            <div v-show="alert" class="validation-message">
              <span class="validation-message" v-show="register.repassword == ''">{{ $t("COMMON.ITEM_TYPE.VALIDATE")
              }}</span>
            </div>

          </div>
          <div class="message">
            {{mesErorr}}
          </div>
        </div>
      </div>

      <div class="p-form-login__loginbutton-area">
        <b-button variant="success" class="p-form-register__form--input__btn" v-on:click="btnSubmit()">{{
            $t("REGISTER.BUTTON_RESGITER")
        }}</b-button>
        <b-button variant="success" class="p-form-register__form--input__btn btn1" @click="goToHome"> {{ $t("BUTTON.CANCEL")
        }}</b-button>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  setup() { },
  data() {
    return {
      messMail: "",
      alert: false,
      errors: "",
      errorsMail: "",
      counting: false,
      timerCount: 0,
      radio_click: 0,
      detail: [],
      isViewInput: false,
      isClicked: false,
      mesErorr: '',
      departId: '',
      personalId:'',
      show: false,
      register: {
        accountBy: 0,
        email: "",
        language: "",
        password: "",
        realname: "",
        realnameLower: "",
        smscode: "",
        repassword: "",
      },
      validate: {
        email: null,
        password: null,
        realname: null,
        realnameLower: null,
        smscode: null,
        repassword: null,
      },

      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  created() {},
  computed: {
    emailState() {
      this.validate.email = this.reg.test(this.register.email) && this.register.email.length > 0;
      return this.validate.email;
    },
    passwordState() {
      if (this.isClicked) {
        this.validate.password = this.register.password.length > 0;
        return this.validate.password;
      }
      return null;
    },
    repasswordState() {
      if (this.isClicked) {
        this.validate.repassword = this.register.repassword.length > 0  && this.register.password === this.register.repassword;
        if(this.register.password != this.register.repassword){
          this.mesErorr = this.$t("REGISTER.ERROR.RESPASSWORD");
        } else this.mesErorr = '';
        return this.validate.repassword;
      }
      return null;
    },
    realnameState() {
      if (this.isClicked) {
        this.validate.realname = this.register.realname.length > 0;
        return this.validate.realname;
      }
      return null;
    },
    realnameLowerState() {
      if (this.isClicked) {
        this.validate.realnameLower = this.register.realnameLower.length > 0;
        return this.validate.realnameLower;
      }
      return null;
    },
    smscodeState() {
      if (this.isClicked) {
        this.validate.smscode = this.register.smscode.length > 0;
        return this.validate.smscode;
      }
      return null;
    },
  },
  methods: {
    checkInputMail() {
      if (this.reg.test(this.register.email) == false) {
        this.counting = false;
        this.messMail = this.$t("REGISTER.MESSAGE");
        this.show = true;
      } else{
        this.show = false;
      }
    },
    sendMail() {
      if (this.reg.test(this.register.email) == false) {
        this.errorsMail = this.$t("API.FORMAT_MAIL");
        this.counting = false;
        setTimeout(this.timeOut, 2000);
      } else {
        this.counting = true;
        this.timerCount = 60;
        this.isViewInput = true;
        if (this.$CONST.DEV) {
          console.log("mail:", this.register.email, " ==> call API: ", "sys/user/sms");
        } else {
          this.$api.post("sys/user/sms",
          { email: this.register.email ,
            languageType: window.localStorage.getItem('languageCode')
          }).then((response) => { });
        }
      }
    },
    checkInputText: function () {
      if (this.register.register == "" || this.register.repassword == "" || this.register.realname == "" || this.register.realnameLower == "" || this.register.smscode == "") {
        this.alert = true;
        return false;
      } else {
        this.alert = false;
        return true;
      }
    },

    async btnSubmit() {
      this.isClicked = true;
      this.getIdUrl();
      var payLoad = {
        accountBy: this.register.accountBy,
        email: this.register.email,
        language: window.localStorage.getItem("languageCode"),
        password: this.register.password,
        realname: this.register.realname,
        realnameLower: this.register.realnameLower,
        smscode: this.register.smscode,
        sysUserDepartDepId: this.departId,
        personalId: this.personalId,
      };
      var check = this.checkInputText();
      if (check) {
        if (this.emailState && this.passwordState && this.repasswordState && this.realnameState && this.realnameLowerState && this.smscodeState) {
          if (this.$CONST.DEV) {
            console.log("mail:", payLoad, " ==> call API: ", "sys/user/register");
            this.$router.push({ path: "/login" });
          } else {
            await this.$api.post("sys/user/register", payLoad).then((response) => {
              if(response.success == false){
                this.mesErorr = response.message;
                return false;
              }else {
                this.$api.post("userLogin/login", {
                  accountId: this.register.email,
                  password: this.register.password,
                }).then((response) => {
                    if(response.success == true) {
                      this.setLocalStorage(response);
                    }
                    else {
                      this.mesErorr = response.message;
                    }
                })
              }
            }).catch((error) => {
            });
          }
        }
      } else {
        this.$bvToast.toast(this.$t("COMMON.PAYMENT.VALIDATE.CHECK_INPUT"), {
          title: `Errors`,
          solid: true,
          variant: `warning`
        })
      }
    },
    setLocalStorage(response) {
      if (response.success) {
        this.$localStorage.set("token", response.result.token);
        this.$localStorage.set("accountBy", response.result.accountBy);
        this.$localStorage.set("name", response.result.name);
        this.$localStorage.set("nameKana", response.result.nameKana);
        this.$localStorage.set("roleId", response.result.roleId);
        this.$localStorage.set("userId", response.result.userId);
        this.$localStorage.set("userIdCode", response.result.userIdCode);
        this.$localStorage.set("email", response.result.email);
        this.$localStorage.set("registerUrl", response.result.registerUrl);
        this.$localStorage.set("nextPayType", response.result.nextPayType);
        sessionStorage.setItem("loginComplete", 1);

        if(this.$localStorage.get("Answer")){
          let set = JSON.parse(window.localStorage.getItem("Answer"))
          if( set.setOrder== '006') {
            setTimeout(()=>{this.$router.back();}, 1000)
          }else {
            this.$api.post("userLogin/getHasUserDetail").then((response) => {
              if(response.result) {
                setTimeout(()=>{this.$router.back();}, 1000)
              } else {
                this.$localStorage.set("pageBack", '/order');
                this.$router.push({ path: '/mypage', query: { view: '0' } })
              }
            });
          }

        }else {
          this.$router.push({ path: "/home" });
        }
        this.loginFalse = false;
      } else {
        this.loginFalse = true;
        this.loginValue.inputCode = "";
        this.message = response.message;
        this.randomCode = this.makeCode(4);
      }
    },
    getIdUrl(){
      var url =  window.location.href;
      url = url.split('#');
      url = url[0].split('/');
      url = url[url.length-1].split('=');
      if(url[0] == 'userId'){
        this.personalId =  url[1];
      }
      if(url[0] == 'departId'){
        this.departId =  url[1];
      }
    },
    timeOut() {
      this.errors = "";
      this.errorsMail = "";
    },
    checkradio(value) {
      this.radio_click = value;
    },
    goToHome: function () {
        setTimeout(()=>{this.$router.back();}, 1000)
    },
    onCountdownEnd: function () {
      this.counting = false;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.counting = false;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.p-form-login {
  display: flex;
  flex-direction: column;
  background-color: #ffffffa6;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &__input-area {
    font-size: 16px;
    margin: 3rem 5rem 3rem;

    &__btn-mail {
      margin: auto;
      width: 88%;
      height: 45px;
      border-radius: 5px !important;
      font-family: system-ui, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-weight: 600;
    }

    &__radio {
      display: flex;
      justify-content: center;
    }

    .code {
      margin-bottom: 1% !important;
      margin-top: 1rem;
    }
  }

  &__logo {
    background-color: black;

    h3 {
      padding: 4%;
      color: #fff;
    }
  }

  &__more-area {
    text-align: right;
    margin-bottom: 3rem;
    margin-right: 1rem;

    .text {
      color: #000000;
    }

    padding: auto;

    .clickable {
      color: #28a745;
    }
  }
}

p {
  margin-top: -6px;
  width: 70px;
  margin-right: 15px;
  font-size: 26px;
}

.input-group-lg>.form-control:not(textarea) {
  height: calc(2em + 1rem + 2px);
}

.input-group-prepend {
  background: #dee2e6;
  width: 30%;
}

.p-form-login__loginbutton-area {
  margin-bottom: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.p-form-register__form--input__btn {
  width: 30%;
  background: #000000;
  color: #fff;
  font-family: system-ui, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.input-group-text {
  width: 100%;
}

.btn1 {
  background: #fff;
  color: #000000;
}
.message{
  color: var(--success);;
}
@media (max-width: 500px) {
  .p-form-login__input-area .code {
    display: flex;
    align-items: center;
  }

  p {
    width: 100% !important;
    margin-right: 10px;
  }

  .p-form-login__input-area__btn-mail {
    border-radius: 0.25rem !important;
    width: 160px;
    font-size: 15px !important;

  }

  .input-group-prepend {
    width: 100% !important;
    background: none;
  }

  .btn-success {
    margin: auto;
    margin-top: 5%;
  }

  .input-group>.form-control:not(:first-child) {
    width: 100% !important;
  }
}

@media (max-width: 415px) {
  .p-form-login__input-area {
    margin: 3rem 1rem 3rem !important;
  }

  .p-form-login__loginbutton-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    width: 160px;
    margin-bottom: 3%;
  }
}
</style>
