<template>
  <div class="plan">
    <h4 class="plan__content">{{ $t("INDEX.PLAN.CONTENT") }}</h4>
    <h2 class="plan__title">{{ $t("INDEX.PLAN.TITLE") }}</h2>
    <p class="plan__text">{{ $t("INDEX.PLAN.TITLE_TEXT") }}</p>
    <b-card>
      <b-tabs card-header-class="plan__menu-option"
        active-nav-item-class="font-weight-bold text-uppercase bg-newColor text-white" pills card align="center">
        <div class="container">
          <b-tab :title="$t('PLAN.PL54') + $t('MENU.SIM_ORDER')" active>
            <div style="display: flex;align-items: baseline;">
              <h2 class="plan__menu-option__title-service mr-3">{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.PLAN") }} </h2>
              <b>{{ $t("INDEX.PLAN.MONTHLY_FEE") }}</b>
            </div>
            <b-card-text>
              <div class="row plan__layout">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PLAN_BASIC") }}</b></th>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PRICE") }}</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in simPlan" :key="index">
                      <td>{{ options[index].text }}</td>
                      <td>
                        {{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1", { price: item.price }) }} <br>
                        {{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1_TAX", { price: tax_included(item.price) }) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button variant="newColor" class="plan__btn"
                @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.SIM_CARD_ONLY)">{{ $t("BUTTON.CHOOSE")
                }}</b-button>
            </b-card-text>
          </b-tab>
          <b-tab :title=" $t('PLAN.PL55') + $t('MENU.WIFI_SERVICE')">
            <div style="display: flex;align-items: baseline;">
              <h2 class="plan__menu-option__title-service mr-3">{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.SIM_CARD_PURCHASE_DETAILS.PLAN") }} </h2>
              <b>{{ $t("INDEX.PLAN.MONTHLY_FEE") }}</b>
            </div>
            <b-card-text>
              <div class="row plan__layout">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PLAN_BASIC") }}</b></th>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PRICE") }}</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in wifiPlan" :key="index">
                      <td>{{ wifiPlan[index].commodityName }}</td>
                      <td>
                        {{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1", { price: wifiPlan[index].price }) }} <br>
                        {{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1_TAX", { price: tax_included(wifiPlan[index].price) })
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button variant="newColor" class="plan__btn"
                @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.WIFI_ONLY)">{{ $t("BUTTON.CHOOSE")
                }}</b-button>
            </b-card-text>
            <div style="display: flex;align-items: baseline;">
              <h2 class="plan__menu-option__title-service mr-3">{{ $t("RENT_WIFI.TITLE") }}</h2>
              <b>{{ $t("INDEX.PLAN.MONTHLY_FEE") }}</b>
            </div>
            <div class="row plan__layout wifi-plan">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="3" class="content-title"><b>{{ $t("RENT_WIFI.NOTICE") }}</b></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">2{{ $t("PLAN.PL2") }}1{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>1{{ $t("PLAN.PL1") }}2{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥1,180</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">2{{ $t("PLAN.PL2") }}3{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>2{{ $t("PLAN.PL1") }}3{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥1,770</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">3{{ $t("PLAN.PL2") }}4{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>3{{ $t("PLAN.PL1") }}4{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥2,360</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">4{{ $t("PLAN.PL2") }}5{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>4{{ $t("PLAN.PL1") }}5{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥2,950</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">5{{ $t("PLAN.PL2") }}6{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>5{{ $t("PLAN.PL1") }}6{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥3,540</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">6{{ $t("PLAN.PL2") }}7{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>6{{ $t("PLAN.PL1") }}7{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥4,130</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">7{{ $t("PLAN.PL2") }}8{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>7{{ $t("PLAN.PL1") }}8{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥4,720</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">8{{ $t("PLAN.PL2") }}9{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>8{{ $t("PLAN.PL1") }}9{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥5,310</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">9{{ $t("PLAN.PL2") }}10{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>9{{ $t("PLAN.PL1") }}10{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥5,900</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">10{{ $t("PLAN.PL2") }}11{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>10{{ $t("PLAN.PL1") }}11{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥6,490</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">11{{ $t("PLAN.PL2") }}12{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>11{{ $t("PLAN.PL1") }}12{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥7,080</span></span>
                    </td>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">12{{ $t("PLAN.PL2") }}13{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>12{{ $t("PLAN.PL1") }}13{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥7,670</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span v-if="this.$localStorage.get('language') == 'vi'">13{{ $t("PLAN.PL2") }}14{{ $t("PLAN.PL1") }}</span>
                                              <span v-else>13{{ $t("PLAN.PL1") }}14{{ $t("PLAN.PL2") }}<br />{{ $t("SIM_SERVICE.STEP5_PURCHASE_CONFIRMATION.WIFI_PURCHASE_DETAILS.PLAN") }}</span>
                                              <span class="ml-10" style="font-weight: bold">¥8,260</span></span>
                    </td>
                    <td><span class="span-td"><span>1{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥8,850</span><br>(15{{ $t("PLAN.PL1") }}～31{{ $t("PLAN.PL1") }})</span></span>
                    </td>
                    <td><span class="span-td"><span>2{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥15,750</span><br>(60{{ $t("PLAN.PL1") }}61{{ $t("PLAN.PL2") }})</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span>3{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥22,000</span><br>(90{{ $t("PLAN.PL1") }}91{{ $t("PLAN.PL2") }})</span></span>
                    </td>
                    <td><span class="span-td"><span>4{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥27,600</span><br>(120{{ $t("PLAN.PL1") }}121{{ $t("PLAN.PL2") }})</span></span>
                    </td>
                    <td><span class="span-td"><span>5{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥30,600</span><br>(150{{ $t("PLAN.PL1") }}151{{ $t("PLAN.PL2") }})</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="span-td"><span>6{{ $t("PLAN.PL3") }}<br />{{ $t("PLAN.PL53") }}</span><span style="font-size: 12px;"><span class="ml-10"
                            style="font-weight: bold; font-size: 16px;">¥33,600</span><br>(180{{ $t("PLAN.PL1") }}181{{ $t("PLAN.PL2") }})</span></span>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="newColor" class="plan__btn"
              @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.RENT_WIFI)">{{ $t("BUTTON.CHOOSE")
              }}</b-button>
          </b-tab>
          <b-tab :title='$t("MENU.OTP_SERVICE")'>
            <b-card-text>
              <div style="display: flex;align-items: baseline;">
                <h2 class="plan__menu-option__title-service mr-3">{{ $t("INDEX.PLAN.PLAN_BASIC") }}</h2>
                <b>{{ $t("INDEX.PLAN.MONTHLY_FEE") }}</b>
              </div>
              <div class="row plan__layout">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PLAN_BASIC") }}</b></th>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PRICE") }}</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in optpPlan" :key="index">
                      <td>{{ options1[index].text }} <br> {{ options1[index].type }}</td>
                      <td>
                        <p>{{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1", { price: optpPlan[index].price }) }}</p>
                        <p>{{ $t("COMMON.ITEM_TYPE.PRICE_MONTH_STEP1_TAX", {
                            price: tax_included(optpPlan[index].price)
                          })
                        }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-text>
            <b-button variant="newColor" class="plan__btn"
              @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.LIGHT_ONLY)">{{ $t("BUTTON.CHOOSE")
              }}</b-button>
          </b-tab>
          <b-tab :title='$t("MENU.SELL_SERVICE")'>
            <b-card-text>
              <div class="row plan__layout">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.SELL_PHONE.PLAN_BASIC") }}</b></th>
                      <th class="content-title"><b>{{ $t("INDEX.PLAN.PRICE") }}</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p>{{ $t("PLAN.PL45") }}</p>
                        <p>{{ $t("PLAN.PL46") }}</p>
                      </td>
                      <td>
                        <p>{{ $t("PLAN.PL47") }}</p>
                        <p>{{ $t("PLAN.PL48") }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>{{ $t("PLAN.PL49") }}</p>
                        <p>{{ $t("PLAN.PL50") }}</p>
                      </td>
                      <td>
                        <p> {{ $t("PLAN.PL51") }} </p>
                        <p>{{ $t("PLAN.PL52") }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-text>
            <b-button variant="newColor" class="plan__btn"
              @click="goRouter($CONST.BUYER.SIM_PURCHASE_SET.IFM_COMMODITY_SET.GENERAL_MERCHANDISE)">{{ $t("BUTTON.CHOOSE") }}</b-button>
          </b-tab>
        </div>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      taxrate: '',
      options: [],
      simPlan: [],
      optpPlan: [],
      wifiPlan: [],
      simPlanOption: this.$t("PLAN.OPTION1"),
      simPlanOption1: this.$t("PLAN.OPTION2"),
    };
  },
  created() {
    this.getData();
    this.options = this.$CONST.BUYER.SIM_PLAN.IFM_COMMODITY;
    this.options1 = this. $t("SIM_SERVICE.STEP2_CHOOSE_CABLE.IFM_COMMODITY");
    this.getTax();
  },
  methods: {
    goRouter(value) {
      this.toTop();
      console.log("đ", this.simPlanOption);
      this.$localStorage.set("menuItemHeader", value);
      this.$router.push("/order");
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    tax_included: function (value) {
      return parseInt(
        value + (value * parseFloat(this.taxrate)) / 100
      );
    },
    getData() {
      if (this.$CONST.DEV) {
        this.$api.post("simPurchase/init", {
          language: window.localStorage.getItem('languageCode')
        }).then((response) => {
          this.simPlan = response.result.amountPlan;
          this.optpPlan = response.result.optPlans;
          this.wifiPlan = response.result.wifiPlans;
        });
      } else {
        this.$api.post("simPurchase/init", { language: window.localStorage.getItem('languageCode') }).then((response) => {
          this.simPlan = response.result.amountPlan;
          this.optpPlan = response.result.optPlans;
          this.wifiPlan = response.result.wifiPlans;
        });
      }
    },
    getTax() {
      this.$api.post("simPurchase/init", {
        language: window.localStorage.getItem('languageCode')
      }).then((response) => {
        this.taxrate = response.result.taxrate;
      });
    }
  }
};
</script>
<style lang="scss"  scoped>
.plan {
  padding-top: 6%;

  &__btn {
    margin: 0 auto 30px;
    width: 140px;
    font-weight: bold;
  }

  &__btn:hover {
    background-color: var(--success);
  }

  &__layout {
    max-width: 1070px;
    margin: auto;
    justify-content: center;
  }

  &__card {
    &__table {
      width: 65%;
      max-width: 710px;
    }
  }

  &__content {
    color: var(--success) !important;
  }

  &__title {
    font-size: 50px;
    font-weight: 600;
    margin-top: 10px;
    letter-spacing: -1px;
    padding: 20px;
  }

  &__text {
    letter-spacing: normal;
    line-height: 24px;
    font-weight: 400;
    max-width: 650px;
    margin: auto;
    padding-bottom: 3%;
  }

  &__menu-option {

    &__sim-service {
      display: flex;
      justify-content: center;

      &__layout {
        margin: 1% 4.5%;
        min-width: 97px;

        &__sim-layout {
          margin: 2% 12%;
        }
      }

      &__title-name {
        font-size: 30px;
        font-weight: 530;
        color: #ec008c;
      }

      &__title-price {
        color: #ec008c;
        font-size: 15px;
        font-weight: bold;
      }
    }

    &__title-service {
      text-align: left;
      margin: 3% 10%;
    }
  }
}

.span-td {
  display: flex;
  gap: 5%;
  justify-content: center;
}

.plan__menu-option__title-service {
  font-weight: bold;
  background: linear-gradient(#000000 0%, #000000 20%, #2c2828 30%, #726e6e 64%, #5e5252 65%, #938b8b 66%, #726c6c 67%, #a4a4a4 75%, #d2d2d2 80%, #bbbbbb 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

::v-deep li.nav-item,
::v-deep .nav-link.active,
::v-deep .nav-link:hover {
  // height: 60px;
  // width: 96px;
}

.card {
  border: none;
}

::v-deep .card-header {
  max-width: 425px !important;
}

.nav {
  width: 303px;
}

.content {
  border: 1px solid #cbc5c5;
  padding: 10px;
  margin: -1px -21px -1px -10px;
}

.content-text:nth-of-type(2n +1) .content {
  background-color: var(--primary);
}

.content-title {
  color: white;
  background-color: #808080;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #7d7e7e;
  width: 30%;
  vertical-align: middle;
}

.table-bordered tr:nth-of-type(2n) {
  background-color: var(--primary);
}

@media (max-width: 400px) {
  .wifi-plan .table td, .table th {
    padding: 2px;
  }

  ::v-deep li.nav-item {
    width: 55%;
  }

  .span-td {
    width: 90px;
    margin: auto;
  }
}

@media (max-width: 650px) {
  .plan {
    &__title {
      font-size: 30px;
    }

    &__text {
      margin: 20px 10px;
    }
  }
}

@media (max-width: 768px) {
  .span-td {
    gap: 0;
    flex-direction: column;
  }
}

@media (max-width: 1300px) {
  .plan__menu-option__sim-service {
    flex-wrap: wrap;
  }
}
</style>
