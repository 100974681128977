<template>
    <div>
      <section>
        <div class="p-login__container">
          <div class="p-login__container--form">
            <InputForm></InputForm>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import InputForm from "./components/inputForm.vue";
  
  export default {
    components: {
      InputForm,
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .p-login__container {
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 810px;
  }
  
  @media screen and (max-width: 1350px) {
    .p-login__container {
      gap: 20px;
  
      &--img {
        margin-top: 30px;
  
        img {
          width: 80%;
        }
      }
    }
  }
  
  @media screen and (max-width: 1240px) {
    .p-login__container {
      gap: 0;
  
      &--img {
        margin-top: 30px;
  
        img {
          width: 80%;
        }
      }
    }
  }
  
  @media screen and (max-width: 1110px) {
    .p-login__container {
      gap: 0;
  
      &--img {
        width: 45%;
        margin-top: 30px;
  
        img {
          width: 70%;
        }
      }
    }
  }
  
  @media screen and (max-width: 1024px) {
    .p-login__container {
      gap: 0px;
  
      &--img {
        width: 50%;
  
        img {
          max-width: 80%;
        }
      }
    }
  }
  
  @media screen and (max-width: 870px) {
    .p-login__container--img {
      display: none;
    }
  }
  @media screen and (max-width: 550px) {
  .p-login__container {
    align-items: flex-start;
  }
}
  </style>
  