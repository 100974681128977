import Vue from 'vue'

import VueRouter from 'vue-router'
import Default from '@/layouts/default.vue'
import Login from '@/layouts/login/index.vue'
import Err from '@/views/error/Err'
import Suc from '@/views/success/Suc/index.vue'
import Register from '@/views/register/Register'
import OrderResult from '@/views/order/result'
import Mypage from '@/views/mypage/Index/index.vue'
import Home from '@/views/home/Home'
import Inquiry from '@/views/inquiry/inquiry'
import Terms from '@/views/about/terms'
import Privacy from '@/views/about/privacy'
import Operating from '@/views/about/operating'
import Notation from '@/views/about/notation'
import Forget from '@/views/login/ForgetPW/index.vue'

Vue.use(VueRouter)


const routes = [

    {
        path: '/',
        component: Default,
        redirect: 'Home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home
            },
            {
              path: '/payment',
              name: 'Payment',
              component: () =>
                  import ('@/views/order/Payment/index.vue')
            },
            {
                path: '/Order',
                name: 'Order',
                component: () =>
                    import ('@/views/order/Index/index.vue'),
                props: true
            },
            {
                path: '/mypage',
                name: 'Mypage',
                component: Mypage
            },
            {
                path: '/orderResult',
                name: 'orderResult',
                component: OrderResult
            },
            {
                path: '/inquiry',
                name: 'inquiry',
                component: () =>
                    import ('@/views/home/Question.vue')
            },
            {
                path: '/notation',
                name: 'Notation',
                component: Notation
            },
            {
                path: '/operating',
                name: 'Operating',
                component: Operating
            },
            {
                path: '/privacy',
                name: 'Privacy',
                component: Privacy
            },
            {
                path: '/terms',
                name: 'Terms',
                component: Terms
            },
            // SIM
            {
                path: '/details/sim/section1',
                name: 'DetailSS1',
                component: () => import('@/views/details/sim/section1/index.vue')
            },
            {
                path: '/details/sim/section2',
                name: 'DetailSS2',
                component: () => import('@/views/details/sim/section2/index.vue')
            },
            {
                path: '/details/sim/section3',
                name: 'DetailSS3',
                component: () => import('@/views/details/sim/section3/index.vue')
            },
            {
                path: '/details/sim/section4',
                name: 'DetailSS4',
                component: () => import('@/views/details/sim/section4/index.vue')
            },
            {
                path: '/details/sim/section5',
                name: 'DetailSS5',
                component: () => import('@/views/details/sim/section5/index.vue')
            },
            {
                path: '/details/sim/section6',
                name: 'DetailSS6',
                component: () => import('@/views/details/sim/section6/index.vue')
            },
            {
                path: '/details/sim/section52',
                name: 'DetailSS52',
                component: () => import('@/views/details/sim/section52/index.vue')
            },
            {
                path: '/details/sim/section53',
                name: 'DetailSS53',
                component: () => import('@/views/details/sim/section53/index.vue')
            },
            {
                path: '/details/sim/section54',
                name: 'DetailSS54',
                component: () => import('@/views/details/sim/section54/index.vue')
            },
            {
                path: '/details/sim/section55',
                name: 'DetailSS55',
                component: () => import('@/views/details/sim/section55/index.vue')
            },
            // wifi
            {
                path: '/details/wifi/section1',
                name: 'DetailWS1',
                component: () => import('@/views/details/wifi/section1/index.vue')
            },
            {
                path: '/details/wifi/section2',
                name: 'DetailWS2',
                component: () => import('@/views/details/wifi/section2/index.vue')
            },
            {
                path: '/details/wifi/section3',
                name: 'DetailWS3',
                component: () => import('@/views/details/wifi/section3/index.vue')
            },
            // light
            {
                path: '/details/light/section1',
                name: 'DetailLS1',
                component: () => import('@/views/details/light/section1/index.vue')
            },
            {
                path: '/details/light/section2',
                name: 'DetailLS2',
                component: () => import('@/views/details/light/section2/index.vue')
            },
            // servers Q&A
            {
                path: '/details/servers/section1',
                name: 'DetailSvS1',
                component: () => import('@/views/details/servers/section1/index.vue')
            },
            {
                path: '/details/servers/section12',
                name: 'DetailSvS12',
                component: () => import('@/views/details/servers/section12/index.vue')
            },
            {
                path: '/details/servers/section13',
                name: 'DetailSvS13',
                component: () => import('@/views/details/servers/section13/index.vue')
            },
            {
                path: '/details/servers/section2',
                name: 'DetailSvS2',
                component: () => import('@/views/details/servers/section2/index.vue')
            },
            {
                path: '/details/servers/section3',
                name: 'DetailSvS3',
                component: () => import('@/views/details/servers/section3/index.vue')
            },
            {
                path: '/details/servers/section4',
                name: 'DetailSvS4',
                component: () => import('@/views/details/servers/section4/index.vue')
            },
            // vip
            {
                path: '/details/vip/section1',
                name: 'DetailVipS1',
                component: () => import('@/views/details/vip/section1/index.vue')
            },
            {
                path: '/details/vip/section2',
                name: 'DetailVipS2',
                component: () => import('@/views/details/vip/section2/index.vue')
            },
            {
                path: '/details/vip/section3',
                name: 'DetailVipS3',
                component: () => import('@/views/details/vip/section3/index.vue')
            },
            {
                path: '/details/vip/section4',
                name: 'DetailVipS4',
                component: () => import('@/views/details/vip/section4/index.vue')
            },
            {
                path: '/details/vip/section5',
                name: 'DetailVipS5',
                component: () => import('@/views/details/vip/section5/index.vue')
            },
            {
                path: '/details/vip/section52',
                name: 'DetailVipS52',
                component: () => import('@/views/details/vip/section52/index.vue')
            },
            // com
            {
                path: '/details/com/section1',
                name: 'DetailComS1',
                component: () => import('@/views/details/com/section1/index.vue')
            },
            {
                path: '/details/com/section2',
                name: 'DetailComS2',
                component: () => import('@/views/details/com/section2/index.vue')
            },
            {
                path: '/details/com/section3',
                name: 'DetailComS3',
                component: () => import('@/views/details/com/section3/index.vue')
            },
            {
                path: '/details/com/section4',
                name: 'DetailComS4',
                component: () => import('@/views/details/com/section4/index.vue')
            },
            {
                path: '/details/com/section5',
                name: 'DetailComS5',
                component: () => import('@/views/details/com/section5/index.vue')
            },
            {
                path: '/details/com/section6',
                name: 'DetailComS6',
                component: () => import('@/views/details/com/section6/index.vue')
            },
            // production
            {
                path: '/details/pro/section1',
                name: 'DetailProS1',
                component: () => import('@/views/details/pro/section1/index.vue')
            },
            {
                path: '/details/pro/section2',
                name: 'DetailProS2',
                component: () => import('@/views/details/pro/section2/index.vue')
            },
        ]
    },
    {
        path: '/',
        redirect: 'login',
        component: Login,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () =>
                    // import ('@/views/login/Login.vue')
                    import ('@/views/login/Login/index.vue')
            },
            {
                path: '/register',
                name: 'register',
                component: () =>
                    import ('@/views/register/Register/index.vue')
            },
            {
                path: '/forget-password',
                name: 'forget',
                component: Forget,
            },
        ]
    },
    {
        path: '/err',
        name: 'Err',
        component: Err,
    },
    {
        path: '/success',
        name: 'Suc',
        component: Suc,
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.afterEach((to, from) => {
    // ...
    window.scrollTo({
        top: 0,
        // behavior: "smooth"
      });
})

export default router
